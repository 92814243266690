import React from 'react';
import { Box } from '@chakra-ui/core';
import { GoThreeBars, GoX } from 'react-icons/go';

interface INavigationButton {
  isOpen: boolean;
  handleClick: () => void;
}

export default function NavigationButton({
  isOpen,
  handleClick,
}: INavigationButton) {
  return (
    <Box
      onClick={handleClick}
      as={isOpen ? GoX : GoThreeBars}
      size="2rem"
      aria-label={isOpen ? 'Close Menu' : 'Open Menu'}
      color="#EA712D"
      display={{ base: 'block', md: 'none' }}
    />
  );
}
