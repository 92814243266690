import { theme } from '@chakra-ui/core';

export default {
  ...theme,
  fonts: {
    ...theme.fonts,
    body: 'Montserrat,Helvetica, Arial, sans-serif',
    heading: 'Montserrat, sans-serif',
  },
  fontSizes: {
    ...theme.fontSizes,
    normal: '1.125rem',
  },
  colors: {
    ...theme.colors,
    brand: {
      primary: '#f38530', // orange
      secondary: '#083261', // dark blue
      lightBlue: '#a8deec', // light blue
    },
  },
};
