import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import withHelmet from '../HOC/withHelmet';
import useVerifyRegistration from 'hooks/useVerifyRegistration';

function SocialSignUpPage() {
  const history = useHistory();
  const { user, verifying } = useVerifyRegistration();

  useEffect(() => {
    if (user) {
      history.push({
        pathname: 'signup/subscription',
        state: {
          userInfo: {
            lname: user.last_name,
            fname: user.first_name,
            email: user.username,
          },
          signUpMethod: 'social',
        },
      });
    }

    if (!verifying && !user) {
      history.push({
        pathname: 'signup/subscription',
        state: {
          signUpMethod: 'normal',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifying, user]);

  return <div>Loading...</div>;
}

export default withHelmet(SocialSignUpPage, 'Create account');
