// define type
export type Book = {
  id: number;
  title: string;
  author: string;
  illustrator: string;
  pageCount: number;
  pageWidth: number;
  pageHeight: number;
  description: string;
  free: boolean;
  ageRanges: string[];
  categories: string[];
};

let books: Book[] = [
  {
    id: 509,
    title: '¡Vivamos la granja!',
    author: 'Jan Dobbins',
    illustrator: 'Laura Huliska-Beith',
    pageCount: 28,
    pageWidth: 1200,
    pageHeight: 1162,
    description:
      'A busy family and their friends spend a day working and playing on the farm. From milking the cows in the morning to closing the gate at night, learn about a day in the life of a farming family. Includes educational endnotes on farming.',
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['Spanish', 'Animals', 'Family'],
  },
  {
    id: 298,
    title: 'Will You Help Doug Find His Dog?',
    author: 'Jane Caston',
    illustrator: 'Carmen Saldaña',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1200,
    description:
      "Help Doug find his dog by patting, tickling and calling to the correct dogs based on Doug's clues! As the reader selects dogs, the pool narrows until a surprise ending reveals Doug's dog — and why he went missing. Featuring hilarious illustrations of funny dogs galore, this text is perfect for collaborative story time with beginning and reluctant readers.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Animals'],
  },
  {
    id: 129,
    title: "Who's in the Forest?",
    author: 'Phillis Gershator',
    illustrator: 'Jill McDonald',
    pageCount: 28,
    pageWidth: 600,
    pageHeight: 600,
    description:
      'From birds in the treetops ("cheep, cheep, cheep") to bear cubs tumbling in a "heap, heap, heap," your young explorer can learn all about "who\'s in the forest, dark and deep?"',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Science & Nature', 'Animals'],
  },
  {
    id: 512,
    title: 'Wheels Around',
    author: 'Shelley Rotner',
    illustrator: 'Shelley Rotner',
    pageCount: 34,
    pageWidth: 1200,
    pageHeight: 1403,
    description:
      'We know what a wheel is, but do we really know all that it does? Everywhere you look, there are wheels - wheels that deliver things, wheels that help people do their jobs, and wheels that help build and fix things. Through lively, colorful photographs and simple text, Wheels Around depicts the many uses of wheels in everyday life - in the country and the city, at work and at play.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Transportation', 'Jobs', 'Non-Fiction'],
  },
  {
    id: 453,
    title: 'Well Done, Little White Fish',
    author: 'Guido Van Genechten',
    illustrator: 'Guido Van Genechten',
    pageCount: 24,
    pageWidth: 1200,
    pageHeight: 1265,
    description:
      'Little White Fish watches his friends do all sorts of things: cutting sea grass, doing somersaults, lighting up the dark, spitting out clouds of ink... Is there something special that Little White Fish can do?\n\nA playful and colorful book about discovering your talents.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Animals'],
  },
  {
    id: 308,
    title: 'Welcome Home Beaver',
    author: 'Magnus Weightman',
    illustrator: 'Magnus Weightman',
    pageCount: 40,
    pageWidth: 1200,
    pageHeight: 1650,
    description:
      "Beaver leaves his riverside home to seek adventure. When he gets lost, Akita helps him find his way home again, but what does Beaver's home look like? Does he live in a nest, hanging in a tree? Or in a hiding place at the bottom of the sea? Beaver and Akita set off in a hot-air balloon to travel the world and find their way home.\n\nThe illustrator and architect Magnus Weightman takes you on a tour around the world visiting all kinds of fascinating animal houses. For children ages 4 and up, and for everyone who loves to explore and who loves animals.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Poems & Rhyming', 'Animals', 'Friendship'],
  },
  {
    id: 130,
    title: "We're Roaming in the Rainforest",
    author: 'Laurie Krebs',
    illustrator: 'Anne Wilson',
    pageCount: 44,
    pageWidth: 600,
    pageHeight: 581,
    description:
      'Colorful collage illustrations and rhyming text introduce children to chatter monkeys, poison dart frogs, leaf-cutter ants and other creatures of the Amazon.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Science & Nature', 'Travel', 'Animals'],
  },
  {
    id: 131,
    title: 'We All Went on Safari',
    author: 'Laurie Krebs',
    illustrator: 'Julia Cairns',
    pageCount: 36,
    pageWidth: 600,
    pageHeight: 581,
    description:
      'Meet Arusha, Mosi and Tumpe, who gather their Masaai friends as they go, and guide us through the Tanzanian terrain. They count from one "lonely leopard" ("moja" in Swahili) to 10 "enormous elephants" ("kumi").',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Science & Nature', 'Travel', 'Animals'],
  },
  {
    id: 262,
    title: 'We All Go Traveling By',
    author: 'Sheena Roberts',
    illustrator: 'Siobhan Bell',
    pageCount: 28,
    pageWidth: 1200,
    pageHeight: 1162,
    description:
      "This rhythmic I-spy journey to school through various landscapes is the perfect introduction to colors, modes of transport, and of course, music! The jaunty text, Siobhan Bell's colorful hand-stitched illustrations and the accompanying music by popular singer Fred Penner are sure to get children singing along happily. The I-Spy theme encourages reader/listener interaction, while the cumulative, repetitive text helps build sequencing skills. Ideal for read-aloud and music and movement activities.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Transportation', 'Travel', 'Poems & Rhyming'],
  },
  {
    id: 28,
    title: 'Wave',
    author: 'Suzy Lee',
    illustrator: 'Suzy Lee',
    pageCount: 44,
    pageWidth: 600,
    pageHeight: 350,
    description:
      "This wordless picture book captures a girl's joyful day at the beach. First, she tips a toe in the water. Next, she courageously teases a wave in a delightful dance that will have even youngest children telling their own version of the story.",
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Science & Nature', 'Family'],
  },
  {
    id: 430,
    title: 'Water Can Be . . .',
    author: 'Laura Purdie Salas',
    illustrator: 'Violeta Dabija',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1232,
    description:
      '"Water can be a . . . thirst quencher, kid drencher, cloud fluffer, fire snuffer.\nFind out about the many roles water plays in this poetic exploration of water throughout the year."',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Science & Nature'],
  },
  {
    id: 112,
    title: 'Walk The Dog',
    author: 'Bob Barner',
    illustrator: 'Bob Barner',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 578,
    description:
      'What could be more fun that learning your ABCs with adorable canines from Airedale to Zwergpinscher? Bulldogs, Chihuahuas and Dachshunds "run,run," "lick, lick," and "sniff, sniff" as they playfully teach children the alphabet.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Animals'],
  },
  {
    id: 217,
    title: 'Vicks, the Polar Bear Cub',
    author: 'Mack',
    illustrator: 'Mack',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 624,
    description:
      "Follow Vicks' journey, the true story of  a brand-new polar cub born in the Rotterdam Zoo as he takes his first swim, learns to fish, and turns one!  Simple, beautiful line drawings and photographs chronicle Vicks transformation into a fully grown polar bear.",
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Science & Nature', 'Animals'],
  },
  {
    id: 448,
    title: 'Uso Obligatorio de Cascos en Esta Área',
    author: 'Susan L. Roth',
    illustrator: 'Susan L. Roth',
    pageCount: 40,
    pageWidth: 1200,
    pageHeight: 1403,
    description:
      'Una mirada única a un dia en la vida de una niña real llamada Kristen, que trabaja como aprendiz de herrero en algunos de los edificios más altos de la ciudad de Nueva York. Siguiendo a Kristen por el área de construcción, los lectores aprenderán que herramientas usan los trabajadores, como los usan, y cómo trabajan en equipo. Con una gran variedad de materiales, etiquetas, y fotos del sitio actual donde trabajó Kristen, Susan L. Roth ha creado una obra maestra de collage creativo y una gran experiencia de aprendizaje para lectores de todas edades.',
    free: false,
    ageRanges: ['9 - 11 yr'],
    categories: ['Spanish', 'Jobs'],
  },
  {
    id: 510,
    title: 'Un regalo para Amma',
    author: 'Meera Sriram',
    illustrator: 'Mariona Cabassa',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1511,
    description:
      'In this #OwnVoices book inspired by the author’s hometown of Chennai, India, a girl explores the vibrant rainbow of delights in a southern Indian street market as she searches for a gift for her amma (mother). Educational endnotes explain all the items on sale and introduce readers to markets around the world.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Spanish', 'Travel', 'Family'],
  },
  {
    id: 103,
    title: 'Turkey Bowl',
    author: 'Phil Bildner',
    illustrator: 'C.F. Payne',
    pageCount: 36,
    pageWidth: 600,
    pageHeight: 771,
    description:
      "This Thanksgiving, nine-year-old Ethan is finally old enough to play in his family's annual Turkey Bowl. But he wakes up on the holiday to a road-closing blizzard! Will he ever get to play?\n\nPrinted with permission of Simon & Schuster Children's Publishing, a Division of Simon & Schuster Inc.",
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Sports', 'Family'],
  },
  {
    id: 433,
    title: 'Tobor',
    author: 'Guido Van Genechten',
    illustrator: 'Guido Van Genechten',
    pageCount: 40,
    pageWidth: 1200,
    pageHeight: 979,
    description:
      "Ben was content playing with his animal friends, but he was beginning to get tired of the same thing every day. On his fifth birthday, a new friend named Tobor arrives. Tobor sure is different from all of Ben's other friends. Tobor is full of ideas and has nonstop energy. At first Ben is happy to play along, but then he begins to wonder if his new friend is to much. Will these two new friends find happiness together? Turn on the switch and find out!",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Friendship'],
  },
  {
    id: 263,
    title: 'To Catch an Elephant',
    author: 'Vanessa Westgate',
    illustrator: 'Vanessa Westgate',
    pageCount: 40,
    pageWidth: 1200,
    pageHeight: 1248,
    description:
      '"Laugh out loud - and learn!\n\n“If you want to catch an elephant, first you must find out where elephants live . . .” \nSo begins this tale of a boy and his quest for a pet elephant. \nBut maybe catching an elephant isn’t as simple as it sounds . . . \n\nA delightful and compassionate story for elephant lovers ages 4 years and up."',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Animals', 'Friendship'],
  },
  {
    id: 59,
    title: 'Tippy-Tippy-Tippy, Hide!',
    author: 'Candace Fleming',
    illustrator: 'G. Brian Karas',
    pageCount: 40,
    pageWidth: 600,
    pageHeight: 720,
    description:
      "The three bunnies from Muncha! Muncha! Muncha! are back! This time seeking shelter with Mr. McGreeley for the winter. Will he keep them out? Or will they keep him in?\n\nPrinted with permission of Simon & Schuster Children's Publishing, a Division of Simon & Schuster Inc.",
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Science & Nature', 'Animals'],
  },
  {
    id: 464,
    title: 'They Swim the Seas',
    author: 'Seymour Simon',
    illustrator: 'Elsa Warnick',
    pageCount: 40,
    pageWidth: 1200,
    pageHeight: 1406,
    description:
      'The oceans of the world are busy highways for creatures on the move. Plankton - small plants and animals, some so tiny they cannot be seen without a microscope - travel up to the surface and then down more than three thousand feet twice a day; eight-hundred-pound green turtles swim more than a thousand miles to lay their eggs; and spiny lobsters, forming long lines, walk across the ocean bottom.',
    free: false,
    ageRanges: ['9 - 11 yr'],
    categories: ['Science & Nature', 'Animals', 'Non-Fiction'],
  },
  {
    id: 277,
    title: 'Thesaurus Rex',
    author: 'Laya Steinberg',
    illustrator: 'Debbie Harter',
    pageCount: 28,
    pageWidth: 1200,
    pageHeight: 1162,
    description:
      "This playful dinosaur will \"slither, skid, slide and glide\" his way into children's hearts! Encourage children to explore synonyms with the loveably cheeky Thesaurus Rex, who has an irresistible way with words. Action-verb and noun synonyms feature throughout Laya Steinberg's energetic text which, paired with award-winning Debbie Harter's vibrant watercolors, will engage boys and girls alike. The alliteration, repetition and rhyme featured in this delightful book will also help to foster speaking and listening skills.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Bedtime', 'Animals', 'Poems & Rhyming'],
  },
  {
    id: 442,
    title: 'The World of Whales - Get to Know the Giants of the Ocean',
    author: 'Darcy Dobell',
    illustrator: 'Becky Thorns',
    pageCount: 74,
    pageWidth: 1200,
    pageHeight: 1400,
    description:
      "You already know that whales aren't fish, but did you know they are mammals, just like us humans, who need to hold their breath under water? In The World of Whales we explore many interesting facts about these fantastic animals, looking at the habits and characteristics that make the many species of this family so special. From the stealthy orcas that traverse the seas, to the beautiful songs of humpbacks and the booming calls of the blue whales, we immerse ourselves in the lives of these grandiose swimmers. Join our subaquatic expedition to follow agile fins and flukes, learn about their routines, and marvel as these marine giants breach the ocean’s surface.\n\nWildlife lover Darcy Dobell is the author of many scientific textbooks and articles aimed at younger readers, and advises organizations on how to protect the oceans.\n\nBritish illustrator Becky Thorns has an inquisitive design style inspired by her travels. The World of Whales is her first picture book",
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Science & Nature', 'Chapter Books', 'Animals', 'Non-Fiction'],
  },
  {
    id: 390,
    title: 'The Wooden Camel',
    author: 'Wanuri Kahiu',
    illustrator: 'Manuela Adreani',
    pageCount: 40,
    pageWidth: 1200,
    pageHeight: 1200,
    description:
      'Etabo dreams of being a camel racer. One day he might even beat his older brother when they race. But with the price of water rising, Etabo’s father must sell the camels, and his siblings must find work. What will Etabo do now? From acclaimed Kenyan filmmaker Wanuri Kahiu and Italian illustrator Manuela Adreani, this story of love and hope centers on the inspiring Turkana people of northwest Kenya. Told with gentleness and humor, it is a universal story about keeping one’s dreams alive.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Family'],
  },
  {
    id: 327,
    title: 'The Universe',
    author: 'Danielle Futselaar',
    illustrator: 'Danielle Futselaar',
    pageCount: 112,
    pageWidth: 1200,
    pageHeight: 1563,
    description:
      'Are you ready for the journey of a lifetime? Jump on board of the Mars Cruiser with Luke and Maya, and travel to a different planet! While the earth is getting smaller and smaller and the stars shoot past the window, you’ll discover all the secrets of the universe. From planets to supernovas and from black holes to aliens: this will be an unforgettable journey!',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Science & Nature', 'Travel', 'Non-Fiction'],
  },
  {
    id: 473,
    title: 'The Truth About Dinosaurs',
    author: 'Guido Van Genechten',
    illustrator: 'Guido Van Genechten',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1660,
    description:
      'Everyone knows that dinosaurs are extinct. But are they really? What if we told you that they’re still alive, and that some of them even live among us! You need proof? Well, here is an authentic photo album from a long, long time ago that’s now being shared for the very first time. Discover the long-withheld truth in this incredible but true story about DINOSAURS.\n\nA funny and illuminating tour of the wonderful world of the dinosaurs. For anyone ages 4 to 250 million years.',
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Science & Nature', 'Animals'],
  },
  {
    id: 102,
    title: 'The Tiny Seed',
    author: 'Eric Carle',
    illustrator: 'Eric Carle',
    pageCount: 36,
    pageWidth: 600,
    pageHeight: 845,
    description:
      "Sometimes being tiny has its advantages. Glorious collage illustrations show a hungry bird and mouse overlooking the tiny seed and its journey through the seasons. But will the tiny seed ever bloom?\n\nPrinted with permission of Simon & Schuster Children's Publishing, a Division of Simon & Schuster Inc.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Science & Nature'],
  },
  {
    id: 293,
    title: 'The Tiny Baker',
    author: 'Hayley Barrett',
    illustrator: 'Alison Jay',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1162,
    description:
      'Enter a whimsical world, created in vivid detail with stunning illustrations by Alison Jay and clever, lyrical rhymes. Insect customers clamor to dine at the Tiny Baker’s cafe, but when the ladybug chefs fly off and upend the pristine bakery’s kitchen, the Tiny Baker learns an important lesson about friendship.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Health', 'Family', 'Jobs'],
  },
  {
    id: 490,
    title: 'The Sweetwater Run',
    author: 'Andrew Glass',
    illustrator: 'Andrew Glass',
    pageCount: 50,
    pageWidth: 1200,
    pageHeight: 1590,
    description:
      'In 1860 the only Pony Express job 13-year-old Will Cody could land was the "sweat and water run," taking care of the tired and thirsty ponies. But one chilly November morning, Will has his big chance. The news of the U.S. presidential election has been entrusted to the pony boys, and from the looks of it, only Will would be able to get the mail through. But should he risk his own safety and the wrath of his boss, the Terrible Slade, and ride himself?',
    free: false,
    ageRanges: ['9 - 11 yr'],
    categories: ['Non-Fiction'],
  },
  {
    id: 216,
    title: 'The Seesaw',
    author: 'Judith Koppens',
    illustrator: 'Eline van Lindenhuizen',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 629,
    description:
      "Welcome to Animal Square, where Giraffe lives with his friends, Mouse, Monkey, Dog, Rabbit and Kitty.  One day, Giraffe decides to play on the seesaw, but he runs into a problem: he can't play on the seesaw by himself, and all of his friends are too light! Read along as the friends work together to devise a plan to help Giraffe.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Fairy Tales', 'Friendship'],
  },
  {
    id: 140,
    title: 'The Real Princess',
    author: 'Brenda Williams',
    illustrator: 'Sophie Fatus',
    pageCount: 44,
    pageWidth: 600,
    pageHeight: 756,
    description:
      "Primo the prince must marry a real princess, but how will he know if she's real? The Queen, has a plan (and a secret) involving a golden pea. A fun counting quiz follows the tale.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Numbers & Alphabet', 'Fairy Tales'],
  },
  {
    id: 54,
    title: 'The Racecar Alphabet',
    author: 'Brian Floca',
    illustrator: 'Brian Floca',
    pageCount: 40,
    pageWidth: 600,
    pageHeight: 679,
    description:
      'Racecars dating back as far as the turn of the 20th century and a very simple text lead youngest car-lovers through their ABCs. "Automobiles--machines on wheels," line up at the starting line. "Belts turning, fuel burning,...The flap of a flag--a race begins!" The cars also appear in numerical order (1, 2, 3, etc.).\n\nPrinted with permission of Simon & Schuster Children\'s Publishing, a Division of Simon & Schuster Inc.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Transportation'],
  },
  {
    id: 141,
    title: 'The Princess and the White Bear King',
    author: 'Tanya Robyn Batt',
    illustrator: 'Nicoletta Ceccoli',
    pageCount: 44,
    pageWidth: 600,
    pageHeight: 600,
    description:
      'In the far North, a princess dreams of a golden crown. Her search for the dream crown leads her to the White Bear King. If your child likes Beauty and the Beast, he or she will find this folktale captivating.',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Fairy Tales'],
  },
  {
    id: 165,
    title: 'The Little Match Girl',
    author: 'Hans Christian Andersen',
    illustrator: 'Debbie Lavreys',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 624,
    description:
      'The welcoming images of what the Little Match Girl sees when she lights her matches to keep warm, help children to accept her journey to heaven to be with her grandmother.',
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Fairy Tales', 'Family'],
  },
  {
    id: 354,
    title: 'The Jellybeans and the Big Book Bonanza',
    author: 'Laura Numeroff and Nate Evans',
    illustrator: 'Lynn Munsinger',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1459,
    description:
      'This energizing book promotes a love of reading by proving that there is the perfect book for everyone. Just as jellybeans are all different flavors but go great together, the girls again use their different strengths and talents to work as a team and make their school Book Bonanza a success.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Animals', 'Friendship'],
  },
  {
    id: 139,
    title: 'The Great Race',
    author: 'Dawn Casey',
    illustrator: 'Anne Wilson',
    pageCount: 36,
    pageWidth: 600,
    pageHeight: 756,
    description:
      '2011 is the Year of the Hare, according to the Chinese Zodiac. Did you ever wonder why each year was named for an animal? Find out in this colorful folktale-and discover the animal with your birth date! ',
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Fairy Tales', 'Animals'],
  },
  {
    id: 291,
    title: 'The Girl with a Brave Heart',
    author: 'Rita Jahanforuz',
    illustrator: 'Vali Mintzi',
    pageCount: 44,
    pageWidth: 1200,
    pageHeight: 1511,
    description:
      "Young Shiraz lives a lonely life with her stepmother and stepsister, completely unaware of the old woman who lives alone next door...until Shiraz's runaway yarn ball brings them face-to-face. This beautiful story from Tehran-born Israeli pop star Rita Jahanforuz explores the power of listening to others and the value of empathy.",
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Family'],
  },
  {
    id: 16,
    title: 'The Gigantic Turnip',
    author: 'Aleksei Tolstoy',
    illustrator: 'Niamh Sharkey',
    pageCount: 44,
    pageWidth: 600,
    pageHeight: 755,
    description:
      "In this retelling of the classic folktale, an old man and an old woman keep a wide array of animals. They also sow, tend, and harvest all of their vegetables--except for one gigantic turnip that won't budge. Who will help?",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Fairy Tales', 'Science & Nature', 'Animals'],
  },
  {
    id: 72,
    title: 'The Fathers Are Coming Home',
    author: 'Margaret Wise Brown',
    illustrator: 'Stephen Savage',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 600,
    description:
      'The author of Goodnight Moon wrote this story to honor World War II fathers returning to their families. In glorious linoprints, "the fish father swims home to his little fish," and "the pig father wallows over to his little piglets."\n\nPrinted with permission of Simon & Schuster Children\'s Publishing, a Division of Simon & Schuster Inc.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Science & Nature'],
  },
  {
    id: 14,
    title: 'The Dot',
    author: 'Peter H. Reynolds',
    illustrator: 'Peter H. Reynolds',
    pageCount: 32,
    pageWidth: 576,
    pageHeight: 538,
    description:
      'Vashti believes she cannot draw. "Just make a mark and see where it takes you," her teacher tells her. Vashti makes a dot; her teacher tells her to sign it. The next day, Vashti\'s teacher has framed Vashti\'s dot, and Vashti is on her way. ',
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Family', 'Poems & Rhyming'],
  },
  {
    id: 13,
    title: 'The Circus Ship',
    author: 'Chris Van Dusen',
    illustrator: 'Chris Van Dusen',
    pageCount: 40,
    pageWidth: 600,
    pageHeight: 624,
    description:
      'The opening lines set the tone for this exciting adventure: "Five miles off the coast of Maine and slightly overdue, a circus ship was steaming south in fog as thick as stew." Sumptuous illustrations show the 15-creature crew post-shipwreck, after they swim ashore to a small island.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Fairy Tales', 'Animals', 'Poems & Rhyming', 'Friendship'],
  },
  {
    id: 108,
    title: 'The Christmas Baby',
    author: 'Marion Dane Bauer',
    illustrator: 'Richard Cowdrey',
    pageCount: 34,
    pageWidth: 600,
    pageHeight: 600,
    description:
      "As an unseen narrator poetically retells the Nativity story, he or she likens the smile of the baby Jesus to the smile of the child listening. Even the animals in the stable appear to be touched by this Holy night.\n\nPrinted with permission of Simon & Schuster Children's Publishing, a Division of Simon & Schuster Inc.",
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Family'],
  },
  {
    id: 109,
    title: 'The Child in the Manger',
    author: 'Liesbet Slegers',
    illustrator: 'Liesbet Slegers',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 434,
    description:
      'Christmas is the day that "celebrates the birthday of Jesus." This simple retelling of the Nativity story with bold, bright colors and child-like drawings is just right for preschoolers',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Family'],
  },
  {
    id: 61,
    title: 'The Boy Who Painted Dragons',
    author: 'Demi',
    illustrator: 'Demi',
    pageCount: 52,
    pageWidth: 600,
    pageHeight: 600,
    description:
      'The phrase "pearls of wisdom" take on a whole new meaning when Ping, who paints dragons out of fear, gains the courage to stand before the legendary beasts and accept their gifts.\n\nPrinted with permission of Simon & Schuster Children\'s Publishing, a Division of Simon & Schuster Inc.',
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Fairy Tales'],
  },
  {
    id: 292,
    title: 'The Boy Who Grew Flowers',
    author: 'Jen Wojtowicz',
    illustrator: 'Steve Adams',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1511,
    description:
      "Quiet Rink always sits at the back of the classroom, away from the other children who have heard strange rumors about his family and prefer to keep their distance. But when a kind new girl joins his class, Rink's life begins to change. Inspired by the experiences of her brother, who is on the autism spectrum, Jen Wojtowicz's heartwarming tale encourages children to empathize with and reach out to others.",
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Family', 'Friendship'],
  },
  {
    id: 273,
    title: 'The Boy and the Egg',
    author: 'Ellen Delange',
    illustrator: 'Martina Heiduczek',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1646,
    description:
      "A boy finds an abandoned egg and decides to take care of it. He wonders what is growing inside. Could it be the friend he has in mind?\n\nA sweet story about caring and dealing with expectations.\nFor children ages 4 and up, and for everyone who's curious!",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Animals'],
  },
  {
    id: 267,
    title: 'The Book Tree',
    author: 'Paul Czajak',
    illustrator: 'Rashin Kheiriyeh',
    pageCount: 40,
    pageWidth: 1200,
    pageHeight: 1511,
    description:
      'When young Arlo accidentally drops a book on the Mayor’s head, the Mayor decides books are dangerous and destroys all the books in town! But thanks to Arlo’s imagination and perseverance, the Mayor finds that suppressing stories cannot stop them from blossoming more beautifully than ever. This timely allegorical tale will be a useful tool for starting conversations with children about the power of activism and the written word.',
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['Fairy Tales'],
  },
  {
    id: 215,
    title: 'The Birthday Surprise',
    author: 'Ann Lootens',
    illustrator: 'Linde Faas',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 624,
    description:
      'Nina notices that everyone is acting very strangely in the Wild Woods one day.  No one has time for her - not Aunt Dora, and not even any of her animal friends!  Nina soon discovers that everyone has been busy planning a delightful surprise...',
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Family', 'Friendship'],
  },
  {
    id: 12,
    title: 'The Birthday Box',
    author: 'Leslie Patricelli',
    illustrator: 'Leslie Patricelli',
    pageCount: 28,
    pageWidth: 600,
    pageHeight: 600,
    description:
      'A birthday boy receives a "big brown box" from Grandma! He stands on his box, he plays hide and seek with the box. But wait! There\'s something inside! A dog the boy names Oscar. Now they can both play inside the box! ',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Fairy Tales', 'Animals', 'Friendship'],
  },
  {
    id: 167,
    title: 'The Big Sleep Book',
    author: 'Guido van Genechten',
    illustrator: 'Guido van Genechten',
    pageCount: 22,
    pageWidth: 600,
    pageHeight: 629,
    description:
      'After showing the way many different animals go to sleep, this sweetly illustrated book depicts Josh in his bed with his teddy bears.',
    free: false,
    ageRanges: ['0 - 2 yr'],
    categories: [],
  },
  {
    id: 283,
    title: 'The Big Five',
    author: 'Bella Makatini',
    illustrator: 'Judi Abbot',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1248,
    description:
      'Danny is spending the week with his grandpa. Grandpa has a painting of five animals. “Those are the big five,” says Grandpa, “the most famous animals from my homeland,” Grandpa tells Danny about one animal each day.\n\nA playful and informative story about wild animals and about the love between a child and a grandparent. For animal lovers ages 3 and up.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Numbers & Alphabet', 'Family', 'Animals'],
  },
  {
    id: 195,
    title: 'The Big Eating Book',
    author: 'Guido van Genechten',
    illustrator: 'Guido van Genechten',
    pageCount: 22,
    pageWidth: 600,
    pageHeight: 629,
    description:
      'Josh learns that everyone has to eat, humans and animals alike! While some animals enjoy veggies and leaves, others crave cheese, hunt for nuts and acorns, or sip from flowers.  Josh eats his own colorful, delicious meal along with three desserts!',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Animals', 'Non-Fiction'],
  },
  {
    id: 151,
    title: 'The Big Baby Book',
    author: 'Guido van Genechten',
    illustrator: 'Guido van Genechten',
    pageCount: 22,
    pageWidth: 600,
    pageHeight: 629,
    description:
      'As Josh waits for their new baby to be born, his mother tells him, "everybody comes into this world a small baby"--lambs, puppies, chicks. Josh, too, was a "small baby" but by story\'s end, he\'s a "big brother!"',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Family'],
  },
  {
    id: 257,
    title: 'The Beeman',
    author: 'Laurie Krebs',
    illustrator: 'Valeria Cis',
    pageCount: 44,
    pageWidth: 1200,
    pageHeight: 1162,
    description:
      'Find out where honey comes from as Grandpa the Beeman teaches the basics of beekeeping to his young grandson. This rhyming story includes 7 pages of educational endnotes full of essential facts about bees, beekeeping, honey, and the vital part that bees play in the natural world.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: [
      'Science & Nature',
      'Poems & Rhyming',
      'Family',
      'Animals',
      'Jobs',
    ],
  },
  {
    id: 402,
    title:
      'The Baseball Adventure of Jackie Mitchell, Girl Pitcher vs. Babe Ruth',
    author: 'Patrick, Jean L. S.',
    illustrator: 'Hammond, Ted',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1774,
    description:
      'During the biggest game of her life . . . a girl pitches to the world’s best slugger.\n\nChattanooga, Tennessee, 1931. Jackie Mitchell is a girl pitcher on a minor-league baseball team, the Chattanooga Lookouts. In her day, few women played sports. But her skill earned her a spot on a men’s team. When the New York Yankees come to town, Jackie must face Babe Ruth at the plate. Can she strike out one of the greatest players in baseball?',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Sports', 'Non-Fiction'],
  },
  {
    id: 110,
    title: 'The Animal Boogie',
    author: 'Debbie Harter',
    illustrator: 'Debbie Harter',
    pageCount: 36,
    pageWidth: 600,
    pageHeight: 581,
    description:
      '"Down in the jungle, come if you dare! What can you see shaking here and there?" Who could resist an invitation like that? A rhyming couplet and a visual clue hint at which animal will boogie down next!',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Animals'],
  },
  {
    id: 288,
    title: 'Thankyouplease ',
    author: 'Pierre Winters',
    illustrator: 'Barbara Ortelli',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1656,
    description:
      'Nina is a young girl of six who is often grumpy and impolite. Her lack of respect and compassion earns her a visit to the circus of Mr. Thankyouplease, where the clever ringmaster tries to teach Nina some good manners. This picture book makes a plea for respect and politeness and explains how kind words and thoughtful manners can make life more pleasant.',
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['Family'],
  },
  {
    id: 495,
    title: 'Teachers and What They Do',
    author: 'Liesbet Slegers ',
    illustrator: 'Liesbet Slegers ',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1248,
    description:
      'We go to school to learn all kinds of things. Our teacher does arts and crafts with us or reads us books. Teacheers also teach the bigger kids to read and write. And they do so much more …\n\nA reassuring, informative book for children about teachers and schools.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Jobs', 'Non-Fiction'],
  },
  {
    id: 517,
    title: 'Taking Time',
    author: 'Jo Loring-Fisher',
    illustrator: 'Jo Loring-Fisher',
    pageCount: 34,
    pageWidth: 1200,
    pageHeight: 1309,
    description:
      "Taking time to listen to a bird's song on the breeze. Taking time to gather up the blossom dancing free. Taking time to imagine the deep sounds of the sea. Taking time to cherish you . . . and cherish me.\n\nThis poem is inspired by principles of mindfulness and invites children around the world to experience the wonders of nature and home.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Travel', 'Poems & Rhyming'],
  },
  {
    id: 69,
    title: 'Sylvester and the Magic Pebble',
    author: 'William Steig',
    illustrator: 'William Steig',
    pageCount: 40,
    pageWidth: 600,
    pageHeight: 791,
    description:
      "Sylvester the donkey collects pebbles, but he never dreamed he?d discover one that grants wishes. Then, in a panic, he makes a wish on the magic pebble and wishes he hadn't. Can he undo it? Find out in this Caldecott Medal -- winning picture book adventure.\n\nPrinted with permission of Simon & Schuster Children's Publishing, a Division of Simon & Schuster Inc.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Fairy Tales', 'Family'],
  },
  {
    id: 474,
    title: 'Sweet Dreams, Zaza',
    author: 'Mylo Freeman',
    illustrator: 'Mylo Freeman',
    pageCount: 24,
    pageWidth: 1200,
    pageHeight: 1256,
    description:
      'Zaza is almost ready to go to sleep . . .\nBut first, she wishes all of her animal friends good night.\n\nA perfect story to read at bedtime, or anytime!',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Bedtime'],
  },
  {
    id: 32,
    title: 'Sweet Dreams, Maisy',
    author: 'Lucy Cousins',
    illustrator: 'Lucy Cousins',
    pageCount: 22,
    pageWidth: 600,
    pageHeight: 520,
    description:
      'After a wonderful day outside playing with her friends, it\'s time to call it a day. Maisy reads a story and sings a lullaby to Panda. The lyrical text has a calming effect: "The moon rises. The stars begin to shine. The world is going to sleep." ',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Bedtime', 'Friendship'],
  },
  {
    id: 118,
    title: 'Suzy Goose and the Christmas Star',
    author: 'Petr Horacek',
    illustrator: 'Petr Horacek',
    pageCount: 40,
    pageWidth: 600,
    pageHeight: 661,
    description:
      "The Christmas tree is missing a star on top! No problem. Suzy Goose will get that bright star in the sky to top the tree. It may sound crazy, but with a little holiday magic, maybe Suzy's plan is not so farfetched.",
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Animals'],
  },
  {
    id: 234,
    title: 'Superhero School',
    author: 'Thierry Robberecht',
    illustrator: 'Philippe Goossens',
    pageCount: 26,
    pageWidth: 1200,
    pageHeight: 1423,
    description:
      'A funny, colorful book that proves wisdom and humor can be far more important than strength. For superheroes ages 4 and up.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Health', 'Friendship'],
  },
  {
    id: 269,
    title: 'Super Ladybug to the Rescue ',
    author: 'Guido Van Genechten',
    illustrator: 'Guido Van Genechten',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1256,
    description:
      'Children will be fascinated with the incredible rescue operations of a teeny-tiny ladybug with an enormous heart in this book that follows his larger-than-life efforts to help animals get out of trouble. International calls for help are always rolling in for Super Ladybug—an African elephant is stuck in the mud, a dog is dangling from the Grand Canyon, a camel has gone dry in the Sahara—luckily, Super Ladybug has a whole lot of super-mega-powerful turbo remedies and a good dose of ladybug sense to help him. Filled with vivid color illustrations, this imaginative superhero book teaches children that even the tiniest creatures can provide supersized help.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Animals', 'Jobs'],
  },
  {
    id: 326,
    title: 'Super Animals: Builders',
    author: 'Reina Ollivier & Karel Claes',
    illustrator: 'Steffie Padmos',
    pageCount: 64,
    pageWidth: 1200,
    pageHeight: 1650,
    description:
      'Just like people, animals need a place to live. The nine animals in this book are very talented builders and make their own homes! Sometimes it’s high in a tree, and sometimes it’s hidden under the ground. They use earth, branches, and leaves, or whatever they can find nearby. Each house has its own style, from small and simple to large and complex. There are animals that prefer to live alone and others that live in groups. Animals build nests, dig tunnels, build roads and dams, and webs. Discover the amazing beaver, cross spider, sociable weaver, termite, stork, meerkat, honeybee, Japanese pufferfish, and mole. See who these special builders are!',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Science & Nature', 'Animals', 'Non-Fiction'],
  },
  {
    id: 271,
    title: 'Star Seeker',
    author: 'Theresa Heine',
    illustrator: 'Victor Tavares',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1511,
    description:
      'Journey through the night sky on a poetic journey that blends adventure, imagination and science to teach the basics of our solar system. Includes educational endnotes about the planets, stars, moons and constellations - and even a little mythology!',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Bedtime', 'Science & Nature', 'Travel', 'Poems & Rhyming'],
  },
  {
    id: 153,
    title: 'Stanley Goes for a Drive',
    author: 'Craig Frazier',
    illustrator: 'Craig Frazier',
    pageCount: 40,
    pageWidth: 600,
    pageHeight: 828,
    description:
      'It seems like an ordinary day for Stanley. He drives in his pick-up truck to milk the cow. But when he tosses his bucket of milk in the air, unexpected things happen!',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Animals'],
  },
  {
    id: 154,
    title: 'Stanley Goes Fishing',
    author: 'Craig Frazier',
    illustrator: 'Craig Frazier',
    pageCount: 40,
    pageWidth: 600,
    pageHeight: 828,
    description:
      'Stanley takes an ordinary sport and turns it into an extraordinary adventure. He looks down in the water, and sees a reflection of the clouds. He casts his fishing line into the sky, and what do you think he catches?',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Sports'],
  },
  {
    id: 497,
    title: 'Spring with Lily and Milo',
    author: 'Pauline Oud',
    illustrator: 'Pauline Oud',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1257,
    description:
      'It’s Spring! Lily and Milo go outside to plant a garden. In the grass, they find a beautiful egg. It’s red with white stripes. Maybe the egg fell out of a bird’s nest?\n\nA simple, interactive story about springtime.',
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['Friendship'],
  },
  {
    id: 377,
    title: 'Soldier for Equality',
    author: 'Duncan Tonatiuh',
    illustrator: 'Duncan Tonatiuh',
    pageCount: 48,
    pageWidth: 1200,
    pageHeight: 1460,
    description:
      'José de la Luz Sáenz (Luz) believed in fighting for what was right. Though born in the United States, Luz often faced prejudice because of his Mexican heritage. Determined to help his community, even in the face of discrimination, he taught school—children during the day and adults in the evenings.\n\nWhen World War I broke out, Luz joined the army, as did many others. His ability to quickly learn languages made him an invaluable member of the Intelligence Office in Europe. However, Luz found that prejudice followed him even to war, and despite his efforts, he often didn’t receive credit for his contributions. Upon returning home to Texas, he joined with other Mexican American veterans to create the League of United Latin American Citizens (LULAC), which today is the largest and oldest Latinx civil rights organization.\n\nUsing his signature illustration style and Luz’s diary entries from the war, award-winning author and illustrator Duncan Tonatiuh tells the story of a Mexican American war hero and his fight for equality.',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Family', 'Jobs', 'Friendship'],
  },
  {
    id: 376,
    title: 'So Many Sounds',
    author: 'Tim McCanna',
    illustrator: 'Andy J. Miller',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1200,
    description:
      'So Many Sounds is a wonderful rhyming read-aloud featuring everyday sounds and a refrain that children will love repeating. The playful text and illustrations are sure to delight little ones while also inviting them to pay more attention to the world around them.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: [
      'Transportation',
      'Bedtime',
      'Science & Nature',
      'Poems & Rhyming',
    ],
  },
  {
    id: 58,
    title: 'Snow! Snow! Snow!',
    author: 'Lee Harper',
    illustrator: 'Lee Harper',
    pageCount: 40,
    pageWidth: 600,
    pageHeight: 750,
    description:
      'A pup wakes up after a night of steady snow to discover... the perfect sledding day! The canine narrator rounds up the gang and heads to "the best sledding hill in the whole wide world."\n\nPrinted with permission of Simon & Schuster Children\'s Publishing, a Division of Simon & Schuster Inc.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Science & Nature'],
  },
  {
    id: 146,
    title: 'Snow White and the Seven Dwarfs',
    author: 'Brothers Grimm',
    illustrator: 'Debbie Lavreys',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 624,
    description:
      'In these lovely illustrations, Snow White is indeed "the most beautiful of them all." No wonder her stepmother is so jealous. But Snow White\'s kindness and friendship are the reasons the dwarves wish to keep her safe.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Fairy Tales'],
  },
  {
    id: 128,
    title: 'Sleeping',
    author: 'Liesbet Slegers',
    illustrator: 'Liesbet Slegers',
    pageCount: 14,
    pageWidth: 600,
    pageHeight: 600,
    description:
      'As a toddler gets ready for bed, your child learns all of the steps to the nightly routine. First the hero puts on polka-dot pajamas, then drinks from a bottle of milk, and of course, reads a book before saying "Good night!"',
    free: false,
    ageRanges: ['0 - 2 yr'],
    categories: ['Bedtime'],
  },
  {
    id: 389,
    title: 'Sleep Well, Siba and Saba',
    author: 'Nansubuga Nagadya Isdahl',
    illustrator: 'Sandra van Doorn',
    pageCount: 40,
    pageWidth: 1200,
    pageHeight: 1200,
    description:
      'Forgetful sisters Siba and Saba are always losing something. Sandals, slippers, sweaters—you name it, they lose it. When the two sisters fall asleep each night, they dream about the things they have lost that day. Until, one night, their dreams begin to reveal something entirely unexpected… With playful illustrations and a lullaby-like rhythm, this heart-warming story set in Uganda is truly one to be treasured.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Poems & Rhyming', 'Family'],
  },
  {
    id: 459,
    title: 'Sing to the Moon',
    author: 'Nansubuga Nagadya Isdahl',
    illustrator: 'Sandra van Doorn',
    pageCount: 40,
    pageWidth: 1200,
    pageHeight: 1200,
    description:
      "For one little Ugandan boy, no wish is too big. First he dreams of reaching the stars and then of riding a supernova straight to Mars. But on a rainy day at his grandfather's house, he is brought down to earth with a bump. Do adventures only happen in galaxies far away or can he find magic a little closer to home? A touching story of a grandfather's love for his grandson and the quiet pleasures of a rainy day.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Travel', 'Poems & Rhyming', 'Family'],
  },
  {
    id: 117,
    title: 'Shopping with Dad',
    author: 'Matt Harvey',
    illustrator: 'Miriam Latimer',
    pageCount: 36,
    pageWidth: 600,
    pageHeight: 756,
    description:
      "When a girl and her dad head to the grocery store with Mommy's shopping list, they enter into an unexpected adventure.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Family', 'Poems & Rhyming'],
  },
  {
    id: 173,
    title: 'Say Hello to Zorro!',
    author: 'Carter Goodrich',
    illustrator: 'Carter Goodrich',
    pageCount: 48,
    pageWidth: 600,
    pageHeight: 600,
    description:
      'Mister Bud is the beloved family dog--until a stranger arrives. Whether your child is adjusting to a new pet, a new sibling or just looking for a laugh, this book will quickly become a favorite.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Fairy Tales', 'Science & Nature', 'Animals', 'Friendship'],
  },
  {
    id: 471,
    title: 'Sarah at the Wedding',
    author: 'Pauline Oud',
    illustrator: 'Pauline Oud',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1249,
    description:
      'Today is a special day. It’s a party. Not just a birthday party, but a really big party… a wedding! Aunt Olivia is getting married to William. \nSarah and Ian are flower girl and ring bearer at Aunt Olivia’s wedding. Ian gets to wear a beautiful suit and Sarah got a new dress. They can carry the veil, hold the rings on a beautiful pillow and throw flowers. Will everything go as planned?\n\nA heartwarming picture book about a wedding and everything that happens on a special day. ',
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['Family'],
  },
  {
    id: 189,
    title: "Ricky's Christmas Tree",
    author: 'Guido van Genechten',
    illustrator: 'Guido van Genechten',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 829,
    description:
      "Ricky decides his family can't have Christmas without a tree, but Mom and Dad are just too busy.  But Ricky's insistence, and his enthusiasm, reminds Dad of the joy of winter and of the importance of making time for family.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Family'],
  },
  {
    id: 303,
    title: "Ricky's Birthday",
    author: 'Guido Van Genechten',
    illustrator: 'Guido Van Genechten',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1656,
    description:
      'It is a very special day today: it’s Ricky’s birthday! The birthday boy wakes up really early and prepares for his birthday party with Mommy. When Ricky’s friends arrive, his best friend Annie is not there … Where could she be? And does Ricky’s birthday have more surprises in store for him?\n\nA celebratory new book in a beloved series about Ricky, for children ages 4 and up.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Family', 'Animals', 'Friendship'],
  },
  {
    id: 148,
    title: 'Ricky Is Brave',
    author: 'Guido van Genechten',
    illustrator: 'Guido van Genechten',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 829,
    description:
      'When Ricky wants to sleep outside in a tent, his father asks him, "Are you brave enough?" "Of course I am," says Ricky. But alone in his tent in the dark, Ricky\'s bravery gets tested.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Bedtime', 'Family'],
  },
  {
    id: 166,
    title: 'Ricky',
    author: 'Guido van Genechten',
    illustrator: 'Guido van Genechten',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 829,
    description:
      'Ricky the rabbit has one ear that hangs down instead of standing up. After he tries many ways to make his ears match, he decides it might not be so bad to be different.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: [],
  },
  {
    id: 47,
    title: 'Rhyming Dust Bunnies',
    author: 'Jan Thomas',
    illustrator: 'Jan Thomas',
    pageCount: 40,
    pageWidth: 600,
    pageHeight: 600,
    description:
      'Meet Ed, Ned, Ted and Bob, comical, colorful Muppet-like creatures. "We rhyme," says one. "All the time!" claims another. As Ed, Ned and Ted spout off rhyming words like "bug," "hug," and "rug," Bob shouts, "Look out!" Bob\'s word does not rhyme, but he has bigger things on his mind.\n\nPrinted with permission of Simon & Schuster Children\'s Publishing, a Division of Simon & Schuster Inc.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Poems & Rhyming'],
  },
  {
    id: 241,
    title: 'Ready, Set, Go! Sports of All Sorts',
    author: 'Celeste Cortright',
    illustrator: 'Christiane Engel',
    pageCount: 28,
    pageWidth: 1200,
    pageHeight: 1162,
    description:
      'Illustrations and easy-to-read, rhyming text introduce the basics of eight sports popular around the world, from track to gymnastics and baseball to karate. Includes endnotes on the different sports.',
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Health', 'Sports'],
  },
  {
    id: 451,
    title: "Rashad's Ramadan and Eid al-Fitr",
    author: 'Lisa Bullard',
    illustrator: 'Holli Conger',
    pageCount: 28,
    pageWidth: 1200,
    pageHeight: 1232,
    description:
      "For Muslims, Ramadan is a time for fasting, prayer, and thinking of others. Rashad tries to be good all month. When it's time for Eid al-Fitr, he feasts and plays! Find out how people celebrate this special time of year.",
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Chapter Books', 'Family'],
  },
  {
    id: 164,
    title: 'Rapunzel',
    author: 'Debbie Lavreys',
    illustrator: 'Debbie Lavreys',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 624,
    description:
      'All of the parts of this favorite tale play out, from the witch calling out, "Rapunzel, Rapunzel, let down your hair!" to the golden-haired heroines visit from a prince in her high tower.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Fairy Tales'],
  },
  {
    id: 466,
    title: 'Pterosaurs',
    author: 'Caroline Arnold',
    illustrator: 'Laurie Caple',
    pageCount: 44,
    pageWidth: 1200,
    pageHeight: 1403,
    description:
      'One hundred million years ago, the skies were filled with enormous flying reptiles. With wings spans up to nearly forty feet, pterosaurs were the dominant life form on earth. The relationship of these giants of the air to dinosaurs and to other reptiles, their varied appearance and lifestyles, their evolution and extinction, the fossil discoveries that scientists are continually making throughout the world—all are depicted in this beautifully illustrated, accessible book for young scientists and their parents and teachers.',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Science & Nature', 'Animals', 'Non-Fiction'],
  },
  {
    id: 49,
    title: "Poppy's Pants",
    author: 'Melissa Conroy',
    illustrator: 'Melissa Conroy',
    pageCount: 36,
    pageWidth: 600,
    pageHeight: 772,
    description:
      '"Meet Poppy, who always wears khaki pants." Poppy, pictured as a stuffed doll with googly eyes and a bright red nose, "spends many hours at his desk writing something," as his granddaughter Penelope observes. But when Poppy gets a hole in his khakis, Penelope mends it all by herself.',
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Family'],
  },
  {
    id: 351,
    title: "Pluto's Secret",
    author: 'Margaret Weitekamp and David DeVorkin',
    illustrator: 'Diane Kidd',
    pageCount: 44,
    pageWidth: 1200,
    pageHeight: 1200,
    description:
      "People, children especially, have been baffled, bewildered, and even outraged by the fact that Pluto is no longer called a planet. Through whimsical artwork and an entertaining dialogue format, Pluto's Secret explains the true story of this distant world. Providing a history of the small, icy world from its discovery and naming to its recent reclassification, this book presents a fascinating look at how scientists organize and classify our solar system as they gain new insights into how it works and what types of things exist within it. The book includes a glossary and bibliography.",
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Science & Nature'],
  },
  {
    id: 156,
    title: 'Playing',
    author: 'Liesbet Slegers',
    illustrator: 'Liesbet Slegers',
    pageCount: 12,
    pageWidth: 600,
    pageHeight: 600,
    description:
      'A boy helps toddlers name their favorite toys. "Look, the ball is rolling away!" he says. Then he stacks his blocks in "a high tower," and reads his book before he puts all the toys away in his wagon.',
    free: false,
    ageRanges: ['0 - 2 yr'],
    categories: [],
  },

  {
    id: 366,
    title: 'Pig in the Palace',
    author: 'Ali Bahrampour',
    illustrator: 'Ali Bahrampour',
    pageCount: 40,
    pageWidth: 1200,
    pageHeight: 1460,
    description:
      'A pig is invited to dinner with the queen in this delightfully funny misfit tale\n\nBobo is rolling around in the mud when somebody slips a letter under his door. It’s an invitation to have dinner with the queen! “Why me?” Bobo wonders. What will he do at a fancy palace? He’s covered in fleas. He doesn’t know which fork to use. He has never been anywhere so grand! Indeed, when he arrives, Bobo feels terribly out of place. And trouble is following him wherever he turns! But maybe . . . he’s not the only one who’d like to let loose?\nFor any kids who have tried their hardest to behave at a grown-up gathering, Bobo the boar is just the itchy, clumsy, endearing hero they’ve been waiting for.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Animals', 'Friendship'],
  },
  {
    id: 113,
    title: 'Penguins, Penguins Everywhere',
    author: 'Bob Barner',
    illustrator: 'Bob Barner',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 600,
    description:
      "Did you know that father penguins keep their chicks' eggs warm? That's just one of the fun facts to be discovered in this boldly illustrated book.",
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Science & Nature', 'Animals', 'Non-Fiction'],
  },
  {
    id: 355,
    title: "Pablo Can't Sleep",
    author: 'Aurélia Higuet',
    illustrator: 'Aurélia Higuet',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 895,
    description:
      'Pablo is tired. But he can’t sleep\nbecause his friends are too busy or\nmake too much noise. Angrily, Pablo walks off.\nIn the desert, everything is quiet at last.\nBut falling asleep is now completely impossible\nsince Pablo feels alone and misses his friends …',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr', '0 - 2 yr'],
    categories: ['Bedtime', 'Animals', 'Friendship'],
  },
  {
    id: 107,
    title: 'Over The River',
    author: 'Derek Anderson',
    illustrator: 'Derek Anderson',
    pageCount: 44,
    pageWidth: 600,
    pageHeight: 600,
    description:
      'A family of turkeys turns the tables in this comical Thanksgiving tale. "To Grandmother\'s house they go," with a boy and his hound in hot pursuit! Luckily, Grandmother Gobbler herself saves the holiday--in more ways than one.\n\nPrinted with permission of Simon & Schuster Children\'s Publishing, a Division of Simon & Schuster Inc.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Family'],
  },
  {
    id: 65,
    title: 'Over in the Hollow',
    author: 'Rebecca Dickinson',
    illustrator: 'S. Britt',
    pageCount: 36,
    pageWidth: 600,
    pageHeight: 514,
    description:
      'This spooky riff on the classic "Over in the Meadow" rhyme counts up from a mother spider spinning cobwebs with her "little spidey one," to an old moldy mummy "lurch[ing]" with his little mummies two, all the way up to 13 ghosts who cry "Boo!"',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Family'],
  },
  {
    id: 454,
    title: 'Oops, I Dropped the Lemon Tart',
    author: 'An Swerts',
    illustrator: 'Eline van Lindenhuizen',
    pageCount: 40,
    pageWidth: 1200,
    pageHeight: 1247,
    description:
      'Lucy was always cheerful and adventurous, but one day she started worrying about everything. She didn’t want to try new things because she didn’t want to make mistakes. The only place she was happy was at her dad’s restaurant, where she would work with her best friend, Evan. It is there that she discovers that something beautiful can come from making a mistake!',
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Family'],
  },
  {
    id: 43,
    title: 'One Duck Stuck',
    author: 'Phyllis Root',
    illustrator: 'Jane Chapman',
    pageCount: 40,
    pageWidth: 600,
    pageHeight: 522,
    description:
      'When one duck "gets stuck in the muck" and cries for help, a variety of animals answer the call, from two fish with "tails going swish," up to 10 dragonflies "zooming through the skies." But it takes a team effort to free the web-footed fowl, as these saturated full-spread paintings and panel illustrations prove.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Animals', 'Poems & Rhyming'],
  },
  {
    id: 505,
    title: 'On y danse les saisons',
    author: 'Stella Blackstone',
    illustrator: 'Maria Carluccio',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1162,
    description:
      'Whirl through the months of the year in this action packed seek-and-find book that takes young readers on an outdoor adventure as the months pass by. The detailed pictures offer a wide variety of items to spot, while also teaching the changes that happen in nature as the year turns.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['French', 'Science & Nature'],
  },
  {
    id: 243,
    title: 'On a Journey',
    author: 'Guido Van Genechten',
    illustrator: 'Guido Van Genechten',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 972,
    description:
      'A heartwarming picture book about traveling together, sharing new experiences, and letting yourself be amazed by the world around you. For everyone ages 4 and up.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Transportation', 'Travel', 'Animals', 'Friendship'],
  },
  {
    id: 122,
    title: 'Olivia Helps With Christmas',
    author: 'Ian Falconer',
    illustrator: 'Ian Falconer',
    pageCount: 58,
    pageWidth: 600,
    pageHeight: 776,
    description:
      "Olivia tries to get ready for Christmas, by untangling the tree lights and setting the table. But everything seems to wind up in a comical mess. Will Olivia be ready by the time Santa comes?\n\nPrinted with permission of Simon & Schuster Children's Publishing, a Division of Simon & Schuster Inc.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Family'],
  },
  {
    id: 136,
    title: 'Off We Go To Mexico',
    author: 'Laurie Krebs',
    illustrator: 'Christopher Corr',
    pageCount: 36,
    pageWidth: 600,
    pageHeight: 581,
    description:
      "We're off to Mexico to relax on la playa (the beach), to climb las piràmides (the pyramids) and visit los museos (the museums) in Mexico City! While on this colorful tour, children also learn a host of Spanish words.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Travel'],
  },
  {
    id: 387,
    title: 'Odd, Weird & Little',
    author: 'Patrick Jennings',
    illustrator: '',
    pageCount: 164,
    pageWidth: 1200,
    pageHeight: 1800,
    description:
      'When the new kid joins his class, Woodrow agrees with his schoolmates—Toulouse is really weird. He’s short—kindergarten short—dresses in a suit like a grandpa, has huge eyes, and barely says a word. But Woodrow isn’t exactly Mr. Popularity. The frequent target of the class bully himself, he figures that maybe all Toulouse needs is a chance. And when the two are put together in gym to play volleyball, they make quite the team. Toulouse can serve, set, and spike like a pro. He really knows how to fly around the court. But when the attention and teasing switch back to Woodrow, he learns that the new kid is great at something else: being a friend.\n\nFull of heart and laughs, Odd, Weird, and Little is another winner from the author of the Guinea Dog series.',
    free: false,
    ageRanges: ['9 - 11 yr'],
    categories: ['Chapter Books', 'Friendship'],
  },
  {
    id: 42,
    title: 'Not Norman: A Goldfish Story',
    author: 'Kelly Bennett',
    illustrator: 'Noah Z. Jones',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 675,
    description:
      'The birthday boy who narrates this "goldfish story" wanted a pet that could run and catch: "Not Norman." Funny cartoonish pictures follow the boy as he takes Norman to school in hopes of giving him away--then realizes he may have overlooked a few of Norman\'s talents.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Animals'],
  },
  {
    id: 116,
    title: 'No Ghost Under My Bed',
    author: 'Guido van Genechten',
    illustrator: 'Guido van Genechten',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 486,
    description:
      "Jake cannot go to sleep. \"Dad! There's a ghost under my bed!\" he cries. Dad looks under Jake's bed, behind Jake's curtains and in Jake's toy chest, until Jake feels safe.",
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Bedtime', 'Family'],
  },
  {
    id: 438,
    title: 'Niko Draws a Feeling',
    author: 'Robert Raczka',
    illustrator: 'Simone Shin',
    pageCount: 44,
    pageWidth: 1200,
    pageHeight: 1467,
    description:
      'Niko loves to draw his world: the ring-a-ling of the ice cream truck, the warmth of sun on his face. But no one appreciates his art. Until one day, Niko meets Iris . . .\n\nThis imaginative and tender story explores the creative process, abstract art, friendship, and the universal desire to feel understood.',
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Friendship'],
  },
  {
    id: 455,
    title: 'New Friends for Zaza',
    author: 'Mylo Freeman  ',
    illustrator: 'Mylo Freeman  ',
    pageCount: 24,
    pageWidth: 1200,
    pageHeight: 1255,
    description:
      'Zaza is going to daycare for the first time. Will she and Rosie make new friends?\n\nA warm book about cuddly friends and new friends.',
    free: false,
    ageRanges: ['0 - 2 yr'],
    categories: ['Friendship'],
  },
  {
    id: 193,
    title: 'My Little Troublemaker',
    author: 'Thierry Robberecht',
    illustrator: 'Philippe Goosens',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 723,
    description:
      'The little fairy is the most polite, best-behaved student at Fairy School, but when a fellow student is unkind, the little fairy finds herself doing all kinds of naughty things!  She confesses her misdeeds to the Fairy Principal who kindly explains about the little troublemaker.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: [],
  },
  {
    id: 403,
    title: 'My Language, Your Language',
    author: 'Bullard, Lisa',
    illustrator: 'Becker, Paula J.',
    pageCount: 28,
    pageWidth: 1200,
    pageHeight: 1232,
    description:
      "There's a new girl in Romelie's class named Camila. Camila speaks Spanish, and she's teaching it to Romelie. That gets Romelie thinking. What other languages do people speak? Her own family uses English and American Sign Language. Her mom's friend knows Somali and Arabic. Can Romelie discover more languages in her city? How are they like her own? How are they different? A diverse cast gives multiple points of comparison.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Friendship'],
  },
  {
    id: 134,
    title: 'My Granny Went To Market',
    author: 'Stella Blackstone',
    illustrator: 'Christopher Corr',
    pageCount: 28,
    pageWidth: 600,
    pageHeight: 581,
    description:
      'Granny goes to Istanbul to buy one magic carpet, which takes her far and wide on the way to her granddaughter. Mural-like pictures show Granny with two temple cats in Thailand and counting her way up to 10 black lamas in Peru.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Numbers & Alphabet', 'Travel', 'Family', 'Poems & Rhyming'],
  },
  {
    id: 238,
    title: 'My Good Morning',
    author: 'Kim Crockett-Corson',
    illustrator: 'Jelena Brezovec',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1248,
    description:
      'A funny picture book about a little girl’s morning routines.  She’s up, ready to go, and can do every task herself without any help from her parents! Parents will laugh as they recognize themselves and their children in this story.',
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['Family'],
  },
  {
    id: 504,
    title: 'My Big Barefoot Book of French and English Words',
    author: 'Barefoot Books',
    illustrator: 'Sophie Fatus',
    pageCount: 52,
    pageWidth: 1200,
    pageHeight: 1412,
    description:
      'The bilingual French-English edition of our celebrated The Barefoot Book of Wonderful Words!\n\nJoin Maya and Sam as they explore their lively neighborhood! You’ll pore over everyday scenes and thematic spreads that burst with clearly-labeled details to boost vocabulary and ignite interest. Readers of all French- and English-language levels will love exploring the colorful, labeled illustrations to find new words and reinforce familiar ones. Boost bilingual vocabulary, and you’ll also improve communication, problem-solving and analytical skills!',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['French'],
  },
  {
    id: 365,
    title: 'Museum',
    author: 'Susan Verde',
    illustrator: 'Peter H. Reynolds',
    pageCount: 40,
    pageWidth: 1200,
    pageHeight: 1201,
    description:
      "When I see a work of art, something happens in my heart!\nAs a little girl tours and twirls through the halls of the art museum, she finds herself on an exciting adventure. Each piece of art evokes something new inside of her: silliness, curiosity, joy, and ultimately inspiration. When confronted with an empty white canvas, she is energized to create and express herself—which is the greatest feeling of all.\nWith exuberant illustrations by Peter H. Reynolds, The Museum playfully captures the many emotions experienced through the power of art, and each child's unique creative process.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Poems & Rhyming'],
  },
  {
    id: 68,
    title: 'Muncha! Muncha! Muncha!',
    author: 'Candace Fleming',
    illustrator: 'G. Brian Karas',
    pageCount: 34,
    pageWidth: 600,
    pageHeight: 720,
    description:
      'Mr. McGreeley dreams of harvesting vegetables in his very first garden. "Yum! Yum! Yummy! I\'ll soon fill my tummy with crisp, fresh veggies," he thinks. But a few long-eared fellows have other plans.\n\nPrinted with permission of Simon & Schuster Children\'s Publishing, a Division of Simon & Schuster Inc.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Science & Nature', 'Animals'],
  },
  {
    id: 252,
    title: 'Mouse is Brave',
    author: 'Judith Koppens',
    illustrator: 'Eline van Lindenhuizen',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1255,
    description:
      '"Welcome to Animal Square where all the animals live and play together. Kitty, Mouse, Monkey, Giraffe, Rabbit, and Dog are six very best friends. They each live in their own little home and do lots of things together. Animal Square is a place full of friendship, happiness, and caring. Come join in the fun!\n\nMouse is afraid often. She won’t sit on the swing, because she’s afraid she might fall. Climbing and playing soccer scares Mouse too. Luckily Giraffe knows what to do. He gives Mouse a daredevil-pebble, which makes Mouse very brave. She swings very high and plays soccer. She’s brave enough to swim! But then Mouse notices she lost her daredevil-pebble. Now what?   \n\nA cheerful story about being brave. For toddlers ages 30 months and up, with a focus on the child’s social skills."',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Animals'],
  },
  {
    id: 356,
    title: "Monty's Special Day",
    author: 'Ellen DeLange',
    illustrator: 'Ma?gorzata Zaj?c',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 875,
    description:
      'Monty is a typical donkey living a typical donkey life. Until the one day, he walks through the open gate and goes on an adventure . . .',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Science & Nature', 'Animals', 'Friendship'],
  },
  {
    id: 415,
    title: 'Monkey with a Tool Belt',
    author: 'Monroe, Chris',
    illustrator: 'Monroe, Chris',
    pageCount: 44,
    pageWidth: 1200,
    pageHeight: 1467,
    description:
      "Whether you need a beebersaw or a chisel, Chico Bon Bon's your monkey. He can build or fix just about anything—from a dock for the ducks to a clock for the Clucks, even a small roller coaster for local chipmunks. But will his tools and his sharp wit save him when an organ grinder sets his sights on making Chico a circus star? Chris Monroe's quirky hero and detailed illustrations will absorb readers in an entertaining adventure that shows there is an inventive way out of every problem—if you have the right tools.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Animals', 'Jobs'],
  },
  {
    id: 204,
    title: 'Mommies and Their Babies',
    author: 'Guido van Genechten',
    illustrator: 'Guido van Genechten',
    pageCount: 22,
    pageWidth: 600,
    pageHeight: 600,
    description:
      '"Animal babies have a mommy, just like you!" The stimulating, high-contrast illustrations teach babies and toddlers about the names of various baby animals, from the tiny "spider mommy" and her "baby spiderling" to the huge "elephant mommy" with her "baby calf."',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Animals', 'Non-Fiction'],
  },
  {
    id: 168,
    title: "Mitchell's License",
    author: 'Hallie Durand',
    illustrator: 'Tony Fucile',
    pageCount: 44,
    pageWidth: 600,
    pageHeight: 675,
    description:
      'At the end of the day, Mitchell gets to "drive" his dad to bed. Going to sleep was never so fun. Fasten your seat belts! ',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Bedtime', 'Family'],
  },
  {
    id: 494,
    title: "Mira's Curly Hair",
    author: 'Maryam Al Serkal',
    illustrator: 'Rebeca Luciani',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1200,
    description:
      "Mira doesn't like her hair. It curls at the front. It curls at the back. It curls everywhere! She wants it to be straight and smooth, just like her Mama's. But then something unpredictable happens . . . and Mira will never look at her mama's hair the same way again! A delightful celebration of natural hair and the courage it takes to be yourself.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Family'],
  },
  {
    id: 89,
    title: 'Mercy Watson: Something Wonky This Way Comes',
    author: 'Kate DiCamillo',
    illustrator: 'Chris Van Dusen',
    pageCount: 94,
    pageWidth: 600,
    pageHeight: 770,
    description:
      'When nearly everyone from Deckawoo Drive pulls up at the drive-in ticket booth, the ticket-taker believes she is "seeing things kinda wonky." She sees a pig in the backseat of the Watsons\' car, and Mercy smells butter. Watch out!',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Chapter Books', 'Family', 'Animals'],
  },
  {
    id: 88,
    title: 'Mercy Watson to the Rescue',
    author: 'Kate DiCamillo',
    illustrator: 'Chris Van Dusen',
    pageCount: 78,
    pageWidth: 600,
    pageHeight: 770,
    description:
      'Meet Mercy, a pig beloved by Mr. and Mrs. Watson. Mercy "likes hot toast with a great deal of butter on it." One night, when the Watsons\' bedroom floor begins to give way, Mercy\'s love of toast leads to their rescue.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Chapter Books', 'Family', 'Animals'],
  },
  {
    id: 92,
    title: 'Mercy Watson Thinks Like a Pig',
    author: 'Kate DiCamillo',
    illustrator: 'Chris Van Dusen',
    pageCount: 78,
    pageWidth: 600,
    pageHeight: 770,
    description:
      'After Mercy Watson eats every one of Eugenia Lincoln\'s pansies, Eugenia takes "extreme measures." She calls Animal Control. But, thinking like a pig, Mercy outsmarts them all.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Numbers & Alphabet', 'Chapter Books', 'Family', 'Animals'],
  },
  {
    id: 91,
    title: 'Mercy Watson Princess in Disguise',
    author: 'Kate DiCamillo',
    illustrator: 'Chris Van Dusen',
    pageCount: 80,
    pageWidth: 600,
    pageHeight: 770,
    description:
      "A pig in a tiara? Well, if it's Mercy Watson on Halloween, of course! Mercy is not so excited about donning a princess dress-until she hears about the treats.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Chapter Books', 'Family', 'Animals'],
  },
  {
    id: 90,
    title: 'Mercy Watson Goes for a Ride',
    author: 'Kate DiCamillo',
    illustrator: 'Chris Van Dusen',
    pageCount: 78,
    pageWidth: 600,
    pageHeight: 770,
    description:
      'When Mercy the pig tries to take the wheel for their Saturday drive, the Watsons must bribe her with extra helpings of hot buttered toast to scoot over. But a surprise passenger in the back seat gives Mercy her chance...',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Chapter Books', 'Family', 'Animals'],
  },
  {
    id: 82,
    title: 'Mercy Watson Fights Crime',
    author: 'Kate DiCamillo',
    illustrator: 'Chris Van Dusen',
    pageCount: 78,
    pageWidth: 600,
    pageHeight: 770,
    description:
      'One night, while Mr. and Mrs. Watson are snoring, Mercy (their pig) wakes up to a screech, which meant the toaster was being pulled across the kitchen counter! (Mercy loves "toast with a great deal of butter on it.") And that is how she finds a thief in the kitchen!',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Fairy Tales', 'Chapter Books', 'Animals', 'Family'],
  },
  {
    id: 404,
    title: 'Meet My Family',
    author: 'Salas, Laura Purdie',
    illustrator: 'Coleman, Stephanie Fizer',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1232,
    description:
      'What kind of families do animal babies have? All different kinds! Charming text and sweet illustrations introduce a wolf pup cared for by the pack, a young orangutan snuggling with its mother high in a tree, a poison dart frog tadpole riding piggyback on its dad, and more. Featuring rhyming verse and informational text, this book lets you discover just how diverse the animal kingdom really is!',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Science & Nature', 'Animals', 'Family', 'Poems & Rhyming'],
  },
  {
    id: 297,
    title: 'Maybe Dying is like Becoming a Butterfly',
    author: 'Pimm van Hest',
    illustrator: 'Lisa Brandenburg',
    pageCount: 34,
    pageWidth: 1200,
    pageHeight: 876,
    description:
      'An important picture book that gives children free rein to express their questions, fears, thoughts, and ideas about death. For children ages 5 and up. Including an epilogue by the grief therapist Rebecca Dabekaussen, with tips on how to discuss this difficult but inevitable subject with children.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Friendship'],
  },
  {
    id: 446,
    title: 'Máquinas Gigantes',
    author: 'Seymour Simon',
    illustrator: 'Multiple Photographers',
    pageCount: 34,
    pageWidth: 1200,
    pageHeight: 36,
    description:
      'Excavadoras arrancan rocas enormes de la tierra. Altas grúas levantan cargas pesadas en puentes y astilleros. Lee MÁQUINAS GIGANTES para aprender más sobre estas máquinas y otras herramientas poderosas!',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Spanish', 'Transportation', 'Jobs', 'Non-Fiction'],
  },
  {
    id: 133,
    title: "Mama Panya's Pancakes",
    author: 'Mary and Rich Chamberlin',
    illustrator: 'Julia Cairns',
    pageCount: 44,
    pageWidth: 600,
    pageHeight: 581,
    description:
      'As Adika and his mama head to market, Adika invites their friends over for pancakes. Will they have enough for everyone? Adika believes they\'ll have "a little bit and a little bit more" for all the guests, who each contribute a little bit, too.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Family', 'Friendship'],
  },
  {
    id: 114,
    title: "Maisy's Snowy Christmas Eve",
    author: 'Lucy Cousins',
    illustrator: 'Lucy Cousins',
    pageCount: 30,
    pageWidth: 600,
    pageHeight: 520,
    description:
      "It is snowing on Christmas Eve. Everyone is invited to Maisy's house. Cyril arrives on snowshoes. Charley and Tallulah come by sled. They all help decorate the tree. But where is Eddie?",
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Family', 'Friendship'],
  },
  {
    id: 9,
    title: 'Maisy Goes to Preschool',
    author: 'Lucy Cousins',
    illustrator: 'Lucy Cousins',
    pageCount: 30,
    pageWidth: 600,
    pageHeight: 495,
    description:
      'Maisy loves preschool: "There\'s always so much to do there and so many friends to see." Maisy paints a picture, eats a snack, hears a story, and takes a nap. As Maisy goes through her day, she reassures children that everything will be just fine.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Family', 'Friendship'],
  },
  {
    id: 493,
    title: "Maisie's Scrapbook",
    author: 'Samuel Narh',
    illustrator: 'Jo Loring-Fisher',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1200,
    description:
      "As the seasons turn, Maisie rides her bull in and out of Dada's tall tales. Her Mama wears linen and plays the viola. Her Dada wears kente cloth and plays the marimba.They come from different places, but they hug her in the same way. And most of all, they love her just the same. A joyful celebration of a mixed-race family and the love that binds us all together.",
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['Family'],
  },
  {
    id: 258,
    title: "Macie's Mirror",
    author: 'Adam Ciccio',
    illustrator: 'Gertie Jaquet',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1249,
    description:
      'Good enough was never good enough for Macie.\nShe wanted to be the best.\nUntil the day Macie took a look in the mirror \nand learned a very important lesson . . .\n\nA story about self-respect and confidence, for not-so-perfect kids ages 4 and up.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Health'],
  },
  {
    id: 386,
    title: 'Luis Paints the World',
    author: 'Terry Farish',
    illustrator: 'Oliver Dominguez',
    pageCount: 44,
    pageWidth: 1200,
    pageHeight: 1027,
    description:
      'Luis wishes Nico wasn’t leaving for the Army. To show Nico he doesn’t need to go, Luis begins a mural on the alleyway wall. Their house, the river, the Parque de las Ardillas—it’s the world, all right there. Won’t Nico miss Mami’s sweet flan? What about their baseball games in the street?\n\nBut as Luis awaits his brother’s return from duty, his own world expands as well, through swooping paint and the help of their bustling Dominican neighborhood.',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Travel', 'Family'],
  },
  {
    id: 416,
    title: 'Lots of Grandparents',
    author: 'Shelly Rotner and Sheila M, Kelly',
    illustrator: 'Shelly Rotner',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1528,
    description:
      "In this charming photo-essay, authors Shelley Rotner and Sheila Kelly describes the roles grandparents play in their grandchildren's lives.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr', '0 - 2 yr'],
    categories: ['Family'],
  },
  {
    id: 152,
    title: 'Lots of Dots',
    author: 'Craig Frazier',
    illustrator: 'Craig Frazier',
    pageCount: 40,
    pageWidth: 600,
    pageHeight: 776,
    description:
      'This book will make you start seeing dots everywhere. Dots in traffic lights, dots on ice cream cones, dots in gumball machines. It will open your eyes to the dots all around us.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: [],
  },
  {
    id: 34,
    title: 'Lost and Found: Three Dog Stories',
    author: 'Jim LaMarche',
    illustrator: 'Jim LaMarche',
    pageCount: 48,
    pageWidth: 600,
    pageHeight: 695,
    description:
      'For dog lovers and for any child who pines for a dog, this trio of tales attests to the human-dog bond. Each story explores the idea that a dog can make a child feel at home anywhere at all. ',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Animals', 'Family', 'Friendship'],
  },
  {
    id: 431,
    title: 'Looking Like Me',
    author: 'Walter Dean Myers',
    illustrator: 'Christopher Myers',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1234,
    description:
      'When you look in a mirror, who do you see?\nA boy? A girl?\nA son? A daughter?\nA runner? A dancer?\nWhoever and whatever you see?just put out your fist and give yourself an ""I am"" BAM!\n\nThis jumping, jazzy, joyful picture book by the award-winning team of Walter Dean Myers and Christopher Myers celebrates every child, and everything that a child can be.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Family', 'Poems & Rhyming'],
  },
  {
    id: 115,
    title: 'Looking For Christmas',
    author: 'Peggy van Gurp',
    illustrator: 'Peggy van Gurp',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 486,
    description:
      'Lowy the snowman sets out to find Christmas. After Lowy helps a little rabbit, a little mouse, and other woodland creatures, they join the snowman in his search. They find Christmas ("Hohoho") where they least expect it.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Animals'],
  },
  {
    id: 162,
    title: 'Look at That! Wild Animals',
    author: 'Guido van Genechten',
    illustrator: 'Guido van Genechten',
    pageCount: 20,
    pageWidth: 600,
    pageHeight: 600,
    description:
      "From a crab and dolphin that live in the sea, to a tiger and monkey you'd find in the jungle, your toddler can see these wild animals up close.",
    free: false,
    ageRanges: ['0 - 2 yr'],
    categories: ['Animals'],
  },
  {
    id: 161,
    title: 'Look at That! Farm Animals',
    author: 'Guido van Genechten',
    illustrator: 'Guido van Genechten',
    pageCount: 20,
    pageWidth: 600,
    pageHeight: 600,
    description:
      'A wide-eyed bullfrog, horse, cat and more peek out at youngest animal lovers. Your toddler can learn the names that go with both the familiar and unfamiliar creatures here--all of them friendly.',
    free: false,
    ageRanges: ['0 - 2 yr'],
    categories: ['Animals'],
  },
  {
    id: 266,
    title: 'Lola’s Fandango',
    author: 'Anna Witte',
    illustrator: 'Micha Archer',
    pageCount: 38,
    pageWidth: 1200,
    pageHeight: 1511,
    description:
      'Little Lola is tired of living in her big sister’s shadow. But when she starts taking secret flamenco lessons from her Papi, will she find the courage to share her new skill with the world? Includes notes on Spanish vocabulary at the end.',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Family'],
  },
  {
    id: 57,
    title: 'LMNO Peas',
    author: 'Keith Baker',
    illustrator: 'Keith Baker',
    pageCount: 40,
    pageWidth: 600,
    pageHeight: 660,
    description:
      'An energetic cast of alphabet peas "work and play in the ABCs." From acrobats, artists, and astronauts to X-ray doctors, "yogis in a pose" and zoologists, these peas teach, deep-dive and parachute in a forest of giant letters and a rainbow of colors.\n\nPrinted with permission of Simon & Schuster Children\'s Publishing, a Division of Simon & Schuster Inc.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: [],
  },
  {
    id: 406,
    title: 'Little White Fish Is So Happy',
    author: 'Guido Van Genechten',
    illustrator: 'Guido Van Genechten',
    pageCount: 24,
    pageWidth: 1200,
    pageHeight: 1249,
    description: 'A playful book about prepositions.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Animals'],
  },
  {
    id: 452,
    title: 'Little White Fish Has Many Friends',
    author: 'Guido Van Genechten',
    illustrator: 'Guido Van Genechten',
    pageCount: 24,
    pageWidth: 1200,
    pageHeight: 1253,
    description:
      'Little White Fish is a very playful fish. Luckily, he has many friends to play with. They play all sorts of games together: tag, hide-and-seek, blowing bubbles... Yes, Little White Fish and his friends have a lot of fun together!\n\nA simple book about friendship and games.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Animals', 'Friendship'],
  },
  {
    id: 229,
    title: 'Little White Fish Has a Party',
    author: 'Guido van Genechten',
    illustrator: 'Guido van Genechten',
    pageCount: 24,
    pageWidth: 1200,
    pageHeight: 1248,
    description: 'A playful book about opposites',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Animals'],
  },
  {
    id: 498,
    title: 'Little White Fish and His Daddy',
    author: 'Guido Van Genechten',
    illustrator: 'Guido Van Genechten',
    pageCount: 24,
    pageWidth: 1200,
    pageHeight: 1248,
    description:
      'Little White Fish has a lot of friends. \nAnd they all have amazing daddies. \nLittle Sea Horse’s dad is really fast. \nLittle Whale’s dad is the biggest in the ocean. \nBut, of course Little White Fish’s daddy is very good at something too!\n\nA heartwarming story for Father’s day, and every day.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Animals', 'Family'],
  },
  {
    id: 231,
    title: 'Little White Fish',
    author: 'Guido van Genechten',
    illustrator: 'Guido van Genechten',
    pageCount: 24,
    pageWidth: 1200,
    pageHeight: 1247,
    description: 'A playful book about colors',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Animals'],
  },
  {
    id: 190,
    title: 'Little Snowman Stan',
    author: 'Guido van Genechten',
    illustrator: 'Guido van Genechten',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 622,
    description:
      "Little Snowman Stan is born on an early winter's day. After standing still for weeks, he is itching to run and play, but is told that he mustn't move or he will melt. Will Little Snowman Stan choose to remain safe and motionless, or will he follow his heart?",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Science & Nature'],
  },
  {
    id: 147,
    title: 'Little Red Riding Hood',
    author: 'Brothers Grimm',
    illustrator: 'Debbie Lavreys',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 624,
    description:
      'For this retelling of the Brothers Grimm tale, the soothing illustrations make the wolf seem less fearsome. After the hunter frees Little Red Riding Hood and her grandmother, he sews up the villain and "[takes] the wolf away."',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Fairy Tales'],
  },
  {
    id: 66,
    title: "Little Quack's New Friend",
    author: 'Lauren Thompson',
    illustrator: 'Derek Anderson',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 600,
    description:
      "At first, when Little Ribbet asks five duckling siblings to play, only Little Quack accepts his invitation. But when Widdle, Waddle, Piddle and Puddle see how much fun they're having, the other feathered four join in, one by one.\n\nPrinted with permission of Simon & Schuster Children's Publishing, a Division of Simon & Schuster Inc.",
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Animals', 'Family', 'Friendship'],
  },
  {
    id: 64,
    title: 'Little Pea',
    author: 'Amy Krouse Rosenthal',
    illustrator: 'Jen Corace',
    pageCount: 36,
    pageWidth: 600,
    pageHeight: 600,
    description:
      "Everyone knows that sweet treats come after the peas and carrots. But Little Pea doesn't like dessert. Youngsters will laugh at the way his parents turn the tables.",
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Family'],
  },
  {
    id: 63,
    title: 'Little Oink',
    author: 'Amy Krouse Rosenthal',
    illustrator: 'Jen Corace',
    pageCount: 30,
    pageWidth: 600,
    pageHeight: 600,
    description:
      "Here's an unusual scene sure to unleash your child's squeals of glee: a piglet who likes everything in its place, and parents who encourage their boy to make his room into a pig sty!",
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Family', 'Animals'],
  },
  {
    id: 253,
    title: 'Little Kangaroo',
    author: 'Guido Van Genechten',
    illustrator: 'Guido Van Genechten',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1271,
    description:
      '"The world is big ... Much, much bigger than Mother Kangaroo\'s pouch. But Little Kangaroo would rather stay with Mommy, where it\'s safer, warmer and easier. Mom tries to show Little Kangaroo all that life has to offer. It does no good at first, but then...\n\nA splendid picture book about letting go in a loving way. "',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Science & Nature', 'Family', 'Animals'],
  },
  {
    id: 62,
    title: 'Little Hoot',
    author: 'Amy Krouse Rosenthal',
    illustrator: 'Jen Corace',
    pageCount: 34,
    pageWidth: 600,
    pageHeight: 600,
    description:
      'In this comical tale, Little Hoot just wants to go to sleep when it\'s dark out, but it\'s his parents who roll out the familiar stall tactics. Papa Owl suggests a glass of water, while Mama Owl says, "What about a bedtime story?"',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Bedtime', 'Family', 'Animals'],
  },
  {
    id: 286,
    title: 'Listen, Listen',
    author: 'Phillis Gershator',
    illustrator: 'Alison Jay',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1200,
    description:
      "Introduce young readers to the sights and sounds of the year - from summer's sizzling sun to winter's crackling snow. Featuring breathtaking illustrations by internationally renowned illustrator, Alison Jay, this book will open your child's eyes and ears to the world around them!",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr', '0 - 2 yr'],
    categories: ['Science & Nature', 'Poems & Rhyming'],
  },
  {
    id: 246,
    title: 'Lin Yi’s Lantern',
    author: 'Brenda Williams',
    illustrator: 'Benjamin Lacombe',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1163,
    description:
      'Meet Lin Yi — a little boy with a big heart and a talent for bargaining. He wants to buy himself a red rabbit lantern at the market for the moon festival tonight; but first, he must buy the things his mother needs. Will he be able to save enough money on his mother’s needs to buy the lantern? This heartwarming story shows the practical use of math in everyday life and the rewards of putting others first. Features educational notes at the end about the Chinese moon festival, life in rural China and the legend of the moon fairy.',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Travel', 'Family'],
  },
  {
    id: 323,
    title: 'Like Pickle Juice',
    author: 'Julie Sternberg',
    illustrator: 'Matthew Cordell',
    pageCount: 132,
    pageWidth: 1200,
    pageHeight: 1622,
    description:
      'Eleanor’s beloved babysitter, Bibi, is moving away. Suddenly, the things she used to enjoy aren’t fun anymore—everything reminds her of Bibi. To make matters worse, Eleanor has a new babysitter, who just isn’t the same. But as the new school year looms ahead, so do new beginnings. And Eleanor is about to learn some special things about herself, friendship, and the bittersweet process of growing up.',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Chapter Books'],
  },
  {
    id: 56,
    title: 'Lightship',
    author: 'Brian Floca',
    illustrator: 'Brian Floca',
    pageCount: 44,
    pageWidth: 600,
    pageHeight: 667,
    description:
      'Anyone who has dreamed of a life on the high seas will be fascinated by this account of the men who live and work aboard the lightship, right down to "the oiler and the engineer [who] keep [her] clean and running," so the ship can do her important duty.\n\nPrinted with permission of Simon & Schuster Children\'s Publishing, a Division of Simon & Schuster Inc.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Transportation', 'Travel'],
  },
  {
    id: 4,
    title: 'Library Lion',
    author: 'Michelle Knudsen',
    illustrator: 'Kevin Hawkes',
    pageCount: 48,
    pageWidth: 600,
    pageHeight: 697,
    description:
      'One day, a lion walks into the library. Because he isn\'t breaking any rules, the head librarian says, "Leave him be." Then one day, the lion breaks a rule and knows he must leave the library. Except that he broke the rule for a very good reason. Will the lion come back?',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Fairy Tales', 'Animals', 'Friendship'],
  },
  {
    id: 261,
    title: 'Let’s Celebrate! Special Days Around the World',
    author: 'Kate DePalma',
    illustrator: 'Martina Peluso',
    pageCount: 44,
    pageWidth: 1200,
    pageHeight: 1162,
    description:
      'Experience 13 special days from cultures around the world! Rhyming text and vibrant illustrations throughout encourages beginning readers, while educational notes at the end brim with facts about the special days for older children to explore. Complete with a calendar to reinforce learning, Let’s Celebrate! makes for a fun and informative companion to the award-winning Barefoot Books Children of the World.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: [
      'Travel',
      'Poems & Rhyming',
      'Family',
      'Non-Fiction',
      'Friendship',
    ],
  },
  {
    id: 8,
    title: "Let's Do Nothing!",
    author: 'Tony Fucile',
    illustrator: 'Tony Fucile',
    pageCount: 42,
    pageWidth: 600,
    pageHeight: 696,
    description:
      'Buddies Frankie and Sal have "played every sport ever invented," painted more pictures than van Gogh, and "baked enough cookies to feed a small country." What\'s left for them to do? Nothing. That\'s it, Frankie thinks, "Let\'s do nothing!" But doing nothing is harder than you think!',
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Friendship'],
  },
  {
    id: 503,
    title: 'Les pancakes de Maman Panya',
    author: 'Mary and Rich Chamberlin',
    illustrator: 'Julia Cairns',
    pageCount: 44,
    pageWidth: 1200,
    pageHeight: 1162,
    description:
      "On market day, Mama Panya's son Adika invites everyone he sees to a pancake dinner. How will Mama Panya ever feed them all? This clever and heartwarming story about Kenyan village life teaches the importance of sharing, even when you have little to give. Includes educational endnotes about life in Kenya.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['French', 'Family', 'Friendship'],
  },
  {
    id: 417,
    title: 'Leaf Can Be . . .',
    author: 'Hosford, Kate',
    illustrator: 'Swiatkowska, Gabi',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1232,
    description:
      'A leaf can be a...shade spiller, mouth filler, tree topper, rain stopper. Find out about the many roles leaves play in this poetic exploration of leaves throughout the year.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Science & Nature', 'Poems & Rhyming'],
  },
  {
    id: 111,
    title: 'Knick Knack Paddy Whack',
    author: 'Christiane Engel',
    illustrator: 'Christiane Engel',
    pageCount: 28,
    pageWidth: 600,
    pageHeight: 581,
    description:
      'This classic children\'s counting song springs to life with a multicultural cast playing musical instruments: "This old man, he played one, he played knick knack on his drum..." As a bonus, children can look for a dog and its bone in every scene.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: [],
  },
  {
    id: 191,
    title: "Kevin's Christmas",
    author: 'Liesbet Slegers',
    illustrator: 'Liesbet Slegers',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 629,
    description:
      'With the discovery of a "sweet" and "spicy" tree inside his house, Kevin begins to learn about his family\'s Christmas traditions. After decorating the tree, setting up the nativity, and eating delicious food, Kevin finds out that the best part of Christmas is sharing it with his family.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Family'],
  },
  {
    id: 278,
    title: 'Kevin and Katie',
    author: 'Liesbet Slegers',
    illustrator: 'Liesbet Slegers',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1268,
    description:
      'When Kevin meets his new neighbor girl, Katie, he wonders if they will like each other and become friends. This heartwarming story with vivid illustrations teaches an important lesson about friendship.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Friendship'],
  },
  {
    id: 214,
    title: "Katie's Day with Grandma",
    author: 'Liesbet Slegers',
    illustrator: 'Liesbet Slegers',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 629,
    description:
      'Katie loves visiting her Grandma, who always has time for Katie.  Together, they grocery shop, make surprise pancakes for Grandpa, string a bead necklace, and top it off with a bedtime story.  Nothing is more wondeful than spending time with sweet Grandma.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Family'],
  },
  {
    id: 249,
    title: 'Just Like Brothers',
    author: 'Elizabeth Baguley',
    illustrator: 'Aurelie Blanz',
    pageCount: 38,
    pageWidth: 1200,
    pageHeight: 1511,
    description:
      "A heartwarming fable for our time! A human mother tells her small son to watch out for wolves, and a wolf mother tells her cub to fear men — but their children don't listen. What will happen when the boy and the cub cross paths in the forest? This simple but profound story makes it easy to start conversations about prejudice, empathy and trust.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Fairy Tales', 'Family', 'Animals', 'Friendship'],
  },
  {
    id: 70,
    title: 'Jeepers Creepers: A Monstrous ABC',
    author: 'Laura Leuck',
    illustrator: 'David Parkins',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 671,
    description:
      'A cast of not-too-scary monsters lead youngsters through the alphabet, from Ann with "alligator skin" to Zelda who\'s "tall and getting taller." They turn the tables to play a joke on readers.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: [],
  },
  {
    id: 484,
    title: "J'ai emmené la lune se promener",
    author: 'Carolyn Curtis',
    illustrator: 'Alison Jay',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1511,
    description:
      'Embark on a dreamy, nighttime jaunt with a young boy and the moon. Overcoming a fear of the dark and discovering the world at night lives at the heart of this poetic tale. Includes notes about the moon and plants and animals that thrive in the wee hours.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['French', 'Bedtime', 'Science & Nature', 'Poems & Rhyming'],
  },
  {
    id: 324,
    title: 'Itzhak - A Boy Who Loved the Violin',
    author: 'Tracy Newman',
    illustrator: 'Abigail Halpin',
    pageCount: 48,
    pageWidth: 1200,
    pageHeight: 1467,
    description:
      'Before becoming one of the greatest violinists of all time, Itzhak Perlman was simply a boy who loved music. Raised by a poor immigrant family in a tiny Tel Aviv apartment, baby Itzhak was transformed by the sounds from his family’s kitchen radio—graceful classical symphonies, lively klezmer tunes, and soulful cantorial chants. The rich melodies and vibrant rhythms spoke to him like magic, filling his mind with vivid rainbows of color. After begging his parents for an instrument, Itzhak threw his heart and soul into playing the violin. Despite enormous obstacles—including a near-fatal bout of polio that left him crippled for life—Itzhak persevered, honing his extraordinary gift. When he performed on the Ed Sullivan Show sat only 13, audiences around the world were mesmerized by the warmth, joy, and passion in every note. Gorgeously illustrated with extensive back matter, this picture-book biography recounts Itzhak’s childhood journey—from a boy with a dream to an internationally acclaimed violin virtuoso.',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Health', 'Family'],
  },
  {
    id: 3,
    title: 'Ish',
    author: 'Peter H. Reynolds',
    illustrator: 'Peter H. Reynolds',
    pageCount: 32,
    pageWidth: 576,
    pageHeight: 538,
    description:
      '"Ramon loved to draw. Anytime. Anything. Anywhere." After his older brother makes fun of his drawing of a vase of flowers, however, nothing looks right to Ramon. Then he discovers that his younger sister rescued the discarded drawing. She celebrates its "vase-ISH!" qualities, and gives Ramon a new way of looking at the world.',
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Family', 'Poems & Rhyming'],
  },
  {
    id: 203,
    title: 'Is Everyone Ready for Fun?',
    author: 'Jan Thomas',
    illustrator: 'Jan Thomas',
    pageCount: 40,
    pageWidth: 600,
    pageHeight: 600,
    description:
      "A hilarious and silly tale about three cows who just want to have fun! ...Unfortunately, it's all at the expense of poor chicken's sofa.  A fun (and funny!) story that will get you and your reader moving, wiggling, and giggling.",
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr', '6 - 8 yr'],
    categories: ['Animals'],
  },
  {
    id: 142,
    title: 'Interrupting Chicken',
    author: 'David Ezra Stein',
    illustrator: 'David Ezra Stein',
    pageCount: 40,
    pageWidth: 600,
    pageHeight: 489,
    description:
      "A little red chicken won't go to bed until Papa reads her a story. The trouble is, she keeps jumping in to save Hansel and Gretel from the witch, and Little Red Riding Hood from the wolf! Will she ever go to sleep?",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Bedtime'],
  },
  {
    id: 429,
    title: 'Infinity and Me',
    author: 'Kate Hosford',
    illustrator: 'Gabi Swiatkowska',
    pageCount: 44,
    pageWidth: 1200,
    pageHeight: 1467,
    description:
      '"When I looked up, I shivered. How many stars were in the sky? A million? A billion? Maybe the number was as big as infinity. I started to feel very, very small. How could I even think about something as big as infinity?\n\nUma can\'t help feeling small when she peers up at the night sky. She begins to wonder about infinity. Is infinity a number that grows forever? Is it an endless racetrack? Could infinity be in an ice cream cone? Uma soon finds that the ways to think about this big idea may just be . . . infinite."',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Numbers & Alphabet', 'Non-Fiction'],
  },
  {
    id: 441,
    title: 'In Great Numbers - How Numbers Shape the World We Live In',
    author:
      'Isabel Thomas, Robert Klanten, Maria-Elisabeth Niebius, and Raphael Honigstein',
    illustrator: 'Daniela Olejníková',
    pageCount: 64,
    pageWidth: 1200,
    pageHeight: 1400,
    description:
      'Over the centuries, people have conceived clever ways to use numbers so that we can understand the world around us better, build cities, find our way home, and much more. In Great Numbers tells the story of this groundbreaking invention.\n\nNumbers are so important in our daily lives. How else would we ask for three scoops of ice cream? Or know whether we’ve got 60 minutes left to play with our friends rather than 60 seconds? Why does a minute have 60 seconds anyway and not 100? Where does zero come from and what language do computers understand?\n\nNumbers are everywhere! In Great Numbers will introduce children to the fascinating world of numbers and explains how they help us in our daily life. 1, 2, 3, go!\n\nSlovakian illustrator Daniela Olejníková is passionate about screen printing and linocut and has illustrated more than 20 titles for junior readers.',
    free: false,
    ageRanges: ['9 - 11 yr'],
    categories: ['Numbers & Alphabet', 'Science & Nature', 'Non-Fiction'],
  },
  {
    id: 60,
    title: 'Imagine a Night',
    author: 'Sarah L. Thomson',
    illustrator: 'Rob Gonsalves',
    pageCount: 40,
    pageWidth: 600,
    pageHeight: 600,
    description:
      "This nighttime fantasy brims with Escher-like puzzles of patchwork bed quilts that transform into rolling hills of patchwork fields, sunflowers that seem to turn into people, and candles that flicker into starlight.\n\nPrinted with permission of Simon & Schuster Children's Publishing, a Division of Simon & Schuster Inc.",
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: [],
  },
  {
    id: 19,
    title: "If You're Happy and You Know It",
    author: 'Anna McQuinn',
    illustrator: 'Sophie Fatus',
    pageCount: 28,
    pageWidth: 600,
    pageHeight: 581,
    description:
      'The song lyrics, "If you\'re happy and you know it (clap your hands!)," here become a jumping off point for glorious illustrations of children around the globe who showcase their culture\'s dress, homes, and language (each says "hello"). ',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Friendship'],
  },
  {
    id: 418,
    title: 'If You Were the Moon',
    author: 'Salas, Laura Purdie',
    illustrator: 'Kim, Jaime',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1232,
    description:
      'What would you do if you were the moon? Do you think you would rest quietly in the night sky? Oh, no. The moon does so much more than you might imagine! It spins like a twilight ballerina, plays tug-of-war with the ocean, and lights a pathway for baby sea turtles. Discover the many other roles the moon plays in this whimsical and lyrical picture book.',
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['Science & Nature'],
  },
  {
    id: 210,
    title: "Ian's New Potty",
    author: 'Pauline Oud',
    illustrator: 'Pauline Oud',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 624,
    description:
      "Ian's mom has brought home a bright red potty and new big boy's underpants. Ian is so excited to be a big boy, but using the potty takes practice, and some patience.  With simple, clear text, this book is a helpful and encouraging introduction to toilet training.",
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['Health', 'Family'],
  },
  {
    id: 435,
    title: "Ian at Grandma and Grandpa's",
    author: 'Pauline Oud',
    illustrator: 'Pauline Oud',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1248,
    description:
      'Ian packs his little suitcase because he is going to Grandma and Grandpa’s house! Grandpa and Grandma have a wonderful time with Ian. They make Ian laugh with their funny ways: Why does Grandpa go shopping with a shovel? Why is Grandma’s guest bed so nice and warm? And Curly the dog does the silliest things….\n\nA fun and reassuring picture book about a toddlers who loves to spend time with his grandparents. With lots of humor, Pauline Oud tells a story about the special bond between grandparents and their grandchildren',
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['Family'],
  },
  {
    id: 281,
    title: 'I Took the Moon for a Walk',
    author: 'Carolyn Curtis',
    illustrator: 'Alison Jay',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1511,
    description:
      'Embark on a dreamy, nighttime jaunt with a young boy and the moon. Overcoming a fear of the dark and discovering the world at night lives at the heart of this poetic tale. Includes notes about the moon and plants and animals that thrive in the wee hours',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Bedtime', 'Science & Nature', 'Poems & Rhyming'],
  },
  {
    id: 157,
    title: 'I Must Have Bobo',
    author: 'Eileen Rosenthal',
    illustrator: 'Marc Rosenthal',
    pageCount: 36,
    pageWidth: 600,
    pageHeight: 600,
    description:
      "Willy wakes up and cannot find Bobo, his sock monkey. With Bobo, Willy is brave enough to go down the slide and walk by the big dog down the street. Willy must have Bobo! Where could Bobo be?\n\nPrinted with permission of Simon & Schuster Children's Publishing, a Division of Simon & Schuster Inc.",
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Animals', 'Family'],
  },
  {
    id: 209,
    title: 'I Have Two Homes',
    author: 'Marian De Smet',
    illustrator: 'Nynke Talsma',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 712,
    description:
      'A beautiful and sensitive story about Nina, a little girl who tells about how it feels now that her parents have split up.  Though they used to be a happy family living in one home, things changed, and Nina discusses the difficulties of adjusting to two homes.  But she is also reassured by her mom and dad that their love for her will never change.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Family'],
  },
  {
    id: 338,
    title: 'I Had a Favorite Hat',
    author: 'Boni Ashburn',
    illustrator: 'Robyn Ng',
    pageCount: 40,
    pageWidth: 1200,
    pageHeight: 1474,
    description:
      'The narrator of this charming picture book loves her summer hat, but as the seasons change, her hat isn’t always appropriate for every occasion. She must use her crafting skills to turn the hat into a work of art, perfect for every season and holiday. Featuring the same characters from the first book, I Had a Favorite Dress, along with the hip, eye-catching art style that won it so many fans, this book is perfect for young crafters and their stylish parents.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: [],
  },
  {
    id: 282,
    title: 'I Could Be, You Could Be',
    author: 'Carolyn Curtis',
    illustrator: 'Barroux',
    pageCount: 28,
    pageWidth: 1200,
    pageHeight: 1162,
    description:
      "Join a young boy and girl on thrilling adventures as they imagine themselves as space-traveling astronauts, ferocious dragons, jungledwelling monkeys and more. The fine line between children's make-believe and the fantastic worlds they create is cleverly portrayed through Barroux’s bright landscapes.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Poems & Rhyming'],
  },
  {
    id: 516,
    title: 'I Am Brown',
    author: 'Ashok Banker',
    illustrator: 'Sandhya Prabhat',
    pageCount: 40,
    pageWidth: 1200,
    pageHeight: 1309,
    description:
      "I am brown. I am beautiful. I am perfect. I designed this computer. I ran this race. I won this prize. I wrote this book. A joyful celebration of the skin you're in—of being brown, of being amazing, of being you.",
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Family'],
  },
  {
    id: 392,
    title: 'How the Continents Move',
    author: 'Jan Leyssens',
    illustrator: 'Joachim Sneyers',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1646,
    description:
      'In the early part of the twentieth century, the researcher Marie Tharp worked on a detailed map of the bottom of the ocean. And then she made a discovery?a chasm down the middle of the Atlantic Ocean. Could this discovery help prove the age-old theory that the continents on Earth move?',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Science & Nature', 'Non-Fiction'],
  },
  {
    id: 439,
    title: 'How Big is Big? How Far is Far? All Around Me',
    author: 'Edited by Robert Klanten and Angela Sangma Francis',
    illustrator: 'Jun Cen',
    pageCount: 56,
    pageWidth: 1200,
    pageHeight: 1400,
    description:
      'How Big is Big? How Far is Far? All Around Me was created to inform imaginatively. After all, why not measure how dense the densest fur could be by the size of a thumb? Or think of speed as though it were a snowflake gliding through the air? Jun Cen’s artwork uses a muted palette and minimal graphics which pay homage to the vintage ladybird pocketbook classics, whilst the design and typography lends it a modern breath for playful learning.\nHow Big is Big? How Far is Far? All Around Me is the follow-on title from the Little Gestalten favourite How big is Big? How Far is Far?. Taking new comparisons which focus on our planet, not only will children learn about all the different ways to measure, but they’ll also discover new facts about the incredible world we live in. Warning: parents may need to buy a measuring tape.\n\nJun Cen is a Chinese artist who is currently based in the US. He is the Overall New Talent winner of the 2013 Association of Illustrators Award. His work has been recognized by The Society of Illustrators, American Illustration, 3 x 3 Illustration Competition and more. His list of clients include: The New York Times, The Washington Post, The Boston Globe, The Verge, Grantland, Oxford University Press, Nobrow and many others.',
    free: false,
    ageRanges: ['9 - 11 yr'],
    categories: [
      'Numbers & Alphabet',
      'Science & Nature',
      'Animals',
      'Non-Fiction',
    ],
  },
  {
    id: 7,
    title: 'Ho Ho Ho Tucker',
    author: 'Leslie McGuirk',
    illustrator: 'Leslie McGuirk',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 632,
    description:
      '"All puppies dream about Christmas," and Tucker, a Westie, is no exception. He makes a snowman for Santa (but eats the carrot nose) and sings Christmas carols (like "Bark the Herald Angels Sing!"). He even earns a special place on Santa\'s team...',
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['Family', 'Animals'],
  },
  {
    id: 242,
    title: 'Here and There',
    author: 'Tamara Ellis Smith',
    illustrator: 'Evelyn Daviddi',
    pageCount: 34,
    pageWidth: 1200,
    pageHeight: 1511,
    description:
      "A young boy experiences the early stages of his parents' separation and finds hope in the beauty and music of nature. This tale of personal growth provides a much-needed mirror for children in times of change and an important reminder for all that there's beauty everywhere.",
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Family'],
  },
  {
    id: 18,
    title: 'Herb, the Vegetarian Dragon',
    author: 'Jules Bass',
    illustrator: 'Debbie Harter',
    pageCount: 36,
    pageWidth: 600,
    pageHeight: 756,
    description:
      'The meat-eating dragons of Nogard Forest enjoy "the sweet taste of royal princesses, and the crispy crunch of brave knights." But Herb is not like the other dragons. He prefers his vegetable patch. Will he be spared when the brave knights vow to rid the forest of dragons?',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Fairy Tales', 'Science & Nature'],
  },
  {
    id: 432,
    title: 'Have You Seen Elephant?',
    author: 'David Barrow',
    illustrator: 'David Barrow',
    pageCount: 34,
    pageWidth: 1200,
    pageHeight: 1378,
    description:
      '"Elephant wants to play hide and seek. See if you can help the others find him—he\'s very good at hiding!\n\nThis tale of absurdity is perfect for sharing with children who will love finding Elephant (and being faster at it than the boy in the book!). Watch out for the dog and the tortoise, too . . ."',
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['Animals', 'Friendship'],
  },
  {
    id: 447,
    title: 'Hard Hat Area',
    author: 'Susan L. Roth',
    illustrator: 'Susan L. Roth',
    pageCount: 40,
    pageWidth: 1200,
    pageHeight: 1403,
    description:
      "A unique day-in-the-life look at a real girl named Kristen who works as an ironworkers apprentice on some of New York City's tallest buildings. Following Kristen around the construction site, readers will learn what tools the workers use, how they use them, and how they work as a team. With a fascinating variety of materials, labels, and photographs from an actual site where Kristen worked, Susan L. Roth has created a masterpiece of creative collage-and an eye-popping learning experience for readers of all ages.",
    free: false,
    ageRanges: ['9 - 11 yr'],
    categories: ['Jobs', 'Non-Fiction'],
  },
  {
    id: 182,
    title: 'Happy Halloween',
    author: 'Liesbet Slegers',
    illustrator: 'Liesbet Slegers',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 434,
    description:
      "Through simple words and bright illustrations, a little girl tells about her fun-filled Halloween day.  She celebrates by carving pumpkins with Daddy, eating Mommy's pumpkin soup, dressing up as a witch, and trick-or-treating with her friends.  But as the day draws to a close, she puts away her costume and candy--and any thoughts of ghosts or witches!--and drifts off to sleep with sweet dreams.",
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr', '6 - 8 yr'],
    categories: ['Family'],
  },
  {
    id: 180,
    title: 'Guess Where I Am?',
    author: 'Liesbet Slegers',
    illustrator: 'Liesbet Slegers',
    pageCount: 28,
    pageWidth: 600,
    pageHeight: 600,
    description:
      'In this fun and engaging series, children can use the brightly colored illustrations as clues to guess the location of a little boy, who finally ends up in his cozy bed after a day of playing, shopping, and running errands.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Bedtime'],
  },
  {
    id: 181,
    title: "Guess What I'm Doing?",
    author: 'Liesbet Slegers',
    illustrator: 'Liesbet Slegers',
    pageCount: 28,
    pageWidth: 600,
    pageHeight: 600,
    description:
      '"Here is my shirt, and there are my pants.  What am I doing?" In this fun and engaging series, children will enjoy guessing the everyday activities of a little boy, using the brightly colored illustrations as clues.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: [],
  },
  {
    id: 6,
    title: 'Great Joy',
    author: 'Kate DiCamillo',
    illustrator: 'Bagram Ibatoulline',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 697,
    description:
      'The week before Christmas, young Frances notices an organ grinder and his monkey outside her apartment window. She worries about where they go at night. After she invites them to the Christmas pageant at the nearby church, a Christmas miracle occurs. ',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Family'],
  },
  {
    id: 311,
    title: 'Grandpa’s Garden',
    author: 'Stella Fry',
    illustrator: 'Sheila Moxley',
    pageCount: 44,
    pageWidth: 1200,
    pageHeight: 1356,
    description:
      'This beautifully told story follows Billy from early spring to late summer as he helps his grandpa on his vegetable patch. They dig the hard ground, sow rows of seeds, and keep them watered and safe from slugs. When harvest time arrives they can pick all the vegetables and fruit they have grown. Children will be drawn in by the poetry of the language and the warm illustrations, while also catching the excitement of watching things grow! Includes educational endnotes on gardening throughout the year.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Science & Nature', 'Family'],
  },
  {
    id: 232,
    title: 'Good-bye Fish',
    author: 'Judith Koppens',
    illustrator: 'Elain van Lindenhuizen',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1258,
    description: 'A gentle first story about loss, grief, and hope.',
    free: false,
    ageRanges: [],
    categories: [],
  },
  {
    id: 353,
    title: 'Good Guys, Bad Guys',
    author: 'Joanne Rocklin',
    illustrator: 'Nancy Carpenter',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1474,
    description:
      'This charming picture book is a timeless celebration of imagination and make-believe. A brother and sister invite their friends to play good guys and bad guys with them. While playing pretend, this rowdy group of kids dress up as pirates, swimmers, cowboys, and more—imagining a vibrant world where the bad guys cause mischief and the good guys save the day. As the day comes to a close, we see the siblings agree to switch who’s “good” and who’s “bad” the next day—showing readers that kids can safely try out good and bad personas as they play.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Poems & Rhyming', 'Friendship'],
  },
  {
    id: 228,
    title: 'Going to the Zoo with Lily and Milo',
    author: 'Pauline Oud',
    illustrator: 'Pauline Oud',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1257,
    description:
      'A fun story about the animals in the zoo with surprises to discover on every page.',
    free: false,
    ageRanges: [],
    categories: [],
  },
  {
    id: 230,
    title: 'Going to the Beach with Lily and Milo',
    author: 'Pauline Oud',
    illustrator: 'Pauline Oud',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1255,
    description:
      'A delightful story about going to the beach and choosing the right beach equipment.',
    free: false,
    ageRanges: [],
    categories: [],
  },
  {
    id: 445,
    title: 'Giant Machines',
    author: 'Seymour Simon',
    illustrator: 'Multiple Photographers',
    pageCount: 34,
    pageWidth: 1200,
    pageHeight: 36,
    description:
      'Bulldozers tear huge rocks from the ground. Tower cranes lift heavy loads on bridges and in shipyards. Dig into GIANT MACHINES to learn more about these machines and many other powerful tools!',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Transportation', 'Jobs', 'Non-Fiction'],
  },
  {
    id: 212,
    title: 'Get Well Soon, Grandpa',
    author: 'Ann Swerts',
    illustrator: 'Jenny Bakker',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 624,
    description:
      'Faye is devastated when her beloved Grandpa Bert suffers a stroke and is rushed to the hospital.  She visits Grandpa every day and notices that even though he has a lopsided smile, and is sometimes hard to understand, she is still his sweet girl.  A gentle, sensitive story about a girl and her grandfather, and the healing and recovery that comes from their love.',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Family'],
  },
  {
    id: 192,
    title: 'Friends',
    author: 'Liesbet Slegers',
    illustrator: 'Liesbet Slegers',
    pageCount: 14,
    pageWidth: 600,
    pageHeight: 600,
    description:
      'Vibrant and colorful illustrations of a purring cat, a bubbly fish, a playful older sister, a tall daddy and a gentle mommy teach about love and friendship.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Family', 'Friendship'],
  },
  {
    id: 341,
    title: 'Fraidy Zoo',
    author: 'Thyra Heder',
    illustrator: 'Thyra Heder',
    pageCount: 48,
    pageWidth: 1200,
    pageHeight: 1322,
    description:
      'Today is the perfect day for the zoo! But Little T is afraid to go. The only trouble is, she can’t remember what scared her at the zoo the last time. To help her remember, her mom, dad, and sister come up with an ingeniously creative solution: in a flurry of bubble wrap, umbrellas, water bottles, salad tongs, a mop, a vacuum cleaner, and much, much more, her family brings to life an astounding parade of zoo animals—in alphabetical order! Can you guess which animals the family is acting out? And can Little T stop feeling like such a “fraidyzoo”?\n \nIn this charming and witty picture book, award-winning author-illustrator Thyra Heder honors the bravery it takes to face your fears, and the loving people who help you do so.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Family', 'Animals'],
  },
  {
    id: 488,
    title: 'Floro, el dragón vegetariano',
    author: 'Jules Bass',
    illustrator: 'Debbie Harter',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1511,
    description:
      "Meet Herb, the dragon who prefers gardening to gobbling meat. When his vegetable-loving ways land him in the castle's dungeon, will he be able to stay true to his values? Packed with wacky visual details and quirky dialogue, Herb, the Vegetarian Dragon overturns your expectations again and again, making for a delightful and thought-provoking read every time.",
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Spanish', 'Fairy Tales', 'Science & Nature'],
  },
  {
    id: 312,
    title: 'Five Little Mermaids',
    author: 'Sunny Scribens',
    illustrator: 'Barbara Vagnozzi',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1162,
    description:
      'Five mermaids guide children on a whimsical singalong journey through the world’s oceans, counting down from 5 to 0. Includes educational notes about mermaid legends, marine animals, and the five oceans, as well as a catchy song and video animation to reinforce STEM learning.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Numbers & Alphabet', 'Travel', 'Poems & Rhyming'],
  },
  {
    id: 227,
    title: 'Firefighters and What They Do',
    author: 'Liesbet Slegers',
    illustrator: 'Liesbet Slegers',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1248,
    description:
      'Fires are really dangerous.  Luckily there are fighfighters!  An instructive book about the brave men who fight fires.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Transportation', 'Science & Nature', 'Jobs', 'Non-Fiction'],
  },
  {
    id: 486,
    title: 'Fast and Slow/Rápido y lento',
    author: 'Britta Teckentrup',
    illustrator: 'Britta Teckentrup',
    pageCount: 16,
    pageWidth: 1200,
    pageHeight: 1165,
    description:
      'Little ones love things that go! Introduce early Spanish and English vocabulary about modes of transportation with this essential bilingual baby book. The appealing, textured artwork illustrates the concept of opposites by comparing slow-moving vehicles like hot-air balloons and bikes with speedy machines like cars and rockets.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Spanish', 'Transportation', 'Jobs'],
  },
  {
    id: 287,
    title: 'Emily’s Tiger',
    author: 'Miriam Latimer',
    illustrator: 'Miriam Latimer',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1511,
    description:
      "When Emily's temper flares, she turns into a tiger! Will Granny be able to help her? With its refreshing focus on intergenerational relationships, this quirky picture book is a fun way to start conversations about frustration and how to manage anger.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Health'],
  },
  {
    id: 440,
    title: 'Elsa and the Night',
    author: 'Jöns Mellgren',
    illustrator: 'Jöns Mellgren',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1450,
    description:
      'Can one make friends with the night? In this story, Elsa can! How, may you ask, could someone even meet the night? Well, one day Elsa is sitting at her kitchen table and, because she is bored, counting the raisins in her cereal. She then suddenly hears a sound coming from under the sofa. When she investigates and catches the thing making the noise, she realizes that it’s ­neither an animal nor a ghost. It’s the night.\n\nBecause Elsa winds up hiding the night in her cookie jar—and giving it a few of her raisins—the day outside doesn’t end. When she takes the night out of its hiding place, it has the chance to hear her talk about her adventures with her friend, an elephant named Olaf. Elsa tells the night that she is very sad because Olaf got sick and no ­medicine was able to help him. The night not only listens to Elsa, but also accompanies her on a heartwarming and moving journey where it cares for her, protects her, and finally carries her through the town in its arms.\n\nBorn in Sweden in 1976, Jöns Mellgren is an author, illustrator, and filmmaker. In 1999, he published his first children’s picture book called Night in the Jungle. This debut was followed by Mustafa and the Storm in 2009 and Rufus in the Underworld in 2010. With Elsa and the Night, Mellgren has added another beautifully poetic picture book to his triptych.',
    free: false,
    ageRanges: ['9 - 11 yr'],
    categories: ['Bedtime', 'Friendship'],
  },
  {
    id: 465,
    title: 'Ellos nadan por los océanos',
    author: 'Seymour Simon',
    illustrator: 'Elsa Warnick',
    pageCount: 42,
    pageWidth: 1200,
    pageHeight: 1006,
    description:
      'Los océanos del mundo son carreteras transitadas por las criaturas en movimiento. Los destellos fascinantes de la misteriosa migración de las criaturas marinas cautivarán y asombrará a los lectores jóvenes.',
    free: false,
    ageRanges: ['9 - 11 yr'],
    categories: ['Spanish', 'Science & Nature', 'Animals', 'Non-Fiction'],
  },
  {
    id: 492,
    title: 'El recorrido de agua dulce',
    author: 'Andrew Glass',
    illustrator: 'Andrew Glass',
    pageCount: 50,
    pageWidth: 1200,
    pageHeight: 1590,
    description:
      'In 1860 the only Pony Express job 13-year-old Will Cody could land was the "sweat and water run," taking care of the tired and thirsty ponies. But one chilly November morning, Will has his big chance. The news of the U.S. presidential election has been entrusted to the pony boys, and from the looks of it, only Will would be able to get the mail through. But should he risk his own safety and the wrath of his boss, the Terrible Slade, and ride himself?',
    free: false,
    ageRanges: ['9 - 11 yr'],
    categories: ['Spanish', 'Non-Fiction'],
  },
  {
    id: 508,
    title: 'El mundo',
    author: 'Barefoot Books',
    illustrator: 'Christopher Corr',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1162,
    description:
      'Back in print! Sing around the world, from mountains to valleys, in towns and cities, with all the fish, birds, trees and flowers of the Earth! Adapted from the popular spiritual song, and with helpful endnotes, this is a wonderfully uplifting and richly informative singalong book.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Spanish', 'Poems & Rhyming'],
  },
  {
    id: 487,
    title: 'Èl huerto del abuelo',
    author: 'Stella Fry',
    illustrator: 'Sheila Moxley',
    pageCount: 42,
    pageWidth: 1200,
    pageHeight: 1356,
    description:
      'This beautifully told story follows Billy from early spring to late summer as he helps his grandpa on his vegetable patch. They dig the hard ground, sow rows of seeds, and keep them watered and safe from slugs. When harvest time arrives they can pick all the vegetables and fruit they have grown. Children will be drawn in by the poetry of the language and the warm illustrations, while also catching the excitement of watching things grow! Includes educational endnotes on gardening throughout the year.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Spanish', 'Science & Nature', 'Family'],
  },
  {
    id: 513,
    title: 'El girar de las ruedas',
    author: 'Shelley Rotner',
    illustrator: 'Shelley Rotner',
    pageCount: 34,
    pageWidth: 1200,
    pageHeight: 1403,
    description:
      'Hay ruedas en todas partes: ruedas que entregan bienes, ayudan a las personas a hacer su trabajo y ayudan a construir y arreglar cosas.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Spanish', 'Transportation', 'Jobs', 'Non-Fiction'],
  },
  {
    id: 463,
    title: 'El día de los dinosaurios',
    author: 'Liza Donnelly',
    illustrator: 'Liza Donnelly',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1403,
    description:
      'Loco por los dinosaurios, un niño y su perro imaginan que ven a uno debajo de cada bulto de nieve hasta que un día reciben una gran sorpresa. Incluye un glosario ilustrado con varios dinosaurios y sus características.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Spanish', 'Animals'],
  },
  {
    id: 127,
    title: 'Eating',
    author: 'Liesbet Slegers',
    illustrator: 'Liesbet Slegers',
    pageCount: 14,
    pageWidth: 600,
    pageHeight: 600,
    description:
      'This toddler hero helps youngest children name all the things they use at mealtime, from putting on a "bib," to using a "cup" and "spoon" and clapping hands after a "good job eating."',
    free: false,
    ageRanges: ['0 - 2 yr'],
    categories: [],
  },
  {
    id: 226,
    title: 'Duck is Stuck',
    author: 'Zoubida Mouhssin',
    illustrator: 'Pascale Moutte-Baur',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 869,
    description: 'A funny and sweet story about friendship for animal lovers',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Animals'],
  },
  {
    id: 502,
    title: 'Driving My Tractor',
    author: 'Jan Dobbins',
    illustrator: 'David Sim',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1162,
    description:
      'Chug along with a farmer and his tractor on this multi-season animal adventure! A busy farmer picks up fifteen animals along his route, but when his trailer hits a stone, chaos ensues. This colorful book combines simple counting instruction with humor, repetition and rhythm to encourage learning fun. Includes educational endnotes on the different machines farmers use and the crops they grow!',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Transportation', 'Animals', 'Poems & Rhyming'],
  },
  {
    id: 458,
    title: 'Dragon Dancer',
    author: 'Joyce Chng',
    illustrator: 'Jeremy Pailler',
    pageCount: 40,
    pageWidth: 1200,
    pageHeight: 1200,
    description:
      '"It is the eve of Chinese New Year. Lanterns are hung in the shopping malls and Yao is preparing to wake the ancient sky dragon, Shen Long, from his year-long sleep. From the moment Shen Long opens his great amber eyes and unfurls his silver-blue tail, Yao will be propelled on a magical journey to battle the bad luck of the previous year and usher in the good. Will he succeed? Will his grandfather watch over him and protect him from harm?\n\nA beautiful story of a Chinese festival and its symbolism for Chinese communities everywhere."',
    free: false,
    ageRanges: ['9 - 11 yr'],
    categories: ['Travel'],
  },
  {
    id: 225,
    title: 'Doctors and What They Do',
    author: 'Liesbet Slegers',
    illustrator: 'Liesbet Slegers',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1248,
    description:
      "Isn't it nice that we have doctors to heal us and to help us stay healthy?  An instructive book about doctors and what they do.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Health', 'Science & Nature', 'Jobs', 'Non-Fiction'],
  },
  {
    id: 449,
    title: 'Dinosaurs with Feathers',
    author: 'Caroline Arnold',
    illustrator: 'Laurie Caple',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1403,
    description:
      'A growing body of fossil evidence says that birds are descended from dinosaurs. Since the discovery in the late 19th century of the famous Archaeopteryx fossil, scientists have compared dinosaur and ancient bird fossils with modern birds and found many important shared characteristics. But is it the most recent discoveries--of dinosaurs with feathers--that have cemented the bird-dinosaur connection and provided us with entirely new and fascinating evidence of how birds are related to these ancient creatures. Hard as it may be to believe, the small feathered creatures sitting on your bird feeder are, in many ways, tiny dinosaurs in disguise!\n\nIn this large format book, full-color illustrations show what these ancient birds and feathered dinosaurs may have looked like in real life, while the text explains the details of the scientific discoveries that have helped us to learn about the ancestry of modern birds.',
    free: false,
    ageRanges: ['9 - 11 yr'],
    categories: ['Science & Nature', 'Chapter Books', 'Animals', 'Non-Fiction'],
  },
  {
    id: 256,
    title: 'Dinosaur Rap',
    author: 'John Foster',
    illustrator: 'Debbie Harter',
    pageCount: 38,
    pageWidth: 1200,
    pageHeight: 1162,
    description:
      "Get movin' and groovin' with 7 dancing dinosaurs! With funky lyrics and a catchy rap performance, this rhythmic romp encourages safe and exciting creative movements that are sure to inspire pretend play. Colorful artwork by bestselling Animal Boogie illustrator Debbie Harter and 6 pages of dinosaur facts make this an inclusive, educational and fun addition to your singalong library. You’re guaranteed to put it on repeat!",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Poems & Rhyming', 'Animals'],
  },
  {
    id: 462,
    title: 'Dinosaur Day',
    author: 'Liza Donnelly',
    illustrator: 'Liza Donnelly',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1403,
    description:
      'Crazy about dinosaurs, a boy and his dog imagine they see one under every lump of snow until the day they get a big surprise. Includes an illustrated glossary listing various dinosaurs and their characteristics.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Animals'],
  },
  {
    id: 391,
    title: 'Dinnertime for Zaza',
    author: 'Mylo Freeman ',
    illustrator: 'Mylo Freeman ',
    pageCount: 24,
    pageWidth: 1200,
    pageHeight: 1262,
    description:
      'Zaza is cooking in her little kitchen. She’s making a meal for her animal friends. Then it’s dinnertime for Zaza.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Health'],
  },
  {
    id: 159,
    title: 'Diego Rivera: His World and Ours',
    author: 'Duncan Tonatium',
    illustrator: 'Duncan Tonatium',
    pageCount: 40,
    pageWidth: 600,
    pageHeight: 667,
    description:
      "This picture-book biography tells of Diego Rivera's early studies of painting in Europe and his return to his native Mexico, where he created murals to celebrate his country's history and its people.",
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Travel'],
  },
  {
    id: 337,
    title: 'Didi Dodo, Future Spy: Recipe for Disaster',
    author: 'Tom Angleberger',
    illustrator: 'Jared Chapman',
    pageCount: 114,
    pageWidth: 1200,
    pageHeight: 1762,
    description:
      'In this series opener, Koko Dodo the cookie chef has a big problem. Today is the day of the royal cookie contest, and someone has stolen his top-secret fudge sauce! Luckily, a spy enters the scene just in time (or rather, crashes in on her roller skates): Didi Dodo! This might be Didi’s first case ever, but she has a daring plan to help Koko. Koko would prefer a safe, simple, sure-to-work plan, but without another option, he and the amateur sleuth take off on a wacky caper full of high-speed chases and big belly laughs that could only come from the mind of the inimitable Tom Angleberger. Each book in the series will focus on a food-related mystery.',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Health'],
  },
  {
    id: 491,
    title: 'Desorientado por tres días',
    author: 'Andrew Glass',
    illustrator: 'Andrew Glass',
    pageCount: 38,
    pageWidth: 1200,
    pageHeight: 1403,
    description:
      "Daniel Boone is asked by a portrait artist why he never wears a coonskin cap. Daniel's answer relates his childhood experiences and tells of a unique set of friends who helped him out of a difficult situation.",
    free: false,
    ageRanges: ['9 - 11 yr'],
    categories: ['Spanish', 'Non-Fiction'],
  },
  {
    id: 506,
    title: 'Des fleurs pour Angélina',
    author: 'Jen Wojtowicz',
    illustrator: 'Jen Wojtowicz',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1511,
    description:
      "Quiet Rink always sits at the back of the classroom, away from the other children who have heard strange rumors about his family and prefer to keep their distance. But when a kind new girl joins his class, Rink's life begins to change. Inspired by the experiences of her brother, who is on the autism spectrum, Jen Wojtowicz's heartwarming tale encourages children to empathize with and reach out to others.\t",
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['French', 'Family', 'Friendship'],
  },
  {
    id: 483,
    title: 'Danser comme une feuille',
    author: 'AJ Irving',
    illustrator: 'Claudia Navarro',
    pageCount: 38,
    pageWidth: 1200,
    pageHeight: 1509,
    description:
      'As her grandmother’s health declines, a young girl begins to lovingly take the lead in their cozy shared autumn traditions. Poetic prose paired with evocative illustrations by Mexican illustrator Claudia Navarro make for a beautiful celebration of life and a gentle introduction to the death of a loved one.',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['French', 'Family'],
  },
  {
    id: 294,
    title: 'Dance Like a Leaf',
    author: 'AJ Irving',
    illustrator: 'Claudia Navarro',
    pageCount: 38,
    pageWidth: 1200,
    pageHeight: 1506,
    description:
      'As her grandmother’s health declines, a young girl begins to lovingly take the lead in their cozy shared autumn traditions. Poetic prose paired with evocative illustrations by Mexican illustrator Claudia Navarro make for a beautiful celebration of life and a gentle introduction to the death of a loved one.',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Animals'],
  },
  {
    id: 186,
    title: 'Daddies and Their Babies',
    author: 'Guido Van Genechten',
    illustrator: 'Guido Van Genechten',
    pageCount: 22,
    pageWidth: 600,
    pageHeight: 600,
    description:
      '"Animal babies have a daddy, just like you!" The stimulating, high-contrast illustrations teach babies and toddlers about the names of various baby animals, from the tiny "fish daddy" and his "baby fry" to the huge "daddy rhinoceros" and his "baby calf."',
    free: false,
    ageRanges: ['0 - 2 yr'],
    categories: ['Animals'],
  },
  {
    id: 224,
    title: 'Crocodile Is Hungry',
    author: 'Aurelia Higuet',
    illustrator: 'Aurelia Higuet',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 869,
    description:
      'Crocodile is hungry!  A funny book with colorful, mouth-watering illustrations.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Animals'],
  },
  {
    id: 343,
    title: 'Counting Animals with Lily and Milo',
    author: 'Pauline Oud',
    illustrator: 'Pauline Oud',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1272,
    description:
      'Lily and Milo take a walk in the meadow. What do they see?\nAnimals. So many animals!\nCan you count the animals along with Lily and Milo?\n\nA cheerful story about animals and counting. For toddlers ages 24 months and up, with a focus on language development.',
    free: false,
    ageRanges: ['0 - 2 yr'],
    categories: ['Numbers & Alphabet', 'Animals'],
  },
  {
    id: 443,
    title: 'Cool Cars',
    author: 'Seymour Simon',
    illustrator: 'Multiple Photographers',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 36,
    description:
      "American cars travel two-and-a-half trillion miles in a year - that's five million round trips to the moon! See more about the history and uses of the automobile, and learn about the cars of the future. ",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Transportation', 'Non-Fiction'],
  },
  {
    id: 223,
    title: 'Cleo',
    author: 'Sassafras De Bruyn',
    illustrator: 'Sassafras De Bruyn',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1656,
    description:
      "A touching story about a girl who isn't as alone as she thought she was.",
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Bedtime', 'Friendship'],
  },
  {
    id: 325,
    title: 'Christmas with Lily and Milo',
    author: 'Pauline Oud',
    illustrator: 'Pauline Oud',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1261,
    description:
      "Lily and Milo are going to buy a Christmas tree. They are wearing warm clothes because it's snowing. In the garden, they make a snowman. In the house, they choose decorations for the tree: ornaments, tinsel, and a gold topper! But when the Christmas tree is ready, Milo is nowhere to be found...",
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Friendship'],
  },
  {
    id: 388,
    title: 'Chicken in the Kitchen',
    author: 'Nnedi Okorafor',
    illustrator: 'Mehrdokht Amini',
    pageCount: 40,
    pageWidth: 1200,
    pageHeight: 1200,
    description:
      'What would you do if you woke up one night to find the shadow of a giant chicken passing your bedroom door? Go and investigate, of course! When Anyaugo follows a giant chicken into her kitchen one warm night in Nigeria, she embarks on a fun-filled adventure where nothing is quite as it seems. Is the mischievous giant chicken a friend or a foe? More importantly, will Anyaugo be able to save the food for the New Yam Festival the next day?',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: [],
  },
  {
    id: 81,
    title: 'Chicka Chicka Boom Boom',
    author: 'Bill Martin Jr and John Archambault',
    illustrator: 'Lois Ehlert',
    pageCount: 36,
    pageWidth: 600,
    pageHeight: 791,
    description:
      'This may be the most often recited ABC book of all time. "A told B, and B told C, \'I\'ll meet you at the top of the coconut tree.\'" As all 26 friends make the climb, they ask, "Chicka chicka boom boom! Will there be enough room?" Or will the tree bend? ("BOOM! BOOM!")\n\nPrinted with permission of Simon & Schuster Children\'s Publishing, a Division of Simon & Schuster Inc.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Poems & Rhyming'],
  },
  {
    id: 496,
    title: 'Chefs and What They Do',
    author: 'Liesbet Slegers ',
    illustrator: 'Liesbet Slegers ',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1248,
    description:
      'Chefs prepare food for the people who come to their restaurants and they have a whole team of cooks to help. Chefs have to work hard to create delicious, healthy dishes. They also have to try some of the food, know a lot about ingredients and make up new recipes. It’s a delicious job!\n\nA tasty, informative book for children about chefs and restaurants.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Jobs', 'Non-Fiction'],
  },
  {
    id: 248,
    title: 'Chandra’s Magic Light',
    author: 'Theresa Heine',
    illustrator: 'Judith Gueyfier',
    pageCount: 44,
    pageWidth: 1200,
    pageHeight: 1511,
    description:
      'Chandra and Deena\'s family has a smoky oil lamp at home, which makes their baby brother cough. Then one day at the market, the sisters spot a man selling lamps that are powered by the sun instead of kerosene — and would be healthier for their brother! But how will they afford to buy one of the "magic" lamps? This lyrical celebration of hard work, determination and sisterhood is brought to life with luminous acrylic illustrations of the mountains and markets of Nepal. Includes 7 pages of facts about Nepal and instructions for making a solar-powered oven.',
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Travel', 'Family'],
  },
  {
    id: 511,
    title: 'Cha-cha-cha en la selva',
    author: 'Barefoot Books',
    illustrator: 'Debbie Harter',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1159,
    description:
      'Dance on down to the jungles of India, where 6 colorful creatures are jumping and jiving beneath the canopy. ',
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['Spanish', 'Animals'],
  },
  {
    id: 67,
    title: 'Carrot Soup',
    author: 'John Segal',
    illustrator: 'John Segal',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 480,
    description:
      "Rabbit loves the spring, and plans his vegetable garden to yield the ingredients for... carrot soup! He plows and plants, waters and weeds, then waits and waits. But when he goes to harvest his carrots, they're gone! Where could they be?\n\nPrinted with permission of Simon & Schuster Children's Publishing, a Division of Simon & Schuster Inc.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Science & Nature', 'Friendship'],
  },
  {
    id: 405,
    title: 'Calling Dr. Zaza',
    author: 'Mylo Freeman ',
    illustrator: 'Mylo Freeman ',
    pageCount: 24,
    pageWidth: 1200,
    pageHeight: 1257,
    description:
      'Zaza’s animal friends aren’t feeling well today. What do they need? A visit from Dr. Zaza!',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Health', 'Jobs', 'Friendship'],
  },
  {
    id: 2,
    title: 'Bunny, My Honey',
    author: 'Anita Jeram',
    illustrator: 'Anita Jeram',
    pageCount: 24,
    pageWidth: 599,
    pageHeight: 529,
    description:
      'Bunny looks just like Mommy Rabbit, "only smaller." He loves to play in the woods with his friends. But one day, Bunny gets lost, and there are no sweeter words to his ears than hearing his mother call out, "Bunny, My Honey."',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Animals', 'Family', 'Friendship'],
  },
  {
    id: 515,
    title: 'Botes a flote',
    author: 'Shelley Rotner',
    illustrator: 'Shelley Rotner',
    pageCount: 64,
    pageWidth: 1200,
    pageHeight: 1403,
    description:
      'A colorful photo-essay provides a look at boats of all sizes and their various uses, from putting out fires and hauling cargo to carrying people and defending shorelines.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Spanish', 'Transportation', 'Jobs', 'Non-Fiction'],
  },
  {
    id: 514,
    title: 'Boats Afloat',
    author: 'Shelley Rotner',
    illustrator: 'Shelley Rotner',
    pageCount: 66,
    pageWidth: 1200,
    pageHeight: 1403,
    description:
      'A colorful photo-essay provides a look at boats of all sizes and their various uses, from putting out fires and hauling cargo to carrying people and defending shorelines.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Transportation', 'Jobs', 'Non-Fiction'],
  },
  {
    id: 158,
    title: 'Blue-Ribbon Dad',
    author: 'Beth Raisner Glass',
    illustrator: 'Margie Moore',
    pageCount: 34,
    pageWidth: 600,
    pageHeight: 600,
    description:
      'A young squirrel plans a surprise for "the best dad that I know," and gives the reasons why--from packing his favorite lunch in the morning to a story before bed.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Family'],
  },
  {
    id: 211,
    title: 'Big Sister Sarah',
    author: 'Pauline Oud',
    illustrator: 'Pauline Oud',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 624,
    description:
      "Sarah's so excited for Mommy to have a baby! After the baby arrives, Sarah shows what a good big sister she is: she helps with baby's diaper and baby's bath.  But when Grandma and Grandpa visit and shower lots of attention on the baby, Sarah's not so sure about being a big sister after all.",
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['Family'],
  },
  {
    id: 472,
    title: 'Big Brother Ian',
    author: 'Pauline Oud',
    illustrator: 'Pauline Oud',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1248,
    description:
      'Ian is going to be a big brother, but it takes some getting used to. At first, everything belonged to him, and he could play with anything. The little bottle, the little cradle, the little top, the little bath, and the little baby seat were toys for Ian and his toy bunny. When he becomes a big brother, Ian suddenly has to share all his toys. When the baby also takes up his mommy’s entire lap, Ian is fed up. But then something unexpected happens….\n\nA warm and tender picture book about a toddler who has to get used to the fact that he is not an only child anymore. Pauline Oud paints a playful and familiar picture of what goes on in a child’s mind when a new little brother or sister comes home.',
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['Family'],
  },
  {
    id: 489,
    title: 'Bewildered for Three Days',
    author: 'Andrew Glass',
    illustrator: 'Andrew Glass',
    pageCount: 38,
    pageWidth: 1200,
    pageHeight: 1403,
    description:
      "Daniel Boone is asked by a portrait artist why he never wears a coonskin cap. Daniel's answer relates his childhood experiences and tells of a unique set of friends who helped him out of a difficult situation.",
    free: false,
    ageRanges: ['9 - 11 yr'],
    categories: ['Non-Fiction'],
  },
  {
    id: 250,
    title: 'Benji and the Giant Kite ',
    author: 'Alan C. Fox',
    illustrator: 'Eefje Kuijl',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1248,
    description:
      '"In a small shop, Benji sees a beautiful kite. It’s huge and it’s bright orange. But the kite is also very expensive. Benji takes on all kind of jobs to earn the money to buy the kite . . . Then finally, the day comes he can fly it!\n\nAn inspiring read-aloud picture book about ambition, perseverance, and kite-flying. For aspiring aviators ages 4 and up"',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: [],
  },
  {
    id: 307,
    title: 'Bear’s Birthday',
    author: 'Stella Blackstone',
    illustrator: 'Debbie Harter',
    pageCount: 28,
    pageWidth: 1200,
    pageHeight: 1165,
    description:
      "Who's popping the balloons at Bear's party? With its simple story and colorful, high-contrast illustrations, Bear's Birthday offers a fun way for little ones to practice counting down from 10 to 1 and to build early literacy skills. A charming entry to the beloved Bear series that has delighted children for 20 years.",
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Poems & Rhyming', 'Animals', 'Friendship'],
  },
  {
    id: 123,
    title: "Bear's First Christmas",
    author: 'Robert Kinerk',
    illustrator: 'Jim LaMarche',
    pageCount: 40,
    pageWidth: 600,
    pageHeight: 800,
    description:
      'As the first snowflakes fall, a bear cub knows he must "sleep until spring." But he\'s lured by a "faraway sound" and other woodland animals follow him on a magical adventure.\n\nPrinted with permission of Simon & Schuster Children\'s Publishing, a Division of Simon & Schuster Inc.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Animals', 'Friendship'],
  },
  {
    id: 482,
    title: 'Bear on a Bike/Ours á vélo',
    author: 'Stella Blackstone',
    illustrator: 'Debbie Harter',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1364,
    description:
      'Can you keep up with busy Bear? Follow Bear to the market, forest, beach and more in this bright French / English edition of Bear on a Bike. Part of the time-tested Bear series, this book introduces little ones to 6 modes of transportation, and the repetitive rhyming text helps build word recognition, develop reading confidence and reinforce early learning.',
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['French', 'Transportation', 'Poems & Rhyming', 'Animals'],
  },
  {
    id: 247,
    title: 'Bear on a Bike',
    author: 'Stella Blackstone',
    illustrator: 'Debbie Harter',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1162,
    description:
      "Join Bear as he tries out different forms of transportation and has an adventure with each one. Part of the beloved Bear series that's delighted children for 20 years. Rhyme and repetition support the learning layers of travel and transportation, as Bear pilots a raft, a rocket ship, a sailboat, a bike, a steam train, and a carriage.",
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Transportation', 'Animals', 'Poems & Rhyming'],
  },
  {
    id: 481,
    title: 'Bear in Sunshine/ Ours au soleil',
    author: 'Stella Blackstone',
    illustrator: 'Debbie Harter',
    pageCount: 28,
    pageWidth: 1200,
    pageHeight: 1364,
    description:
      "What does Bear like to do for fun when it's sunny? Windy? Misty? Find out in this colorful French / English bilingual edition of Bear in Sunshine. The rhyming text and high-contrast illustrations introduce the four seasons, while adorable visual details - references to familiar nursery rhymes, classic films and more - keep little ones engaged and adults amused.",
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['French', 'Numbers & Alphabet', 'Animals'],
  },
  {
    id: 222,
    title: 'Bear in Love',
    author: 'Sam Loman',
    illustrator: 'Sam Loman',
    pageCount: 34,
    pageWidth: 1379,
    pageHeight: 1434,
    description:
      'A sweet story about being in love and not having the courage to speak up. ',
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['Animals', 'Friendship'],
  },
  {
    id: 480,
    title: 'Bear in a Square/ Ours dans un carré',
    author: 'Stella Blackstone',
    illustrator: 'Debbie Harter',
    pageCount: 28,
    pageWidth: 1200,
    pageHeight: 1364,
    description:
      'Meet Bear in the beloved book that started it all -- now in bilingual English and French! Ignite the love of reading with this first entry to the Bear series that has delighted children for 20 years. Rhyme, repetition, counting and a two-page spread recapping the shapes and colors all reinforce educational building blocks.',
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['French', 'Animals'],
  },
  {
    id: 479,
    title: 'Bear about town/ Ours en ville',
    author: 'Stella Blackstone',
    illustrator: 'Debbie Harter',
    pageCount: 28,
    pageWidth: 1200,
    pageHeight: 1364,
    description:
      "Follow loveable Bear as he visits the bakery, swimming pool and other places in his town! This French / English bilingual addition to the beloved Bear series features delightful rhyming text, teaches the days of the week and includes a whimsical full-spread map of Bear's town.",
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['French', 'Animals'],
  },
  {
    id: 450,
    title: 'Be A Maker',
    author: 'Katey Howes',
    illustrator: 'Elizabet Vukovi?',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1467,
    description:
      'How many things can you make in a day? A tower, a friend, a change? \nRhyme, repetition, and a few seemingly straightforward questions engage young readers in a discussion about the many things we make—and the ways we can make a difference in the world. This simple, layered story celebrates creativity through beautiful rhyming verse and vibrant illustrations with a timely message.',
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Science & Nature', 'Poems & Rhyming'],
  },
  {
    id: 155,
    title: 'Bathing',
    author: 'Liesbet Slegers',
    illustrator: 'Liesbet Slegers',
    pageCount: 14,
    pageWidth: 600,
    pageHeight: 600,
    description:
      'This charming book helps little ones name all the parts of their bathtime routine. Starting with soap on their washcloths to making "little waves" with a toy boat, they end by wrapping up in a "warm and cuddly" towel.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Family'],
  },
  {
    id: 268,
    title: 'Barefoot Books Children of the World',
    author: 'Kate DePalma & Tessa Strickland',
    illustrator: 'David Dean',
    pageCount: 70,
    pageWidth: 1200,
    pageHeight: 1412,
    description:
      'Discover the must-have book for all children! Barefoot Books Children of the World depicts kids of a wide range of shapes, sizes, races, abilities and lifestyles from countries and cultures all over the world. From enjoying special days to trying on silly hats, this ground-breaking picture book celebrates the big ideas and everyday moments that all children share.\n A simple narrative with stunning hand-painted illustrations by award-winning Barefoot Books World Atlas artist David Dean invites both small children and independent readers to think about their own experiences while providing a window into the experiences of others. A full 15 pages of educational endnotes explain the detailed illustrations, encouraging meaningful conversations about diversity and inclusion. Older children will be empowered to explore these important issues on their own. An essential addition to every home or classroom library!',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Travel', 'Family', 'Non-Fiction', 'Friendship'],
  },
  {
    id: 367,
    title: 'Bakers and What They Do',
    author: 'Liesbet Slegers',
    illustrator: 'Liesbet Slegers',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1254,
    description:
      'Do you love fresh bread and sweet treats? Well, do you know who makes them? A baker! Bakers wake up in the middle of the night to start work each day. There is so much to do to get the delicious baked goods ready for when the shop opens in the morning.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Health', 'Jobs', 'Non-Fiction'],
  },
  {
    id: 444,
    title: 'Automóviles Fantásticos',
    author: 'Seymour Simon',
    illustrator: 'Multiple Photographers',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 36,
    description:
      'Los coches Americanos viajan dos billones y medio de millas en un año - eso son cinco millones de viajes de ida y vuelta a la luna! Lee más sobre la historia y usos del automóvil, y aprende sobre el uso de los coches del futuro. ',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Spanish', 'Transportation', 'Non-Fiction'],
  },
  {
    id: 124,
    title: 'Auntie Claus: Home for the Holidays',
    author: 'Elise Primavera',
    illustrator: 'Elise Primavera',
    pageCount: 44,
    pageWidth: 600,
    pageHeight: 700,
    description:
      "In order to see her niece Sophie dance as the Sugar Plum Fairy in The Nutcracker, Auntie Claus moves the North Pole to New York City. But there are unintended consequences. Can Sophie have her cake and eat it, too?\n\nPrinted with permission of Simon & Schuster Children's Publishing, a Division of Simon & Schuster Inc.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Family'],
  },
  {
    id: 460,
    title: 'At the Edge of the Pond',
    author: 'Jennifer Owings Dewey',
    illustrator: 'Jennifer Owings Dewey',
    pageCount: 50,
    pageWidth: 1200,
    pageHeight: 1406,
    description:
      'Explore the incredible diversity of life in and around a pond, from dawn to night. Sun rays touch the surface and stir everything into motion. Land and water meet at the edge, where the water shrew makes its home. Every creature, from turtle to mosquito, to algae, has its own territorial circle and life cycle.',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Science & Nature', 'Animals', 'Non-Fiction'],
  },
  {
    id: 221,
    title: 'Astronauts And What They Do',
    author: 'Liesbet Slegers',
    illustrator: 'Liesbet Slegers',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1248,
    description:
      'An instructive, engaging child-size book about astronauts, the space shuttle and space travel.',
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Transportation', 'Science & Nature', 'Travel', 'Jobs'],
  },
  {
    id: 119,
    title: 'Angela and the Baby Jesus',
    author: 'Frank McCourt',
    illustrator: 'Raúl Colón',
    pageCount: 36,
    pageWidth: 600,
    pageHeight: 781,
    description:
      "Little Angela, age 6, worries about the Baby Jesus catching cold, there on display on the altar at St. Joseph's church. So she takes him home to keep him warm in this uplifting, sometimes harrowing adventure by the author of Angela's Ashes.\n\nPrinted with permission of Simon & Schuster Children's Publishing, a Division of Simon & Schuster Inc.",
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Family'],
  },
  {
    id: 46,
    title: "And Here's to You!",
    author: 'David Elliott',
    illustrator: 'Randy Cecil',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 661,
    description:
      'Have you ever thought of cats as "Purring People"? This joyous book in electric-bright colors proposes a toast to nature\'s creatures, including the "Singing People" (frogs) and the "Dreaming People" (dogs): "Here\'s to the howling ones,/ the running, yipping, yowling ones." This book is sure to inspire a few toasts of your own!',
    free: false,
    ageRanges: [],
    categories: [],
  },
  {
    id: 44,
    title: 'An Egg Is Quiet',
    author: 'Dianna Hutts Aston',
    illustrator: 'Sylvia Long',
    pageCount: 36,
    pageWidth: 600,
    pageHeight: 733,
    description:
      "Unlock the mysterious world of eggs in the wild, from tiny ladybug eggs to giant (8-pound!) ostrich eggs to the fossil of a dinosaur's egg. This beautifully illustrated book reveals the secrets of where you find them and what they look like. ",
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Science & Nature', 'Animals', 'Non-Fiction'],
  },
  {
    id: 208,
    title: 'Amy',
    author: 'Ritva Lukkarinen and Pirkko Vainio',
    illustrator: 'Ritva Lukkarinen and Pirkko Vainio',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 829,
    description:
      'While enjoying a snowy day, 5-year old Amy finds a lost, sick duck under the woodpile.  She brings him home and lovingly nurtures him back to good health, and soon she and her duck, "Lucky," are close friends.  But Mommy says ducks belong outside, and Amy must eventually say goodbye.',
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Animals', 'Family'],
  },
  {
    id: 55,
    title: 'All Things Bright and Beautiful',
    author: 'Cecil F. Alexander',
    illustrator: 'Ashley Bryan',
    pageCount: 40,
    pageWidth: 600,
    pageHeight: 696,
    description:
      'The lyrics of Cecil F. Alexander\'s 19th-century hymn ("All things bright and beautiful all creatures great and small all things wise and wonderful the Lord God made them all") inspire glorious cut-paper collage illustrations from Ashley Bryan that resemble stained glass.\n\nPrinted with permission of Simon & Schuster Children\'s Publishing, a Division of Simon & Schuster Inc.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: [],
  },
  {
    id: 53,
    title: "Alistair and Kip's Great Adventure!",
    author: 'John Segal',
    illustrator: 'John Segal',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 750,
    description:
      "Alistair, a bored cat, coaxes his canine pal, Kip, to join him in first making a boat, then sailing off in it. But when a wave deposits them on what they think is an island, they're in for even more excitement.\n\nPrinted with permission of Simon & Schuster Children's Publishing, a Division of Simon & Schuster Inc.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Friendship'],
  },
  {
    id: 485,
    title: 'Algarabía en la granja',
    author: 'Margaret Read MacDonald',
    illustrator: 'Sophie Fatus',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1162,
    description:
      'Quack, moo and neigh along to this riotous cumulative song that features animals of all shapes and sizes. Inspired by a folk tale from Chile, this classic Barefoot Books singalong story introduces children to animals and their babies, and teaches how to count up to 16 in multiples of 2.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Spanish', 'Animals', 'Family'],
  },
  {
    id: 322,
    title: 'Above the Rim',
    author: 'Jen Bryant',
    illustrator: 'Frank Morrison',
    pageCount: 48,
    pageWidth: 1200,
    pageHeight: 1482,
    description:
      'The story of Elgin Baylor, basketball icon and civil rights advocate, from an all-star team\n \nHall-of-famer Elgin Baylor was one of basketball’s all-time-greatest players—an innovative athlete, team player, and quiet force for change. One of the first professional African-American players, he inspired others on and off the court. But when traveling for away games, many hotels and restaurants turned Elgin away because he was black. One night, Elgin had enough and staged a one-man protest that captured the attention of the press, the public, and the NBA.',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Sports', 'Non-Fiction'],
  },
  {
    id: 23,
    title: 'ABC Doctor',
    author: 'Liz Murphy',
    illustrator: 'Liz Murphy',
    pageCount: 36,
    pageWidth: 600,
    pageHeight: 464,
    description:
      'Organized by the 26 letters of the alphabet, this comforting text lets children know what to expect from a visit to the doctor. "E" for "examination" explains that "your doctor will listen to your heart, [and] take your temperature," and "S" describes a "stethoscope."',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Health', 'Jobs', 'Non-Fiction'],
  },
  {
    id: 220,
    title: 'A Warm Friendship',
    author: 'Ellen DeLange',
    illustrator: 'Jacqueline Molnar',
    pageCount: 34,
    pageWidth: 1200,
    pageHeight: 1657,
    description: 'A warm story about making friends and letting them go.',
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Animals', 'Friendship'],
  },
  {
    id: 219,
    title: 'A Tummy Full of Secrets',
    author: 'Pimm van Hest',
    illustrator: 'Nynke Talsma',
    pageCount: 32,
    pageWidth: 1200,
    pageHeight: 1657,
    description:
      "A warm, reassuring, story about a girl who discovers what can happen when you don't share your secrets. ",
    free: false,
    ageRanges: ['6 - 8 yr'],
    categories: ['Family'],
  },
  {
    id: 401,
    title: 'A Story About Afiya',
    author: 'Berry, James',
    illustrator: 'Cunha, Anna',
    pageCount: 34,
    pageWidth: 1200,
    pageHeight: 1309,
    description:
      'Some people have dresses for every occasion but Afiya needs only one. Her dress records the memories of her childhood, from roses in bloom to pigeons in flight, from tigers at the zoo to October leaves falling. A joyful celebration of a young girl’s childhood, written by the late Coretta Scott King Book Award-winning Jamaican poet James Berry.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Science & Nature'],
  },
  {
    id: 45,
    title: 'A Seed Is Sleepy',
    author: 'Dianna Hutts Aston',
    illustrator: 'Sylvia Long',
    pageCount: 40,
    pageWidth: 600,
    pageHeight: 733,
    description:
      'The creators of An Egg Is Quiet now explore the snug insides of a seed and what happens when it wakes up! Just think of the seed of a giant redwood: "Who would guess that a seed as small as a freckle would grow into the world\'s tallest tree?" ',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: [
      'Science & Nature',
      'Animals',
      'Poems & Rhyming',
      'Non-Fiction',
    ],
  },
  {
    id: 339,
    title: 'A Potty for Zaza',
    author: 'Mylo Freeman',
    illustrator: 'Mylo Freeman',
    pageCount: 24,
    pageWidth: 1200,
    pageHeight: 1263,
    description:
      'Mommy shows Zaza a potty. Who could it be for? Is it a potty for Zaza?\n\nA simple and sweet book about a child’s first potty. For toddlers ages 24 months and up, with a focus on the child’s skills.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: [],
  },
  {
    id: 245,
    title: 'A Place to Stay: A Shelter Story',
    author: 'Erin Gunti',
    illustrator: 'Estelí Meza',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1511,
    description:
      'This simple, touching picture book shows readers a women’s shelter through the eyes of a young girl, who, with her mother’s help, uses her imagination to overcome her anxiety and adjust. Includes factual endnotes detailing various reasons people experience homelessness and the resources available to help.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Family'],
  },
  {
    id: 461,
    title: 'A orillas del estanque',
    author: 'Jennifer Owings Dewey',
    illustrator: 'Jennifer Owings Dewey',
    pageCount: 50,
    pageWidth: 1200,
    pageHeight: 1154,
    description:
      'Explore la increíble diversidad de la vida dentro y alrededor de un estanque, desde el amanecer hasta la noche. Los rayos del sol tocan la superficie y ponen todo en movimiento. La tierra y el agua se encuentran en el borde, donde la musaraña del agua hace su hogar. Cada criatura, desde la tortuga hasta el zancudo, a las algas, tiene su propio círculo territorial y ciclo de vida.',
    free: false,
    ageRanges: ['9 - 11 yr', '6 - 8 yr'],
    categories: ['Spanish', 'Science & Nature', 'Animals', 'Non-Fiction'],
  },
  {
    id: 101,
    title: 'A House for Hermit Crab',
    author: 'Eric Carle',
    illustrator: 'Eric Carle',
    pageCount: 36,
    pageWidth: 600,
    pageHeight: 844,
    description:
      "When Hermit Crab outgrows his shell, he finds another shell that fits but looks rather plain. So he recruits a sea anemone, starfish and others to help him add some pizzazz.\n\nPrinted with permission of Simon & Schuster Children's Publishing, a Division of Simon & Schuster Inc.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Science & Nature', 'Family'],
  },
  {
    id: 276,
    title: 'A Hole in the Bottom of the Sea',
    author: 'Jessica Law',
    illustrator: 'Jill McDonald',
    pageCount: 28,
    pageWidth: 1200,
    pageHeight: 1162,
    description:
      'Swim with 5 sea creatures in this colorful, tongue-twisting singalong! Based on the traditional cumulative song, each verse introduces a new animal and its place in the marine food chain, from the snail to the shark. Chosen as the BookTrust National Bookstart Week book in 2016, A Hole in the Bottom of the Sea offers a delightful dip into multi-sensory science learning sure to inspire young marine biologists. Enhanced CD includes video animation and audio singalong.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Science & Nature', 'Animals', 'Poems & Rhyming'],
  },
  {
    id: 272,
    title: 'A Gift for Amma: Market Day in India',
    author: 'Meera Sriram',
    illustrator: 'Mariona Cabassa',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1511,
    description:
      'In this #OwnVoices book inspired by the author’s hometown of Chennai, India, a girl explores the vibrant rainbow of delights in a southern Indian street market as she searches for a gift for her amma (mother). Educational endnotes explain all the items on sale and introduce readers to markets around the world.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Travel', 'Family'],
  },
  {
    id: 237,
    title: 'A Dictionary of Dance',
    author: 'Liz Murphy',
    illustrator: 'Liz Murphy',
    pageCount: 28,
    pageWidth: 1200,
    pageHeight: 928,
    description:
      "In A Dictionary of Dance, readers will learn about arabesques, break dancing, choreographers, and the zones of the body — everything that comes together to make a dance, whether it's ballet or the polka.",
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Non-Fiction'],
  },
  {
    id: 218,
    title: 'A Cake for Rabbit',
    author: 'Judith Koppens',
    illustrator: 'Eline van Lindenhuizen',
    pageCount: 32,
    pageWidth: 1241,
    pageHeight: 1301,
    description:
      "Rabbit is hungry. She goes looking for her friends at the animal square, surely they may have something sweet for her. Dog is eating a yummy apple, but he'd rather not share it with Rabbit.  Mouse and Monkey also want to eat all alone. Will nobody share their food and drinks with Rabbit?  A cheerful story about sharing.",
    free: false,
    ageRanges: ['3 - 5 yr'],
    categories: ['Animals'],
  },
  {
    id: 340,
    title: "5 O'Clock Band",
    author: 'Troy Andrews and Bill Taylor',
    illustrator: 'Bryan Collier',
    pageCount: 48,
    pageWidth: 1200,
    pageHeight: 1269,
    description:
      'After letting his band down by missing rehearsal, a boy called Shorty has some serious questions about what it means to be a leader. He hits the streets of New Orleans to find some answers and to soak up some inspiration. Along the way, he’ll meet people who have their own special wisdom to share about being an artist, a leader, and a friend.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['Friendship'],
  },
  {
    id: 71,
    title: '1, 2, I Love You',
    author: 'Alice Schertle',
    illustrator: 'Emily Arnold McCully',
    pageCount: 32,
    pageWidth: 600,
    pageHeight: 741,
    description:
      'As a parent and child (pictured in as elephants) count off everyday activities, such as gliding on a swingset and playing tin soldiers, they find a cause for celebration and a way to express their love.',
    free: false,
    ageRanges: ['3 - 5 yr', '0 - 2 yr'],
    categories: ['Family'],
  },
  {
    id: 507,
    title: '1, 2, 3, partons en safari',
    author: 'Laurie Krebs',
    illustrator: 'Julia Cairns',
    pageCount: 36,
    pageWidth: 1200,
    pageHeight: 1162,
    description:
      'Join Arusha, Mosi, Tumpe and their Maasai friends as they set out on a counting journey through the grasslands of Tanzania. Along the way, the children encounter all sorts of animals including elephants, lions and monkeys, while counting from one to ten in both English and Swahili. The lively, rhyming text is accompanied by an illustrated guide to counting in Swahili, a map, notes about each of the animals, and interesting facts about Tanzania and the Maasai people.',
    free: false,
    ageRanges: ['3 - 5 yr', '6 - 8 yr'],
    categories: ['French', 'Science & Nature', 'Travel', 'Animals'],
  },
];

// sort books alphabetically
// books.sort(function (a, b) {
//   // changing case, (to upper or lower) ensures case-insensitive sort
//   var titleA = a.title.toUpperCase();
//   var titleB = b.title.toUpperCase();
//   return titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
// });

//randomly select 180 books from catalog
const books180: Book[] = [];

interface selectedDict {
  [key: number]: number;
}

// dictionary to hold our selected indices
let selected: selectedDict = {};

while (books180.length < books.length) {
  // get a random index
  let picked = Math.floor(Math.random() * books.length);
  // if already selected, continue
  if (picked in selected) {
    continue;
  }
  // otherwise, add to dictionary and push the book in that index to books180 array
  selected[picked] = 1;
  books180.push(books[picked]);
}

export default books180;
