import React from 'react';
import { Text, Box, Flex } from '@chakra-ui/core';

import useScrollToTop from '../../hooks/useScrollToTop';

export default function Patent() {
  const { refEl } = useScrollToTop();

  return (
    <Box pt={20} ref={refEl} height={{ sm: 'calc(100vh - 18.25rem)' }}>
      <Flex flexDirection="column" alignItems="center" py={6}>
        <Text
          fontWeight="bold"
          py={1}
          fontSize="1.875rem"
          color="brand.secondary"
          letterSpacing="0.02em"
          lineHeight="3.25rem"
          textAlign="center"
        >
          Patent
        </Text>
      </Flex>
      <Flex
        height="100%"
        fontSize="sm"
        backgroundColor="brand.lightBlue"
        justifyContent="center"
        px={3}
      >
        <Box
          width="5xl"
          py={12}
          fontSize="1rem"
          lineHeight="1.3125rem"
          letterSpacing="0.02em"
        >
          <Text>
            Readeo is a leading provider of interactive group communication and
            interaction and Readeo's patented technology is used to provide the
            Readeo experience.
            <br />
            <br />
            This page is intended to serve as notice under 35 U.S.C. § 287(a).
            U.S. Pat. No. 8,269,813
          </Text>
        </Box>
      </Flex>
    </Box>
  );
}
