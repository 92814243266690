export default [
  {
    id: 'yearly',
    label: 'Membership type',
    plan: 'Yearly family plan',
    trial: '14-DAY FREE TRIAL',
    price: '$99.99/year',
  },
  {
    id: 'monthly',
    label: 'Membership type',
    plan: 'Monthly family plan',
    trial: '14-DAY FREE TRIAL',
    price: '$9.99/month',
  },
];
