/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Text, List, ListItem, Link, Box, Flex } from '@chakra-ui/core';

import useScrollToTop from '../../hooks/useScrollToTop';
import { copyrightListStyle } from './copyright.style';

export default function Copyright() {
  const { refEl } = useScrollToTop();

  const year = new Date().getFullYear();

  return (
    <Box pt={20} ref={refEl} minHeight={{ sm: 'calc(100vh - 18.25rem)' }}>
      <Flex flexDirection="column" alignItems="center" py={6}>
        <Text
          fontWeight="bold"
          fontSize="1.875rem"
          color="brand.secondary"
          letterSpacing="0.02em"
          lineHeight="3.25rem"
          textAlign="center"
        >
          Copyright policy for Readeo, LLC
        </Text>
        <Text>&copy; 2009-{year} Readeo, LLC</Text>
      </Flex>
      <Flex
        height="100%"
        fontSize="sm"
        backgroundColor="brand.lightBlue"
        justifyContent="center"
        px={3}
      >
        <Box
          width="5xl"
          py={12}
          fontSize="1rem"
          lineHeight="1.3125rem"
          letterSpacing="0.02em"
        >
          <Text>
            The Readeo.com web site and content contained therein (hereinafter
            “Content”) is the property of Readeo, LLC, its licensors, or its
            users, with certain rights reserved. Readeo, LLC consistent with
            this Copyright Policy and its Terms of Service, grants to you a
            limited license to access and use the Content.
            <br />
            <br />
            If you believe anything hosted on our website infringes your
            copyright, please follow the Digital Millennium Copyright Act steps
            below:
          </Text>
          <br />
          <br />
          <Text fontWeight="bold">Digital Millenium Copyright Act</Text>
          <br />
          <List spacing={4} as="ol" css={copyrightListStyle}>
            <ListItem>
              Notice. If you are copyright owner or an agent thereof and believe
              that any Site Content or other content infringes upon your
              copyrights, you may submit a notification pursuant to the Digital
              Millennium Copyright Act (“DMCA”) by providing our Copyright Agent
              with the following information in writing (see 17 U.S.C. §
              512(c)(3) for further detail):
              <List as="ol" spacing={4}>
                <br />
                <ListItem>
                  A physical or electronic signature of a person authorized to
                  act on behalf of the owner of an exclusive right that is
                  allegedly infringed;
                </ListItem>
                <ListItem>
                  Identification of the copyrighted work claimed to have been
                  infringed, or, if multiple copyrighted works at a single
                  online site are covered by a single notification, a
                  representative list of such works at that site;
                </ListItem>
                <ListItem>
                  Identification of the material that is claimed to be
                  infringing or to be the subject of infringing activity and
                  that is to be removed or access to which is to be disabled and
                  information reasonably sufficient to permit the service
                  provider to locate the material;
                </ListItem>
                <ListItem>
                  Information reasonably sufficient to permit the service
                  provider to contact you, such as an address, telephone number,
                  and, if available, an electronic mail;
                </ListItem>
                <ListItem>
                  A statement that you have a good faith belief that use of the
                  material in the manner complained of is not authorized by the
                  copyright owner, its agent, or the law; and
                </ListItem>
                <ListItem>
                  A statement that the information in the notification is
                  accurate, and under penalty of perjury, that you are
                  authorized to act on behalf of the owner of an exclusive right
                  that is allegedly infringed.
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              Copyright Agent. Readeo, LLC’s designated Copyright Agent to
              receive notifications of claimed infringement is: Brett Crockett,
              11483 Rainstone CT, South Jordan, UT 84095 email:{' '}
              <Link href="mailto:copyright@readeo.com," color="blue.400">
                copyright@readeo.com.
              </Link>{' '}
              You acknowledge that if you fail to comply with all of the
              requirements, your DMCA notice may not be valid.
            </ListItem>
          </List>
        </Box>
      </Flex>
    </Box>
  );
}
