import React from 'react';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';

import withHelmet from '../HOC/withHelmet';
import useConfig from '../hooks/useConfig';

import GiftCardCheckout from '../components/giftcard/checkout';
/*  converting this to a function component,
 *  wrapping with RecurlyProvider and Elements component,
 *  allows to use recurly instance within the wrapped component
 */

function CheckoutPage() {
  const { recurlyKey } = useConfig();

  return (
    <>
      <RecurlyProvider publicKey={`${recurlyKey.public}`}>
        <Elements>
          <GiftCardCheckout />
        </Elements>
      </RecurlyProvider>
    </>
  );
}

export default withHelmet(CheckoutPage, 'Give the gift of reading - checkout');
