import React from 'react';
import { Flex } from '@chakra-ui/core';
import styled from '@emotion/styled';
import { useIsMobileView } from 'hooks/useMobileView';
import logo1 from 'assets/images/logo1.png';
import logo2 from 'assets/images/logo2.png';
import logo3 from 'assets/images/logo3.png';
import logo4 from 'assets/images/logo4.png';
import logo5 from 'assets/images/logo5.png';
import logo6 from 'assets/images/logo6.png';
import logo7 from 'assets/images/logo7.png';
import logo8 from 'assets/images/logo8.png';
import CompanyLogos from './CarouselLogo';
import logo from 'assets/images/5_Desk-featured_logos.jpg';

const Hr = styled.hr`
  border: 0.5px thin rgba(209, 213, 219, 0.4);
  border-top-width: 2px;
  height: 0.375rem;
  overflow: visible;
  text-align: center;
  color: gray;
  width: 100%;
  &:after {
    background: white;
    content: 'Featured in';
    padding: 0 4px;
    position: relative;
    top: -13px;
    color: gray;
  }
`;

export default function HorizontalLine() {
  const isMobile = useIsMobileView();
  const logos = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8];
  return (
    <Flex
      width={{ base: '100%', md: '75%', lg: '60%' }}
      flexDirection="column"
      margin="60px  auto 0px  auto"
    >
      <Hr style={{ marginBottom: isMobile ? '0rem' : 'inherit' }} />
      {isMobile ? (
        <CompanyLogos logos={logos} />
      ) : (
        <Flex
          maxWidth="inherit"
          justifyContent="space-between"
          alignItems="center"
          className="example"
          height={100}
        >
          <img
            alt="logo"
            src={logo}
            style={{
              margin: 'inherit',
            }}
          />
        </Flex>
      )}
    </Flex>
  );
}
