import React from 'react';
import { Box } from '@chakra-ui/core';
import Pricing from './Pricing';
import Help from './Help';
import MoreReasons from './MoreReasons';
import Shelves from './Shelves';
import AppDownload from './AppDownload';
import Header from './Header';
import HowItWorks from './HowItWorks';
import Reviews from './Reviews';

export default function Landing() {
  return (
    <Box>
      <Header />
      <HowItWorks />
      <Shelves />
      <AppDownload />
      <Reviews />
      <Pricing />
      <Help />
      <MoreReasons />
    </Box>
  );
}
