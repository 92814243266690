import { Flex, Text, Button, useDisclosure, Box } from '@chakra-ui/core';
import React, { useState } from 'react';
import axios from '../../utils/axios';
import useToastMod from '../../hooks/useToastMod';

import TeacherContact from './Modals/TeacherContact';
import CopyLinkModal from './Modals/CopyLink';
import ReachOut from './Modals/ReachOut';
import { useIsMobileView } from 'hooks/useIsMobileView';
import { useIsTabletView } from 'hooks/useIsTabletView';

const ProgramUsers = () => {
  const isMobileView = useIsMobileView();
  const isTabletView = useIsTabletView();
  const interestDisclosure = useDisclosure();
  const cpyLinkDisclosure = useDisclosure();
  const reachOutDisclosure = useDisclosure();
  const toast = useToastMod();
  const [isLoading, setIsLoading] = useState(false);

  const makeAPICall = async (
    type: 'guardians' | 'administrators',
    payload: any,
  ) => {
    setIsLoading(true);
    try {
      const url =
        type === 'guardians'
          ? 'grp-interest/guardians'
          : 'grp-interest/administrators';
      const { data } = await axios.post(url, payload);
      toast({
        title: data.message,
        status: 'success',
        duration: 10000,
        position: 'top',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Something went wrong!',
        status: 'error',
        duration: 10000,
        position: 'top',
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      if (type === 'guardians') {
        interestDisclosure.onClose();
        cpyLinkDisclosure.onOpen();
        return;
      }
      return reachOutDisclosure.onClose();
    }
  };

  const hoverImages = [
    require('../../assets/images/1-guardian-2.webp'),
    require('../../assets/images/2-teacher-2.webp'),
    require('../../assets/images/3-admin-2.webp'),
  ];
  const images = [
    require('../../assets/images/1-guardian-1.webp'),
    require('../../assets/images/2-teacher-1.webp'),
    require('../../assets/images/3-admin-1.webp'),
  ];
  const users = [
    {
      name: 'I’m a parent/guardian',
      info: "wanting to share this program with my child's class",
      img: images[0],
      cta: {
        text: 'share details',
        disclosure: interestDisclosure,
      },
    },
    {
      name: 'I’m a teacher',
      info: 'excited to use this program in my classroom.',
      img: images[1],
      cta: {
        text: 'learn more',
        openWindow: () => window.open(`${window.location.origin}/teacher`),
      },
    },
    {
      name: 'I’m an administrator',
      info: 'seeking further information on behalf of my school or district.',
      img: images[2],
      cta: {
        text: 'reach out now',
        disclosure: reachOutDisclosure,
      },
    },
  ];

  const updateStyleOnHover = (a: string, isMouseEnter: boolean) => {
    const element = document.getElementsByClassName(a)[0] as HTMLElement;
    const button = element.children[3] as HTMLElement;
    const image = element.children[0] as HTMLElement;
    const hoverImage = element.children[1] as HTMLElement;
    if (isMouseEnter) {
      image.style.display = 'none';
      hoverImage.style.backgroundSize = '100% 100%';
      button.style.background = '#f38530';
      button.style.color = 'white';
      button.style.border = 'none';
    } else {
      image.style.display = 'block';
      hoverImage.style.backgroundSize = '0% 0%';
      button.style.background = 'white';
      button.style.color = isMobileView ? 'white' : '#083261';
      button.style.border = '2px solid #019CD5';
    }
  };

  return (
    <Flex
      backgroundImage={`url(${require('../../assets/images/Background_2.webp')})`}
      width="100%"
      bgSize="cover"
      justifyContent="center"
      minHeight="15rem"
      p={{ base: '1rem', md: '2rem 3rem 0.2rem' }}
    >
      <Flex color="white" flexDir="column" maxWidth="1300px">
        <Text
          textAlign="center"
          fontSize={{ base: '24px', md: '30px' }}
          fontWeight={600}
          my="1rem"
          mb={{ base: '1rem', md: '2rem' }}
          display={{ base: 'none', md: 'initial' }}
        >
          How will you use the program?
        </Text>
        <Flex
          justifyContent={{ base: 'space-between', md: 'space-evenly' }}
          mt="5rem"
          flexDir={{ base: 'column', md: 'row' }}
        >
          {users.map((user, idx) => (
            <Flex
              minHeight={{ base: '250px', md: '300px' }}
              mb={{ base: '7rem', md: '5rem' }}
              flexDir="column"
              bg="#FFFFFF"
              borderRadius="12px"
              width={{ base: '100%', md: '30%' }}
              p={{ base: '.5rem', md: '1.5rem' }}
              position="relative"
              key={idx}
              alignItems={{ base: 'center', md: 'initial' }}
              textAlign={{ base: 'center', md: 'left' }}
              className={`class${idx}`}
              onMouseLeave={() => {
                if (!isTabletView) updateStyleOnHover(`class${idx}`, false);
              }}
              onMouseEnter={() => {
                if (!isTabletView)
                  updateStyleOnHover(`class${idx}`, true);
              }}
            >
              <Box
                background={`url(${user.img}) no-repeat center center `}
                backgroundSize="cover"
                width="140px"
                height="140px"
                position="absolute"
                top={{ base: '-32%', md: '-20%' }}
                right="calc((100% - 140px )/2)"
              >
                {' '}
              </Box>
              <Box
                background={`url(${hoverImages[idx]}) no-repeat `}
                backgroundSize="0% 0%"
                width="170px"
                height="170px"
                position="absolute"
                top="-30%"
                right="calc((100% - 170px )/2)"
                backgroundPosition="50% 100%"
              >
                {' '}
              </Box>
              <Box paddingBottom={{ md: '3.75rem' }}>
                <Text
                  color="black"
                  fontSize={{ base: '1.5rem', xl: '30px' }}
                  fontWeight={700}
                  mt={{ base: '3rem', md: '4rem' }}
                >
                  {user.name}
                </Text>
                <Text
                  color="#666666"
                  fontSize="18px"
                  fontWeight={600}
                  mb={{ md: '2.625rem' }}
                >
                  {user.info}
                </Text>
              </Box>
              <Button
                borderRadius="6px"
                border="2px solid #019CD5"
                width="calc(100% - 48px)"
                textTransform="uppercase"
                color={isMobileView ? 'white' : '#083261'}
                bg={{ base: '#019CD5', md: '#ffffff' }}
                mt="1.5rem"
                height={{ base: '50px', md: '64px' }}
                fontWeight={700}
                fontSize={{ base: '16px', md: '18px' }}
                onClick={() => {
                  if (user.cta.disclosure) {
                    return user.cta.disclosure.onOpen();
                  }
                  user.cta.openWindow();
                }}
                position={{ md: 'absolute' }}
                bottom={{ md: '2rem' }}
              >
                {user.cta.text}
              </Button>
            </Flex>
          ))}
        </Flex>
      </Flex>
      <TeacherContact
        disclosure={interestDisclosure}
        makeAPICall={makeAPICall}
        isLoading={isLoading}
      />
      <CopyLinkModal disclosure={cpyLinkDisclosure} />
      <ReachOut
        disclosure={reachOutDisclosure}
        makeAPICall={makeAPICall}
        isLoading={isLoading}
      />
    </Flex>
  );
};

export default ProgramUsers;
