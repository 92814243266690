import React from 'react';
import axios from '../../utils/axios';
import { Link, useHistory } from 'react-router-dom';
import { Flex, Text, PseudoBox, CircularProgress } from '@chakra-ui/core';
import { TiTick } from 'react-icons/ti';
import { StyledButton } from '../../styles/elements.styled';
import { useIsMobileView } from 'hooks/useMobileView';

export default function CardSelector() {
  const [requesting, setRequesting] = React.useState(false);
  const [selected, setSelected] = React.useState(3);
  const [options, setOptions] = React.useState<any>([]);
  const isMobile = useIsMobileView();

  const infoRef = React.useRef<HTMLDivElement>(null);

  // access history object
  const history = useHistory();

  React.useEffect(() => {
    setRequesting(true);
    axios
      .get('giftcards/products')
      .then(({ data }) => {
        setRequesting(false);
        setOptions(data.data);
      })
      .catch((err) => {
        setRequesting(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // handlers
  const handleSelect = (id: number) => {
    setSelected(id);
  };

  const clickHandler = (item: number) => {
    return history.push({
      pathname: '/giftcard/details',
      state: {
        product_id: item,
      },
    });
  };

  return (
    <Flex
      direction="column"
      width="100%"
      mt="2rem"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        direction={['column', 'row']}
        justify="center"
        my={['initial', '1rem']}
        justifyContent={['initial', 'space-between']}
        width={['100%', '80%']}
      >
        {requesting && (
          <Flex width="100%" justify="center">
            <CircularProgress isIndeterminate color="orange" />
          </Flex>
        )}
        {!requesting && !options.length && (
          <Text ref={infoRef}>
            Unable to load gift card options. Please check your network
            connection and{' '}
            <Link
              to=""
              // triggers whole page refresh
              onClick={() => history.go(0)}
              style={{ fontWeight: 'bold', textDecoration: 'underline' }}
            >
              refresh this page
            </Link>{' '}
            to try again
          </Text>
        )}
        {!requesting &&
          !!options.length &&
          options.map((price: any) => (
            <Flex
              height={['90px', '180px']}
              width={['100%', '303px']}
              background={selected === price.id ? 'white' : '#BFE6F4'}
              border={selected === price.id ? '6px solid #019CD5' : '0'}
              borderRadius="11px"
              alignItems="center"
              justifyContent={['space-between', 'center']}
              position="relative"
              cursor="pointer"
              onClick={() => handleSelect(price.id)}
              mx={['initial', '.5rem']}
              my={['.5rem', 'initial']}
              px={['1rem', 'initial']}
            >
              <Text
                fontSize={['40px', '65px']}
                fontWeight="bold"
                textAlign={['left', 'center']}
                color={selected === price.id ? '#083261' : '#019CD5'}
              >
                ${Number(price?.amount).toFixed()}
              </Text>
              <PseudoBox
                position={['relative', 'absolute']}
                bg={selected === price.id ? '#F38530' : '#FFFFFF'}
                left={['unset', '10px']}
                bottom={['unset', '10px']}
                width="35px"
                height="35px"
                borderRadius="full"
                opacity={selected === price.id ? 1 : 0.5}
              >
                <Flex position="relative">
                  {selected === price.id && (
                    <TiTick
                      size="4rem"
                      color="#083261"
                      style={{
                        position: 'absolute',
                        left: '-10px',
                        top: '-20px',
                      }}
                    />
                  )}
                </Flex>
              </PseudoBox>
            </Flex>
          ))}
      </Flex>
      {/* <Text
        fontSize="16px"
        fontWeight="500"
        fontStyle="italic"
        mt="2rem"
        lineHeight="30px"
      >
        Gift cards of $50+
      </Text> */}
      <StyledButton
        width="300px"
        size="lg"
        style={{
          borderRadius: '0.25rem',
          height: isMobile ? '40px' : '60px',
        }}
        // isDisabled={requesting ? true : !options.length ? true : false}
        onClick={() => clickHandler(selected)}
      >
        SELECT GIFT
      </StyledButton>
    </Flex>
  );
}
