import React from 'react';
import { Box, Text, PseudoBox, Heading } from '@chakra-ui/core';

import useScrollToTop from '../../hooks/useScrollToTop';

import SectionCreator from './SectionCreator';
import CardSelector from './CardSelector';
import Perks from './Perks';
import Reviews from './Reviews';
import FAQ from './FAQ';
import RedeemSection from './Redeem';

import gcBanner from '../../assets/images/rd-gcbanner.png';
import gcBannerNew from '../../assets/images/rd-gcbanner-new.png';
import gcBannerBase from '../../assets/images/rd-gcbanner-base.png';
import FaqBg from '../../assets/images/faq_gift_bg.png';
import { useIsMobileView } from 'hooks/useMobileView';

export default function GiftCard() {
  const { refEl } = useScrollToTop();
  const isMobile = useIsMobileView();

  const handleScroll = (top: number) => {
    return window.scrollTo({
      top,
      behavior: 'smooth',
    });
  };

  const togglePromotionDetails = process.env.REACT_APP_SALES_BANNER === 'show';

  return (
    <Box ref={refEl}>
      <Box
        width="100%"
        height={{ base: '100vh', md: 600, xl: 900 }}
        mt={{ base: 30, md: 0 }}
        position="relative"
        background="inherit"
        backgroundImage={{
          base: `url(${gcBannerBase})`,
          md: `url(${togglePromotionDetails ? gcBanner : gcBannerNew})`,
        }}
        backgroundRepeat="no-repeat"
        backgroundPosition="center center"
        backgroundSize="cover"
      >
        <Box
          width={{ base: '100vw', md: 750 }}
          position="absolute"
          top={{ base: '2rem', md: '2rem', lg: '2rem' }}
          right="50%"
          transform="translateX(50%)"
        >
          <Box px={{ md: '11rem' }} mt={{ md: '2rem' }}>
            <SectionCreator
              id="intro"
              hText=""
              hSize="lg"
              hColor="white"
              background="inherit"
              ChildComponent={() => (
                <>
                  <Heading
                    as="h1"
                    color="white"
                    fontSize={{ base: '2rem', md: '2.5rem' }}
                  >
                    Share the joy
                  </Heading>
                  <Heading
                    as="h1"
                    color="white"
                    fontSize={{ base: '2rem', md: '2.5rem' }}
                  >
                    of storytime
                  </Heading>
                  <Text my="1.5rem" color="white">
                    Need a gift the whole family will love? A Readeo membership
                    lets you connect virtually with loved ones, no matter how
                    far apart.
                  </Text>
                  {[0, 1].map((el) => (
                    <PseudoBox
                      key={el}
                      as="button"
                      height={{ base: '50px', md: '60px' }}
                      width="100%"
                      mt="1rem"
                      fontFamily="Montserrat, sans-serif"
                      fontWeight="bold"
                      borderRadius="0.25rem"
                      color="white"
                      alignSelf="center"
                      backgroundColor={
                        el === 0
                          ? 'rgba(243, 133, 48, 1)'
                          : 'rgba(0, 156, 213, 1)'
                      }
                      _hover={{
                        backgroundColor:
                          el === 0
                            ? 'rgba(243, 133, 48, 0.7)'
                            : 'rgba(0, 156, 213, 0.7)',
                      }}
                      onClick={() =>
                        el === 0 ? handleScroll(950) : handleScroll(4000)
                      }
                    >
                      {el === 0 ? 'GIVE A GIFT' : 'REDEEM A GIFT'}
                    </PseudoBox>
                  ))}
                </>
              )}
            />
          </Box>
        </Box>
      </Box>
      <SectionCreator
        id="options"
        hText="Gift a Readeo Gold Plan"
        hColor="brand.secondary"
        paddingT={isMobile ? 5 : 10}
        background="white"
        hFont={isMobile ? '30px' : '42px'}
        mt={isMobile ? '0' : '4rem'}
        paddingB={isMobile ? 10 : 50}
        subText="Choose the amount you’d like to give, and help a friend or loved one experience Readeo Gold for the first time."
        ChildComponent={CardSelector}
      />
      <SectionCreator
        id="memories"
        hText="Unwrap priceless memories together, near and far"
        hColor="brand.secondary"
        width="100%"
        background="#A8DEEC"
        hFont={isMobile ? '24px' : '33px'}
        ChildComponent={Perks}
      />
      <SectionCreator
        id="reviews"
        hText={'Rave reviews for Readeo’s BookChat™'}
        hColor="white"
        paddingB={5}
        wide={true}
        width="100%"
        padXSmall="0rem"
        hFont={isMobile ? '24px' : '30px'}
        background="linear-gradient(90deg, rgba(7, 53, 104, 0.4) 6.65%, rgba(17, 72, 133, 0) 50.74%, rgba(7, 53, 104, 0.4) 92.7%), linear-gradient(0deg, #2C5D96, #2C5D96), #EF3657"
        bgBlend="multiply, normal, normal"
        ChildComponent={Reviews}
      />
      <SectionCreator
        id="faq"
        hText="Frequently Asked Questions"
        hColor="#FFFFFF"
        width="100%"
        backgroundImage={`url(${FaqBg})`}
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        backgroundSize="cover"
        hFont={isMobile ? '24px' : '30px'}
        ChildComponent={FAQ}
      />
      <RedeemSection />
    </Box>
  );
}
