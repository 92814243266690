import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/core';
import { FaRegPlayCircle } from 'react-icons/fa';
import headerBg from 'assets/images/fish_farm_bg2.png';
import gadgets from 'assets/images/gadgets.png';
import { StyledButton } from 'styles/StyledButton';

import Reasons from './Reasons';
import Reviews from './Reviews';
import Help from './Help';
import Info from './Info';
import Footer from './Footer';

import config from 'config';

const SubPage = () => {
  return (
    <Box position="relative">
      <Box
        background={`url(${headerBg})`}
        mt="40vh"
        minHeight="1400px"
        backgroundSize="cover"
      >
        <Box position="absolute" top="-30vh" width="100%">
          <Flex
            justifyContent="space-evenly"
            alignItems="center"
            width="100%"
            flexDir={{
              base: 'column',
              md: 'row',
            }}
          >
            <Image src={gadgets} width="60%" objectFit="cover" />
            <Box width="25%">
              <Text
                color="#021E6A"
                fontWeight="600"
                fontSize="36px"
                lineHeight="40px"
                letterSpacing="0.02em"
              >
                Read together, from wherever!
              </Text>
              <Text>
                Enjoy more time with the little ones in your life, even when
                you're apart by combining video chat with nearly 1,500
                beautifully digitized kids' books.
              </Text>
              <StyledButton
                borderRadius="6px"
                backgroundColor="#F38530"
                color="#ffffff"
                width="75%"
                mt="2rem"
                height="64px"
                fontWeight="700"
                fontSize="18px"
                onClick={() =>
                  (window.location.href = `${config.appUrl}/signup?plan`)
                }
              >
                TRY READEO FREE
              </StyledButton>
              <StyledButton
                borderRadius="6px"
                backgroundColor="#019CD5"
                color="#ffffff"
                width="75%"
                mt="0"
                height="64px"
                fontWeight="700"
                fontSize="18px"
                onClick={() =>
                  window.open('https://www.youtube.com/watch?v=NoyYHgS0rPY')
                }
              >
                <FaRegPlayCircle />
                <Text ml="0.5rem">LEARN MORE</Text>
              </StyledButton>
            </Box>
          </Flex>

          <Reasons />
          <Reviews />
          <Info />
          <Help />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default SubPage;
