import React from 'react';
import { Flex, Text } from '@chakra-ui/core';

import SectionLabel from './SectionLabel';

export interface ISectionCreatorProps {
  id: string;
  hText: string;
  subText?: string;
  hSize?: string;
  hFont?: string;
  hColor: string;
  span?: string;
  wide?: boolean;
  width?: string;
  padXSmall?: string;
  paddingT?: number | number[];
  paddingB?: number;
  background?: string;
  backgroundImage?: string;
  backgroundRepeat?: string;
  backgroundPosition?: string;
  backgroundSize?: string;
  ChildComponent: React.FC;
  OptChildComponent?: React.FC;
  mt?: string;
  bgBlend?: string;
}

export default function SectionCreator(props: ISectionCreatorProps) {
  const {
    id,
    hText,
    hSize,
    hColor,
    wide,
    width,
    padXSmall = '1rem',
    paddingT = 10,
    paddingB = 50,
    background,
    backgroundImage,
    backgroundRepeat,
    backgroundPosition,
    backgroundSize,
    ChildComponent,
    OptChildComponent,
    subText,
    hFont,
    mt,
    bgBlend,
  } = props;

  return (
    <Flex
      id={id}
      width={width}
      justifyContent="center"
      background={background}
      backgroundImage={backgroundImage}
      backgroundRepeat={backgroundRepeat}
      backgroundPosition={backgroundPosition}
      backgroundSize={backgroundSize}
      style={{
        backgroundBlendMode: bgBlend,
      }}
    >
      <Flex
        width={{ base: '100vw', md: '100vw', lg: wide ? '100vw' : 1200 }}
        px={[padXSmall, padXSmall, wide ? '0rem' : '0.94rem']}
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        paddingTop={paddingT}
        paddingBottom={paddingB}
        mt={mt}
      >
        <SectionLabel
          color={hColor}
          size={hSize || 'lg'}
          text={hText}
          fontSize={hFont}
        />
        {subText && (
          <Text
            fontSize={{ base: '16px', md: '18px' }}
            lineHeight={{ base: '22px', md: '24px' }}
            width={{ base: 'initial', md: '643.94px' }}
            fontWeight="500"
          >
            {subText}
          </Text>
        )}
        {OptChildComponent && <OptChildComponent />}
        <ChildComponent />
      </Flex>
    </Flex>
  );
}
