import React from 'react';
import { Flex } from '@chakra-ui/core';

interface ISectionLayout {
  background?: string | { [key: string]: string | number };
  backgroundColor?: string | { [key: string]: string | number };
  flexDirection?: any;
  children: React.ReactNode;
  m?: any;
  pt?: any;
  p?: any;
  py?: any;
  height?: any;
  color?: any;
  borderTop?: string;
}

export default function SectionLayout(props: ISectionLayout) {
  const { flexDirection, children, ...styles } = props;
  return (
    <Flex justifyContent={'center'} {...styles}>
      <Flex width={1200} flexDirection={flexDirection || 'row'}>
        {children}
      </Flex>
    </Flex>
  );
}
