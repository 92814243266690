import React from 'react';
import { Box, Image } from '@chakra-ui/core';

function Img({ src, alt }: { src: string; alt: string }) {
  return (
    <Box height="100%" width="100%">
      <Image src={src} alt={alt} />
    </Box>
  );
}

export default Img;
