/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Text, Flex, Heading } from '@chakra-ui/core';

import { heroStyle, heroContent, mainContent } from './hero.styles';
import { IDisclosure, PlayButton } from '../shared';
import config from 'config';

export default function Hero(props: { openVideo: IDisclosure['onOpen'] }) {
  return (
    <Flex css={mainContent}>
      <Flex css={heroStyle}>
        <Flex css={heroContent}>
          <Heading as="h1">Read together from anywhere</Heading>
          <Flex as="section">
            <Text textAlign="left">
              BookChat&trade; by Readeo lets you enjoy more time with the little
              ones in your life, even when you're apart by combining video chat
              with over 1,500 digital kids' books.
            </Text>
            <Flex
              width="100%"
              justifyContent={{ base: 'center', md: 'space-between' }}
              mt="1.5rem"
              flexDir={{
                base: 'column',
                md: 'row',
              }}
            >
              <Heading
                as="button"
                fontSize="1rem !important"
                height="50px !important"
                width={{ base: '100%', md: '48%' }}
                onClick={() =>
                  (window.location.href = `${config.appUrl}/signup`)
                }
                justifyContent="center"
                alignSelf="center"
              >
                TRY IT FOR FREE
              </Heading>
              <Flex
                alignItems="center"
                onClick={props.openVideo}
                cursor="pointer"
                ml={{ md: '1rem' }}
                mt={{ base: '1rem', md: '0rem' }}
                width={{ base: '  100%', md: '48%' }}
                alignSelf="center"
                justifyContent="center"
              >
                <PlayButton
                  size="2rem"
                  playBorderWidth="0.5rem 0px 0.5rem 0.8rem"
                  borderWidth="2px"
                  marginLeft="0.3rem"
                />
                <Text
                  pl={3}
                  color="#EDEDED"
                  fontWeight={700}
                  fontSize="1rem !important"
                  letterSpacing="0.03em"
                  lineHeight="24px"
                >
                  SEE IT IN ACTION
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          className="img-container"
          width={{ base: '100%', md: '60%' }}
          justifyContent={{ base: 'center', md: 'initial' }}
        >
          <img src={`/assets/tablet.png`} alt="tablet" />
        </Flex>
      </Flex>
    </Flex>
  );
}
