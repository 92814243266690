import React, { ReactEventHandler } from 'react';
import { useHistory } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io';
import {
  Flex,
  Text,
  Grid,
  Select,
  Heading,
  CircularProgress,
} from '@chakra-ui/core';

import { IState } from './_init';
import { StyledImage, StyledButton } from '../../styles/elements.styled';

import ages from './assets/ages';
import config from 'config';

type AllBooksProps = {
  state: IState;
  changeHandler: ReactEventHandler;
  trigger: any;
};

export default function AllBooks(props: AllBooksProps) {
  const { state, changeHandler, trigger } = props;

  // get access to history object
  const history = useHistory();

  return (
    <Flex
      justifyContent={'center'}
      minHeight={{ sm: 'calc(100vh - 18.25rem)' }}
      backgroundColor={{
        md: 'white',
        lg: 'brand.lightBlue',
      }}
    >
      <Flex width={1200} px={{ md: '0.94rem' }} flexDirection="column">
        <Flex marginBottom="2.5rem" justify={['center', 'center', 'flex-end']}>
          <Select
            name="ageRange"
            id="ageRange"
            width={['45%', '45%', '9.38rem']}
            marginRight="0.25rem"
            color="white"
            cursor="pointer"
            borderColor={state.ageRange ? '#1e4786' : '#f38520'}
            backgroundColor={state.ageRange ? '#1e4786' : '#f38520'}
            borderRadius={0}
            placeholder="AGE"
            value={state.ageRange}
            onChange={changeHandler}
          >
            {ages.map((age, idx) => (
              <option key={idx} value={age}>
                {`${age}s`}
              </option>
            ))}
          </Select>
        </Flex>
        <Grid
          gap={6}
          marginTop={10}
          paddingBottom={6}
          templateColumns="repeat(6, 1fr)"
          display={{ base: 'none', lg: 'grid' }}
        >
          {state.books &&
            state.books
              .filter((book) =>
                state.category === ''
                  ? book
                  : book.categories.some((cat) => state.category === cat)
                  ? book
                  : null,
              )
              .filter((book) =>
                state.ageRange === ''
                  ? book
                  : book.ageRanges.some((range) => state.ageRange === range)
                  ? book
                  : null,
              )
              .map((book) => (
                <StyledImage
                  key={book.id}
                  width="10.5rem"
                  objectFit="scale-down"
                  objectPosition="top"
                  src={
                    book?.id !== undefined
                      ? `https://assets.readeo.com/books/${book?.id}/1.jpg`
                      : ''
                  }
                  boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                  fallbackSrc="/loading-fallback.gif"
                  alt={book.title}
                  cursor="pointer"
                  onClick={() =>
                    history.push({
                      pathname: `/library/book/${book.id}`,
                    })
                  }
                />
              ))}
        </Grid>
        {/* define book area display for medium and smaller screens */}
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          display={{ base: 'flex', lg: 'none' }}
        >
          {state.books &&
            state.books
              .filter((book) =>
                state.category === ''
                  ? book
                  : book.categories.some((cat) => state.category === cat)
                  ? book
                  : null,
              )
              .filter((book) =>
                state.ageRange === ''
                  ? book
                  : book.ageRanges.some((range) => state.ageRange === range)
                  ? book
                  : null,
              )
              .map((book) => (
                <Flex
                  as="div"
                  key={book.id}
                  width="100%"
                  alignItems="center"
                  borderBottom="0.5px solid #CBD5E0"
                  position="relative"
                  onClick={() =>
                    history.push({
                      pathname: `/library/book/${book.id}`,
                    })
                  }
                >
                  <StyledImage
                    width="6rem"
                    src={
                      book?.id !== undefined
                        ? `https://assets.readeo.com/books/${book?.id}/1.jpg`
                        : ''
                    }
                    fallbackSrc="/loading-fallback.gif"
                    alt={book.title}
                    px={0}
                    cursor="pointer"
                  />
                  <Flex
                    marginLeft="0.8rem"
                    width="66%"
                    flexDirection="column"
                    py={1}
                  >
                    <Heading as="h6" fontSize="1.125rem" color="#083261">
                      {book?.title}
                    </Heading>
                    <Text opacity={0.8}>Written by {book?.author}</Text>
                    <Text opacity={0.8}>
                      Illustrated by {book?.illustrator}
                    </Text>
                    <i
                      style={{
                        color: '#f38530',
                        position: 'absolute',
                        top: '37%',
                        right: 0,
                        fontSize: '2rem',
                        fontWeight: 'bold',
                      }}
                    >
                      <IoIosArrowForward />
                    </i>
                  </Flex>
                </Flex>
              ))}
        </Flex>
        {/* this div triggers the intersection observer */}
        <div ref={trigger}></div>
        {/* show loading element if isFetching is true */}
        {state.isFetching ? (
          <CircularProgress
            isIndeterminate
            color="blue"
            marginTop="1rem"
            marginBottom="2.5rem"
            alignSelf="center"
          ></CircularProgress>
        ) : null}
        {/* show CTA button when all books have been loaded */}
        <StyledButton
          onClick={() => (window.location.href = `${config.appUrl}/login`)}
        >
          LOGIN TO VIEW MORE
        </StyledButton>
      </Flex>
    </Flex>
  );
}
