import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/core';

import useScrollToTop from '../../../hooks/useScrollToTop';

import PageNav from '../pageNav';
import SectionCreator from '../SectionCreator';

import RecipientData from './RecipientData';

export default function GiftCardDetails() {
  const { refEl } = useScrollToTop();

  const location = useLocation();

  // @ts-ignore
  if (!(location && location.state && location.state.product_id)) {
    return <Redirect to="/giftcard" />;
  }

  return (
    <Box ref={refEl} minHeight="calc(100vh - 18.25rem)">
      <PageNav />
      <SectionCreator
        id="details"
        hText="Delivery details"
        hColor="brand.secondary"
        paddingT={[85, 60]}
        background="white"
        hFont="30px"
        ChildComponent={RecipientData}
        OptChildComponent={() => (
          <Text
            fontSize={15}
            letterSpacing="0.02em"
            width={{ base: '100%', lg: '48rem' }}
            px="0.5rem"
          >
            Let us know who’s getting this Readeo membership, and add a note for
            a personalized touch!
          </Text>
        )}
      />
    </Box>
  );
}
