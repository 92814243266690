import React from 'react';
import { Text, Flex } from '@chakra-ui/core';
import { BsArrow90DegUp } from 'react-icons/bs';

export default function SkipPrompt() {
  return (
    <Flex
      id="buddies-helper-text"
      alignItems="center"
      justify="center"
      color="#2e2e2e"
    >
      <BsArrow90DegUp />
      <Text
        mt="0.5rem"
        ml="0.1rem"
        fontFamily="Montserrat"
        fontSize={14}
        lineHeight="2.875rem"
        fontWeight={300}
        letterSpacing="0.02em"
      >
        Don’t worry, you can do this later
      </Text>
    </Flex>
  );
}
