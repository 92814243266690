import React from 'react';
import { Box, Flex } from '@chakra-ui/core';

import useConfig from '../../hooks/useConfig';
import useScrollToTop from '../../hooks/useScrollToTop';

import PageNav from '../shared/pageNav';
import PageIntro from '../shared/PageIntro';

import { StyledButton } from '../../styles/elements.styled';

export default function Start() {
  const { refEl } = useScrollToTop();

  const { appUrl } = useConfig();

  return (
    <Box ref={refEl} minHeight="calc(100vh - 18.25rem)">
      <Flex flexDir="column" alignItems="center" background={'white'}>
        <PageNav />
        <PageIntro
          heading="Welcome to Readeo"
          text="Create lasting connections with your loved ones even when you’re far apart."
        />
      </Flex>
      <Flex justifyContent={'center'}>
        <Flex width={420} px="0.938rem">
          <StyledButton
            width="100%"
            size="lg"
            fontWeight="bold"
            fontSize="13pt"
            onClick={() => (window.location.href = appUrl)}
          >
            START READING
          </StyledButton>
        </Flex>
      </Flex>
    </Box>
  );
}
