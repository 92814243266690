import React, { useState, useEffect } from 'react';
import ItemsCarousel from 'react-items-carousel';
import { Box, Image } from '@chakra-ui/core';

import logos from './logos';

export default function CompanyLogos() {
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const tick = () =>
    setActiveItemIndex((prevState) => (prevState + 1) % (logos.length - 3 + 1));

  useEffect(() => {
    const interval = setInterval(tick, 5000);
    return () => clearInterval(interval);
  }, []);

  const renderedLogos = logos.map((logo) => {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
        key={logo.alt}
      >
        <Image src={logo.image} alt={logo.alt} />
      </Box>
    );
  });

  return (
    <Box
      height={{ base: '4rem', sm: '10rem' }}
      width={{ base: '20rem', lg: '32.8rem' }}
      margin="0.5rem auto"
    >
      <ItemsCarousel
        gutter={0}
        numberOfCards={3}
        activeItemIndex={activeItemIndex}
        requestToChangeActive={setActiveItemIndex}
        children={renderedLogos}
      />
    </Box>
  );
}
