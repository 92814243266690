import React from 'react';
import { Box, Image } from '@chakra-ui/core';

import { IDisclosure, VideoModal } from './VideoModal';
import { PlayButton } from './PlayButton';

interface IImageVideoModal {
  image: string;
  imageAlt: string;
  disclosure: IDisclosure;
}

export function ImageVideoModal(props: IImageVideoModal) {
  const { image, imageAlt, disclosure } = props;

  return (
    <Box mb="1rem" position="relative">
      <Box width="fit-content">
        <PlayButton
          borderWidth="4px"
          size="5rem"
          position="absolute"
          onClick={disclosure.onOpen}
          marginLeft="0.6rem"
          playBorderWidth="0.938rem 0px 0.938rem 1.875rem"
          _hover={{
            background: '#CF7129',
            borderColor: '#CF7129',
          }}
        />
        <Image width="100%" src={image} alt={imageAlt} />
      </Box>
      <VideoModal disclosure={disclosure} />
    </Box>
  );
}
