import React from 'react';
import { useEffect, useState } from 'react';
import { Flex, Link } from '@chakra-ui/core';
import { Link as RouteLink } from 'react-router-dom';
import { FaCaretRight } from 'react-icons/fa';
import { useIsMobileView } from 'hooks/useMobileView';
import holidayStripesLeft from '../../../assets/images/holiday-stripes-left.png';
import holidayStripesRight from '../../../assets/images/holiday-stripes-right.png';
import holidayStripesLeftMobile from '../../../assets/images/holiday-stripes-left-mobile.png';
import holidayStripesRightMobile from '../../../assets/images/holiday-stripes-right-mobile.png';

export default function SalesBanner() {
  const isMobileView = useIsMobileView();

  const [smallerStripes, setSmallerStripes] = useState(
    window.matchMedia('(max-width: 1300px)')?.matches,
  );
  const checkScreen = () =>
    setSmallerStripes(window.matchMedia('(max-width: 1300px)')?.matches);

  useEffect(() => {
    window.addEventListener('resize', checkScreen);
    return () => window.removeEventListener('resize', checkScreen);
  }, []);

  return (
    <Flex
      backgroundColor="#EA3636"
      color="white"
      width="100%"
      // place permanently on top of the page
      position="fixed"
      top={0}
      // zIndex={1800}
      //   top="3.5rem"
      bottom={{ base: 0, md: 'initial' }}
      zIndex={{ base: 9, md: 20 }}
      justifyContent="center"
      alignItems="center"
      height="2.75rem"
      background={`url(${
        smallerStripes ? holidayStripesLeftMobile : holidayStripesLeft
      }) bottom left no-repeat`}
    >
      <Link
        // @ts-ignore
        as={RouteLink}
        height="44px"
        paddingLeft={2}
        to="/pricing"
        fontSize="0.95rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        padding="0"
        background={`url(${
          smallerStripes ? holidayStripesRightMobile : holidayStripesRight
        }) bottom right no-repeat`}
        _focus={{
          outline: 'none',
        }}
      >
        {isMobileView ? (
          <Flex
            alignItems="center"
            style={{
              fontWeight: 600,
              fontSize: '16px',
              color: 'EEEEEE',
            }}
          >
            View our <b>&nbsp;Holiday Sale</b>
            <FaCaretRight />
          </Flex>
        ) : (
          <Flex alignItems="center">
            Holiday Sale: 50% off paid plans.{' '}
            <b>&nbsp;Ends Dec 31 - Click here</b>
            <FaCaretRight />
          </Flex>
        )}
      </Link>
    </Flex>
  );
}
