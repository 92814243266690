import styled from '@emotion/styled';
import { Flex, Input, Image, Button, Textarea } from '@chakra-ui/core';

export const StyledInput = styled(Input)`
  height: 3.125rem;
`;

export const StyledTextarea = styled(Textarea)`
  resize: none;
`;

export const StyledImage = styled(Image)`
  :hover {
    box-shadow: -0.1rem 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  }
`;

export const StyledButton = styled(Button)`
  height: 4.0625rem;
  min-width: 14.3125rem;
  font-family: 'Montserrat', sans-serif;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 0;
  color: white;
  align-self: center;
  background-color: rgba(243, 133, 48, 1);
  :hover {
    background-color: rgba(243, 133, 48, 0.7);
  }
`;

export const InputGroup = styled(Flex)`
  flex-direction: column;
  position: relative;
  margin-bottom: 1rem;
  /* adjust padding-right for input[type="password"] */
  input[type='password'] {
    padding-right: 3rem;
  }
  /* adjust background-color for input[type="date"] */
  input[type='date'] ~ label {
    background-color: white;
  }
  /* adjust font-weight for input[id="coupon"] */
  input[id='coupon'] {
    font-weight: bold;
  }
  input {
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 400;
    color: #2e2e2e;
    :focus,
    :hover {
      border: 0.05rem solid #4e7cff;
    }
    :valid {
      border: 0.05rem solid #48bb78;
    }
    :focus ~ label,
    :valid ~ label {
      top: 0.1rem;
      left: 0.75rem;
      font-size: 0.7rem;
      padding: 0 0.25rem;
      color: #a0aebf;
      background: transparent;
    }
  }
  label {
    position: absolute;
    top: 0.8rem;
    left: 1rem;
    z-index: 99;
    transition: 0.5s ease-in-out;
    pointer-events: none;
    font-weight: initial;
    color: #a0aebf;
  }
  select {
    height: 3.125rem;
  }
  i,
  svg,
  > #spinner {
    position: absolute;
    right: 0.8rem;
    top: 30%;
    z-index: 99;
    font-size: 1.2rem;
    cursor: pointer;
  }

  button {
    position: absolute;
    right: 0rem;
    z-index: 99;
  }

  /* specific styles for i[id="cvv"] */
  #cvv {
    right: 0.3rem;
    top: 25%;
    font-size: 0.9rem;
    svg {
      right: 0.1rem;
      top: 25%;
      font-size: 0.9rem;
    }
  }
  span {
    position: absolute;
    top: 0.8rem;
    left: 1rem;
  }
`;
