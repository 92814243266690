import React from 'react';
import { useDisclosure, Box } from '@chakra-ui/core';

import Hero from '../hero';
import Sections from '../sections';
import useScrollToTop from '../../hooks/useScrollToTop';
import ModalIntercept from 'components/intercept';

export default function Home() {
  const { refEl } = useScrollToTop();
  const disclosure = useDisclosure();

  return (
    <Box ref={refEl}>
      <Hero openVideo={disclosure.onOpen} />
      <Sections disclosure={disclosure} />
      <ModalIntercept />
    </Box>
  );
}
