import React from 'react';
import { Heading as Title } from '@chakra-ui/core';

interface AuxProps {
  text: string;
}

export function Heading(props: AuxProps) {
  return (
    <Title as="h2" color="#1E4786" py="1rem" fontSize={['1.5rem', '1.88rem']}>
      {props.text}
    </Title>
  );
}
