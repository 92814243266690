import React, { useLayoutEffect } from 'react';

import useQuery from '../../../hooks/useQuery';

export default function Confirmation1() {
  let query = useQuery();

  useLayoutEffect(() => {
    try {
      // @ts-ignore
      const { _refersion } = window;
      _refersion(() => {
        // @ts-ignore
        _rfsn._addTrans({
          order_id: query.get('id'),
          currency_code: 'USD',
        });
        // @ts-ignore
        _rfsn._addItem({
          sku: query.get('plan'),
          quantity: 1,
          price: query.get('amount'),
        });
        // @ts-ignore
        _rfsn._sendConversion();
      });
      // REFERSION TRACKING: END
    } catch (err) {}
  }, [query]);

  return <div></div>;
}
