import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/core';
import desktopImage from 'assets/images/3_Publisher_Logos.png';
import abramsLogo from 'assets/images/Abrams_logo.png';
import keyLogo from 'assets/images/key.png';
import simonLogo from 'assets/images/simon.png';
import candleWickLogo from 'assets/images/candlewick.png';
import leanerLogo from 'assets/images/lerner.png';
import chronicleBooksLogo from 'assets/images/chronicle.png';
import familiusLogo from 'assets/images/familius-logo.png';
import LittleGestaltenLogo from 'assets/images/little-gestalten.png';
import bookCluster from 'assets/images/book-cluster.jpg';
import { useIsMobileView } from 'hooks/useMobileView';
import { useIsLargeTabletView } from 'hooks/useIsLargeTabletView';
import mobileImg from 'assets/images/section_mobile.png';
import desktopVideo from 'assets/video/shelve.mp4';
import CompanyLogos from './CarouselLogo';

export default function Shelves() {
  const isMobile = useIsMobileView();
  const isLargeTablet = useIsLargeTabletView();
  const logos = [
    abramsLogo,
    candleWickLogo,
    keyLogo,
    chronicleBooksLogo,
    familiusLogo,
    leanerLogo,
    LittleGestaltenLogo,
    simonLogo,
  ];

  return (
    <Box
      height={{ base: '750px', lg: '500px', xl: '620px' }}
      width="100%"
      display="flex"
      alignItems={{ base: 'flex-end', lg: 'center' }}
      justifyContent={{ base: 'flex-end', lg: 'center' }}
      px={isMobile ? 'none' : '15%'}
      position="relative"
    >
      <video
        playsInline
        autoPlay
        muted
        loop
        poster={`${isMobile ? mobileImg : bookCluster}`}
        id="bgvid"
        style={{
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -10,
        }}
      >
        <source src={desktopVideo} type="video/mp4" />
      </video>
      <Flex
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="center"
        width={isMobile ? '100%' : 'initial'}
        opacity={isMobile ? 0.9 : 0.96}
        background={
          isMobile
            ? 'linear-gradient(180deg, rgba(255, 255, 255, 0) 3.59%, #FFFFFF 28.53%, #FFFFFF 100%)'
            : 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #E7FAFF;'
        }
        id="ddd"
        mx={isMobile || !isLargeTablet ? 'initial' : '64px'}
        style={{ ...(!isMobile && { margin: 'auto', borderRadius: '20px' }) }}
        marginTop={isMobile ? '20rem' : 'auto'}
        px={{ base: '1rem', lg: '3rem' }}
        py="3rem"
      >
        <Box
          width={isMobile ? '100%' : '45%'}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Text
            color="#083261"
            fontWeight="600"
            fontSize="1.875rem"
            lineHeight="21px"
          >
            Fill your shelves
          </Text>
          <Text
            fontSize="1.125rem"
            color="#3D3D3D"
            fontFamily="Montserrat"
            lineHeight="24px"
            paddingY="1em"
          >
            Readeo Gold Plans offer access to our full library — over 1,000
            books (and counting)! New books are added every week from our
            award-winning publishers:
          </Text>
        </Box>
        {!isMobile ? (
          <Box width={isMobile ? '350px' : '50%'}>
            <img alt="" src={desktopImage} />
          </Box>
        ) : (
          <CompanyLogos logos={logos} />
        )}
      </Flex>
    </Box>
  );
}
