import React from 'react';
import withHelmet from '../HOC/withHelmet';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';

import useConfig from '../hooks/useConfig';

import Upgrade from '../components/account/Upgrade';

/*  converting this to a function component,
 *  wrapping with RecurlyProvider and Elements component,
 *  allows to use recurly instance within the wrapped component
 */

function UpgradeAccount() {
  const { recurlyKey } = useConfig();

  return (
    <RecurlyProvider publicKey={`${recurlyKey.public}`}>
      <Elements>
        <Upgrade />
      </Elements>
    </RecurlyProvider>
  );
}

export default withHelmet(UpgradeAccount, 'Upgrade your membership');
