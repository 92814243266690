import React from 'react';
import { Select } from '@chakra-ui/core';
import { BiChevronDown } from 'react-icons/bi';

export default function AttributionSurvey({
  setAttribution,
}: {
  setAttribution: (val: string) => void;
}) {
  const surveyOptions: string[] = [
    'TikTok',
    'Facebook/Instagram',
    'YouTube',
    'Podcast/Radio',
    'Referred by a Friend',
    'Email',
    'Mail Flyer',
    'Banner Ad',
  ];

  const handleSurvey = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setAttribution(event.target.value);
  };

  return (
    <Select
      color="#083261"
      placeholder="How did you hear about Readeo?"
      size="lg"
      mt="60px"
      fontWeight="400"
      borderColor="#a7dce8"
      icon={BiChevronDown}
      iconColor="blue"
      iconSize="30px"
      onChange={handleSurvey}
    >
      {surveyOptions.map((surveyOption, indx) => (
        <option key={indx} value={surveyOption}>
          {surveyOption}
        </option>
      ))}
    </Select>
  );
}
