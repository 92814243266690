import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, Stack } from '@chakra-ui/core';

import SpeechBubble from '../speech-bubble';
import reviews from './reviews-data';

export default function ReviewSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const index = currentIndex + 1;
      setCurrentIndex(index === reviews.length ? 0 : index);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [currentIndex]);

  const handleGoTo = (pos: number) => {
    if (pos !== currentIndex) {
      setCurrentIndex(pos);
    }
  };

  const indicators = reviews.map(({ author }, index) => {
    const isCurrent = index === currentIndex;
    return (
      <Box
        key={`${author}${index}`}
        as="span"
        background={isCurrent ? 'white' : '#7F0027'}
        rounded="full"
        height={isCurrent ? '15px' : '10px'}
        width={isCurrent ? '15px' : '10px'}
        cursor="pointer"
        onClick={() => handleGoTo(index)}
      ></Box>
    );
  });

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      m={{ sm: '2rem 2rem 0 2rem' }}
      key={`${reviews[currentIndex].author}${currentIndex}`}
    >
      <SpeechBubble text={reviews[currentIndex].review} />
      <Flex
        p={6}
        width={{ base: '95%', sm: '100%' }}
        justifyContent="space-between"
        flexDirection={{ sm: 'column-reverse' }}
        alignItems="center"
      >
        <Stack isInline spacing={2} alignItems="center" mt={{ sm: '1rem' }}>
          {indicators}
        </Stack>

        <Box textAlign="center" alignSelf="flex-end">
          <Text color="white" fontStyle="italic" fontSize="normal">
            —{reviews[currentIndex].author}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
}
