interface IGenerateSettingsProps {
  xl: number;
  lg: number;
  md: number;
  sm: number;
  finite?: true; // can only be true if defined
}

export default function generateSettings(props: IGenerateSettingsProps) {
  return {
    infinite: props.finite ? !props.finite : true,
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    slidesToShow: props.xl,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: props.lg,
          centerMode: true,
          centerPadding: '20px',
          arrows: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: props.md,
          centerMode: true,
          centerPadding: '20px',
          arrows: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: props.sm,
          centerMode: true,
          centerPadding: '20px',
          arrows: false,
          slidesToScroll: 1,
        },
      },
    ],
  };
}
