import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Flex, FormControl } from '@chakra-ui/core';

import axios from '../../utils/axios';
import useScrollToTop from '../../hooks/useScrollToTop';
import useToastMod from '../../hooks/useToastMod';
import { rmail } from '../authentication/utils';
import handleKeyPress from '../../utils/keypress';

import PageNav from '../shared/pageNav';
import PageIntro from '../shared/PageIntro';
import BuddiesInfo from './BuddiesInfo';
import SkipPrompt from '../shared/SkipPrompt';

import { StyledButton } from '../../styles/elements.styled';

// initial state values
const _init: { [key: string]: any } = {
  requesting: false,
  invalidEmail1: true,
  invalidEmail2: true,
  invalidEmail3: true,
  invalidEmail4: true,
  email1: '',
  email2: '',
  email3: '',
  email4: '',
};

export default function Buddies() {
  const [state, setState] = useState(_init);

  const { refEl } = useScrollToTop();

  // refs
  const buttonRef = useRef<HTMLButtonElement>(null);

  // toast some bread
  const toast = useToastMod();

  // access history object
  const history = useHistory();

  const emails = [state.email1, state.email2, state.email3, state.email4];

  // handlers
  const changeHandler = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value });
    // form the matching checker slice of state - eg - invalidEmail1
    const checker = 'invalidE' + e.target.name.substring(1);
    if (rmail.test(e.target.value)) {
      setState((prevState) => ({ ...prevState, [checker]: false }));
      return true;
    }
    setState((prevState) => ({ ...prevState, [checker]: true }));
    return false;
  };

  const submitHandler = (e: any) => {
    setState({ ...state, requesting: true });
    const emailsObj = {
      ...(!state.invalidEmail1 && { email1: state.email1 }),
      ...(!state.invalidEmail2 && { email2: state.email2 }),
      ...(!state.invalidEmail3 && { email3: state.email3 }),
      ...(!state.invalidEmail4 && { email4: state.email4 }),
    };
    // extract values from emailsObj, if any
    const emails = Object.values(emailsObj);
    axios
      .post('invitations/buddies', {
        emails,
      })
      .then(() => {
        setState({ ...state, requesting: false });
        toast({
          title: `${emails.length > 1 ? 'Invites' : 'Invite'} sent`,
          description: `Your invitation to your ${
            emails.length > 1 ? 'buddies' : 'buddy'
          } is on its way.`,
          status: 'success',
          duration: 10000,
          position: 'top',
          isClosable: true,
        });
        return history.push({
          pathname: '/share',
          state: { isSignup: true },
        });
      })
      .catch(() => {
        setState({ ...state, requesting: false });
        toast({
          title: 'Something went wrong!',
          description: 'Please try again shortly',
          status: 'error',
          duration: 10000,
          position: 'top',
          isClosable: true,
        });
      });
  };

  return (
    <Box ref={refEl} minHeight="calc(100vh - 18.25rem)">
      <Flex flexDir="column" alignItems="center" background={'white'}>
        <PageNav />
        <PageIntro
          heading="Build your buddy list"
          text="Before you can use BookChat™ you need to have a reading buddy! You can
        add up to four buddies to your family plan. Invite others to join Readeo
        by using their email addresses."
        />
      </Flex>
      <Flex justifyContent={'center'}>
        <Flex width={420} px="0.938rem">
          <FormControl
            as="form"
            width={'100%'}
            mb={30}
            display={'flex'}
            flexDirection={'column'}
            onKeyPress={(e) => handleKeyPress(e, buttonRef, submitHandler)}
          >
            <BuddiesInfo state={state} changeHandler={changeHandler} />
            <StyledButton
              ref={buttonRef}
              width="100%"
              size="lg"
              onClick={
                buttonRef.current?.textContent === 'SEND NOW'
                  ? submitHandler
                  : () =>
                      history.push({
                        pathname: '/share',
                        state: { isSignup: true },
                      })
              }
              isLoading={state.requesting}
              loadingText="Submitting"
            >
              {emails.some(
                (email, idx) =>
                  email.length > 1 && !state[`invalidEmail${idx + 1}`],
              )
                ? 'SEND NOW'
                : 'NEXT STEP'}
            </StyledButton>
            <SkipPrompt />
          </FormControl>
        </Flex>
      </Flex>
    </Box>
  );
}
