import React from 'react';
import { Flex, Text, Heading } from '@chakra-ui/core';

export interface IPageIntroProps {
  heading: string;
  text: string;
}

export default function PageIntro({ heading, text }: IPageIntroProps) {
  return (
    <Flex
      width={{ base: 370, sm: 420 }}
      px="0.938rem"
      pb={50}
      flexDirection={'column'}
      alignItems={'center'}
      textAlign="center"
    >
      <Heading
        as="h3"
        fontSize="1.875rem"
        color="brand.secondary"
        letterSpacing={'0.01em'}
      >
        {heading}
      </Heading>
      <Text marginTop={5} fontSize={16} letterSpacing={'0.02em'}>
        {text}
      </Text>
    </Flex>
  );
}
