/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Text, Flex } from '@chakra-ui/core';

import { speechBubbleStyle } from './speech-bubble.styles';

export default function SpeechBubble({ text }: { text: string }) {
  return (
    // @ts-ignore
    <Flex alignItems="center" css={speechBubbleStyle}>
      <Text fontSize="normal" fontWeight="bold" p={4}>
        “{text}”
      </Text>
    </Flex>
  );
}
