import React from 'react';

import { Heading } from '@chakra-ui/core';

export function AccordionTitle({ text }: { text: string }) {
  return (
    <Heading
      color="#68B8CD"
      textAlign="center"
      fontWeight="bold"
      fontSize="1.3rem"
      lineHeight="3.4rem"
    >
      {text}
    </Heading>
  );
}
