import { useEffect, useState, useRef } from 'react';

import { debounce } from '../utils/debounce';

export default function useSticky() {
  const [isSticky, setSticky] = useState(false);
  const element = useRef<HTMLElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!element.current) return;

      element.current.getBoundingClientRect().top <= -75
        ? setSticky(true)
        : setSticky(false);
    };
    window.addEventListener('scroll', debounce<() => void>(handleScroll));
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  return { isSticky, element };
}
