import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Text, Heading, Image, Link } from '@chakra-ui/core';

import { useIsMobileView } from 'hooks/useMobileView';

import bookCluster from 'assets/images/Email_Free_book.png';
import appleLogo from 'assets/images/app_store.png';
import google from 'assets/images/google-play.png';
import mobileBookCluster from 'assets/images/Email_Free_book_mobile.jpg';

const StyledLink = styled(Link)`
  /* disable blue highlight on focus */
  &:focus {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }
`;

export default function AppDownload() {
  const isMobile = useIsMobileView();
  return (
    <Box
      position="relative"
      height="37.5rem"
      background={`url(${
        isMobile ? mobileBookCluster : bookCluster
      }) no-repeat center center`}
      paddingTop="1.5rem"
      alignContent="center"
      display="flex"
      backgroundSize="cover"
      mb="30px"
    >
      <Flex
        position="absolute"
        left={isMobile ? 0 : '50%'}
        top={isMobile ? '55%' : '30%'}
        justifyContent={'center'}
        flexDir="column"
        fontFamily="Montserrat"
        maxWidth="500px"
        alignItems={{ bae: 'initial', md: 'center' }}
        m="0 auto"
        px={'15px'}
        textAlign="center"
        color="white"
      >
        <Heading
          fontWeight={600}
          fontSize={isMobile ? '1.5rem' : '2.375rem'}
          color="white"
          lineHeight="2.6875rem"
          textAlign={'left'}
        >
          Find us in the App Store!
        </Heading>
        <Text
          fontSize={isMobile ? '1rem' : '1.125rem'}
          fontWeight={500}
          lineHeight="1.5rem"
          textAlign={'left'}
        >
          Turn screen time into storytime with everything you love about Readeo,
          in an app! Access all the favorite titles, and BookChat with friends
          and family, wherever you use your phone or tablet.
        </Text>
        <Flex px="1.25rem" mt="30px">
          <StyledLink
            href={process.env.REACT_APP_LINK_APP_STORE || ''}
            isExternal
            style={{ marginRight: '1rem', cursor: 'pointer' }}
          >
            <Image src={appleLogo} alt="app store logo" />
          </StyledLink>
          <StyledLink
            href={process.env.REACT_APP_LINK_PLAY_STORE || ''}
            isExternal
            style={{ cursor: 'pointer' }}
          >
            <Image src={google} alt="play store logo" />
          </StyledLink>
        </Flex>
      </Flex>
    </Box>
  );
}
