import { Environments, Config } from './config.types';

const config: Config = {
  [Environments.development]: {
    baseUrl: 'https://account.local.readeo.com/api',
    appUrl: 'http://localhost:3000',
    recaptchaKey: '6LfKdcAZAAAAANr6-qmXX6-X5jqyvEIg1RMw6ZPZ',
    googleAnalytics: 'UA-000000-01',
    fbPixelId: 'dev-pixel-id',
    recurlyKey: {
      // public: 'ewr1-5EmszYao1NjJGUCn4fj3lv', // Sandbox
      public: 'ewr1-rIjdWiKbWWxMR4Vuqo56vl', // Dev
    },
  },
  [Environments.qa]: {
    baseUrl: 'https://account.test.readeo.com/api',
    appUrl: 'https://app.test.readeo.com',
    recaptchaKey: '6LfKdcAZAAAAANr6-qmXX6-X5jqyvEIg1RMw6ZPZ',
    googleAnalytics: 'UA-000000-01',
    fbPixelId: 'qa-pixel-id',
    recurlyKey: {
      //public: 'ewr1-5EmszYao1NjJGUCn4fj3lv',
      public: 'ewr1-rIjdWiKbWWxMR4Vuqo56vl',
    },
  },
  [Environments.production]: {
    baseUrl: 'https://account.readeo.com/api',
    appUrl: 'https://app.readeo.com',
    recaptchaKey: '6Ld9mMAZAAAAAJzY1UYSKdItj9wgC5x3L6KxgfCv',
    googleAnalytics: 'UA-9565869-3',
    fbPixelId: '525502241492607',
    recurlyKey: {
      public: 'ewr1-IIa7slIFCms9mz5bij7M81',
    },
  },
};

const environment =
  (process.env.REACT_APP_ENV as Environments) || Environments.development;

export default config[environment];
