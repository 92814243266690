import React from 'react';
import {
  Box,
  Flex,
  Grid,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Button,
  PseudoBoxProps,
} from '@chakra-ui/core';

import { useIsLargeTabletView } from 'hooks/useIsLargeTabletView';
// assets
import { ReactComponent as RedCancelMark } from 'assets/svg/red-cancel-icon.svg';
import { ReactComponent as GreenCheckMark } from 'assets/svg/green-check-icon.svg';
import { ReactComponent as Exclamation } from 'assets/svg/exclamation-mark.svg';
import { ReactComponent as QuestionMark } from 'assets/svg/question-mark.svg';

import config from 'config';

type Plans = 'free' | 'gold' | 'family-gold';
type CustomPlanCheck = Partial<Record<Plans, () => JSX.Element>>;
type ButtonStyle = Omit<PseudoBoxProps, 'size'>;

type PlanContentType = {
  text: (() => JSX.Element) | string;
  packages: [string, string?, string?];
  customMark?: CustomPlanCheck;
};

const buttonStyles: { [key in Plans]: ButtonStyle } = {
  free: {
    background: '#FFF',
    color: '#083261',
    border: '1px solid #019CD5',
    _hover: {
      background: '#FFF',
    },
  },
  gold: {
    background: '#019CD5',
    color: '#FFFFFF',
    _hover: {
      background:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #019CD5',
    },
  },
  'family-gold': {
    background: '#F38530',
    color: '#FFFFFF',
    _hover: {
      background:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #f38530',
    },
  },
};

const plans = {
  free: {
    monthly: 'FREE',
    annually: 'Forever',
  },
  gold: {
    monthly: '$9.99/month',
    annually: '$8.33/mo annually',
  },
  'family-gold': {
    monthly: '$14.99/month',
    annually: '$9.99/mo annually',
  },
};

export default function ChoosePlanDesktop({
  fishFarm,
}: {
  fishFarm?: boolean;
}) {
  const isLargeTabletView = useIsLargeTabletView();

  const getButtonText = (plan: Plans) => {
    if (plan === 'free') return 'Stay Free';
    if (plan === 'gold') return 'Go Gold';
    return 'Family Gold';
  };

  const contents: PlanContentType[] = [
    {
      text: 'BookChat with the ones you love',
      packages: ['free', 'gold', 'family-gold'],
    },
    {
      text: 'Read books on your own',
      packages: ['free', 'gold', 'family-gold'],
    },
    {
      text: () => (
        <Box>
          <Text fontFamily="Montserrat">Full Library access</Text>
          {isLargeTabletView ? (
            <Text
              fontSize={12}
              color={fishFarm ? 'ffffff' : '#2E2E2E'}
              opacity={fishFarm ? 1 : 0.5}
            >
              (over 1,000 books and counting!)
            </Text>
          ) : null}
        </Box>
      ),
      packages: ['free', 'gold', 'family-gold'],
      customMark: {
        free: () => (
          <Box>
            Limited{' '}
            <Popover trigger="hover" placement="top">
              <PopoverTrigger>
                <QuestionMark style={{ display: 'inline' }} />
              </PopoverTrigger>
              <PopoverContent
                zIndex={100000}
                borderRadius="10px"
                color="#000000"
              >
                <PopoverArrow />
                <PopoverBody textAlign="center" p="30px 20px">
                  Free members can only read our 5 free books (which we swap out
                  weekly), or preview the first few pages of any of our other
                  books
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Text
              fontSize={12}
              color={fishFarm ? 'ffffff' : '#2E2E2E'}
              opacity={fishFarm ? 1 : 0.5}
            >
              (5 books rotate weekly)
            </Text>
          </Box>
        ),
      },
    },

    {
      text: () => (
        <Box>
          Create customized bookshelves
          <Popover trigger="hover" placement="top">
            <PopoverTrigger>
              <Exclamation
                style={{
                  display: 'inline',
                  marginLeft: '.25rem',
                  cursor: 'pointer',
                }}
              />
            </PopoverTrigger>
            <PopoverContent zIndex={100000} borderRadius="10px" color="#000000">
              <PopoverArrow />
              <PopoverBody textAlign="center" p="30px 20px">
                Create as many bookshelves as you want with as many books as you
                like. Plus, you can name them anything you wish – like 'Ryan's
                Bedtime Stories'.
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      ),
      packages: ['gold', 'family-gold'],
    },
    {
      text: () => (
        <Box>
          Bookmarks
          <Popover trigger="hover" placement="top">
            <PopoverTrigger>
              <Exclamation
                style={{
                  display: 'inline',
                  marginLeft: '.25rem',
                  cursor: 'pointer',
                }}
              />
            </PopoverTrigger>
            <PopoverContent borderRadius="10px">
              <PopoverArrow />
              <PopoverBody textAlign="center" p="30px 20px" color="#000000">
                Bedtime always comes too soon — mark your place and pick up
                right where you left off, whenever!
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      ),
      packages: ['gold', 'family-gold'],
    },
    {
      text: () => (
        <Box>
          Gift Readeo Gold to 4 others
          <Popover trigger="hover" placement="top">
            <PopoverTrigger>
              <Exclamation
                style={{
                  display: 'inline',
                  marginLeft: '.25rem',
                  cursor: 'pointer',
                }}
              />
            </PopoverTrigger>
            <PopoverContent borderRadius="10px">
              <PopoverArrow />
              <PopoverBody textAlign="center" p="30px 20px" color="#000000">
                These members will have unlimited access to our entire library,
                can read solo or together during a BookChat, and invite guests
                outside of the Family Plan to BookChat for free!
              </PopoverBody>
            </PopoverContent>
          </Popover>
          {isLargeTabletView ? (
            <Text
              fontSize={12}
              color={fishFarm ? 'ffffff' : '#2E2E2E'}
              opacity={fishFarm ? 1 : 0.5}
            >
              (who also have all these benefits)
            </Text>
          ) : null}
        </Box>
      ),
      packages: ['family-gold'],
    },
  ];

  const BoxPlan = ({
    heading,
    plan,
    isSecond,
    fishFarm,
  }: {
    heading: string;
    plan: Plans;
    isSecond?: boolean;
    fishFarm?: boolean;
  }) => {
    const price = plans[plan];

    const backgroundStyle = buttonStyles[plan];

    const renderCheck = (content: PlanContentType, plan: Plans) => {
      const isAvailable = content.packages.includes(plan);
      if (!isAvailable) return <RedCancelMark />;
      if (content.customMark?.[plan]) return content.customMark[plan]?.();
      return <GreenCheckMark />;
    };

    const goToSignUp = (plan: Plans) => {
      window.location.href = `${config.appUrl}/signup?plan=${plan}`;
    };

    return (
      <Box textAlign="center">
        <Flex
          paddingX={!isLargeTabletView ? '1rem' : '1.3rem'}
          background="#FFFFFF"
          borderRadius="6px"
          flexDirection="column"
          justifyContent="space-between"
          paddingTop="1.25rem"
          paddingBottom="1.5rem"
          marginTop="16px"
          color={fishFarm ? '#000000' : 'initial'}
        >
          <Box>
            <Text
              fontWeight="600"
              fontSize="1.125rem"
              letterSpacing="0.02em"
              fontFamily="Montserrat"
            >
              {price.monthly}
            </Text>
            <Text
              opacity={0.6}
              letterSpacing={!isLargeTabletView ? '0.01em' : '0.02em'}
              fontFamily="Montserrat"
              fontSize={!isLargeTabletView ? '13px' : 'initial'}
            >
              {price.annually}
            </Text>
          </Box>
          <Flex flexDirection="column" alignItems="center">
            {contents.map((content, index) => (
              <Box
                height="70px"
                justifyContent="center"
                key={index}
                display="flex"
                alignItems="center"
              >
                {renderCheck(content, plan)}
              </Box>
            ))}
          </Flex>
          <Button
            {...backgroundStyle}
            minWidth="20px"
            height={!isLargeTabletView ? '40px' : '50px'}
            textTransform="uppercase"
            borderRadius="6px"
            fontSize={!isLargeTabletView ? '13px' : '14px'}
            fontFamily="Montserrat"
            fontStyle="normal"
            fontWeight="bold"
            letterSpacing="0.01em"
            mt={5}
            onClick={() => {
              goToSignUp(plan);
            }}
          >
            {getButtonText(plan)}
          </Button>
        </Flex>
      </Box>
    );
  };

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      paddingTop="1rem"
      paddingBottom="1.5rem"
      justifyContent="center"
    >
      <Flex justifyContent="center">
        <Grid
          gridTemplateColumns={!isLargeTabletView ? '27% 70%' : 'auto 800px'}
        >
          <Box pt="38px" position="relative">
            <Flex
              paddingX="1.3rem"
              borderRadius="6px"
              flexDirection="column"
              paddingTop="1.25rem"
              paddingBottom="1.5rem"
              justifyContent="space-between"
            >
              <Text fontWeight={600}>Pricing</Text>
              <Flex flexDirection="column" marginTop="1rem">
                {contents.map(({ text }, index) => (
                  <Box
                    height="70px"
                    key={index}
                    display="flex"
                    alignItems="center"
                  >
                    {typeof text === 'function' ? text() : text}
                  </Box>
                ))}
              </Flex>
              <Box height="51px" />
            </Flex>
          </Box>
          <Grid
            templateColumns={
              !isLargeTabletView ? 'repeat(3, 155px)' : 'repeat(3, 230px)'
            }
            gap={!isLargeTabletView ? 4 : 6}
            ml="1.25rem"
            pb="2rem"
          >
            <BoxPlan plan="free" heading="Free Plan" fishFarm={fishFarm} />
            <BoxPlan
              plan="gold"
              heading="Gold Plan"
              isSecond={true}
              fishFarm={fishFarm}
            />
            <BoxPlan
              plan="family-gold"
              heading="Gold Family Plan"
              fishFarm={fishFarm}
            />
          </Grid>
        </Grid>
      </Flex>
    </Flex>
  );
}
