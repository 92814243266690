import { Box } from '@chakra-ui/core';
import React from 'react';
import Hero from './Hero';
import Logos from './Logos';
import Reasons from '../Reasons';
import Info from '../Info';
import Help from '../Help';
import Footer from '../Footer';
import Gadget from './Gadget';
import ReadTogether from './ReadTogether';

import readBg from 'assets/images/bg_read.png';

export default function MobileFishFam() {
  return (
    <Box width="100%">
      <Hero />
      <Logos />
      <Gadget />
      <Box
        background={`url(${readBg}) no-repeat`}
        backgroundSize="contain"
        mt="2rem"
        pt="1rem"
      >
        <ReadTogether />
        <Reasons />
      </Box>
      <Info />
      <Help />
      <Footer />
    </Box>
  );
}
