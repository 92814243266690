import React from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Flex,
  Text,
  Radio,
  FormLabel,
  RadioGroup,
  FormControl,
  FormHelperText,
} from '@chakra-ui/core';

import handleKeyPress from '../../../utils/keypress';
import { validateEmail } from '../../authentication/utils';
import { useIsMobileView } from 'hooks/useMobileView';

import {
  InputGroup,
  StyledInput,
  StyledButton,
  StyledTextarea,
} from '../../../styles/elements.styled';

// define initial state values
const _init: any = {
  requesting: false,
  invalidE: true,
  email: '',
  rname: '',
  from: '',
  message: '',
  delivery: 'now',
};

export default function RecipientData() {
  const [state, setState] = React.useState(_init);

  const history = useHistory();
  const location = useLocation();
  const isMobile = useIsMobileView();

  // refs
  const buttonRef = React.useRef(null);

  //   handlers
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.currentTarget.name === 'delivery') {
      setState({ ...state, deliver_at: e.currentTarget.valueAsNumber });

      return;
    }
    setState({ ...state, [e.currentTarget.name]: e.currentTarget.value });
    if (e?.currentTarget?.name === 'email') {
      setState((prevState: any) => ({
        ...prevState,
        invalidE: validateEmail(e?.currentTarget?.value),
      }));
    }
  };

  const clickHandler = () => {
    const [first_name, ...rest] = state.rname.split(' ');
    const data = {
      email: state.email,
      from: state.from,
      ...(first_name && {
        first_name: first_name,
      }),
      ...(rest.length && {
        last_name: rest.join(' '),
      }),
      // @ts-ignore
      product_id: location.state.product_id,
      ...(state.delivery === 'later' &&
        state.deliver_at !== undefined && { deliver_at: state.deliver_at }),
      ...(state.message.length && { personal_message: state.message }),
    };

    history.push({
      pathname: '/giftcard/checkout',
      state: data,
    });
  };

  return (
    <Flex width={['100vw', '65%']} justifyContent="center" px="0.938rem">
      <FormControl
        as="form"
        width="100%"
        my={30}
        display="flex"
        flexDirection="column"
        onKeyPress={(e) => handleKeyPress(e, buttonRef, clickHandler)}
      >
        <Flex
          flexDirection={['column', 'row']}
          justifyContent={['initial', 'space-between']}
          width="100%"
          boxSizing="border-box"
        >
          <Flex flexDir="column" width={['100%', '48%']}>
            <InputGroup width="100%">
              <StyledInput
                type="text"
                name="email"
                id="email"
                autoComplete="email"
                isRequired
                value={state.email}
                onChange={changeHandler}
                style={{
                  border: !state.email.length
                    ? '0.05rem solid #68B8CD'
                    : state.invalidE
                    ? '0.05rem solid red'
                    : '0.05rem solid #48bb78',
                }}
              />
              <FormLabel htmlFor="email">Recipient's email*</FormLabel>
            </InputGroup>
            <InputGroup width="100%">
              <StyledInput
                type="text"
                name="rname"
                id="rname"
                autoComplete="rname"
                isRequired
                value={state.rname}
                onChange={changeHandler}
                style={{
                  border:
                    state && state.rname && state.rname.length
                      ? '0.05rem solid #48bb78'
                      : '0.05rem solid#68B8CD',
                }}
              />
              <FormLabel htmlFor="name">Recipient's name</FormLabel>
            </InputGroup>
            <InputGroup width="100%">
              <StyledInput
                type="text"
                name="from"
                id="from"
                autoComplete="from"
                isRequired
                value={state.from}
                onChange={changeHandler}
                style={{
                  border:
                    state && state.from && state.from.length
                      ? '0.05rem solid #48bb78'
                      : '0.05rem solid #68B8CD',
                }}
              />
              <FormLabel htmlFor="from">Your name</FormLabel>
            </InputGroup>
            <Flex mb="0.5rem">
              <Text mr="1rem">Delivery timing: </Text>
              <RadioGroup
                display="flex"
                onChange={(e) =>
                  setState({ ...state, delivery: e.target.value })
                }
                value={state.delivery}
              >
                <Radio mr="0.5rem" value="now">
                  Immediately
                </Radio>
                <Radio value="later">Later</Radio>
              </RadioGroup>
            </Flex>
            {state.delivery === 'later' && (
              <InputGroup width="100%">
                <StyledInput
                  type="date"
                  name="delivery"
                  id="delivery"
                  autoComplete="delivery"
                  isRequired
                  min={moment(Date.now()).add(1, 'days').format('yyyy-M-D')}
                  onChange={changeHandler}
                  style={{
                    border:
                      state && state.delivery && state.delivery.length
                        ? '0.05rem solid #48bb78'
                        : '0.05rem solid #68B8CD',
                  }}
                />
                <FormLabel htmlFor="from">Delivery date</FormLabel>
              </InputGroup>
            )}
          </Flex>
          <Flex width={['100%', '48%']}>
            <InputGroup width="100%">
              <StyledTextarea
                type="text"
                name="message"
                id="message"
                autoComplete="message"
                isRequired
                maxLength={100}
                value={state.message}
                onChange={changeHandler}
                placeholder="Add a message (optional)"
                style={{
                  border:
                    state && state.message && state.message.length
                      ? '0.05rem solid #48bb78'
                      : '0.05rem solid#68B8CD',
                  height: isMobile
                    ? '148px'
                    : state?.delivery === 'later'
                    ? '287px'
                    : '220px',
                }}
              />
            </InputGroup>
          </Flex>
        </Flex>
        <FormHelperText
          id="form-helper-text"
          my="1rem"
          fontSize={12}
          letterSpacing="0.02em"
          color="#2e2e2e"
          textAlign="center"
        >
          * Readeo will send an email to the recipient to inform them of their
          gift and remind them to redeem.
        </FormHelperText>{' '}
        <StyledButton
          size="lg"
          onClick={clickHandler}
          isLoading={state.requesting}
          loadingText="Submitting"
          isDisabled={
            state.requesting
              ? true
              : !state.email.length || !state.from.length || state.invalidE
              ? true
              : false
          }
          width={{ base: '100%', md: '40%' }}
          style={{ borderRadius: '0.25rem', height: '50px' }}
          ref={buttonRef}
        >
          CONTINUE
        </StyledButton>
      </FormControl>
    </Flex>
  );
}
