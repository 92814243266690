export const debounce = <T extends Function>(
  func: T,
  wait = 15,
  immediate = true,
) => {
  let timeOut: ReturnType<typeof setTimeout> | null;
  return function (this: Window) {
    let context = this,
      args = arguments;

    const later = () => {
      timeOut = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeOut;

    if (timeOut) {
      clearTimeout(timeOut);
    }

    timeOut = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};
