import { IState } from '../authState.types';

export const rpass = /(?=.*[a-z])(?=.*[A-Z])/;
export const rpass2 = /(?=.*\d)/;
export const rpass2Alt = /(?=.*\W)/;
export const rmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const testLength = (value: string) => {
  if (value.length >= 8) {
    return true;
  } else {
    return false;
  }
};

const testValid = (value: string) => {
  if (rpass2.test(value) || rpass2Alt.test(value)) {
    return true;
  } else {
    return false;
  }
};

// Uncomment this code block if you want to enforce desired password strength together with meeting the required conditions

// const testStrength = (value: string, setState: SetState) => {
//   if (zxcvbn(value).score >= 2) {
//     setState((prevState) => ({ ...prevState, weak: false }));
//     return true;
//   } else {
//     setState((prevState) => ({ ...prevState, weak: true }));
//     return false;
//   }
// };

// form field validators
export const validateEmail = (value: string) => {
  if (rmail.test(value)) {
    return true;
  }
  return false;
};

export const validatePassword = (value: string) => {
  return {
    invalidP: !testValid(value),
    short: !testLength(value),
  };
};

// check button disabled status
export const checkDisabled = (state: IState & { giftcardValid?: boolean }) => {
  const {
    requesting,
    couponValid,
    couponExpired,
    giftcardValid,
    email,
    exists,
    short,
    invalidP,
    fname,
    lname,
    paymentType,
    coupon,
    zip,
  } = state;
  if (paymentType === 'gift_card' && giftcardValid && !requesting) return false;

  if (
    (exists || (fname && lname)) &&
    rmail.test(email) &&
    !short &&
    !invalidP &&
    ((paymentType === 'credit_card' && zip) || paymentType === 'paypal') &&
    (!coupon?.length || couponValid) &&
    !couponExpired &&
    !requesting
  )
    return false;
  else return true;
};

export const toolTipError = (state: IState & { giftcardValid?: boolean }) => {
  const {
    requesting,
    couponValid,
    giftcardValid,
    email,
    exists,
    short,
    invalidP,
    fname,
    lname,
    paymentType,
    coupon,
    zip,
  } = state;

  let errorResponse = '';
  if (paymentType === 'gift_card' && giftcardValid && !requesting) {
    errorResponse = `Invalid Gift card`;
  }

  if ((exists || (fname && lname)) && !rmail.test(email)) {
    errorResponse = ` ${errorResponse}
Invalid Email`;
  }
  if (short || invalidP) {
    errorResponse = ` ${errorResponse}
Invalid Password format`;
  }
  if (
    !((paymentType === 'credit_card' && zip) || paymentType === 'paypal') ||
    (coupon?.length && !couponValid && !requesting)
  ) {
    errorResponse = ` ${errorResponse} 
      Invalid Billing Information`;
  }
  return errorResponse;
};
