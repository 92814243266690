import React from 'react';
import { Flex, Link, Image } from '@chakra-ui/core';

import facebook from '../../assets/images/facebook.svg';
import instagram from '../../assets/images/instagram.svg';
import twitter from '../../assets/images/twitter.svg';
import youtube from '../../assets/images/youtube.svg';

const socials = [
  {
    link: 'https://facebook.com/readeo',
    alt: 'Readeo Facebook',
    name: 'facebook',
    img: facebook,
  },
  {
    link: 'https://www.instagram.com/readeo_kids/',
    alt: 'Readeo Instagram',
    name: 'instagram',
    img: instagram,
  },
  {
    link: 'https://twitter.com/readeo',
    alt: 'Readeo Twitter',
    name: 'twitter',
    img: twitter,
  },
  {
    link: 'https://www.youtube.com/channel/UCaLfp-RDZLLoPOAhWV5KCxQ',
    alt: 'Readeo Youtube',
    name: 'youtube',
    img: youtube,
  },
];

interface ISocialProps {
  link: string;
  alt: string;
  name: string;
  img: any;
}

function Social(props: ISocialProps) {
  return (
    <Link pr={5} href={props.link} isExternal>
      <Image width="36px" height="36px" src={props.img} alt={props.alt} />
    </Link>
  );
}

export default function Socials() {
  return (
    <Flex
      justifyContent={{ base: 'center', sm: 'flex-start' }}
      flexDirection="column"
    >
      <Flex py={2} alignItems="center">
        {socials.map((social) => (
          <Social
            key={social.name}
            link={social.link}
            alt={social.alt}
            name={social.name}
            img={social.img}
          />
        ))}
      </Flex>
    </Flex>
  );
}
