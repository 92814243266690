import React from 'react';
import { Box, Text, Stack } from '@chakra-ui/core';

export default function Contact() {
  return (
    <Stack fontSize="0.9rem">
      <Box>
        <Text
          // opacity={0.5}
          fontWeight="bold"
          fontSize="12px"
          letterSpacing="wider"
          color="#4D87CA"
        >
          Social Media
        </Text>
        {/* <Text>
          Readeo LLC
          <br />
          303 E Wacker Drive, Suite 1040
          <br />
          Chicago, IL 60601
        </Text>
        <UILink href="mailto:support@readeo.com" color="blue.400">
          support@readeo.com
        </UILink> */}
      </Box>
    </Stack>
  );
}
