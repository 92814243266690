import { useEffect, useState } from 'react';

export function useIsLargeTabletView() {
  const [isLargeTabletView, setIsLargeTabletView] = useState(
    window.matchMedia('(min-width: 1200px)')?.matches,
  );
  const checkMobileScreen = () =>
    setIsLargeTabletView(window.matchMedia('(min-width: 1200px)')?.matches);

  useEffect(() => {
    window.addEventListener('resize', checkMobileScreen);
    return () => window.removeEventListener('resize', checkMobileScreen);
  }, []);
  return isLargeTabletView;
}
