import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { FaQuestionCircle } from 'react-icons/fa';
import { CardElement } from '@recurly/react-recurly';
import {
  Flex,
  Text,
  Button,
  Tooltip,
  FormLabel,
  PseudoBox,
} from '@chakra-ui/core';

import { InputGroup, StyledInput } from '../../../../styles/elements.styled';

export default function PaymentMethod(props: any) {
  const {
    state: {
      requesting,
      checking,
      couponValid,
      couponExpired,
      couponDescription,
      fname,
      lname,
      zip,
      coupon,
      paymentType,
      giftcard,
    },
    changeHandler,
    choose,
    check,
    checkCoupon,
  } = props;

  // check if current route matches signup route
  let match = useRouteMatch('/signup');

  var ua = navigator.userAgent;

  const paymentTypes = match
    ? [
        { id: 'credit_card', value: 'Credit card' },
        { id: 'paypal', value: 'PayPal' },
        { id: 'gift_card', value: 'Gift card' },
      ]
    : [
        { id: 'credit_card', value: 'Credit card' },
        { id: 'paypal', value: 'PayPal' },
      ];

  return (
    <>
      <Flex
        justifyContent={'space-between'}
        marginBottom="1rem"
        flexWrap="wrap"
      >
        {paymentTypes.map(
          (type) =>
            !(
              (ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1) &&
              type.id === 'paypal'
            ) && (
              <PseudoBox
                as="div"
                display="flex"
                key={type.id}
                width={type.id === 'gift_card' ? '100%' : '48%'}
                mb={type.id !== 'gift_card' ? '1rem' : ''}
                p={{ xsm: '0.8rem', md: '2.25rem' }}
                justifyContent={type.id === 'gift_card' ? 'center' : 'initial'}
                backgroundColor={
                  paymentType === type.id ? 'white' : 'rgba(255,255,255,0.3)'
                }
                cursor="pointer"
                borderRadius="0.375rem"
                border={
                  paymentType === type.id
                    ? '0.1rem solid #f38530'
                    : '0.1rem solid #68B8cd'
                }
                _hover={{ backgroundColor: 'white' }}
                onClick={() => choose(type.id)}
              >
                <img
                  src={`/assets/${type.id}.png`}
                  alt={type.id}
                  style={{
                    width: '25px',
                    height: '25px',
                    marginRight: '0.2rem',
                  }}
                />
                {type.value}
              </PseudoBox>
            ),
        )}
      </Flex>
      <PseudoBox
        as="div"
        display={paymentType && paymentType !== 'paypal' ? 'block' : 'none'}
      >
        <input
          type="hidden"
          data-recurly="first_name"
          name="first_name"
          defaultValue={fname}
        />
        <input
          type="hidden"
          data-recurly="last_name"
          name="last_name"
          defaultValue={lname}
        />{' '}
        {paymentType === 'gift_card' && (
          <InputGroup width="100%">
            <StyledInput
              type="text"
              name="giftcard"
              id="giftcard"
              autoComplete="off"
              isRequired
              value={giftcard}
              onChange={changeHandler}
              style={{
                border:
                  giftcard && giftcard.length
                    ? '0.05rem solid #48bb78'
                    : '0.05rem solid#68B8CD',
              }}
            />
            <FormLabel htmlFor="giftcard">Gift card code</FormLabel>
            <Button
              width="25%"
              height="100%"
              top="50%"
              transform="translateY(-50%)"
              color="white"
              isDisabled={
                requesting ? true : giftcard.length < 1 ? true : false
              }
              isLoading={requesting}
              backgroundColor="#23AAD9"
              borderTopLeftRadius="0rem"
              borderBottomLeftRadius="0rem"
              onClick={() => check(giftcard)}
            >
              APPLY
            </Button>
          </InputGroup>
        )}
        {paymentType === 'credit_card' && (
          <>
            <InputGroup width="100%">
              <CardElement />
              <Tooltip
                aria-label="cvv"
                label="CVV/C is a three or four-digit number located on the back of your credit/debit card on the right side of the white signature strip"
                placement="top"
                zIndex={199}
              >
                <i id="cvv" aria-labelledby="cvv">
                  <FaQuestionCircle />
                </i>
              </Tooltip>
            </InputGroup>
            <InputGroup width="100%">
              <StyledInput
                data-recurly="postal_code"
                type="text"
                name="zip"
                id="zip"
                autoComplete="off"
                isRequired
                value={zip}
                onChange={changeHandler}
                style={{
                  border:
                    zip && zip.length
                      ? '0.05rem solid #48bb78'
                      : '0.05rem solid#68B8CD',
                }}
              />
              <FormLabel htmlFor="zip">Zip/postal code</FormLabel>
            </InputGroup>
          </>
        )}
      </PseudoBox>
      {match && paymentType !== 'gift_card' ? (
        <>
          {couponValid && !couponExpired && !!couponDescription.length && (
            <Text
              as="em"
              fontSize={13}
              style={{ color: 'green', marginBottom: '0.625rem' }}
            >
              {couponDescription}
            </Text>
          )}
          {!!coupon.length && !couponValid && !couponExpired && (
            <Text
              as="em"
              fontSize={13}
              style={{ color: 'red', marginBottom: '0.625rem' }}
            >
              Invalid coupon. Try using free14days
            </Text>
          )}
          {!!coupon.length && couponValid && couponExpired && (
            <Text
              as="em"
              fontSize={13}
              style={{ color: 'red', marginBottom: '0.625rem' }}
            >
              Coupon Expired
            </Text>
          )}

          <InputGroup width="100%">
            <StyledInput
              type="text"
              name="coupon"
              id="coupon"
              autoComplete="off"
              value={coupon}
              onChange={changeHandler}
              style={{
                border:
                  giftcard && giftcard.length
                    ? '0.05rem solid #48bb78'
                    : '0.05rem solid#68B8CD',
              }}
            />

            <FormLabel htmlFor="coupon">Coupon code</FormLabel>
            <Button
              width="25%"
              height="100%"
              top="50%"
              transform="translateY(-50%)"
              color="white"
              isDisabled={checking || coupon.length === 0 ? true : false}
              isLoading={checking}
              backgroundColor="#23AAD9"
              borderTopLeftRadius="0rem"
              borderBottomLeftRadius="0rem"
              style={{
                border:
                  !coupon.length || (couponValid && !couponExpired)
                    ? '0.05rem solid #48bb78'
                    : '0.05rem solid red',
              }}
              onClick={() => checkCoupon(coupon)}
            >
              APPLY
            </Button>
          </InputGroup>
        </>
      ) : null}
      <Text as="span" fontSize="0.875rem">
        By continuing, you agree to our{' '}
        <Link
          to="/terms-of-service"
          style={{
            color: '#1e4786',
            fontWeight: 'bold',
          }}
        >
          terms of service{' '}
        </Link>
        and{' '}
        <Link
          to="/privacy"
          style={{
            color: '#1e4786',
            fontWeight: 'bold',
          }}
        >
          privacy policy
        </Link>
      </Text>
    </>
  );
}
