import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecurly } from '@recurly/react-recurly';
import Recaptcha from 'react-google-recaptcha';
import {
  Box,
  Flex,
  Text,
  Button,
  Heading,
  Skeleton,
  FormControl,
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/core';

import axios from '../../utils/axios';
import useScrollToTop from '../../hooks/useScrollToTop';
import useToastMod from '../../hooks/useToastMod';
import useConfig from '../../hooks/useConfig';
import handleKeyPress from '../../utils/keypress';
import config from '../../config';

import _init from './_init';
import BillingInfo from './BillingInfo';

export default function Upgrade() {
  const [state, setState] = useState(_init);
  const [loading, setLoading] = useState(false);

  const { refEl } = useScrollToTop();
  const { recaptchaKey } = useConfig();

  // refs
  const recaptchaRef = useRef(null);
  const buttonRef = useRef(null);
  const cancelRef = useRef(null);
  const formRef = useRef<HTMLFormElement>(null);

  // access recurly instance
  const recurly = useRecurly();

  // toast some bread
  const toast = useToastMod();

  // access history object
  const history = useHistory();

  useEffect(() => {
    const getUser = async () => {
      setState((prevState) => ({ ...prevState, requesting: true }));
      try {
        const {
          data: { data },
          status,
        } = await axios.get('accounts');
        if (status === 200) {
          return setState((prevState) => ({
            ...prevState,
            requesting: false,
            isLoaded: true,
            accountExists: true,
            fname: data[0].first_name,
            lname: data[0].last_name,
            address: data[0].street,
            city: data[0].city,
            sorp: data[0].state,
            country: '',
          }));
        }
      } catch (err) {
        return setState((prevState) => ({
          ...prevState,
          requesting: false,
          dialog: true,
        }));
      }
    };
    getUser();
    // eslint-disable-next-line
  }, []);

  // handlers
  const changeHandler = (e: any) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.value });
  };

  // payment type handler
  const choosePay = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    e.preventDefault();
    if (state.paymentType === type) {
      setState({ ...state, paymentType: '' });
    } else {
      setState({ ...state, paymentType: type });
    }
  };

  // close alert dialog
  const closeAlert = () => {
    setState((prevState) => ({
      ...prevState,
      dialog: false,
    }));
    return history.push({ pathname: '/library' });
  };

  const handlePayPal = async (token_id: string) => {
    const payload = {
      token_id,
      payment_method: state.paymentType,
    };
    axios
      .post('subscriptions/updatebilling', payload)
      .then(({ data: { message, data } }) => {
        setLoading(false);
        toast({
          title: `${message}`,
          description: `Voila ${data[0].firstName}!, your billing Info has been updated.`,
          status: 'success',
          duration: 10000,
          position: 'top',
          isClosable: true,
        });
        // Push to confirmation page
        return (window.location.href = config.appUrl);
      })
      .catch((err) => {
        setLoading(false);
        // @ts-ignore
        window?.grecaptcha?.reset();
        return toast({
          title: 'Something went wrong!',
          description: err && err.message ? err.message : '',
          status: 'error',
          duration: 10000,
          position: 'top',
          isClosable: true,
        });
      });
  };

  const submitHandler = async (e: any) => {
    e.preventDefault();
    try {
      setState({ ...state, requesting: true });
      // @ts-ignore
      const captcha_token = await recaptchaRef.current.executeAsync();
      if (captcha_token) {
        // @ts-ignore
        recurly.token(formRef.current, (err, token) => {
          if (err) {
            setState((prevState) => ({
              ...prevState,
              requesting: false,
            }));
            // handle error
            return toast({
              title: 'An error occured',
              description: `${err.message}`,
              status: 'error',
              duration: 10000,
              position: 'top',
              isClosable: true,
            });
          } else {
            const payload = {
              token_id: token.id,
              captcha_token: captcha_token,
            };
            axios
              .post('subscriptions/updatebilling', payload)
              .then(({ data: { message, data } }) => {
                setState({ ...state, requesting: false });
                toast({
                  title: `${message}`,
                  description: `Voila ${data[0].firstName}!, your billing Info has been updated.`,
                  status: 'success',
                  duration: 10000,
                  position: 'top',
                  isClosable: true,
                });
                return (window.location.href = config.appUrl);
              })
              .catch((err) => {
                setState({ ...state, requesting: false });
                // @ts-ignore
                window?.grecaptcha?.reset();
                return toast({
                  title: 'An error occurred',
                  description: `${err.message}`,
                  status: 'error',
                  duration: 10000,
                  position: 'top',
                  isClosable: true,
                });
              });
          }
        });
      } else {
        throw new Error('Invalid response from Recaptcha');
      }
    } catch (err) {
      setState({ ...state, requesting: false });
      // @ts-ignore
      window?.grecaptcha?.reset();
      return toast({
        title: err.message,
        description:
          'Unable to verify your request. Please refresh this page and try again',
        status: 'error',
        duration: 10000,
        position: 'top',
        isClosable: true,
      });
    }
  };

  return (
    <Box minHeight={{ sm: 'calc(100vh - 18.25rem)' }}>
      <Flex justifyContent={'center'} background={'white'} ref={refEl}>
        <Flex
          width={1200}
          px={['1.5rem', '1.5rem', '0.94rem']}
          flexDirection={'column'}
          textAlign={'center'}
          paddingTop={127}
          paddingBottom={50}
        >
          <Heading
            as="h3"
            size="xl"
            color="brand.secondary"
            letterSpacing={'0.01em'}
          >
            Update your Billing Information
          </Heading>
          <Text marginTop={5} fontSize={16} letterSpacing={'0.02em'}>
            Please enter your correct billing details—and let's get your
            subscription updated!
          </Text>
        </Flex>
      </Flex>
      <Flex
        justifyContent={'center'}
        background={
          'linear-gradient(0deg, rgba(131, 208, 228, 0.5), rgba(131, 208, 228, 0.5)), #FFFFFF'
        }
      >
        <Flex width={420} px="0.938rem">
          <Skeleton isLoaded={state.isLoaded}>
            <FormControl
              as="form"
              width="100%"
              my={30}
              display="flex"
              flexDirection="column"
              ref={formRef}
              onKeyPress={(e) => handleKeyPress(e, buttonRef, submitHandler)}
            >
              <BillingInfo
                state={state}
                changeHandler={changeHandler}
                choosePay={choosePay}
                loading={loading}
                setLoading={setLoading}
                buttonRef={buttonRef}
                handlePayPal={handlePayPal}
                submitHandler={submitHandler}
              />
              <Recaptcha
                ref={recaptchaRef}
                sitekey={recaptchaKey}
                size="invisible"
                badge="bottomleft"
              />
              <AlertDialog
                isOpen={state.dialog}
                leastDestructiveRef={cancelRef}
                onClose={closeAlert}
              >
                <AlertDialogOverlay />
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Oops! Non-existent Account
                  </AlertDialogHeader>
                  <AlertDialogBody>
                    There seems to be an issue with that account code.
                  </AlertDialogBody>
                  <AlertDialogFooter>
                    <Button variantColor="red" onClick={closeAlert} ml={3}>
                      Close
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </FormControl>
          </Skeleton>
        </Flex>
      </Flex>
    </Box>
  );
}
