import React from 'react';
import { Flex, Text, Accordion } from '@chakra-ui/core';
import { CustomAccordionItem } from 'components/shared';
import { StyledButton } from 'styles/StyledButton';
import { useIsMobileView } from 'hooks/useMobileView';
const Help = () => {
  const isMobile = useIsMobileView();

  const items = [
    {
      title: 'How many users can I invite?',
      text: `As a Free Member you can invite as many people as you’d like! If you
      are a Readeo Gold Family Member, you can invite up to four other
      members who would have full access to all Readeo has to offer (Our
      award-winning library, custom bookshelves and boomark capabilties).`,
      expanded: true,
    },
    {
      title: 'Can I read the Fish Fam’s book on a free plan?',
      text: `All Readeo users will be able to read the Fish Fam’s book for free during the month of June. After that, you’ll need a paid plan to access their content`,
      expanded: isMobile ? false : true,
    },
    {
      title: 'What age ranges are the books appropriate for?',
      text: `Readeo has books for children ages 0-11, but many children older than 11 also enjoy using Readeo!`,
      expanded: false,
    },
    {
      title: 'Do both sides need to be paid members to BookChat?',
      text: `Nope! Enjoy reading virtually with other users whether you’re on a free or paid plan.`,
      expanded: false,
    },
    {
      title: 'Where can I learn more about the Readeo library?',
      text: `To see a sample of the types of books we have in our library click <a style="color:midnightblue; font-weight: bold" href="/library">here</a>.`,
      expanded: false,
    },
    {
      title: 'Is there a Readeo app for my tablet or mobile device?',
      text: `Yes! Find us in the Google Play or iOS app stores by searching “Readeo”.`,
      expanded: false,
    },
  ];
  return (
    <Flex
      maxWidth="1320px"
      height="100%"
      fontSize="sm"
      justifyContent="center"
      py={{ base: '1rem 1rem 0', md: '2rem' }}
      flexDir="column"
      m={{ base: '2rem auto 0', md: '0 auto' }}
    >
      <Text
        textAlign="center"
        alignSelf="center"
        fontSize={{ base: '34px', md: '44px' }}
        color="#083261"
        fontWeight={600}
      >
        Still have questions?
      </Text>
      <Accordion allowMultiple py={{ base: 4, md: 6 }}>
        <Flex justify="center" w="100%" fontSize="1rem" flexWrap="wrap">
          {items.map(
            (
              {
                title,
                text,
                expanded,
              }: {
                title: string;
                text: string;
                expanded: boolean;
              },
              index: number,
            ) => (
              <CustomAccordionItem
                spacing={3}
                title={title}
                expanded={expanded}
                width="45%"
                mx="1rem"
                fishFarm={true}
                key={index}
              >
                <Text dangerouslySetInnerHTML={{ __html: text }}></Text>
              </CustomAccordionItem>
            ),
          )}
        </Flex>
      </Accordion>
      <Flex justifyContent="center">
        <StyledButton
          padding="1.5rem"
          width={{ base: '90%', md: 'inherit' }}
          fontWeight={700}
          height="60px"
          onClick={() => (window.location.href = 'https://readeo.com/faq')}
        >
          GET MORE ANSWERS
        </StyledButton>
      </Flex>
    </Flex>
  );
};

export default Help;
