import React, { useEffect } from 'react';
import { useRecurly } from '@recurly/react-recurly';
import { Text } from '@chakra-ui/core';

import useToast from '../../../../hooks/useToastMod';

import { StyledButton } from '../../../../styles/elements.styled';

export default function PayPalButton(props: any) {
  const { loading, setLoading, isDisabled, handlePayPal, buttonRef } = props;

  const recurly = useRecurly();
  const toast = useToast();

  const payPal = recurly.PayPal({
    display: { displayName: 'Readeo, LLC' },
  });
  useEffect(() => {
    payPal.on('token', (token) => {
      handlePayPal(token.id);
    });

    payPal.on('error', (error) => {
      setLoading(false);
      toast({
        title: error.message || 'An error occurred trying to connect to PayPal',
        status: 'error',
        duration: 10000,
        position: 'top',
        isClosable: true,
      });
    });

    // @ts-ignore
    payPal.on('cancel', () => {
      setLoading(false);
      toast({
        title: 'Action canceled, Please try again if this was an error',
        status: 'info',
        duration: 10000,
        position: 'top',
        isClosable: true,
      });
    });
  }, [payPal, handlePayPal, setLoading, toast]);

  const handleClick = () => {
    setLoading(true);
    payPal.start();
  };

  return (
    <StyledButton
      size="lg"
      onClick={handleClick}
      isLoading={loading}
      loadingText="Submitting"
      isDisabled={isDisabled}
      ref={buttonRef}
    >
      <>
        <Text as="span">PAY WITH</Text>
        <img
          src={`/assets/paypal-white.png`}
          alt="paypal"
          style={{ width: '25px', height: '25px', margin: '0 0.2rem' }}
        />
        <Text as="span" fontStyle="italic">
          Pay
        </Text>
        Pal
      </>
    </StyledButton>
  );
}
