import React from 'react';
import BgImage from 'assets/images/fish_mobile_bg.jpg';
import GadgetImage from 'assets/images/fish_gadget_mobile.png';
import { Box, Image } from '@chakra-ui/core';

export default function Gadget() {
  return (
    <Box
      width="100%"
      overflowX="scroll"
      background={`url(${BgImage}) no-repeat`}
      backgroundSize="cover"
      mt="20px"
      pt="20px"
    >
      <Image src={GadgetImage} minWidth="max-content" />
    </Box>
  );
}
