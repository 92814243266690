import React from 'react';
import { Box } from '@chakra-ui/core';
import { useIsMobileView } from 'hooks/useMobileView';

export default function HowItWorks() {
  const isMobile = useIsMobileView();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      px={isMobile ? '5%' : '20%'}
      py={'5%'}
      pt={isMobile ? '0' : '5%'}
    >
      <Box
        as="iframe"
        w="100%"
        h="479.64px"
        title="This is how the Readeo story begins"
        // @ts-ignore
        allow="autoplay"
        src="https://www.youtube.com/embed/NoyYHgS0rPY?autoplay=true"
        allowFullScreen
      />
    </Box>
  );
}
