import { Button } from '@chakra-ui/core';
import styled from '@emotion/styled';

interface ButtonProp {
  backgroundColor?: string;
  color?: string;
  mt?: string;
}

export const StyledButton = styled(Button)<ButtonProp>`
  min-width: 14.3125rem;
  font-family: 'Montserrat', sans-serif;
  margin-top:  ${(props) => (props.mt ? props.mt : '1.5rem')};
  margin-bottom: 1.5rem;
  color: ${(props) => (props.color ? props.color : 'white')};
  align-self: center;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#f38530'};
  /* background-color: #f38530; */
  :hover {
    background: ${(props) =>
      props.backgroundColor === '#ffffff'
        ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #083261'
        : props.backgroundColor
        ? `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${props.backgroundColor}}`
        : 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #f38530'};
`;
