import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  Text,
  Button,
  FormLabel,
  ModalCloseButton,
  FormControl,
} from '@chakra-ui/core';
import { IDisclosure } from '../../shared';
import {
  InputGroup,
  StyledInput,
  StyledTextarea,
} from '../../../styles/elements.styled';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  note: '',
};

const ModalComponent = ({
  disclosure,
  makeAPICall,
  isLoading,
}: {
  disclosure: IDisclosure;
  makeAPICall: (t: 'guardians' | 'administrators', p: any) => void;
  isLoading: boolean;
}) => {
  const { isOpen, onClose } = disclosure;
  const [state, setState] = useState(initialState);

  const changeHandler = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      size={{ md: '660px' }}
      isCentered
      closeOnEsc
    >
      <ModalOverlay bg="#00152dcc" zIndex={9999} />

      <ModalContent
        width="100%"
        zIndex={9999}
        borderRadius="10px"
        p={{ base: '2rem 1rem', md: '2rem' }}
      >
        <ModalCloseButton size="lg" />
        <ModalBody
          display="flex"
          boxSizing="border-box"
          flexDir="column"
          justifyContent="center"
          fontSize="18px"
          p="0 !important"
        >
          <Text
            textAlign={{ base: 'left', md: 'center' }}
            mt="2rem"
            color="#08315F"
            fontSize="30px"
            fontWeight={500}
          >
            Thank you for your interest!
          </Text>
          <Text
            textAlign={{ base: 'left', md: 'center' }}
            color="#2E2E2E"
            fontSize="18px"
            mt="1rem"
            mb="2rem"
          >
            Please fill in this information for us
          </Text>
          <FormControl
            as="form"
            onSubmit={(e) => {
              e.preventDefault();
              makeAPICall('administrators', state);
              setState(initialState);
            }}
          >
            <Flex
              justifyContent={{ md: 'space-between' }}
              flexDir={{
                base: 'column',
                md: 'row',
              }}
            >
              <InputGroup width={{ base: '100%', md: '48%' }}>
                <StyledInput
                  type="text"
                  name="firstName"
                  id="firstName"
                  autoComplete="off"
                  isRequired
                  border="1px solid #BC1717"
                  value={state.firstName}
                  onChange={changeHandler}
                />
                <FormLabel htmlFor="firstName">First name</FormLabel>
              </InputGroup>
              <InputGroup width={{ base: '100%', md: '48%' }}>
                <StyledInput
                  type="text"
                  name="lastName"
                  id="lastName"
                  autoComplete="off"
                  isRequired
                  border="1px solid #BC1717"
                  value={state.lastName}
                  onChange={changeHandler}
                />
                <FormLabel htmlFor="lastName">Last name</FormLabel>
              </InputGroup>
            </Flex>
            <InputGroup width={'100%'}>
              <StyledInput
                type="email"
                name="email"
                id="email"
                autoComplete="off"
                isRequired
                value={state.email}
                onChange={changeHandler}
              />
              <FormLabel htmlFor="email">Email</FormLabel>
            </InputGroup>

            <StyledTextarea
              placeholder="Your interest in Readeo’s Guest Reader Program..."
              height="127px"
              id="note"
              name="note"
              onChange={changeHandler}
            />

            <Button
              type="submit"
              bg="#F38530"
              borderRadius="6px"
              width="100%"
              color="white"
              height="50px"
              fontWeight={700}
              fontSize="16px"
              mb="3rem"
              mt="1rem"
              isLoading={isLoading}
              _hover={{
                backgroundColor: 'rgba(243, 133, 48, 0.7)',
              }}
            >
              SEND MY INFO
            </Button>
          </FormControl>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalComponent;
