import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { Flex, Heading, Image, Text, Box, Tag, Stack } from '@chakra-ui/core';

import books, { Book } from './assets/books';
import { StyledImage } from '../../styles/elements.styled';
import useScrollToTop from '../../hooks/useScrollToTop';

import bookImage from '../../assets/images/book.svg';
import config from 'config';

// pick from colors list to make tags have random backgrounds
const colors = [
  'gray',
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'blue',
  'purple',
  'pink',
];

export default function SingleBook() {
  const { refEl } = useScrollToTop();
  // get book id in url params
  const { id } = useParams();
  const history = useHistory();

  const [book, setBook] = useState<Book | null>(null);
  const [coverImage, setCoverImage] = useState({ url: '', isCover: false });

  const imageURL = (bookId: number, pageNo: number) => {
    return `https://assets.readeo.com/books/${bookId}/${pageNo}.jpg`;
  };

  useEffect(() => {
    const book = books.find((book) => book.id === parseInt(id, 10));

    if (book) {
      setBook(book);
      setCoverImage({ url: imageURL(book.id, 1), isCover: true });
    }
  }, [id]);

  const labelClick = (key: 'ageRange' | 'category', value: string) => {
    return history.push({
      pathname: '/library',
      state: {
        filter: {
          [key]: value,
        },
      },
    });
  };

  return (
    <Flex
      pt={16}
      ref={refEl}
      justifyContent={'center'}
      minHeight="calc(100vh - 18.25rem)"
    >
      <Flex
        width={800}
        px={{ base: '1rem', md: '0.94rem' }}
        paddingBottom="4rem"
        flexDirection="column"
      >
        <Flex marginBottom="2rem" justify="flex-start">
          <Link
            to="/library"
            style={{
              marginTop: '2rem',
              display: 'flex',
              alignItems: 'center',
              fontSize: '1rem',
              fontWeight: 'bold',
              color: '#f38530',
            }}
          >
            <i style={{ fontSize: '1rem', marginRight: '0.5rem' }}>
              <IoIosArrowBack />
            </i>
            Back to Library
          </Link>
        </Flex>
        <Flex
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'initial', md: 'top' }}
        >
          {!book ? (
            <Stack>
              <Image src={bookImage} alt="Book" />
              <Text textAlign="center" fontWeight="bold" color="#083261">
                Book does not exist
              </Text>
            </Stack>
          ) : (
            <>
              <Image
                key={book.id}
                size="24rem"
                marginBottom={{
                  base: '1.5rem',
                  md: '0',
                }}
                objectFit="initial"
                src={coverImage.url}
                fallbackSrc="/loading-fallback.gif"
                alt={book.title}
                alignSelf={{ base: 'center', md: 'initial' }}
                boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                transition="all 2s ease-in-out"
              />

              <Flex
                width={{ sm: '100%', md: '50%' }}
                marginLeft={[0, 0, '2rem']}
                flexDirection="column"
              >
                <Stack>
                  <Heading as="h6" fontSize="1.8rem" color="#083261">
                    {book.title}
                  </Heading>
                  <Text fontSize="1rem" opacity={0.8}>
                    Written by {book.author}
                  </Text>
                  <Text fontSize="1rem" opacity={0.8}>
                    Illustrated by {book.illustrator}
                  </Text>
                  <Flex flexWrap="wrap">
                    <Text
                      marginRight="0.2rem"
                      fontWeight="bold"
                      color="#083261"
                    >
                      {book.ageRanges !== undefined && book.ageRanges.length > 1
                        ? 'Age ranges:'
                        : 'Age range:'}
                    </Text>
                    {book.ageRanges.map((range, idx) => (
                      <Tag
                        key={idx}
                        size="sm"
                        variantColor={
                          colors[Math.floor(Math.random() * colors.length)]
                        }
                        marginRight="0.1rem"
                        cursor="pointer"
                        onClick={() => labelClick('ageRange', range)}
                      >
                        {range.split('yr')[0]}
                      </Tag>
                    ))}
                  </Flex>
                  <Flex flexWrap="wrap">
                    <Text
                      marginRight="0.2rem"
                      fontWeight="bold"
                      color="#083261"
                    >
                      {book.categories !== undefined &&
                      book.categories.length > 1
                        ? 'Categories:'
                        : 'Category:'}
                    </Text>
                    {book.categories.map((cat, idx) => (
                      <Tag
                        key={idx}
                        size="sm"
                        variantColor={
                          colors[Math.floor(Math.random() * colors.length)]
                        }
                        marginRight="0.1rem"
                        marginTop="0.1rem"
                        cursor="default"
                      >
                        {cat}
                      </Tag>
                    ))}
                  </Flex>
                </Stack>
                <Heading
                  as="h6"
                  marginTop="1rem"
                  fontSize="1.1rem"
                  color="#083261"
                >
                  Preview
                </Heading>
                <Stack marginTop="0.5rem" isInline>
                  {[2, 4, 5, 7].map((page) => (
                    <StyledImage
                      key={page}
                      size={20}
                      objectFit="initial"
                      src={imageURL(book.id, page)}
                      fallbackSrc="/loading-fallback.gif"
                      alt={book.title}
                      cursor="pointer"
                      alignSelf={{ base: 'center', md: 'initial' }}
                      onClick={() =>
                        setCoverImage({
                          url: imageURL(book.id, page),
                          isCover: false,
                        })
                      }
                    />
                  ))}
                </Stack>
                <Text
                  marginTop="1.1875rem"
                  fontSize="1rem"
                  lineHeight="1.31rem"
                  letterSpacing="0.04em"
                >
                  {book.description}
                </Text>
                <Box
                  as="button"
                  width="100%"
                  marginTop="1.5rem"
                  alignSelf="center"
                  color="white"
                  padding={4}
                  backgroundColor="brand.primary"
                  textAlign="center"
                  fontWeight="bold"
                  onClick={() =>
                    (window.location.href = `${config.appUrl}/signup`)
                  }
                >
                  SIGN UP FOR FREE
                </Box>
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
