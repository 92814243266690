import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import {
  Box,
  Flex,
  Image,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
} from '@chakra-ui/core';
import { MdClose } from 'react-icons/md';
import { FaQuestionCircle } from 'react-icons/fa';
import { BsExclamationCircle } from 'react-icons/bs';

import StarGif from 'assets/images/star.gif';
import { StyledButton } from 'styles/StyledButton';
import ChoosePlanDesktop from '../landing/PricingDesktop';

import { ReactComponent as GreenCheckMark } from 'assets/svg/green-check-icon.svg';

import config from 'config';
import { useIsMobileView } from 'hooks/useMobileView';
import generateSettings from 'utils/carouselSettings';

interface itemType {
  text: string;
  info?: string;
  subText?: string;
}
interface dataType {
  name: string;
  items: itemType[];
  price: { monthly: string; annually: string };
  cta: { text: string; plan: string };
}

const data: dataType[] = [
  {
    name: 'Gold Family Plan',
    items: [
      {
        text:
          'All the Gold Plan benefits plus you allow up to 4 more people on your account with these same banefits',
      },
    ],
    price: { monthly: '$15/month', annually: '$10/mo annually' },
    cta: { text: 'GIVE THE FAMILY GOLD', plan: 'family-gold' },
  },
  {
    name: 'FREE Plan',
    items: [
      { text: 'BookChat with the ones you love' },
      { text: 'Read books on your own' },
      {
        text: 'Limited Library access',
        info: ` Free members can only read our 5 free books (which we swap out
        weekly), or preview the first few pages of any of our other
        books`,
      },
    ],
    price: { monthly: 'FREE', annually: 'Forever' },
    cta: { text: 'TRY READEO FREE', plan: 'free' },
  },
  {
    name: 'Gold Plan',
    items: [
      { text: 'BookChat with the ones you love' },
      { text: 'Read books on your own' },
      {
        text: 'Full Library access',
        subText: '(over 1,000 books and counting!)',
      },
      {
        text: 'Create customized bookshelves',
        info: `Create as many bookshelves as you want with as many books as
      you like. Plus, you can name them anything you wish – like
      'Ryan's Bedtime Stories'.`,
      },
      {
        text: 'Bookmark feature',
        info:
          'Bedtime always comes too soon — mark your place and pick up right where you left off, whenever!',
      },
    ],
    price: { monthly: '$10/month', annually: '$8.33/mo annually' },
    cta: { text: 'GO FOR GOLD', plan: 'gold' },
  },
];

const Info = () => {
  const [showPricing, setShowPricing] = useState(false);
  const [, setCurrentFeature] = React.useState(0);
  const [isChanged, setIsChanged] = React.useState(false);

  const isMobile = useIsMobileView();

  useEffect(() => {
    if (isChanged) {
      setIsChanged(false);
    }
  }, [isChanged]);

  useEffect(() => {
    // set second gold frame as start frame
    setCurrentFeature(1);
    const time = setInterval(() => {
      setCurrentFeature((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
    }, 10000);

    return () => {
      if (time) clearInterval(time);
    };
  }, []);

  let settings = generateSettings({
    xl: 3,
    lg: 3,
    md: 2,
    sm: 1,
  });

  settings.autoplay = false;

  return (
    <Box
      background="#001C3D"
      padding="2rem 1rem"
      minHeight="700px"
      mt={{ base: 0, md: '3rem' }}
      fontFamily="Montserrat"
      color="#ffffff"
    >
      {showPricing ? (
        <Box position="relative" mt="2rem">
          <MdClose
            style={{
              position: 'absolute',
              color: 'white',
              right: '20px',
              fontSize: '2rem',
              cursor: 'pointer',
            }}
            onClick={() => setShowPricing(false)}
          />
          {isMobile ? null : <ChoosePlanDesktop fishFarm={true} />}
        </Box>
      ) : (
        <Flex
          width={{ base: '100%', md: '790px' }}
          justifyContent="center"
          flexDir="column"
          m="0 auto"
          alignItems="center"
          position="relative"
        >
          <Image
            src={StarGif}
            w={{ base: '9rem', md: '11em' }}
            h={{ base: '9rem', md: '11em' }}
            objectFit="cover"
            rounded="full"
            mt={{ base: '2rem', md: '5rem' }}
          />
          <Text
            fontSize={{ base: '24px', md: '36px' }}
            fontWeight="600"
            mb="1rem"
            textAlign="center"
          >
            Sign up for FREE. Upgrade whenever!
          </Text>
          <Text textAlign="center" fontSize="18px" lineHeight="24px">
            No matter what plan you chose, you’ll always be able to BookChat
            with the ones you love—anytime, anywhere. Upgrade to access our
            entire library of nearly 1,500 books, create bookshelves, bookmark
            your spot, and add family members.
          </Text>
          {isMobile ? (
            <Box width="100%" p={{ base: '1.5rem 0' }} className="plans">
              <Slider {...settings}>
                {data.map((plan: dataType, index: number) => (
                  <Flex
                    key={index}
                    height="500px"
                    p="1rem"
                    position="relative"
                    background="#fff"
                    borderRadius="6px"
                    color="#111111"
                    width="100%"
                    flex="0 0 auto"
                    flexDir="column"
                    mx=".5em"
                    fontWeight={500}
                  >
                    <Text fontSize="27px" fontWeight="bold" mb="1rem">
                      {plan.name}
                    </Text>
                    {plan.items.map(
                      (
                        {
                          text,
                          info,
                          subText,
                        }: { text: string; info?: string; subText?: string },
                        index: number,
                      ) => (
                        <Flex width="100%" mb=".5rem" key={index}>
                          <GreenCheckMark style={{ width: '1.2rem' }} />
                          <Text width="90%" ml=".5rem">
                            {text}
                            {subText ? (
                              <Text as="span" fontSize="10px" display="block">
                                {subText}
                              </Text>
                            ) : null}
                          </Text>
                          {info ? (
                            <Popover trigger="hover" placement="top">
                              <PopoverTrigger>
                                {plan.cta.plan === 'free' ? (
                                  <FaQuestionCircle
                                    color="#019CD5"
                                    size="1.5rem"
                                  />
                                ) : (
                                  <BsExclamationCircle
                                    color="#019CD5"
                                    size="1.5rem"
                                  />
                                )}
                              </PopoverTrigger>
                              <PopoverContent>
                                <PopoverArrow />
                                <PopoverBody textAlign="center">
                                  {info}
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          ) : null}
                        </Flex>
                      ),
                    )}
                    <Box
                      position="absolute"
                      width="90%"
                      bottom="0px"
                      margin="0 auto"
                      transform="translate(1px, 10px)"
                    >
                      <Flex
                        borderRadius="116px"
                        height="97px"
                        bg="#017daa1a"
                        flexDir="column"
                        alignContent="center"
                        justifyContent="center"
                        textAlign="center"
                        color="#083261"
                      >
                        <Text fontWeight="600" fontSize="1.3rem">
                          {plan.price.monthly}
                        </Text>
                        <Text>{plan.price.annually}</Text>
                      </Flex>
                      <StyledButton
                        backgroundColor={
                          plan.cta.plan === 'free' ? '#019CD5' : '#F38530'
                        }
                        width="100%"
                        height="50px"
                        onClick={() =>
                          (window.location.href = `${config.appUrl}/signup?plan=${plan.cta.plan}`)
                        }
                      >
                        {plan.cta.text}
                      </StyledButton>
                    </Box>
                  </Flex>
                ))}
              </Slider>
            </Box>
          ) : (
            <Flex
              width="80%"
              alignItems="center"
              mt="2rem"
              justifyContent="space-around"
              flexDir={{ base: 'column', md: 'row' }}
            >
              <StyledButton
                borderRadius="6px"
                backgroundColor="#F38530"
                color="#ffffff"
                height="50px"
                fontWeight="bold"
                fontSize="18px"
                width="215px"
                onClick={() =>
                  (window.location.href = `${config.appUrl}/signup`)
                }
              >
                SIGN UP FREE
              </StyledButton>
              <StyledButton
                borderRadius="6px"
                backgroundColor="#019CD5"
                color="#ffffff"
                height="50px"
                fontWeight="bold"
                fontSize="18px"
                width="254px"
                onClick={() => setShowPricing(true)}
              >
                VIEW PRICING PLANS
              </StyledButton>
            </Flex>
          )}
        </Flex>
      )}
    </Box>
  );
};

export default Info;
