import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Flex, Box } from '@chakra-ui/core';

export interface INav {
  id: number;
  label: string;
  route: string;
  match: boolean;
}

export default function PageNav() {
  const history = useHistory();

  const navs: INav[] = [
    {
      id: 1,
      label: 'Invite Members',
      route: '/buddies',
      match: useRouteMatch('/buddies') ? true : false,
    },
    {
      id: 2,
      label: 'Share',
      route: '/share',
      match: useRouteMatch('/share') ? true : false,
    },
    {
      id: 3,
      label: 'Follow us',
      route: '/follow-us',
      match: useRouteMatch('/follow-us') ? true : false,
    },
    {
      id: 4,
      label: 'Start Reading',
      route: '/start',
      match: useRouteMatch('/start') ? true : false,
    },
  ];
  return (
    <Flex
      width="100%"
      height="4.3125rem"
      mt={58}
      mb={50}
      align="center"
      justify="center"
      backgroundColor="brand.lightBlue"
    >
      <Flex width={420} position="relative" align="center" justify="center">
        <Box width="100%" position="absolute" borderTop="3px solid #083261" />
        <Flex width="100%" height="auto" justify="space-between">
          {navs.map(({ id, label, route, match }) => {
            return (
              <Flex
                key={id}
                width={match ? '60%' : '1.875rem'}
                height="1.875rem"
                justify="center"
                align="center"
                color={match ? 'white' : 'initial'}
                fontFamily="Montserrat"
                fontWeight="700"
                fontSize="0.75rem"
                lineHeight="1.875rem"
                letterSpacing="0.01em"
                textTransform={match ? 'uppercase' : 'none'}
                zIndex={199}
                cursor={match ? 'initial' : 'pointer'}
                border="3px solid #083261"
                borderRadius={match ? '1.875rem' : '50%'}
                backgroundColor={match ? '#083261' : '#D1F1F8'}
                onClick={() => history.push({ pathname: route })}
              >
                {match ? label : id}
              </Flex>
            );
            // );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
}
