import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import SectionLabel from '../authentication/signup/v3/sectionLabel';
import PlanSelector from '../authentication/signup/v3/planSelector';

import PaymentMethod from '../authentication/signup/v3/paymentMethod';
import PayPalButton from '../authentication/signup/v3/PayPalButton';
import ButtonControls from './buttonControls';

interface IBilling {
  state: any;
  changeHandler: (arg1: any) => void;
  chooseMem?: (e: React.ChangeEvent<HTMLInputElement>, type: string) => void;
  choosePay: (e: React.ChangeEvent<HTMLInputElement>, type: string) => void;
  loading: boolean;
  setLoading: any;
  buttonRef: any;
  handlePayPal: (token_id: string) => void;
  submitHandler: (e: React.ChangeEvent<HTMLInputElement>, type: string) => void;
}

const checkDisabled = (state: any) => {
  const { requesting, paymentType, zip } = state;
  if (
    ((paymentType === 'credit_card' && zip) || paymentType === 'paypal') &&
    !requesting
  )
    return false;
  else return true;
};

export default function BillingInfo(props: IBilling) {
  const {
    state,
    changeHandler,
    chooseMem,
    choosePay,
    loading,
    setLoading,
    buttonRef,
    handlePayPal,
    submitHandler,
  } = props;

  // check if current route matches upgrade route
  let match = useRouteMatch('/upgrade');

  return (
    <>
      {match !== null && match?.isExact ? (
        <>
          <SectionLabel text="Choose a plan" mt="0rem" />
          <PlanSelector state={state} choose={chooseMem} />
        </>
      ) : null}
      <SectionLabel text="Payment method" mt={match ? '2.5rem' : '0rem'} />
      <PaymentMethod
        state={state}
        changeHandler={changeHandler}
        choose={choosePay}
      />
      {state.paymentType === 'paypal' ? (
        <PayPalButton
          loading={loading}
          setLoading={setLoading}
          isDisabled={checkDisabled(state)}
          handlePayPal={handlePayPal}
        />
      ) : (
        <ButtonControls
          state={state}
          isDisabled={checkDisabled(state)}
          submitHandler={submitHandler}
          buttonRef={buttonRef}
        />
      )}
    </>
  );
}
