import React, { useEffect } from 'react';
import ReactGA, { FieldsObject } from 'react-ga';
import { RouteComponentProps } from 'react-router-dom';
import { Environments } from '../config/config.types';

export default function withGAPageTracker<T extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<T>,
  options: FieldsObject = {},
) {
  const environment =
    (process.env.REACT_APP_ENV as Environments) || Environments.development;

  const trackPage = (page: string) => {
    if (environment === 'production') {
      ReactGA.set({ page, ...options });
      ReactGA.pageview(page);
      try {
        //@ts-ignore
        window.fbq('track', 'PageView');
      } catch (err) {}
    }
  };

  return (props: T) => {
    useEffect(() => {
      trackPage(props.location.pathname);
    }, [props.location.pathname]);

    return <WrappedComponent {...props} />;
  };
}
