export default {
  requesting: false,
  isLoaded: false,
  accountExists: false,
  dialog: false,
  isOpen: false,
  fname: '',
  lname: '',
  plan: 'yearly',
  paymentType: '',
  address: '',
  city: '',
  sorp: 'Illinois',
  zip: '',
  country: 'United States',
};
