import { css } from '@emotion/core';

export const mainContent = css`
  width: 100%;
  height: 75vh;
  position: relative;
  background-image: url('/assets/blue_bg.png');
  background-size: cover;
  background-position: center 1.2rem;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 0px) and (max-width: 480px) {
    min-height: 50rem;
  }

  @media screen and (min-width: 480px) {
    background-position: center;
    background-image: url('/assets/blue_bg.png');
    min-height: 55rem;
  }

  @media screen and (min-width: 480px) and (max-width: 768px) {
    .img-container {
      width: 45%;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 1200px) and (orientation: landscape) {
    min-height: 45rem;
  }
`;

export const heroStyle = css`
  -webkit-box-pack: space-between;
  justify-content: space-between;
  -ms-flex-pack: center;
  align-items: center;
  padding: 4rem 4rem 0rem;
  max-width: 1350px;
  @media screen and (min-width: 0px) and (max-width: 480px) {
    flex-direction: column-reverse;
    padding: 3rem 1rem 0rem;
    -webkit-box-pack: space-around;
    justify-content: space-around;
  }

  @media screen and (min-width: 480px) and (max-width: 768px) {
    padding: 2rem;
  }
`;

export const heroContent = css`
  width: 37%;
  flex-direction: column;
  justify-content: center;
  transform: translateY(-20%);
  animation: fadeIn 1s ease-in;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation: fadeIn 1s ease-in;

  @media screen and (min-width: 0px) and (max-width: 480px) {
    width: 100%;
    transform: translateY(0);
  }

  @media screen and (min-width: 480px) and (max-width: 768px) {
    width: 50%;
    transform: translateY(0);
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0.75;
    }
    100% {
      opacity: 1;
    }
  }

  & > h1 {
    color: white;
    font-size: 1.88rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    line-height: 2.5rem;
    padding: 1rem 0;
    text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);

    @media screen and (min-width: 480px) {
      font-size: 46px;
      line-height: 3.125rem;
      font-weight: 600;
    }
    @media screen and (min-width: 480px) and (max-width: 768px) {
      font-size: 1.8rem;
      line-height: 2rem;
    }
  }

  & > section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    p {
      color: #ededed;
      font-size: 0.938rem;

      @media screen and (min-width: 480px) {
        font-size: 1.125rem;
      }
      @media screen and (min-width: 480px) and (max-width: 768px) {
        font-size: 1rem;
        line-height: 1.4rem;
      }
    }
  }

  & button {
    color: white;
    background: #f38530;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: bold;
    font-size: 0.938rem;
    transition: background 0.3s ease-in-out;
    border-radius: 6px;
    margin-bottom: 8px;

    &:hover {
      background: #cf7129;
    }

    @media screen and (min-width: 480px) {
      height: 50px;
      font-size: 0.9375rem;
    }

    @media screen and (min-width: 480px) and (max-width: 768px) {
      height: 35px !important;
      font-size: 14px !important;
      width: 80%;
      margin-top: 0rem;
    }
  }
`;
