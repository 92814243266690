import React from 'react';
import { TiTick } from 'react-icons/ti';
import { FormLabel } from '@chakra-ui/core';

import { InputGroup, StyledInput } from '../../styles/elements.styled';

const fields = [
  {
    name: 'email1',
    invalid: 'invalidEmail1',
    label: 'Email #1',
  },
  {
    name: 'email2',
    invalid: 'invalidEmail2',
    label: 'Email #2',
  },
  {
    name: 'email3',
    invalid: 'invalidEmail3',
    label: 'Email #3',
  },
  {
    name: 'email4',
    invalid: 'invalidEmail4',
    label: 'Email #4',
  },
];

export default function BuddiesInfo(props: any) {
  const { state, changeHandler } = props;

  return (
    <>
      {fields.map((field) => {
        return (
          <InputGroup key={field.name} width="100%">
            <StyledInput
              type="text"
              name={field.name}
              id={field.name}
              autoComplete="off"
              isRequired
              value={state[field.name]}
              paddingRight={!state[field.invalid] ? '3rem' : '4.5rem'}
              onChange={changeHandler}
              style={{
                border: !state[field.name].length
                  ? '1.5px solid #68B8CD'
                  : state[field.invalid]
                  ? '0.05rem solid red'
                  : '0.05rem solid #48bb78',
              }}
            />
            <FormLabel htmlFor={field.label}>{field.label}</FormLabel>
            {!state[field.invalid] ? (
              <TiTick color="#338f3d" />
            ) : (
              <i
                style={{
                  fontSize: '0.8125rem',
                  fontWeight: 300,
                  color: '#676767',
                }}
              >
                Optional
              </i>
            )}
          </InputGroup>
        );
      })}
    </>
  );
}
