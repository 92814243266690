import React from 'react';
import { Heading } from '@chakra-ui/core';

export interface ISectionLabelProps {
  color: string;
  text: string;
  fontSize?: string;
  size?: any;
  lineHeight?: string;
}

export default function SectionLabel(props: ISectionLabelProps) {
  const { color, text, size, fontSize, lineHeight } = props;

  return (
    <Heading
      as="h3"
      size={size}
      fontWeight={{ base: '600', md: '700' }}
      color={color}
      fontSize={fontSize || 'initial'}
      letterSpacing="0.01em"
      lineHeight={lineHeight}
      textAlign={['center', 'initial']}
    >
      {text}
    </Heading>
  );
}
