const logos = [
  {
    image: `${require('../../assets/images/dailycandy_logo.png')}`,
    alt: 'DailyCandy',
  },
  {
    image: `${require('../../assets/images/aarp_logo.png')}`,
    alt: 'AARP',
  },
  {
    image: `${require('../../assets/images/military_logo.png')}`,
    alt: 'Military.com',
  },
  {
    image: `${require('../../assets/images/pubweekly_logo.png')}`,
    alt: 'Publishers Weekly',
  },
  {
    image: `${require('../../assets/images/about_logo.png')}`,
    alt: 'About.com',
  },
  {
    image: `${require('../../assets/images/channel2_logo.png')}`,
    alt: 'Channel 2',
  },
  {
    image: `${require('../../assets/images/usa_today_logo.png')}`,
    alt: 'USA Today',
  },
];

export default logos;
