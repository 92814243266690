import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/core';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { setCookie, getCookie } from '../utils/cookies';
import { getJsonFromUrl } from '../utils/parseParameters';

import routes, { IRoute } from './routes';
import Footer from '../components/footer';
import withGAPageTracker from '../HOC/withGAPageTracker';
import config from 'config';

const AnimatedRoutes = withRouter(({ location, match }) => {
  useEffect(() => {
    if (!getCookie('_rfbc')) {
      const params = getJsonFromUrl(location.search);
      params.forEach(
        (param) =>
          param.key === 'fbclid' &&
          setCookie('_rfbc', param.value, { domain: 'readeo.com' }),
      );
    }
    const { pathname } = location;
    if (['/reset-password', '/signup', '/login'].includes(pathname)) {
      window.location.href = `${config.appUrl}${pathname}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TransitionGroup>
      {/* <Route 
          path="/"
           //@ts-ignore
          render={({ location }) => {
            //@ts-ignore
            window.gtag('config', 'AW-435320927', {
              page_path: location.pathname + location.search,
            });
          }}
    /> */}
      <CSSTransition
        key={location.pathname}
        classNames="page"
        in={match != null}
        timeout={300}
        unmountOnExit
      >
        <Switch location={location}>
          {routes.map(({ path, component: Component, ...props }: IRoute) => {
            const ComponentWithGAPageTracker = withGAPageTracker<any>(
              Component,
            );
            return (
              <Route {...props} key={path} path={path}>
                {(routeProps) => (
                  <Box className="page">
                    <ComponentWithGAPageTracker {...routeProps} />
                    {path !== '/fish-fam' && <Footer />}
                  </Box>
                )}
              </Route>
            );
          })}
          <Route path="*" component={() => <Redirect to="/" />} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
});

export default function Router() {
  return (
    <Box className="container">
      <AnimatedRoutes />
    </Box>
  );
}
