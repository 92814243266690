import React from 'react';
import { TiTick } from 'react-icons/ti';
import { Flex, Text, PseudoBox } from '@chakra-ui/core';

import memplans from '../../../../utils/plans';

export default function PlanSelector(props: any) {
  const { state, choose } = props;

  return (
    <Flex justifyContent={'space-between'}>
      {memplans.map((mem) => (
        <PseudoBox
          as="div"
          display="flex"
          key={mem.id}
          width="48%"
          flexDirection="column"
          p={{ xsm: '0.8rem', md: '1.1875rem' }}
          position="relative"
          backgroundColor={
            state.plan === mem.id ? 'white' : 'rgba(255,255,255,0.3)'
          }
          cursor="pointer"
          borderRadius="0.375rem"
          border={
            state.plan === mem.id
              ? '0.1rem solid #f38530'
              : '0.1rem solid transparent'
          }
          _hover={{ backgroundColor: 'white' }}
          onClick={(e) => choose(e, mem.id)}
        >
          <Text
            fontSize="1.125rem"
            fontWeight="bold"
            lineHeight="1.3125rem"
            marginBottom="0.2rem"
            color="brand.secondary"
          >
            {mem.plan}
          </Text>
          <Text fontSize="1rem" lineHeight="1.125rem" color="brand.secondary">
            {mem.price}
          </Text>
          <PseudoBox
            as="div"
            width="2.5rem"
            height="2.5rem"
            marginTop="1.5rem"
            backgroundColor={state.plan === mem.id ? '#f38530' : '#98d3e2'}
            borderRadius="50%"
            alignSelf="center"
          >
            {state.plan === mem.id ? (
              <TiTick color="white" fontSize="2.5rem" />
            ) : null}
          </PseudoBox>
          {mem.id === 'yearly' ? (
            <PseudoBox
              as="div"
              fontSize="0.625rem"
              px="0.3rem"
              position="absolute"
              top="-0.5rem"
              color="white"
              backgroundColor="brand.secondary"
              letterSpacing="0.02em"
            >
              SAVE 17%
            </PseudoBox>
          ) : null}
        </PseudoBox>
      ))}
    </Flex>
  );
}
