import React from 'react';
import { Box } from '@chakra-ui/core';
import { useRouteMatch } from 'react-router-dom';

import useSticky from 'hooks/useSticky';

import Header from 'components/header';
import FreeModelHeader from 'components/header/free-model';

export default function MainLayout(props: { children: React.ReactNode }) {
  const { isSticky, element } = useSticky();

  const isFreeModel = useRouteMatch({
    path: '/landing',
    strict: true,
  });

  const isFishfarm = useRouteMatch({
    path: '/fish-fam',
    strict: true,
  });

  return (
    <Box width="100%" height="100%">
      {!isFreeModel && !isFishfarm && <Header isSticky={isSticky} />}
      {isFreeModel && <FreeModelHeader isSticky={isSticky} />}
      <Box minHeight="calc(100% - 292px)" ref={element}>
        {props.children}
      </Box>
    </Box>
  );
}
