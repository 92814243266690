import React from 'react';
import { Text, Link, Box, Flex } from '@chakra-ui/core';

import useScrollToTop from '../../hooks/useScrollToTop';

export default function TermsOfService() {
  const { refEl } = useScrollToTop();

  return (
    <Box pt={20} ref={refEl}>
      <Flex flexDirection="column" alignItems="center" py={6}>
        <Text
          fontWeight="bold"
          py={1}
          fontSize="1.875rem"
          color="brand.secondary"
          letterSpacing="0.02em"
          lineHeight="3.25rem"
          textAlign="center"
        >
          Terms of service
        </Text>
      </Flex>
      <Flex
        fontSize="sm"
        backgroundColor="brand.lightBlue"
        justifyContent="center"
        px={3}
      >
        <Box
          width="5xl"
          py={12}
          fontSize="1rem"
          lineHeight="1.3125rem"
          letterSpacing="0.02em"
        >
          <Text fontWeight="bold" py={1}>
            READEO.COM TERMS OF SERVICE
          </Text>
          <Text>
            This Readeo.com service (the “Service) is owned and operated by
            Readeo LLC (referred to as “Readeo”, “we”, “us”, or “our” herein).
            This Agreement sets forth the terms and conditions under which ALL
            USERS OF THE READEO WEBSITE AGREE TO BE BOUND. Other pages of this
            site may contain further terms and conditions to which you also
            agree to be bound. If you do not agree to this Agreement in its
            entirety, you may not use our site or services.
            <br />
            <br />
            Any person who establishes an account with Readeo will be referred
            to as the “Account Holder” in this Agreement. “Account” means the
            account registered to any user, pursuant to the registration
            procedures for the Service. The terms of this Agreement apply to all
            users of the Service via the Account, whether or not they are the
            Account Holder. The Account Holder is responsible for making each of
            the users of their Account (and anyone else they may allow access to
            their Account) aware of the terms of this Agreement and for ensuring
            compliance. The Account Holder for an Account is entirely liable for
            all activities conducted through that Account.
            <br />
            <br />
            We reserve the right, in our sole and absolute discretion, to
            change, modify, add, or remove (collectively, “Change” or the
            “Changes”) portions of this Agreement at any time, and Changes will
            be effective immediately upon posting of the revised Agreement at
            the domain or subdomain where the prior version was posted. It is
            your responsibility to review these terms. Although notification of
            Changes to this Agreement may from time to time be posted on the
            Service or sent via e-mail or postal mail, such notification does
            not waive or otherwise excuse the your affirmative obligation to
            review the Agreement for Changes, and to understand and agree to the
            most up-to-date terms of the Service. We suggest that you review the
            terms of this Agreement each time prior to using the Service.
            <br />
            <br />
            If any future Changes to this Agreement are unacceptable to you, or
            cause you to no longer be in compliance with this Agreement, you
            must terminate your Account. Your continued use of the Service
            following Changes to this Agreement will signify that you accept
            those Changes.
            <br />
            <br />
            We may change, modify, suspend, or discontinue any aspect of the
            Service at any time, including, without limitation, the availability
            of any Service feature, database or content, hours of availability,
            or equipment needed to access the Service. We may also impose limits
            on certain features or restrict your access to parts or all of the
            Service for extended periods of time, without notice or liability.
            We reserve the right to interrupt the Service with or without prior
            notice for any reason or no reason, and you shall not be entitled to
            any refunds of fees for interruption of the Service or its otherwise
            failure to perform. We cannot guarantee that this Service or any
            data stored or transmitted in connection with it will be
            uninterrupted, error-free, and secure of viruses and other malware.
            <br />
            <br />
            We are not an Internet provider and do not provide Internet access,
            and you are responsible for all fees associated with your Internet
            connection. You are likewise solely responsible for and must provide
            all computer and other equipment necessary to access the Service,
            including without limitation Internet access software, modems, and
            web cameras. We do not guarantee that the Service will operate on
            any specific equipment, browser, or any particular operating system,
            and we are not responsible for any damage, either temporary or
            permanent, to equipment or software in connection with using the
            Service.
          </Text>
          <br />
          <Text fontWeight="bold" py={1}>
            Restrictions on Use of Materials
          </Text>
          <Text>
            All materials published by Readeo (including, but not limited to,
            digital books, informational resources, photographs, images,
            illustrations, audio clips, and video (collectively, “Content”)) are
            protected by copyright, and owned or controlled by, or otherwise
            used with permission by, Readeo, its affiliated companies, or a
            third-party provider. You shall abide by all copyright notices,
            information, or restrictions contained in any Content accessed
            through the Service.
            <br />
            <br />
            The Service is copyright protected as a collective work and/or
            compilation, pursuant to U.S. copyright laws, international
            conventions, and other copyright laws. No material from the Service
            or any Web site owned, operated, licensed, or controlled by Readeo
            may be copied, reproduced, republished, uploaded, posted, or
            transmitted, nor may derivative works be created from them or
            distributed in any way withour prior written permission from Readeo.
            Your use of this Service will constitute an affirmative
            acknowledgment that unauthorized copying of the Software or
            duplication of the Software in any manner, including software that
            has been modified, merged, or included with the Software, or the
            written materials associated therewith, is expressly forbidden.
            Using our Content for any other purpose, including but not limited
            to using any of our Content on any other Web site or networked
            computer environment, is a violation of our copyright and other
            proprietary rights and is strictly prohibited.
            <br />
            <br />
            You further acknowledge that you may not sublicense, transfer, sell,
            or assign this license, the Software, or your Member or Account ID.
            Any attempt to sublicense, transfer, auction, sell or assign the
            license is void, and such attempts, regardless of whether made by
            Account Holder, will result in immediate termination of Account.
            <br />
            <br />
            If you download software from the Service, the software, including
            any files, images incorporated in or generated by the software, and
            data accompanying the software (collectively, the “Software”), are
            licensed to you by Readeo. We hereby grant to you a limited,
            revocable, non-exclusive license to use the Software solely in
            connection with the Service via an authorized and fully-paid (or
            guest or authorized free subscription or trial) Account. You
            represent, warrant, and covenant (a) that no materials of any kind
            submitted through your Account will (i) violate, plagiarize, or
            infringe upon the rights of any third party, including copyright,
            trademark, privacy, or other personal or proprietary rights; or (ii)
            contain libelous or otherwise unlawful material; (b) the credit card
            provided to us is valid, you are authorized to use the credit card,
            and you are at least thirteen (13) years old; (c) we may charge the
            credit card provided to us, as more fully described in the Section
            titled “Price and Payment” below; and (d) you will fully comply with
            the terms of this Agreement. Readeo reserves the right, within in
            its sole and absolute discretion, to remove any materials submitted
            through your Account that violate any of the above or are otherwise
            unlawful, harmful, offensive, or inappropriate.
            <br />
            <br />
            You do not own the Account, nor do you own any data stored on our
            servers. When using the Service, you may accumulate relationships,
            personal bookshelves, personal recommendations and other items, that
            reside as data on our servers. All Account data may be deleted,
            altered, moved, or transferred at any time for any reason or no
            reason at all in Readeo’s sole and absolute discretion. In the event
            that such data is corrupted, destroyed, or otherwise lost, you
            acknowledge that Readeo shall not be subject to any liability.
            <br />
            <br />
            You hereby indemnify, defend, and hold Readeo, its affiliated
            companies, and all officers, directors, owners, agents, information
            providers, affiliates, licensors, and licensees (collectively, the
            “Indemnified Parties”) harmless from and against any and all
            liability and costs incurred by the Indemnified Parties in
            connection with any claim arising out of any breach by you or any
            user of your Account.
            <br />
            <br />
            You further acknowledge and agree that we may, in our sole and
            absolute discretion, track, record, observe or follow any and all of
            your interactions within the Service.
          </Text>
          <br />
          <Text fontWeight="bold" py={1}>
            Disclaimer
          </Text>
          <Text>
            THE MATERIALS IN THIS SERVICE ARE PROVIDED “AS IS” AND WITHOUT
            WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. TO THE FULLEST
            EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, READEO DISCLAIMS ALL
            WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
            IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
            PURPOSE. READEO DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE
            SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE
            CORRECTED, OR THAT THIS SERVICE OR THE SERVER THAT MAKES IT
            AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. READEO
            DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR
            THE RESULTS OF THE USE OF THE MATERIALS IN THIS SERVICE IN TERMS OF
            THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. YOU (AND NOT
            READEO) ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR,
            OR CORRECTION. APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF IMPLIED
            WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
            <br />
            <br />
            WITHOUT LIMITATION OF THE FOREGOING, YOU ACKNOWLEDGE THAT, AS A
            SERVICE TO USERS OF THE READEO SERVICE, WE INCLUDE LINKS TO OTHER
            WEB SITES ON THE WORLD WIDE WEB PORTION OF THE INTERNET AND THAT
            READEO HAS NO CONTROL OVER, AND MAKES NO REPRESENTATIONS OF ANY KIND
            WHATSOEVER, REGARDING THE CONTENT OR APPROPRIATENESS OF CONTENT ON
            SUCH WEB SITES, AND YOU HEREBY IRREVOCABLY WAIVE ANY CLAIM AGAINST
            US WITH RESPECT TO SUCH WEB SITES.
            <br />
            <br />
            Further, Readeo explicitly disclaims any responsibility for the
            accuracy, content, or availability of information found on sites
            that link to or from the Services from third parties not associated
            with Readeo. Readeo encourages discretion when browsing the Internet
            using our or anyone else’s service. Because some sites employ
            automated search results or otherwise link you to sites containing
            information that may be deemed inappropriate or offensive, Readeo
            cannot be held responsible for the accuracy, copyright compliance,
            legality, or decency of material contained in third-party sites, and
            you hereby irrevocably waive any claim against us with respect to
            such sites. Readeo cannot ensure that you will be satisfied with any
            products or services that you purchase from a third-party site that
            links to or from the Service since other shop channels are owned and
            operated by independent retailers. Readeo does not endorse any of
            the merchandise, nor has Readeo taken any steps to confirm the
            accuracy or reliability of any of the information contained in such
            third-party sites. Readeo does not make any representations or
            warranties as to the security of any information including, without
            limitation, credit card and other personal information you might be
            requested to give any third party and you hereby irrevocably waive
            any claim against us with respect to such sites. We strongly
            encourage you to make whatever investigation you feel necessary or
            appropriate before proceeding with any online or offline transaction
            with any of these third parties.
          </Text>
          <br />
          <Text fontWeight="bold" py={1}>
            Limitation of Liability
          </Text>
          <Text>
            UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE,
            SHALL READEO BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY, OR
            CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF, OR THE INABILITY
            TO USE, THE MATERIALS IN THIS SERVICE OR ANY OTHER WEB SITE, EVEN IF
            READEO OR A READEO AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. APPLICABLE LAW MAY NOT ALLOW THE
            LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL, SPECIAL,
            EXEMPLARY, OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR
            EXCLUSION MAY NOT APPLY TO YOU. IN NO EVENT SHALL READEO’S TOTAL
            LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION
            (WHETHER IN CONTRACT, TORT INCLUDING, BUT NOT LIMITED TO,
            NEGLIGENCE, OR OTHERWISE) EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR
            ACCESSING THE SERVICE.
          </Text>
          <br />
          <Text fontWeight="bold" py={1}>
            Security
          </Text>
          <Text>
            As part of the registration process, Account Holders will select a
            password and account ID (“Account ID”). We reserve the right to
            delete or change your Account ID for any reason or no reason. You
            shall provide Readeo with accurate, complete, and up-to-date Account
            information. Failure to do so shall constitute a breach of this
            Agreement, which may result in immediate termination of your Account
            or other measures, including legal action. You may not (i) select or
            use an Account ID of another person or entity with the intent to
            impersonate that person, including Account IDs that are deliberately
            misleading or deceptive as to the identity of the Member; (ii) use a
            name subject to the rights of any other person or entity without
            authorization; or (iii) use an Account ID that Readeo, in its sole
            and absolute discretion, deems inappropriate or offensive. Without
            limiting any of its additional rights in this Agreement or
            otherwise, Readeo reserves the right to enforce these security
            measures at any time, and Readeo’s allowance of an Account ID shall
            not be considered a waiver of our ability to terminate, suspend, or
            otherwise reevaluate your Account at a later time.
            <br />
            <br />
            You shall notify Readeo by e-mail at{' '}
            <Link href="mailto:legal@readeo.com" color="blue.400">
              legal@readeo.com
            </Link>{' '}
            of any known or suspected unauthorized use(s) of your Account, or
            any known or suspected breach of security, including loss, theft, or
            unauthorized disclosure of your password.
            <br />
            <br />
            You shall be responsible for maintaining the confidentiality of your
            password. Any fraudulent, abusive, or otherwise illegal activity may
            be grounds for termination of your Account, at Readeo’s sole and
            absolute discretion, and you may be reported to appropriate
            law-enforcement agencies.
          </Text>
          <br />
          <Text fontWeight="bold" py={1}>
            Price and Payment
          </Text>
          <Text>
            Readeo reserves the right at any time to charge additional fees for
            access to the Service. Readeo reserves the right to change the
            amount of, or basis for determining, any fees or charges for the
            Service, and to institute new fees or charges effective upon prior
            notice to Account Holders via the email address of record on the
            Account Holder’s account or posting upon the domain or subdomain of
            the Service or any other method that is designed to provide
            reasonable notice to Account Holders. Readeo reserves the right to
            provide the Service at no charge for promotional reasons or
            otherwise (such as a guest account, free subscription or trial).
            PROVISION OF PROMOTIONAL TRIALS OR OTHER PREFERRED OR DISCOUNTED
            ACCESS TO SOME MEMBERS (INCLUDING, BUT NOT LIMITED TO, FULL OR
            LIMITED SUBSCRIPTIONS OFFERED FREE OF CHARGE) SHALL NOT OBLIGATE
            READEO TO PROVIDE SUCH PROMOTIONS, DISCOUNTS, OR PREFERRED ACCESS TO
            OTHER MEMBERS.
            <br />
            <br />
            Each Account Holder agrees to pay all charges to the Account,
            including applicable taxes, in accordance with billing terms in
            effect at the time the fee or charge becomes payable. Account
            Holders must provide Readeo with valid information as requested
            during the registration process, which may include (but is not
            limited to) credit card information, promotional codes, or other
            means of payment.
            <br />
            <br />
            If you have reason to believe that your Account is no longer secure
            (for example, in the event of a loss, theft, or unauthorized
            disclosure or use of your Account ID, Password, or any credit,
            debit, PayPal, or charge card information stored on the Service),
            you must promptly change your Password and notify Readeo of the
            problem (by giving notice as described in the Notice section below)
            to avoid possible liability for any unauthorized charges to your
            Account.
            <br />
            <br />
            If Account Holder is paying by credit card (including debit and
            other charge cards):
            <br />
            <br />
            Readeo shall charge the Account Holder’s credit card on the date
            Account Holder subscribes to the Service or if you have signed up
            for a free trial period, Readeo shall charge the Account Holder’s
            credit card on the day that the free trial expires. Thereafter,
            Readeo will automatically renew and charge the Account Holder as
            follows:
            <br />
            <br />
            Upon every one (1)-month anniversary of the first billing date for
            monthly subscriptions
            <br />
            <br />
            Upon every six (6)-month anniversary of the first billing date for
            semiannual subscriptions if available
            <br />
            <br />
            Upon every one (1)-year anniversary of the first billing date for
            annual subscriptions if available
            <br />
            <br />
            The renewal charge shall be equal to or less than the original
            subscription price, unless otherwise notified in advance by Readeo
            via the email address of record on the Account Holder’s account or
            posting upon the domain or subdomain of the Service or any other
            method that is designed to provide reasonable notice to Account
            Holders. You may notify Readeo that you wish to cancel your
            subscription at any time, but such notice shall be provided in
            writing, via your customer dashboard, or via email. Readeo agrees
            that it will terminate your Account upon receipt of notification
            from the Account Holder. Account Holder will not be entitled to, but
            may yet receive, the benefit of any remaining paid period of service
            that remains on the account. Refunds will be issued on a
            case-by-case basis. If you feel a refund is due, please contact our
            office for further assistance.
            <br />
            <br />
            Your right to use the Service is subject to any limits established
            by Readeo or by your credit card issuer. If payment cannot be
            charged to your credit card or your charge is returned to Readeo for
            any reason, including chargeback, Readeo reserves the right to
            either suspend or terminate your access and Account.
          </Text>
          <br />
          <Text fontWeight="bold" py={1}>
            Parental Consent
          </Text>
          <Text>
            Each user of the Services is required to have a unique Account;
            provided, however, that children under the age of eighteen (18) are
            not permitted to establish their own Accounts. Rather, each child
            under the age of eighteen (18) must only access the Services through
            an account that is established by such child’s parent or legal
            guardian. By establishing an Account, an Account Holder does
            represent and warrant to Readeo that such Account Holder is the
            parent or legal guardian of all minors who are allowed to access the
            Services through such Account. Further, such Account Holder
            represents that such Account Holder has implemented appropriate
            safeguards to monitor such minor’s use of the Services, and by
            providing Account information to a minor child, the Account Holder
            does consent to the minor child’s access to the Services. Each
            Account Holder agrees to hold Readeo harmless from any damage, claim
            or liability suffered by any minor child who accesses the Services
            through such Account Holder’s Account that is incurred as a result
            of such minor’s use of the Services.
            <br />
            <br />
            Under the Children’s Online Privacy Protection Act (“COPPA”),
            parental consent is required for the online collection, use and/or
            disclosure of personal information obtained from a child under the
            age of thirteen (13). By establishing an account, the Account Holder
            agrees to our collection, use and disclosure of personal information
            in accordance with the Privacy Policy [www.readeo.com/privacy] with
            respect to any child under the age of thirteen (13) for whom the
            Account Holder allows to use the Account.
            <br />
            <br />
          </Text>
          <br />
          <Text fontWeight="bold" py={1}>
            Notice
          </Text>
          <Text>
            The Account Holder will submit, maintain, and keep up to date a
            correct e-mail address and other Account information. Without
            waiving or excusing Account Holder’s obligation to review the terms
            of this Agreement for changes, we may give notice to the Account
            Holder by means of a general notice on the Service, electronic mail
            to your e-mail address on record in our Account information, by
            written communication sent by first-class mail to your address on
            record in our Account information or by any other method that is
            designed to provide reasonable notice to Account Holders.
            <br />
            <br />
            All notices from you to Readeo should be in writing to{' '}
            <Link href="mailto:customerservice@readeo.com" color="blue.400">
              customerservice@readeo.com.
            </Link>
          </Text>
          <br />
          <Text fontWeight="bold" py={1}>
            Non-Verifiability of Content and Affirmative Obligation to Report
            Abuse
          </Text>
          <Text>
            You acknowledge and agree that Readeo is not liable for any third
            party content but may, but is not obligated to, review, monitor,
            edit, refuse, or modify such third party content at any time and for
            any reason.
            <br />
            <br />
            Readeo does not represent or endorse the accuracy or reliability of
            any book, advice, opinion, statement, or other information
            displayed, uploaded, or distributed through the Service by any user,
            information provider, or other third-party individual or entity. You
            acknowledge that any reliance upon any such opinion, advice,
            statement, memorandum, or information shall be at your sole risk.
            <br />
            <br />
            Readeo has limited ability, if any, to oversee the quality, safety,
            morality, legality, truthfulness or accuracy of the content of
            communications between users. You understand, therefore, that by
            using Readeo, you may be exposed to content that is offensive,
            indecent or otherwise objectionable, and that you use Readeo at your
            own risk. The content of the communication spread by the use of
            Readeo is entirely the responsibility of the person from whom such
            content originated. You have an obligation to report all
            inappropriate behavior to Readeo immediately. If you continue to
            have problematic interactions with other users, then you must stop
            using the Service immediately.
          </Text>
          <br />
          <br />{' '}
          <Text fontWeight="bold" py={1}>
            Termination of Service
          </Text>
          <Text>
            This Agreement is effective until terminated by either party. You
            may terminate this Agreement and your right to use the Service at
            any time by modifying your account settings. Upon cancellation you
            may no longer have access to the Service. Readeo may terminate your
            Account or access rights to this Service immediately without notice
            for any reason or no reason in our sole and absolute discretion.
            Upon termination, you must destroy all materials obtained from this
            Service and all copies thereof, whether made under the terms of this
            Agreement or otherwise.
          </Text>
          <br />
          <Text fontWeight="bold" py={1}>
            Other
          </Text>
          <Text>
            This Service is controlled and operated by Readeo from its offices
            within the State of Illinois, United States of America. By
            downloading or using the Software, you represent and warrant that
            you are located in the United States or a country to which the US is
            not currently embargoing goods.
            <br />
            <br />
            Those who access this Service from locations outside of the United
            States do so on their own initiative and are responsible for
            compliance with local laws, if and to the extent local laws are
            applicable. Software from this Service is further subject to United
            States export controls. No Software from this Service may be
            downloaded or otherwise exported or reexported (i) into (or to a
            national or resident of) Cuba, Iraq, North Korea, Iran, Syria, or
            any other country to which the U.S. has embargoed goods; or (ii) to
            anyone on the U.S. Treasury Department’s list of Specially
            Designated Nationals or the U.S. Commerce Department’s Table of Deny
            Orders. By downloading or using the Software, you represent and
            warrant that you are not located in, under the control of, or a
            national or resident of any such country or on any such list.
            Certain Software that Account Holders download to use or install
            from a CD-ROM is “Restricted Computer Software.” Use, duplication,
            or disclosure by the U.S. Government is subject to restrictions as
            set forth in this Agreement and as provided in DFARS 227.7202-1(a)
            and 227.7202-3(a) (1995), DFARS 252.227-7013 (October 1988), FAR
            12.212(a) (1995), FAR 52.227-19, or FAR 52.227-14, as applicable.
            <br />
            <br />
            This Agreement shall be governed by and construed in accordance with
            the laws of the State of Utah, without giving effect to any
            principles of conflicts of law. By using the Service, you hereby
            consent to the personal jurisdiction of the courts of Salt Lake
            County, Utah, and agree that all disputes will be handled within the
            jurisdiction of Salt Lake County, Utah.
            <br />
            <br />
            If any provision of this Agreement shall be unlawful, void, or for
            any reason unenforceable, then that provision shall be deemed
            severable from this Agreement and shall not affect the validity and
            enforceability of any remaining provisions.
          </Text>
          <br />
          <Text fontWeight="bold" py={1}>
            Entire Agreement
          </Text>
          <Text>
            This Agreement constitutes the entire agreement between the parties
            with respect to the subject matter contained herein and supersedes
            all previous and contemporaneous agreements, proposals, and
            communications, written or oral, between Readeo representatives and
            you. Readeo may amend or modify this Agreement or impose new
            conditions at any time upon notice from Readeo to you as described
            in the Section titled “Notice” above. Any use of the Service by you
            after such notice shall be deemed to constitute acceptance by Member
            of such amendments, modifications, or new conditions. In the event
            that a conflict exists between this Terms of Service Agreement and
            other Readeo policies such as the Privacy Policy or Copyright
            policy, this Terms of Service agreement shall be determinative.
          </Text>
          <Text>
            <br />
            <br />
            To contact us about matters relating to our Terms and Conditions,
            please send email{' '}
            <Link href="mailto:legal@readeo.com" color="blue.400">
              legal@readeo.com.
            </Link>
          </Text>
        </Box>
      </Flex>
    </Box>
  );
}
