import React, { useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Box, Flex, Text, Image, Button } from '@chakra-ui/core';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';

import { useIsMobileView } from 'hooks/useIsMobileView';

import config from 'config';
import navItems from './navItems';

import { ReactComponent as BurgerMenuOpenMobile } from 'assets/svg/burger-menu-open-mobile.svg';
import { ReactComponent as BurgerMenuOpen } from 'assets/svg/burger-menu-open.svg';
import { ReactComponent as BurgerMenuClose } from 'assets/svg/burger-menu-close.svg';

interface IFreeModelHeader extends RouteComponentProps {
  isSticky: boolean;
}

export default withRouter(function FreeModelHeader(props: IFreeModelHeader) {
  const { isSticky } = props;

  const [showMenu, setShowMenu] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(0);

  const isMobile = useIsMobileView();

  const toggleNavMenu = () => {
    setShowMenu(!showMenu);
  };

  const selectMenuItem = (idx: number) => () => {
    window.scrollTo(0, navItems[idx].scrollY);
    setActiveMenuItem(idx);
    setShowMenu(false);
  };

  return (
    <Flex
      w="100%"
      flexDirection={{ base: 'column' }}
      align="end"
      position="fixed"
      zIndex={1800}
      background={{
        base: showMenu ? '#083261' : 'white',
        md: 'none',
      }}
    >
      <Flex
        width="100%"
        height="3.7rem"
        zIndex={1800}
        alignItems={{ base: 'center', md: 'flex-end' }}
        pl={isSticky || isMobile ? '0rem' : { base: 2, md: 3 }}
        transition="all 0.1s ease"
        justifyContent="center"
        position="relative"
      >
        <Link
          to="/"
          style={{
            ...(isMobile && { margin: '0 auto' }),
            ...(!isMobile && { position: 'absolute', left: 0 }),
          }}
        >
          <Box
            w="8.125rem"
            mb="0.25rem"
            style={{ ...(!isSticky && !isMobile && { paddingLeft: '1.5rem' }) }}
          >
            {/* mobile header logo */}
            <Image
              w="full"
              src={require(showMenu
                ? '../../../assets/images/readeo-logo-free-model.svg'
                : '../../../assets/images/Readeo_Logo_Dark.svg')}
              alt="Readeo"
              display={{ md: 'none' }}
            />

            {/* desktop header logo */}
            <Image
              w="full"
              src={
                !isSticky
                  ? require('../../../assets/images/readeo-logo-free-model.svg')
                  : require('../../../assets/images/readeo-logo-free-model-alt.svg')
              }
              alt="Readeo"
              display={{
                base: 'none',
                md: 'block',
              }}
            />
          </Box>
        </Link>

        <Flex position="absolute" right={0}>
          {!isMobile && (
            <Button
              onClick={() => (window.location.href = `${config.appUrl}/signup`)}
              color="white"
              bg="#F38530"
              width="12.5rem"
              size="sm"
              height="auto"
              fontWeight="bold"
              fontSize="13pt"
              borderRadius="0rem"
            >
              SIGN UP
            </Button>
          )}
          {showMenu ? (
            <BurgerMenuClose cursor="pointer" onClick={toggleNavMenu} />
          ) : isMobile ? (
            <BurgerMenuOpenMobile cursor="pointer" onClick={toggleNavMenu} />
          ) : (
            <BurgerMenuOpen cursor="pointer" onClick={toggleNavMenu} />
          )}
        </Flex>
      </Flex>
      {showMenu && (
        <Flex
          flexDirection="column"
          width={{ base: '100%', md: '16.25rem' }}
          height={{ base: '100vh', md: 'initial' }}
          backgroundColor="#083261"
        >
          <>
            {isMobile && (
              <Button
                onClick={() =>
                  (window.location.href = `${config.appUrl}/signup`)
                }
                color="white"
                bg="#F38530"
                size="sm"
                height="3.75rem"
                fontWeight="bold"
                fontSize="13pt"
                borderRadius="0.375rem"
                m="0.75rem 1rem"
              >
                SIGN UP
              </Button>
            )}
            {navItems.map((item, idx) => (
              <Box
                key={idx}
                padding={{ base: '1rem 2.5rem', md: '1rem 1.5rem' }}
                backgroundColor={
                  idx === activeMenuItem ? 'rgba(0, 0, 0, 0.2)' : 'inherit'
                }
                cursor="pointer"
                onClick={selectMenuItem(idx)}
              >
                <Text color="white" fontSize="1.125rem" fontWeight={600}>
                  {item.name}
                </Text>
              </Box>
            ))}
            <Link to="/">
              <Flex
                color="#019CD5"
                padding={{ base: '2rem 2.5rem 1rem', md: '2rem 1.5rem 1rem' }}
                cursor="pointer"
                align="center"
              >
                <Text color="#019CD5" fontSize="1.125rem" fontWeight={600}>
                  Readeo.com
                </Text>
                <MdKeyboardArrowRight fontSize="1.125rem" />
              </Flex>
            </Link>
          </>
        </Flex>
      )}
    </Flex>
  );
});
