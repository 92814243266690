import React from 'react';
import { Box, Flex, Text, Link as UILink } from '@chakra-ui/core';
import Socials from '../footer/Socials';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <Flex
      bg="#001C3D"
      minHeight="100px"
      width="100%"
      color="#ffffff"
      p={{ base: '1rem', md: '2rem 3rem' }}
      justifyContent="space-between"
      alignItems="center"
      flexDir={{ base: 'column', md: 'row' }}
    >
      <Box mb={{ base: '1rem', md: 'initial' }} width="100%">
        <Text fontSize="19px" fontWeight="bold">
          Readeo.com
        </Text>
        <Flex
          fontSize="12px"
          // lineHeight="1.875rem"
          letterSpacing="0.04em"
          flexDirection={{ base: 'column', md: 'row' }}
          p="0px"
        >
          <Text mt={{ base: '.5rem', md: 'initial' }}>
            &copy; {year} Readeo, LLC. All rights reserved.
          </Text>
          <Flex mt={{ base: '.5rem', md: 'initial' }}>
            {[
              { url: '/copyright', name: 'Copyright' },
              { url: '/terms-of-service', name: 'Terms of service' },
              { url: '/privacy', name: 'Privacy' },
              { url: '/blog', name: 'Blog' },
            ].map((item, idx) => (
              <Flex key={item.name}>
                <Text
                  px=".5rem"
                  display={{
                    base: idx === 0 ? 'none' : 'initial',
                    md: 'initial',
                  }}
                >
                  |
                </Text>
                <UILink
                  href={`https://readeo.com${item.url}`}
                  fontWeight={{ base: 600, md: 'initial' }}
                >
                  {item.name}
                </UILink>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Box>
      <Box
        width={{ base: '100%', md: 'initial' }}
        borderTop={{ base: '1px solid #e6f2f780', md: 'none' }}
        pt={{ base: '.5rem', md: 'initial' }}
      >
        <Socials />
      </Box>
    </Flex>
  );
};

export default Footer;
