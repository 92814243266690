import React from 'react';
import { Box } from '@chakra-ui/core';
import { useIsTabletView } from 'hooks/useIsTabletView';
import Header from './Header';
import Logos from './Logos';
import ReadTogether from './SubPage';
import MobileFishFam from './mobile';

const FishFam = () => {
  const isTabletView = useIsTabletView();
  return (
    <Box fontFamily="Montserrat">
      {isTabletView ? (
        <MobileFishFam />
      ) : (
        <>
          <Header />
          <Logos />
          <ReadTogether />
        </>
      )}
    </Box>
  );
};

export default FishFam;
