import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Text, Grid, Heading } from '@chakra-ui/core';
import Slider from 'react-slick';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';

import { Star } from 'components/giftcard/Stars';
import Img from 'components/giftcard/Img';

import generateSettings from '../../utils/carouselSettings';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StyledCarouselArrowBox = styled(Box)`
  padding: 0.35rem;
  border-radius: 100%;
  background: #c4c4c4;
  :hover {
    background: #019cd5;
  }
`;

function Arrow(props: { type: 'next' | 'prev'; onClick?: () => void }) {
  let className = props.type === 'next' ? 'nextArrow' : 'prevArrow';
  className += ' arrow';
  const char =
    props.type === 'next' ? (
      <FaChevronRight color="white" size={24} />
    ) : (
      <FaChevronLeft color="white" size={24} />
    );
  return (
    <StyledCarouselArrowBox
      className={className}
      style={{ padding: '1rem' }}
      onClick={props.onClick}
    >
      {char}
    </StyledCarouselArrowBox>
  );
}

const Reviews = () => {
  const settings = {
    ...generateSettings({ xl: 3, lg: 3, md: 3, sm: 1 }),
    nextArrow: <Arrow type="next" />,
    prevArrow: <Arrow type="prev" />,
  };

  const items = [
    {
      avatar: 'laurie-d',
      name: 'Laurie D.',
      review:
        '“Anyone with grandchildren should get this. My grandchildren love reading with us on this. It is as close to being in person as you can get!”',
    },
    {
      avatar: 'alana-k',
      name: 'Alana K.',
      review:
        '“I am really impressed. My child had better quality visits over books with his grandparents than he ever has before. He is totally over FaceTime chats, but this is different! It’s an activity to do together. 5 stars!”',
    },
    {
      avatar: 'diane-h',
      name: 'Diane H.',
      review:
        '“I am loving doing this with my grandchildren. My grandson was turning the pages as I read today, and he thought that was awesome. Such an improvement on trying to hold a book so he can see it as I read.”',
    },
    {
      avatar: 'melissa-s',
      name: 'Melissa S.',
      review:
        '“Readeo has been an amazing way to connect! We live 12 hours away from family and it’s so special that the grandparents can read our kids bedtime stories. The website is so easy to use and they have a very wide variety of books to choose from. I totally recommend!”',
    },
  ];
  return (
    <Box mt="5rem">
      <Text
        fontWeight="600"
        textAlign="center"
        fontSize="44px"
        letterSpacing="0.01em"
        color="#083261"
      >
        Our customers love Readeo
      </Text>

      <Flex
        maxWidth={{ base: '100%', md: '85%' }}
        width={{ base: '100%', md: '1320px' }}
        alignItems="center"
        margin="2rem auto"
        justifyContent="space-evenly"
      >
        <Box width="100%" className="reviews">
          <Slider {...settings}>
            {items.map((item, idx) => (
              <Box
                key={idx}
                paddingX={{ base: '0.25rem', md: '0.5rem', xl: '0.75rem' }}
              >
                <Grid
                  gridTemplateRows="1fr 2fr"
                  height="400px"
                  background="#fff"
                  borderRadius="20px"
                  padding={{ base: 3, md: 5 }}
                  border="3px solid #DBDBDB"
                  backgroundColor="red"
                  mb="1rem"
                >
                  <Flex alignItems="center" paddingBottom={3}>
                    <Box
                      width="4.8rem"
                      marginRight={{ base: '0.9rem', md: '0.75rem' }}
                    >
                      <Img src={`/assets/${item.avatar}.png`} alt={item.name} />
                    </Box>
                    <Flex flexDirection="column">
                      <Flex
                        flexDirection="column"
                        paddingLeft={{ base: '0.3rem', md: 0 }}
                        alignItems="flex-start"
                      >
                        <Heading as="h4" fontSize="1.5rem" textAlign="left">
                          {item.name}
                        </Heading>
                        <Text
                          fontFamily="Montserrat, sans-serif"
                          color="gray.400"
                          fontSize="sm"
                          textAlign="left"
                        >
                          Verified Customer
                        </Text>
                      </Flex>
                      <Flex alignSelf="flex-start">
                        <Star small />
                        <Star small />
                        <Star small />
                        <Star small />
                        <Star small />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box textAlign={{ md: 'left' }} height="100%">
                    {item.review}
                  </Box>
                </Grid>
              </Box>
            ))}
          </Slider>
        </Box>
      </Flex>
    </Box>
  );
};

export default Reviews;
