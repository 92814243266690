import memories1 from '../../assets/images/rd-memories-1.png';
import memories2 from '../../assets/images/rd-memories-2.png';
import memories3 from '../../assets/images/rd-memories-3.png';

export const cardOptions = [
  {
    id: '1year',
    term: '1 year',
    price: '100',
    savings: '20',
  },
  {
    id: '6month',
    term: '6 months',
    price: '50',
    savings: '10',
  },
];

export const perks = [
  {
    id: 1,
    background: `url(${memories1})`,
    text: 'Redeem gift card and create member account',
  },
  {
    id: 2,
    background: `url(${memories2})`,
    text: 'Members invite family to join (for FREE)',
  },
  {
    id: 3,
    background: `url(${memories3})`,
    text: 'Read thousands of award-winning books together',
  },
];

export const reviewers = [
  {
    id: 1,
    avatar: 'laurie-d',
    name: 'Laurie D.',
    review:
      '“Anyone with grandchildren should get this. My grandchildren love reading with us on this. It is as close to being in person as you can get!”',
  },
  {
    id: 2,
    avatar: 'london-b',
    name: ' London B.',
    review: '“One more [book] grammy!”',
  },
  {
    id: 3,
    avatar: 'steve-l',
    name: 'Steve L.',
    review: '“Grandparents would be nuts not to purchase this.”',
  },
  {
    id: 4,
    avatar: 'diane-h',
    name: 'Diane H.',
    review:
      '“I am loving doing this with my grandchildren. My grandson was turning the pages as I read today, and he thought that was awesome. Such an improvement on trying to hold a book so he can see it as I read.”',
  },
  {
    id: 5,
    avatar: 'andi-h',
    name: 'Andi H.',
    review:
      '“My kids love it and want to read with their grandparents every night!”',
  },
  {
    id: 6,
    avatar: 'alana-k',
    name: 'Alana K.',
    review:
      '“I am really impressed. My child had better quality visits over books with his grandparents than he ever has before. He is totally over FaceTime chats, but this is different! It’s an activity to do together. 5 stars!”',
  },
  {
    id: 7,
    avatar: 'nadine-b',
    name: 'Nadine B.',
    review: '“I want to read with Gramma before I go outside and play!”',
  },
  {
    id: 8,
    avatar: 'melissa-s',
    name: 'Melissa S.',
    review:
      '“Readeo has been an amazing way to connect! We live 12 hours away from family and it’s so special that the grandparents can read our kids bedtime stories. The website is so easy to use and they have a very wide variety of books to choose from. I totally recommend!”',
  },
  {
    id: 9,
    avatar: 'mary-b',
    name: 'Mary B.',
    review:
      '“I love Readeo, we now have a standing date with grandkids to read and have fun with them. Thanks to the creator of this lovely idea!”',
  },
  {
    id: 10,
    avatar: 'lynn-f',
    name: 'Lynn F.',
    review:
      '“Just finished another [Readeo] session with my 3 year old granddaughter. She requests this multiple times a week. I would highly recommend this. She loves the books and never voluntarily asks to end the session. We read at least 7 or 8 books each time.”',
  },
  {
    id: 11,
    avatar: 'barbara-r',
    name: 'Barbara R.',
    review:
      '“I am loving this! The first words out of my two-year-old grandson’s mouth when his dad FaceTimes me in the morning are,“Read book grandma!” I recommend without reservation.',
  },
  {
    id: 12,
    avatar: 'sara-e',
    name: 'Sara E.',
    review:
      '“This has been such a great tool for my dad and kiddos to spend quality time together, states apart!”',
  },
];

export const faqData = [
  {
    title: 'How does your gift subscription work?',
    text:
      'The gifter can choose when the digital gift card is emailed to the recipient. Recipients can redeem their gift card on our site and begin their membership at any time.',
  },
  {
    title:
      'Where can the gift card be redeemed? Can it be redeemed in the app store?',
    text:
      'All gift cards must be redeemed on our website. After choosing a paid plan, users can simply select the gift card option as their payment method.',
  },
  {
    title:
      'What if I would like to continue using Readeo when my gift card balance runs out?',
    text:
      'Simply add another payment method to your profile to ensure you don’t miss out on any BookChats!',
  },
  {
    title: 'Are gift subscriptions refundable?',
    text:
      "Yes, gift cards can be refunded to the original payment method. We're so confident you'll love Readeo we offer a money-back guarantee at any time!",
  },
  {
    title: 'How many people can I connect with during a BookChat?',
    text:
      'Up to four accounts can participate in a BookChat at any one time—but during storytime, feel free to fit as many people in front of the camera as you’d like!',
  },
  {
    title: 'What’s included in a Gold Plan?',
    text:
      'There are two types of Gold Plans: The base Gold Plan for one user and the Gold Family plan for five users. Gift cards can be used to pay for both plans! Once on a Gold Plan, users can access our entire library (over 1,000 books and counting!). Paid users can also create and share custom bookshelves, and use our bookmark feature. Gold Family Plan members can invite up to four other people to join their plan and receive all the same Gold Member benefits as the primary account holder. Everyone in the Family Plan can read solo or together during a BookChat. Plus, each Readeo member can invite guests outside of the Family Plan to BookChat with them for free.',
  },
  {
    title: 'How much does Readeo cost?',
    text:
      'Readeo Gold Plans start at $8.33/mo. when paid annually, and Gold Family Plans start at $9.99/mo. when paid annually. Each Gold Plan has a money-back guarantee.',
  },
  {
    title:
      'How often do you add books? Do I pay more when new books are added?',
    text:
      'We add new books every week! All new books are included with your subscription.',
  },
  {
    title: 'Can I read books from the library without being in a BookChat?',
    text:
      'Yes! Many children enjoy reading our books on their own, using our book reader, or even reading them together with another person when they’re in the same room. With Readeo, you and your littles will have access to our virtual library worth over $15,000.',
  },
  {
    title: 'Is there a time or book limit on BookChats?',
    text:
      'No. BookChat as long as you’d like, and read as many books as you want during each session. It’s unlimited!',
  },
  {
    title: 'What’s the age range for Readeo’s library?',
    text:
      'Our books are suitable for children between 0–11 years of age, but people of all ages (especially the young at heart) can enjoy our books.',
  },
];
