export enum Environments {
  qa = 'qa',
  development = 'development',
  production = 'production',
}

interface IEnvironmentVariables {
  baseUrl: string;
  appUrl: string;
  recaptchaKey: string;
  googleAnalytics: string;
  fbPixelId: string;
  recurlyKey: {
    public: string;
  };
}

export type Config = {
  [E in Environments]: IEnvironmentVariables;
};
