import React from 'react';
import { Box } from '@chakra-ui/core';

type propTypes = {
  bl?: boolean;
  tabIndex: number;
  onClick: Function;
  text: string;
  selected: boolean;
};
export default function Tab(props: propTypes) {
  return (
    <Box
      borderBottom="2px solid #083261"
      borderTop="2px solid #083261"
      borderRight="2px solid #083261"
      w={{ md: '8rem', lg: '11.3rem' }}
      h="3.1rem"
      textAlign="center"
      paddingY="0.9rem"
      fontSize="1rem"
      fontWeight="bold"
      as="a"
      //@ts-ignore
      style={{
        borderLeft: props.bl ? '2px solid #083261' : 0,
        backgroundColor: props.selected ? '#F38530' : 'white',
        color: props.selected ? 'white' : '',
      }}
      onClick={() => props.onClick(props.tabIndex)}
      cursor="pointer"
    >
      {props.text}
    </Box>
  );
}
