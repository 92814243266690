import React from 'react';
import styled from '@emotion/styled';
import Slider from 'react-slick';
import { Box, Flex, Grid, Heading, Text } from '@chakra-ui/core';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Img from 'components/giftcard/Img';
import { Star } from 'components/giftcard/Stars';
import { reviewers } from 'components/giftcard/data';

import generateSettings from '../../utils/carouselSettings';
import HorizontalLine from './HorizontalLine';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';

const StyledCarouselArrowBox = styled(Box)<{ type?: string }>`
  padding: 0.2rem;
  border-radius: 100%;
  background: #c4c4c4;
  display: flex;
  margin: auto;
  width: 60px;
  height: 60px;
  margin: ${(props) => (props.type === 'next' ? '0 0 0 100px' : '0 10px 0 0')};
  justify-content: center;
  align-items: center;

  :hover {
    background: #019cd5;
  }
`;

function Arrow(props: { type: 'next' | 'prev'; onClick?: () => void }) {
  let className = props.type === 'next' ? 'nextArrow' : 'prevArrow';
  className += ' arrow';
  const char =
    props.type === 'next' ? (
      <FaChevronRight color="white" size={24} />
    ) : (
      <FaChevronLeft color="white" size={24} />
    );
  return (
    <StyledCarouselArrowBox
      className={className}
      onClick={props.onClick}
      type={props.type}
    >
      {char}
    </StyledCarouselArrowBox>
  );
}
export default function Reviews() {
  const settings = {
    ...generateSettings({ xl: 3, lg: 2, md: 2, sm: 1 }),
    nextArrow: <Arrow type="next" />,
    prevArrow: <Arrow type="prev" />,
  };
  return (
    <Box>
      <Text
        fontWeight={600}
        fontSize={{ base: '24px', md: '30px' }}
        color="#083261"
        textAlign="center"
        margin="2rem 0 1rem"
      >
        What people are saying
      </Text>
      <Flex
        width={{ base: '100%', md: '75%', lg: '80%', xl: '60%' }}
        alignItems="center"
        margin="2rem auto"
      >
        <Box width="100%" className="reviews">
          <Slider {...settings}>
            {reviewers.map((item, idx) => (
              <Box
                key={idx}
                height={{ base: '24rem', md: '30rem', lg: '30rem' }}
                paddingX={{ base: '0.25rem', md: '0.5rem', xl: '0.75rem' }}
                width="22rem"
                mb="15.1px"
              >
                <Grid
                  gridTemplateRows="1fr 2fr"
                  height="100%"
                  maxHeight="max-content"
                  background="#fff"
                  borderRadius="20px"
                  padding={{ base: 3, md: 5 }}
                  border="3px solid #DBDBDB"
                  backgroundColor="red"
                >
                  <Flex alignItems="center" paddingBottom={3}>
                    <Box
                      width="4.8rem"
                      marginRight={{ base: '0.9rem', md: '0.75rem' }}
                    >
                      <Img src={`/assets/${item.avatar}.png`} alt={item.name} />
                    </Box>
                    <Flex flexDirection="column">
                      <Flex
                        flexDirection="column"
                        paddingLeft={{ base: '0.3rem', md: 0 }}
                        alignItems="flex-start"
                      >
                        <Heading as="h4" fontSize="1.5rem" textAlign="left">
                          {item.name}
                        </Heading>
                        <Text
                          fontFamily="Montserrat, sans-serif"
                          color="gray.400"
                          fontSize="sm"
                          textAlign="left"
                        >
                          Verified Customer
                        </Text>
                      </Flex>
                      <Flex alignSelf="flex-start">
                        <Star small />
                        <Star small />
                        <Star small />
                        <Star small />
                        <Star small />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Text
                    textAlign={{ md: 'left' }}
                    height="100%"
                    lineHeight="24px"
                    fontSize="18px"
                  >
                    {item.review}
                  </Text>
                </Grid>
              </Box>
            ))}
          </Slider>
        </Box>
      </Flex>
      <HorizontalLine />
    </Box>
  );
}
