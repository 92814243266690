import React from 'react';
import { Text } from '@chakra-ui/core';

export default function SectionLabel({
  text,
  mt,
}: {
  text: string;
  mt?: string;
}) {
  return (
    <Text
      fontFamily="Montserrat, sans-serif"
      fontSize="1.125rem"
      lineHeight="1.3125rem"
      color="brand.secondary"
      textAlign="center"
      marginTop={mt}
      marginBottom="1rem"
    >
      {text}
    </Text>
  );
}
