import React from 'react';
import withHelmet from '../HOC/withHelmet';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';

import useConfig from '../hooks/useConfig';

import Update from '../components/account/UpdateBilling';

/*  converting this to a function component,
 *  wrapping with RecurlyProvider and Elements component,
 *  allows to use recurly instance within the wrapped component
 */

function UpdateBilling() {
  const { recurlyKey } = useConfig();

  return (
    <RecurlyProvider publicKey={`${recurlyKey.public}`}>
      <Elements>
        <Update />
      </Elements>
    </RecurlyProvider>
  );
}

export default withHelmet(UpdateBilling, 'Update your billing information');
