import React from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/core';

// components
import ChoosePlanDesktop from './PricingDesktop';

import { useIsMobileView } from 'hooks/useMobileView';
import PricingPlansMobile from './PricingMobile';

import config from 'config';

export default function PricingPlans() {
  const isMobile = useIsMobileView();

  return (
    <Box
      background={
        'linear-gradient(0deg, #D6F6FF, #D6F6FF), linear-gradient(0deg, rgba(173, 238, 255, 0.5), rgba(173, 238, 255, 0.5)), #FFFFFF'
      }
      paddingTop="1.5rem"
      alignContent="center"
    >
      <Flex
        justifyContent={'center'}
        flexDir="column"
        pt={{ base: 3, md: 9 }}
        pb={{ base: 6, md: '6px', lg: 0 }}
        fontFamily="Montserrat"
        width={{ base: '100%', md: '70%' }}
        alignItems={{ bae: 'initial', md: 'center' }}
        m="0 auto"
        px={{ base: '1rem', md: 'initial' }}
        textAlign={{ base: 'left', md: 'center' }}
      >
        {isMobile ? (
          <>
            <Text
              fontWeight={600}
              fontSize={{ base: '24px', md: '30px' }}
              color="#083261"
              textAlign={'center'}
              lineHeight={'27px'}
              pb="8px"
            >
              Sign up FREE now, <br /> upgrade later
            </Text>
            <Text
              fontWeight="normal"
              fontSize={{ base: '16px', md: '30px' }}
              textAlign={'center'}
              fontStyle="normal"
              lineHeight={'22px'}
              color="#2E2E2E"
              letterSpacing={'0.01em'}
            >
              Try us out for free and upgrade anytime with a 14-day free trial.
              We also ooffer a money-back guarantee at any point, no questions
              asked.
            </Text>
          </>
        ) : (
          <Text
            fontWeight={600}
            fontSize={{ base: '24px', md: '30px' }}
            color="#083261"
            textAlign={'center'}
          >
            Sign up for FREE. Upgrade whenever.
          </Text>
        )}

        <Button
          onClick={() => (window.location.href = `${config.appUrl}/signup`)}
          color="white"
          bg="#F38530"
          borderRadius="6px"
          width={{ base: '100%', md: '215px' }}
          size="sm"
          height="50px"
          fontWeight="bold"
          fontSize="13pt"
          marginTop="1rem"
          _hover={{
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #f38530',
          }}
        >
          SIGN UP FOR FREE
        </Button>
      </Flex>

      {isMobile ? <PricingPlansMobile /> : <ChoosePlanDesktop />}
    </Box>
  );
}
