import React from 'react';
import { Flex, Stack, Text, Image, Accordion, Box } from '@chakra-ui/core';
import { CustomAccordionItem } from 'components/shared';
import HelpIcon from 'assets/svg/help.svg';
import { StyledButton } from 'styles/StyledButton';

const faqs: { [key: string]: string }[] = [
  {
    question: 'What ages of children is Readeo best for?',
    answer: 'Our current focus is primarily on the 0-11 year age rang',
  },
  {
    question: 'How many users can I invite?',
    answer: `As a Free Member you can invite as many people as you’d like! If you are a Readeo Gold Family Member, you can invite up to four other members who would have full access to all Readeo has to offer (Our award-winning library, custom bookshelves and boomark capabilties).`,
  },
  {
    question: `Is any sort of credit card info required if I'm signing up for a Free Membership?`,
    answer: `No! Signing up for a Free Membership does not require a credit card.`,
  },
  {
    question: 'What is the Readeo money-back guarantee?',
    answer: `If you aren’t completely satisfied with your Readeo subscription, we off a 100% money-back guarantee—no questions asked. That’s how confident we are that you will love it!`,
  },
  {
    question: 'What are the benefits of upgrading to a Gold Membership?',
    answer:
      'Gold Members have full access to our entire library of more than 1,000 books and counting, as well as our main features like bookmarks, custom bookshelves, and more!',
  },
  {
    question:
      'Is there any sort of time limit or book restraint for reading sessions?',
    answer:
      'There are no time limits for any of our users, no matter their membership level. However, Gold Members have full access to our library while Free Members are limited to reading one of our five free books (swapped out weekly).',
  },
];
export default function Help() {
  return (
    <Flex
      width="100%"
      height="100%"
      fontSize="sm"
      backgroundColor="#AFE5F8"
      justifyContent="center"
      py={{ base: '1rem 1rem 0', md: '2rem' }}
    >
      <Box
        px={3}
        py={4}
        width="5xl"
        fontSize="1rem"
        lineHeight="1.3125rem"
        letterSpacing="0.01em"
        maxWidth="960px"
        justifyContent="center"
      >
        <Flex
          justifyContent="center"
          flexDir={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'center', md: 'initial' }}
        >
          <Image
            src={HelpIcon}
            width={{ base: '3.5rem', md: '5rem' }}
            height={{ base: '3rem', md: '3.5rem' }}
          />
          <Text
            textAlign="center"
            alignSelf="center"
            fontSize="24px"
            color="#083261"
            ml="1.5rem"
            mt={{ base: '1rem', md: 'initial' }}
            fontWeight={600}
          >
            Still have questions?{' '}
          </Text>
        </Flex>
        <Accordion allowMultiple py={{ base: 4, md: 6 }}>
          <Stack
            justify="center"
            alignItems="center"
            w="100%"
            fontSize="1rem"
          ></Stack>
          {faqs.map((faq, idx) => (
            <CustomAccordionItem
              spacing={1}
              title={faq.question}
              expanded={idx === 0}
              key={idx}
            >
              {faq.answer}
            </CustomAccordionItem>
          ))}
        </Accordion>
        <Flex justifyContent="center">
          <StyledButton
            padding="1.5rem"
            width={{ base: '100%', md: 'inherit' }}
            fontWeight={700}
          >
            GET MORE ANSWERS
          </StyledButton>
        </Flex>
      </Box>
    </Flex>
  );
}
