export default function hideAnyExistingToast() {
  /* This function assumes that existing toasts are only displayed on top.
   * To take care of other positions, you may use querySelectorAll && Array.from()
   * to get all spans inside the toast div, then map through the elements.
   */
  const toast = document.querySelector('span.Toaster__manager-top');

  // check if toast exists and has at least 1 childNode
  if (toast !== null && toast.childNodes.length > 0)
    // if it does, replace its innerHTML with a string to hide it from view
    // @ts-ignore
    toast.childNodes[0].innerHTML = '';
}
