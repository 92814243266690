import { useEffect, useState } from 'react';

export function useIsTabletView() {
  const [isTabletView, setIsTabletView] = useState(
    window.matchMedia('(max-width: 768px)')?.matches,
  );
  const checkTabletScreen = () =>
    setIsTabletView(window.matchMedia('(max-width: 768px)')?.matches);

  useEffect(() => {
    window.addEventListener('resize', checkTabletScreen);
    return () => window.removeEventListener('resize', checkTabletScreen);
  }, []);

  return isTabletView;
}
