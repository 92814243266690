import React from 'react';
import { Box, PseudoBox } from '@chakra-ui/core';

const ButtonText = ({ title, ...styles }: any) => {
  return (
    <PseudoBox
      display="flex"
      fontSize="md"
      fontWeight="bold"
      textAlign="center"
      letterSpacing="0.01em"
      fontFamily="Montserrat"
      textTransform="uppercase"
      _focus={{ boxShadow: 'none' }}
      _hover={{ color: 'gray.400', outline: 'none' }}
      alignItems="center"
      {...styles}
    >
      <Box>{title}</Box>
    </PseudoBox>
  );
};

export default ButtonText;
