import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/core';

import useScrollToTop from '../../../hooks/useScrollToTop';

import PageNav from '../pageNav';
import SectionCreator from '../SectionCreator';
import GiftCardBilling from './GiftCardBilling';

export default function GiftCardCheckout() {
  const { refEl } = useScrollToTop();

  const location = useLocation();

  // @ts-ignore
  if (!(location && location.state && location.state.email)) {
    return <Redirect to="/giftcard" />;
  }

  return (
    <Box ref={refEl} minHeight="calc(100vh - 18.25rem)">
      <PageNav />
      <SectionCreator
        id="checkout"
        hText="Checkout"
        hColor="brand.secondary"
        paddingT={[85, 60]}
        background="#FFFFFF"
        hFont="30px"
        ChildComponent={GiftCardBilling}
      />
    </Box>
  );
}
