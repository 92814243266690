import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  AspectRatioBox,
  Box,
  ModalBody,
} from '@chakra-ui/core';

export interface IDisclosure {
  isOpen: boolean;
  onOpen(): void;
  onClose(): void;
  onToggle(): void;
}

export function VideoModal(props: { disclosure: IDisclosure }) {
  const { isOpen, onClose } = props.disclosure;

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      size={{ base: 'sm', md: '60rem' }}
      isCentered
      closeOnEsc
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton zIndex={1600} left={3} background="#FFF" />
        <ModalBody p="0">
          <AspectRatioBox ratio={16 / 9}>
            <Box
              as="iframe"
              w="100%"
              title="This is how the Readeo story begins"
              // @ts-ignore
              allow="autoplay"
              src="https://www.youtube.com/embed/NoyYHgS0rPY?autoplay=true"
              allowFullScreen
            />
          </AspectRatioBox>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
