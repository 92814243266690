import { Box } from '@chakra-ui/core';
import useScrollToTop from 'hooks/useScrollToTop';
import React from 'react';
import HeroSection from './Hero';
import GettingStarted from './GettingStarted';
import ProgramUsers from './ProgramUsers';
const SchoolPage = () => {
  const { refEl } = useScrollToTop();

  return (
    <Box ref={refEl} width="100%" boxSizing="border-box">
      <HeroSection />
      <ProgramUsers />
      <GettingStarted />
    </Box>
  );
};

export default SchoolPage;
