import { FunctionComponent } from 'react';

import About from '../pages/About';
import Buddies from '../pages/Buddies';
import Confirmation from '../pages/Confirmation';
import Copyright from '../pages/Copyright';
import Faq from '../pages/Faq';
import Home from '../pages/Home';
import Library from '../pages/Library';
import Patent from '../pages/Patent';
// import Pricing from '../pages/Pricing';
import Privacy from '../pages/Privacy';
import Share from '../pages/Share';
import SingleBook from '../pages/SingleBook';
import TermsOfService from '../pages/TermsOfService';
import UpdateBilling from '../pages/UpdateBilling';
import UpgradeAccount from '../pages/UpgradeAccount';
import Follow from '../pages/FollowUs';
import Start from '../pages/Start';
import GiftCard from '../pages/GiftCard';
import GiftCardDetails from '../pages/GiftCardDetails';
import GiftCardCheckout from '../pages/GiftCardCheckout';
import GiftCardConfirmation from '../pages/GiftCardConfirmation';
import SubscribeSocialAuth from 'pages/SubscribeSocialAuth';
import Landing from '../pages/Landing';
import Attribution from 'pages/Attribution';
import FishFarm from 'pages/FishFam';
import School from 'pages/School';
import ReferAFriend from 'pages/ReferAFriend';

export interface IRoute {
  name: string;
  path: string;
  component: FunctionComponent;
  exact?: boolean;
}

const routes: IRoute[] = [
  {
    name: 'Landing',
    path: '/landing',
    component: Landing,
    exact: true,
  },
  {
    name: 'FishFarm Page',
    path: '/fish-fam',
    component: FishFarm,
    exact: true,
  },
  {
    name: 'Home',
    path: '/',
    component: Home,
    exact: true,
  },
  {
    name: 'About',
    path: '/about',
    component: About,
  },

  {
    name: 'Buddies',
    path: '/buddies',
    component: Buddies,
  },
  {
    name: 'Social Auth',
    path: '/auth/(google|facebook|apple)',
    component: SubscribeSocialAuth,
  },
  // {
  //   name: 'Pricing',
  //   path: '/pricing',
  //   component: Pricing,
  // },
  {
    name: 'Library',
    path: '/library',
    exact: true,
    component: Library,
  },
  {
    name: 'Single Book Page',
    path: '/library/book/:id',
    component: SingleBook,
  },
  {
    name: 'Faq',
    path: '/faq',
    component: Faq,
  },
  {
    name: 'Terms of service',
    path: '/terms-of-service',
    component: TermsOfService,
  },
  {
    name: 'Refer a Friend Terms & Conditions',
    path: '/refer-a-friend',
    component: ReferAFriend,
  },
  {
    name: 'Privacy',
    path: '/privacy',
    component: Privacy,
  },
  {
    name: 'Copyright',
    path: '/copyright',
    component: Copyright,
  },
  {
    name: 'Patent',
    path: '/patent',
    component: Patent,
  },
  {
    name: 'Org User Confirmation',
    path: '/org/confirmation',
    component: Confirmation,
  },
  {
    name: 'Guest Confirmation',
    path: '/guest/confirmation',
    component: Confirmation,
  },
  {
    name: 'Member Confirmation',
    path: '/confirmation',
    component: Confirmation,
  },
  {
    name: 'Attribution',
    path: '/confirm',
    component: Attribution,
  },
  {
    name: 'Upgrade Account',
    path: '/upgrade',
    component: UpgradeAccount,
  },
  {
    name: 'Update Billing',
    path: '/update',
    component: UpdateBilling,
  },
  {
    name: 'Share',
    path: '/share',
    component: Share,
  },
  {
    name: 'Follow us',
    path: '/follow-us',
    component: Follow,
  },
  {
    name: 'Start',
    path: '/start',
    component: Start,
  },
  {
    name: 'Gift Card',
    path: '/giftcard',
    exact: true,
    component: GiftCard,
  },
  {
    name: 'Gift Card Details',
    path: '/giftcard/details',
    component: GiftCardDetails,
  },
  {
    name: 'Gift Card Checkout',
    path: '/giftcard/checkout',
    component: GiftCardCheckout,
  },
  {
    name: 'Gift Card Confirmation',
    path: '/giftcard/confirmation',
    component: GiftCardConfirmation,
  },
  {
    name: 'School',
    path: '/schools',
    component: School,
  },
];

export default routes;
