import React from 'react';
import {
  Box,
  IconButton,
  AccordionItem,
  AccordionPanel,
  AccordionHeader,
  Text,
} from '@chakra-ui/core';
import { useLocation } from 'react-router-dom';
import { FaPlus, FaMinus } from 'react-icons/fa';

type ICustomAccordionItem = {
  title: string;
  content?: string;
  children?: React.ReactNode;
  spacing?: number | string | { [key: string]: number | string };
  expanded?: boolean;
  width?: string;
  mx?: string;
  fishFarm?: boolean;
};

type Item = {
  title: string;
  text?: string;
};

export interface AccordionDataType {
  headingText: string;
  items: Item[];
}

export function CustomAccordionItem({
  title,
  content,
  children,
  spacing,
  expanded,
  width,
  mx,
  fishFarm,
}: ICustomAccordionItem) {
  let location = useLocation();
  let expand = location.hash === '#tech' && title.includes('browser');

  return (
    <AccordionItem
      minWidth={width || '100%'}
      width={{ base: '100%', md: width || '100%' }}
      background="white"
      my={spacing}
      mx={mx}
      defaultIsOpen={!!expand || expanded}
      borderRadius={fishFarm ? '4px' : '0px'}
    >
      {({ isExpanded }) => (
        <Box
          minHeight="100%"
          border={fishFarm && isExpanded ? '1px solid #D8E0EB' : 'none'}
        >
          <AccordionHeader
            width="100%"
            height={{ base: '80px', md: 'initial' }}
            boxShadow={'none !important'}
            style={{
              backgroundColor: isExpanded || fishFarm ? '#00377a14' : 'initial',
            }}
          >
            <IconButton
              as="div"
              aria-label="Accordion"
              fontSize="1.2rem"
              padding="0.6rem"
              color="#fff"
              borderRadius="full"
              icon={isExpanded ? FaMinus : FaPlus}
              boxShadow={'none !important'}
              style={{
                backgroundColor: isExpanded ? '#083261' : '#F38530',
              }}
            />
            <Text
              flex="1"
              textAlign="left"
              paddingLeft="1rem"
              fontSize="1rem"
              lineHeight="1.3rem"
              color="#333333"
              fontWeight="bold"
            >
              {title}
            </Text>
          </AccordionHeader>
          <Box px={{ sm: '3rem' }}>
            {/* can use children and dangerouslySetInnerHTML 
          if content use dangerouslySetInnerHTML else use children */}
            {!content ? (
              <AccordionPanel pb={4} my={{ base: 'initial', md: '1rem' }}>
                {children}
              </AccordionPanel>
            ) : (
              <AccordionPanel
                pb={4}
                my={{ base: 'initial', md: '1rem' }}
                dangerouslySetInnerHTML={{ __html: content }}
              >
                {}
              </AccordionPanel>
            )}
          </Box>
        </Box>
      )}
    </AccordionItem>
  );
}
