import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function withHelmet(Component: any, title?: string) {
  return () => {
    const location = useLocation();
    return (
      <>
        <Helmet>
          <title>
            {location.pathname === '/' ? title : `Readeo - ${title}`}
          </title>
        </Helmet>
        <Component />
      </>
    );
  };
}
