import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Flex,
  Text,
  Button,
} from '@chakra-ui/core';

// TODO: Determine when the intercept is supposed to popup
const ModalIntercept = () => {
  const { isOpen, onClose } = useDisclosure();
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      size={{ md: '60rem' }}
      isCentered
      closeOnEsc
    >
      <ModalOverlay bg="white" opacity={{ md: 0.88 }} zIndex={9999} />
      <ModalContent
        width="100%"
        zIndex={9999}
        height={{ base: 'calc(100vh - 18.25rem)', md: 'initial' }}
      >
        <ModalBody
          p="0 !important"
          display="flex"
          boxSizing="border-box"
          flexDir={{
            base: 'column',
            md: 'row',
          }}
        >
          <Flex width={{ base: '100%', md: '70%' }}>
            <img src={'/assets/intercept.png'} alt="group of students" />
          </Flex>
          <Flex
            bg="#fff"
            flexDir="column"
            color="#083261"
            p={{ base: '1rem', md: '3rem 1rem' }}
            width={{ base: '100%', md: '30%' }}
          >
            <Text
              textTransform="uppercase"
              fontSize="14px"
              fontWeight={600}
              mb="0.5rem"
            >
              Introducing Readeo's
            </Text>
            <Text fontSize="2rem" fontWeight={700} lineHeight="30px">
              Guest Reader Program
            </Text>
            <Text my="1rem">
              Read to your child’s class — anytime, anywhere. Tell us a bit
              about their teacher and we’ll handle the rest.
            </Text>
            <Flex
              borderRadius="6px"
              fontWeight={700}
              flexDir="column"
              my={{ base: '2rem', md: 'initial' }}
            >
              <Button
                mb={'1rem'}
                border="1.04475px solid #019CD5"
                bg="white"
                textTransform="uppercase"
              >
                Maybe Later
              </Button>
              <Button bg="#f38530" color="#fff" textTransform="uppercase">
                Learn More
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalIntercept;
