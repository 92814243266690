import React from 'react';
import {
  Text,
  List,
  ListItem,
  Stack,
  Link,
  Box,
  Flex,
  Accordion,
} from '@chakra-ui/core';

import useScrollToTop from '../../hooks/useScrollToTop';
import { CustomAccordionItem } from '../shared';

export default function Privacy() {
  const { refEl } = useScrollToTop();

  return (
    <Flex
      pt={20}
      ref={refEl}
      width="100%"
      alignItems="center"
      flexDirection="column"
      minHeight={{ sm: 'calc(100vh - 18.25rem)' }}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        py={6}
        width={{ sm: '60%' }}
        textAlign="center"
      >
        <Text
          fontWeight="bold"
          py={1}
          fontSize="1.875rem"
          color="brand.secondary"
          letterSpacing="0.02em"
          lineHeight={{ sm: '3.25rem' }}
        >
          Readeo privacy statement
        </Text>
        <Text fontSize="1rem" px={3}>
          Readeo is committed to respecting your privacy and the confidentiality
          of your personal data, traffic data and communications content.
        </Text>
      </Flex>
      <Flex
        width="100%"
        height="100%"
        fontSize="sm"
        backgroundColor="brand.lightBlue"
        justifyContent="center"
      >
        <Box
          px={3}
          py={4}
          width="5xl"
          fontSize="1rem"
          lineHeight="1.3125rem"
          letterSpacing="0.01em"
        >
          <Text textAlign="center" py={1} fontSize="0.75rem">
            Readeo LLC, organized under the laws of the state of Utah, having
            its registered office in Chicago, IL, is collecting and processing
            your personal information under this Privacy Statement. This Privacy
            Statement is designed to assist you in understanding how Readeo uses
            and safeguards the information you provide in using Readeo’s
            services.
          </Text>

          <Accordion allowMultiple py={6}>
            <Stack
              justify="center"
              alignItems="center"
              mb="1.88rem"
              w="100%"
              fontSize="1rem"
            >
              <CustomAccordionItem
                spacing={1}
                title="What information does Readeo collect?"
              >
                Readeo may gather and process information about you, including
                (but not limited to) information in the following categories:
                <br />
                <br />
                <List
                  as="ol"
                  styleType="lower-alpha"
                  listStylePosition="outside"
                  spacing={3}
                  pl={{ base: 6, sm: 12 }}
                >
                  <ListItem>
                    Identification data (e.g. name, address, telephone, email
                    address);
                  </ListItem>
                  <ListItem>
                    Profile information (any information that you choose to make
                    a part of your Readeo user profile such as a list of
                    contacts, bookshelves, etc.);
                  </ListItem>
                  <ListItem>
                    Electronic identification data (e.g. IP addresses, cookies);
                  </ListItem>
                  <ListItem>
                    Banking and payment information (credit card information,
                    account number);
                  </ListItem>
                  <ListItem>
                    Survey results and information about your usage of Readeo;
                  </ListItem>
                  <ListItem>
                    Products or services ordered through Readeo or its
                    affiliates;
                  </ListItem>
                  <ListItem>
                    Correspondence between you and Readeo; and
                  </ListItem>
                  <ListItem>
                    Traffic data (data processed for the purpose of the
                    conveyance of the Service)
                  </ListItem>
                </List>
                <br />
                <Text>
                  Readeo does not knowingly collect personal information from
                  children under the age of thirteen (13). If you are under 13
                  years of age, you may only visit this site with your parents
                  or guardians and you may not send any information about
                  yourself to Readeo. If you are a parent or guardian that has
                  become aware that your child has provided Readeo with personal
                  information, then please contact Readeo via e-mail at{' '}
                  <Link href="mailto:legal@readeo.com," color="blue.400">
                    legal@readeo.com,
                  </Link>{' '}
                  and Readeo will purge such information from its files. If
                  Readeo itself becomes aware that a child under 13 has provided
                  us with personal information, we will purge such information
                  from its files.
                </Text>
                <br />
                <Text fontStyle="italic">
                  Child Online Privacy Protection Act (COPPA) Compliance
                </Text>
                Parents may provide personal information for children under the
                age of thirteen (13). Readeo collects only the information
                necessary to participate in the service. This Privacy Policy
                governs the type of information collected, its use, and its
                disclosure. The parent can agree to the collection of
                information without agreeing to the disclosure of that
                information to third parties. The parent can view the
                information and request that it be deleted by contacting{' '}
                <Link href="mailto:legal@readeo.com," color="blue.400">
                  legal@readeo.com,
                </Link>{' '}
                and providing the following information: (1) Email Subject
                Title: COPPA Deletion Request; (2) specific identification of
                the information; and (3) a statement requesting that which is to
                be deleted.
                <br />
                <br />
              </CustomAccordionItem>

              <CustomAccordionItem
                spacing={1}
                title="How does Readeo use this information and for what purpose?"
              >
                Our primary purpose in collecting information is to provide you
                with a safe, smooth, efficient, and customized experience.
                Readeo collects and processes, or has third party service
                providers acting on Readeo’s behalf collecting and processing,
                personal data relating to you, as permitted or necessary to:
                <br />
                <br />
                <List
                  as="ol"
                  styleType="disc"
                  spacing={3}
                  pl={{ base: 6, sm: 12 }}
                  listStylePosition="outside"
                >
                  <ListItem>
                    provide you with the Readeo service, in particular to convey
                    the communications you make by means of the Readeo Service;
                  </ListItem>
                  <ListItem>
                    take, process or deliver orders made via our websites,
                    invoice you or notify you of the status of your order;
                  </ListItem>
                  <ListItem>
                    provide you with customer support and troubleshoot problems;
                  </ListItem>
                  <ListItem>
                    inform you about service updates and faults;
                  </ListItem>
                  <ListItem>
                    request feedback or participation in on-line surveys;
                  </ListItem>
                  <ListItem>resolve disputes;</ListItem>
                  <ListItem>verify your identity;</ListItem>
                  <ListItem>
                    customize, measure, and improve Readeo’s software, our
                    products and website’s content and layout;
                  </ListItem>
                  <ListItem>
                    organize and carry out Readeo’s marketing or promotional
                    operations/offers, contests, games and similar events; and
                  </ListItem>
                  <ListItem>
                    protect your and Readeo’s interests, including in particular
                    to enforce our Terms of Service and fight against fraud.
                  </ListItem>
                </List>
                <br />
                <br />
              </CustomAccordionItem>

              <CustomAccordionItem
                spacing={1}
                title="To whom does Readeo transfer your personal information?"
              >
                Except as provided below, Readeo shall not sell, rent, trade or
                otherwise transfer any personal and/or traffic data or
                communications content to any third party without your explicit
                permission, unless it is obliged to do so under applicable laws
                or by order of the competent authorities.
                <br />
                <br />
                Readeo may disclose personal information to respond to legal
                requirements, to protect Readeo’s interests, to enforce our
                policies or to protect anyone’s rights, property, or safety.
                <br />
                <br />
                In order to provide you with Readeo’s Service, Readeo may
                sometimes, if necessary, share your personal and traffic data
                with partner content providers, service providers, distributors
                of Readeo’s Service, and/or the third party banking
                organizations or other providers of payment, email delivery,
                analytical services, customer support, or hosting services.
                Readeo will always require these third parties to take
                appropriate organizational and technical measures to protect
                your personal data and traffic data and to observe the relevant
                legislation.
                <br />
                <br />
                Your information may be stored and processed in any country in
                which Readeo or its hosting partners maintain facilities,
                including outside of the US. In this regard, or for purposes of
                sharing or disclosing data in accordance with this article 3,
                Readeo reserves the right to transfer information outside of
                your country. By using Readeo’s Service you consent to any such
                transfer of information outside of your country. As Readeo
                continues to develop its business, Readeo might sell or buy
                subsidiaries or business units. In such transactions as well as
                in the event Readeo or substantially all of its assets are
                acquired by a third party, personal information of Readeo users
                will generally be one of the transferred business assets. We
                reserve the right to include your personal information,
                collected as an asset, in any such transfer to a third party.
                <br />
                <br />
              </CustomAccordionItem>

              <CustomAccordionItem
                spacing={1}
                title="What are cookies and gifs? How does Readeo use them?"
              >
                Cookies are pieces of information that the Readeo software may
                store on your computer’s hard drive in order to help web
                applications to recognize you or your preferences. Readeo’s
                cookies may contain identifying information. Cookies enable
                Readeo to gain information about the use of its websites. This
                information may be analyzed by third parties on our behalf.
                <br />
                <br />
                Please bear in mind that some personalized services may not be
                available if you choose to disable cookies.
                <br />
                <br />
                Clear Gifs
                <br />
                <br />
                Readeo web pages may include clear gifs. Clear gifs (also known
                as web-beacons) are tiny graphics with a unique identifier,
                which are used to count your visits to the page (but which do
                not retain any personally identifiable information). In
                addition, Readeo uses clear gifs in our HTML-based emails to let
                us know which emails have been opened and acted upon. This
                allows us to gauge the effectiveness of certain communications
                and the effectiveness of our marketing campaigns.
                <br />
                Third Party Cookies
                <br />
                Any advertisements appearing on our websites are delivered to
                you by our web advertising partners. Please be aware that these
                third parties may use cookies to obtain information about your
                visits to this site, such as number of times you have viewed an
                advertisement (but not your name, address, or other personal
                information). This information is used to serve advertisements
                to you.
                <br />
                <br />
              </CustomAccordionItem>

              <CustomAccordionItem spacing={1} title="Your user profile">
                Your user profile is the information you decide to make
                available on the Readeo website, some of which may be available
                to other Readeo users. Any personal information may be managed
                by editing your account settings. You may choose certain details
                that all Readeo users may see and other details which will only
                be visible to your Readeo contacts. It may include your Readeo
                user name, full name, address, gender, date of birth, custom
                bookshelves, and any other information that you decide to make
                available. You control your user profile and you can access your
                user profile from the “Manage Account” menu in the Readeo
                application in order to view, correct, complete or remove
                information.
                <br />
                <br />
              </CustomAccordionItem>

              <CustomAccordionItem
                spacing={1}
                title="Linked websites and third party applications"
              >
                Readeo websites may contain links that will let you leave
                Readeo’s website and access another website. Linked websites are
                not under the control of Readeo and it is possible that these
                websites have a different privacy policy. This Privacy Statement
                applies solely to personal information that is acquired on the
                websites or through your use of the Readeo Service and/or your
                relationship with Readeo, so Readeo urges you to be careful when
                you enter any personal information online. Readeo accepts no
                responsibility or liability for these other websites. Readeo
                does not necessarily sponsor, endorse, or otherwise affiliate
                with third party websites and/or applications.
                <br />
                <br />
                You may be required to use any extras, plug-ins or third party
                applications together with the Readeo Service (“Applications”),
                the provider of such Applications may obtain access to certain
                personal information about you. Readeo does not and cannot
                control how the provider of Applications may use any personal
                information connected in connection with such Applications.
                Please ensure that you review any privacy policies or other
                terms applicable to the use of such Application before
                installation.
                <br />
                <br />
              </CustomAccordionItem>

              <CustomAccordionItem
                spacing={1}
                title="How does Readeo protect your personal information?"
              >
                Readeo shall take appropriate organizational and technical
                measures to protect the personal data and traffic data provided
                to it or collected by it with due observance of the applicable
                obligations and exceptions under the relevant legislation. Your
                personal and traffic data can only be accessed by authorized
                employees or consultants of Readeo or the entities that need to
                have access to this data in order to be able to fulfill their
                given duties.
                <br />
                <br />
              </CustomAccordionItem>

              <CustomAccordionItem spacing={1} title="Personal data of friends">
                You may be interested in inviting your friends to join Readeo.
                In the event you wish to use Readeo’s referral or ‘gift a
                subscription’ service, you will be directed to a webpage and
                will have the possibility of adding one or more email addresses
                in an online form. The information entered in this form, will
                only be used for the purposes of automatically generating an
                email message to these potential Readeo users. By entering your
                friend’s email address you are confirming that your friend has
                consented to the supply of their details. Readeo may identify
                you as the person who has made the referral in the email sent to
                your friend.
                <br />
                <br />
              </CustomAccordionItem>

              <CustomAccordionItem spacing={1} title="Contacting you">
                Readeo may from time to time ask you to provide information on
                your experiences which will be used to measure and improve
                quality. You are at no time under any obligation to provide any
                of such data. Any and all information which is voluntarily
                submitted in feedback forms on the website or any survey that
                you accept to take part in is used for the purposes of reviewing
                this feedback and improving the Readeo software and websites.
                <br />
                <br />
                Readeo may contact you to send you messages about faults and
                service issues. Further, Readeo reserves the right to use email
                or the Readeo software to notify you of any eventual claims
                related to your use of Readeo software, the Readeo websites
                and/or Readeo products, including without limitation claims of
                violation of third party rights.
                <br />
                <br />
                Readeo may send you alerts by email to notify you when someone
                has tried to contact you. Readeo may also use email or the
                Readeo software to keep you up to date with news about the
                Readeo software and Readeo products that you have purchased
                and/or to inform you about other Readeo entities’ products or
                other Readeo related information. You can opt-out of the receipt
                of alerts and/or promotional emails by clicking the
                ‘unsubscribe’ link on the email that you receive. You can also
                manage your communication preferences by signing into your
                account page on{' '}
                <Link href="www.readeo.com" color="blue.400">
                  www.readeo.com.
                </Link>
                <br />
                <br />
                If you have registered for or use co-branded Readeo products,
                the emails you receive may be from our partner company, acting
                on Readeo’s behalf.
                <br />
                <br />
              </CustomAccordionItem>

              <CustomAccordionItem
                spacing={1}
                title="Can this privacy statement be modified?"
              >
                Readeo reserves the right to modify the provisions of this
                Privacy Statement if deemed necessary. We will inform you of
                these changes by publishing the revised Privacy Statement at
                readeo.com/privacy. The revised Privacy Statement shall become
                effective immediately upon publishing it. You agree that the
                express acceptance by you, or your continued use of Readeo shall
                constitute your acceptance of the Privacy Statement.
                <br />
                <br />
              </CustomAccordionItem>

              <CustomAccordionItem
                spacing={1}
                title="What rights do you have and how can you contact Readeo?"
              >
                You have the right to access and, where relevant, to amend your
                personal information. Some of your information may be viewed or
                edited online by logging into your My Account page on
                www.Readeo.com. We will respond to requests to view, correct,
                complete or remove your personal information within a reasonable
                period of time and upon verification of your identity. Please
                address any questions to our customer support team at
                customerservice@readeo.com.
                <br />
                <br />
                Readeo will use its best efforts to accommodate requests to
                delete personal information unless it is required to be retained
                by law or for lawful business purposes.
                <br />
                <br />
              </CustomAccordionItem>

              <CustomAccordionItem
                spacing={1}
                title="How long is your personal data kept by Readeo?"
              >
                Readeo will retain your information for as long as is necessary
                to: (1) fulfill any of the Purposes (as defined in article 3 of
                this Privacy Statement) or (2) comply with applicable
                legislation, regulatory requests and relevant orders from
                competent courts.
                <br />
                <br />
                <Text>
                  To contact us about matters relating to our Privacy Policy,
                  please send email{' '}
                  <Link href="mailto:legal@readeo.com," color="blue.400">
                    legal@readeo.com.
                  </Link>
                </Text>
                <br />
                <br />
              </CustomAccordionItem>
            </Stack>
          </Accordion>
        </Box>
      </Flex>
    </Flex>
  );
}
