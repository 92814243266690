import React from 'react';
import { ThemeProvider as Provider } from '@chakra-ui/core';
import customTheme from './theme';

export default function ThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return <Provider theme={customTheme}>{children}</Provider>;
}
