import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Box, Flex } from '@chakra-ui/core';

import NavWrapper from './nav-wrapper';
import ButtonText from './button-text';

import './nav-mobile.styles.css';

export interface INavigationProps {
  isOpen: boolean;
  isSticky: boolean;
  activePath: string;
  handleClick: () => void;
}

const navItems = [
  { title: 'home', path: '/', exact: true },
  {
    title: 'schools',
    path: '/schools',
    exact: true,
    borderRadius: '60px',
  },
  { title: 'library', path: '/library' },
  // { title: 'pricing', path: '/pricing' },
  { title: 'about', path: '/about' },
  { title: 'gifts', path: '/giftcard' },
  {
    title: 'log in',
    path: `/login`,
    // border: '3px solid #083261', // unnecessary border
    borderRadius: '4px',
  },
  {
    title: 'join',
    path: `/signup`,
    color: 'white',
    background: '#F38530',
    border: '3px solid #F38530',
    borderRadius: '4px',
  },
];

export default function Navigation({
  isOpen,
  isSticky,
  activePath,
  handleClick,
}: INavigationProps) {
  const exclusion = ['/library', '/pricing', '/about', '/schools'];

  return (
    <>
      {/* <--- mobile */}
      <CSSTransition in={isOpen} timeout={200} classNames="mobile-nav">
        <Box className="mobile-nav">
          <Flex
            as="nav"
            w="full"
            alignItems="center"
            color="brand.secondary"
            flexDirection={{ base: 'column' }}
            overflow="auto"
            pb={8}
          >
            {navItems.map(({ path, exact, title, ...styles }, id) => (
              <NavWrapper
                key={id}
                title={title}
                to={path}
                exact={exact}
                onClick={handleClick}
                {...styles}
              >
                <ButtonText
                  title={title}
                  margin="1rem"
                  paddingTop="0.625rem"
                  background={styles.background}
                  color={styles.color}
                  border={styles.border}
                  width="18.75rem"
                  height="3.125rem"
                  alignItems="center"
                  justifyContent="center"
                  {...styles}
                />
              </NavWrapper>
            ))}
          </Flex>
        </Box>
      </CSSTransition>
      {/* ---> */}

      {/* <--- desktop */}
      <Box
        display={{ base: 'none', md: 'flex' }}
        width="48rem"
        // border="1px solid green"
      >
        <Flex
          as="nav"
          w="full"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {navItems.map(({ path, exact, title, ...styles }, id) => (
            <NavWrapper key={id} title={title} to={path} exact={exact}>
              <ButtonText
                title={title}
                padding={styles.background ? '0.4rem 1.2rem' : '.4rem .8rem'}
                {...styles}
                border={isSticky ? 'none' : styles.border}
                color={
                  styles.color
                    ? styles.color
                    : activePath === path
                    ? 'gray.400'
                    : isSticky || exclusion.includes(activePath)
                    ? 'brand.secondary'
                    : 'white'
                }
              />
            </NavWrapper>
          ))}
        </Flex>
      </Box>
      {/* ---> */}
    </>
  );
}
