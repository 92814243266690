import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  Text,
  Button,
  ModalCloseButton,
} from '@chakra-ui/core';
import { IDisclosure } from '../../shared';

const ModalComponent = ({ disclosure }: { disclosure: IDisclosure }) => {
  const { isOpen, onClose } = disclosure;
  const [copied, setCopied] = useState(false);
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      size={{ md: '660px' }}
      isCentered
      closeOnEsc
    >
      <ModalOverlay bg="#00152dcc" zIndex={9999} />
      <ModalContent
        width="100%"
        zIndex={9999}
        borderRadius="10px"
        p={{ base: '2rem 1rem', md: '2rem' }}
      >
        <ModalCloseButton size="lg" />

        <ModalBody
          display="flex"
          boxSizing="border-box"
          flexDir="column"
          justifyContent="center"
          fontSize="18px"
          p="0 !important"
        >
          <Flex
            flexDir="column"
            my="2rem"
            justifyContent="center"
            alignSelf="center"
          >
            <Text
              textAlign={{ base: 'left', md: 'center' }}
              color="#08315F"
              fontSize="30px"
              fontWeight={500}
              mb="1rem"
            >
              We'll be in touch shortly!
            </Text>
            <Text textAlign={{ base: 'left', md: 'center' }}>
              Would you like to try reaching out to this teacher directly? Copy
              and send them this link so they can learn more.
            </Text>
            <Button
              bg="#F38530"
              borderRadius="6px"
              width="100%"
              color="white"
              height="50px"
              fontWeight={700}
              fontSize="16px"
              mt="3rem"
              _hover={{
                background: '#F38530',
                color: 'white',
                border: 'none',
              }}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}/teacher/`,
                );
                setCopied(true);

                setTimeout(() => {
                  setCopied(false);
                }, 5000);
              }}
            >
              {copied ? 'COPIED' : 'COPY LINK'}
            </Button>
            <Button
              bg="transparent"
              borderRadius="6px"
              width="100%"
              height="50px"
              fontWeight={700}
              fontSize="16px"
              mt="1rem"
              border="2px solid #019CD5"
              onClick={disclosure.onClose}
            >
              CLOSE
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalComponent;
