import React from 'react';
import { Flex, Text, PseudoBox, FormLabel } from '@chakra-ui/core';

import axios from '../../utils/axios';
import useToastMod from '../../hooks/useToastMod';

import SectionLabel from './SectionLabel';

import giftcardS from '../../assets/images/readeo_gift_card.png';

import { InputGroup, StyledInput } from '../../styles/elements.styled';
import { useIsMobileView } from 'hooks/useMobileView';

import config from 'config';

export default function RedeemSection() {
  const [code, setCode] = React.useState('');
  const [valid, setValid] = React.useState(false);
  const [requesting, setRequesting] = React.useState(false);
  const isMobile = useIsMobileView();

  // toast some bread
  const toast = useToastMod();

  // const signUpIfValid = () => {
  //   // reset the input field state
  //   setCode('');
  //   window.open(`${config.appUrl}/signup?gift-card=true`, '_blank');
  // }

  // check giftcard validity
  const checkGiftCard = (value: string) => {
    setRequesting(true);
    axios
      .get(`giftcards/${value}`)
      .then(({ data }) => {
        setRequesting(false);
        if (data.data[0].redeemed)
          throw new Error('Gift card code has already been redeemed ');
        setValid(true);
        toast({
          title: 'Gift card code is valid 🎉',
          description: '',
          status: 'success',
          duration: 10000,
          position: 'top',
          isClosable: true,
        });
        // return (window.location.href = `${config.appUrl}/signup`);
      })
      .catch((err) => {
        setRequesting(false);
        return toast({
          title: 'An error occured',
          description:
            err && err.response && err.response.data
              ? err.response.data.message
              : err.message,
          status: 'error',
          duration: 5000,
          position: 'top',
          isClosable: true,
        });
      });
  };

  return (
    <Flex
      id="redeem"
      width="100%"
      height={['max-content', '35.625rem']}
      justifyContent="center"
      background="radial-gradient(25.44% 76.52% at 68.44% 44.53%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #A8DEEC"
    >
      <Flex
        width={{ base: '100vw', md: '100vw', lg: 1200 }}
        px={{ base: '1.5rem', md: '0.94rem', lg: '0.94rem' }}
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems="center"
        textAlign="center"
        paddingTop={10}
        paddingBottom={50}
      >
        <Flex
          flexDirection="column"
          width={{ base: '100%', md: '40%' }}
          mb={{ base: '1.5rem', md: '' }}
          mr={{ lg: '1.5rem' }}
          textAlign="left"
        >
          <SectionLabel
            color="#083261"
            size="lg"
            text="Need to redeem your membership gift?"
            fontSize="30px"
            lineHeight="37px"
          />
          <Text mt="1rem" mb="2rem" color="#083261">
            Redeem your membership by entering your gift code below. You can
            find the code in the Readeo gift email you received in your inbox.
          </Text>
          <InputGroup width="100%">
            <StyledInput
              type="text"
              name="email"
              id="email"
              autoComplete="email"
              isRequired
              value={code}
              onChange={(e: any) => setCode(e.target.value)}
              style={{
                border: !code.length
                  ? ''
                  : !valid
                  ? '0.05rem solid red'
                  : '0.05rem solid #48bb78',
                height: isMobile ? '50px' : '60px',
                borderRadius: '6px',
              }}
            />
            <FormLabel htmlFor="email">Enter code</FormLabel>
          </InputGroup>
          <PseudoBox
            as="button"
            height={['50px', '4.0625rem']}
            width="100%"
            fontFamily="Montserrat, sans-serif"
            fontWeight="bold"
            borderRadius="0.25rem"
            color="white"
            alignSelf="center"
            backgroundColor="rgba(243, 133, 48, 1)"
            fontSize="21px"
            _hover={{
              backgroundColor: 'rgba(243, 133, 48, 0.7)',
            }}
            onClick={() =>
              !requesting && valid
                ? window.open(
                    `${config.appUrl}/signup?gift-card=true`,
                    '_blank',
                  )
                : checkGiftCard(code)
            }
          >
            {!requesting && valid ? 'PROCEED TO SIGN UP' : 'REDEEM NOW'}
          </PseudoBox>
        </Flex>
        <Flex
          width={['100%', '60%']}
          height={['10rem', '30rem']}
          background="inherit"
          backgroundImage={`url(${giftcardS})`}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize={{ base: '15rem', md: '25rem', lg: '40rem' }}
        />
      </Flex>
    </Flex>
  );
}
