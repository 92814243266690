import React from 'react';
import { Box, Text } from '@chakra-ui/core';

export default function SectionLabel({ label }: { label: string }) {
  return (
    <Box m="2rem 0" borderTop="1px solid #A7DCE8" position="relative">
      <Text
        width={
          label.length > 9
            ? { base: '70%', sm: '60%', md: '45%' }
            : { base: '45%', sm: '35%', md: '30%' }
        }
        backgroundColor="white"
        textAlign="center"
        position="absolute"
        top="-1rem"
        right="0"
        left="0"
        mx="auto"
        fontFamily="Montserrat"
        fontWeight="bold"
        fontSize="1rem"
        lineHeight="1.875rem"
        color="#083261"
      >
        {label}
      </Text>
    </Box>
  );
}
