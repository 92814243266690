import React from 'react';
import zxcvbn from 'zxcvbn';
import { Text, Icon, Stack, Box } from '@chakra-ui/core';

import { rpass2, rpass2Alt } from './utils';

type IProps = {
  password: string;
  display?: string;
};

function SvgBox(props: any) {
  return <Box as="svg" {...props} />;
}

export default function PasswordConditions({ password, display }: IProps) {
  const score = zxcvbn(password).score;

  const createLabel = (score: number) => {
    switch (score) {
      case 0:
        return 'Weak';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Moderate';
      case 4:
        return 'Strong';
      default:
        return 'Weak';
    }
  };

  return (
    <>
      <Stack isInline>
        {!password ? (
          <SvgBox
            as="svg"
            role="img"
            // xmlns="http://www.w3.org/2000/svg"
            width="26px"
            height="26px"
            viewBox="0 0 26 26"
            fill="none"
            size="1.5rem"
          >
            <circle
              cx="13.1172"
              cy="12.6484"
              r="12"
              fill="white"
              stroke="#019CD5"
            />
            <path
              d="M15.3987 12.6602L18.0147 14.0762L16.8387 16.0442L14.1987 14.4842L14.2467 17.3882H11.9907L12.0147 14.4602L9.37466 16.0442L8.22266 14.0762L10.8387 12.6602L8.22266 11.2202L9.37466 9.2522L12.0147 10.8362L11.9907 7.9082H14.2467L14.1987 10.8362L16.8387 9.2522L18.0147 11.2202L15.3987 12.6602Z"
              fill="#083261"
            />
          </SvgBox>
        ) : (
          <Icon
            name={password.length >= 8 ? 'check-circle' : 'warning'}
            size="1.5rem"
            color={password.length >= 8 ? 'green.500' : 'red.500'}
          />
        )}
        <Text>8 or more characters</Text>
      </Stack>
      <Stack isInline marginTop="0.5rem">
        {!password ? (
          <SvgBox
            as="svg"
            role="img"
            // xmlns="http://www.w3.org/2000/svg"
            width="26px"
            height="26px"
            viewBox="0 0 26 26"
            fill="none"
            size="1.5rem"
          >
            <circle
              cx="13.1172"
              cy="12.6484"
              r="12"
              fill="white"
              stroke="#019CD5"
            />
            <path
              d="M15.3987 12.6602L18.0147 14.0762L16.8387 16.0442L14.1987 14.4842L14.2467 17.3882H11.9907L12.0147 14.4602L9.37466 16.0442L8.22266 14.0762L10.8387 12.6602L8.22266 11.2202L9.37466 9.2522L12.0147 10.8362L11.9907 7.9082H14.2467L14.1987 10.8362L16.8387 9.2522L18.0147 11.2202L15.3987 12.6602Z"
              fill="#083261"
            />
          </SvgBox>
        ) : (
          <Icon
            name={
              rpass2.test(password) || rpass2Alt.test(password)
                ? 'check-circle'
                : 'warning'
            }
            size="1.5rem"
            color={
              rpass2.test(password) || rpass2Alt.test(password)
                ? 'green.500'
                : 'red.500'
            }
          />
        )}
        <Text>At least one number or special character</Text>
      </Stack>
      <Stack marginTop="0.5rem" hidden={password.length === 0}>
        <Text>
          <strong>Strength: </strong>
          <span
          // style={{
          //   color: score <= 1 ? 'red' : score <= 3 ? 'orange' : 'green',
          // }}
          >
            {createLabel(score)}
          </span>
        </Text>
        <Text fontSize={12}>
          Avoid passwords that are easy to guess or used with other websites
        </Text>
      </Stack>
    </>
  );
}
