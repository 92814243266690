import React from 'react';
import ReactGA from 'react-ga';
import { CSSReset } from '@chakra-ui/core';

import ThemeProvider from './components/theme';
import Router from './routes';
import MainLayout from './layouts/MainLayout';
import config from './config';
import { Environments } from './config/config.types';

const environment =
  (process.env.REACT_APP_ENV as Environments) || Environments.development;

if (environment === 'production') {
  ReactGA.initialize(config.googleAnalytics);
}

function App() {
  return (
    <ThemeProvider>
      <CSSReset />
      <MainLayout>
        <Router />
      </MainLayout>
    </ThemeProvider>
  );
}

export default App;
