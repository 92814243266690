import React from 'react';
import { Flex, Accordion } from '@chakra-ui/core';
import { faqData } from './data';

import { CustomAccordionItem } from '../shared';

export default function FAQ() {
  return (
    <Flex width="100%" mt="1.5rem" textAlign="left">
      <Accordion allowMultiple w="100%">
        {faqData.map((item, _idx: number) => (
          <CustomAccordionItem
            title={item.title}
            key={_idx}
            content={item.text}
            spacing={2}
          />
        ))}
      </Accordion>
    </Flex>
  );
}
