import React from 'react';
import Slider from 'react-slick';
import { Box, Flex, Text } from '@chakra-ui/core';

import { perks } from './data';
import generateSettings from '../../utils/carouselSettings';

export default function Perks() {
  const settings = generateSettings({ xl: 3, lg: 3, md: 2, sm: 1 });

  return (
    <Flex
      width="100%"
      alignItems="center"
      marginY={{ base: 3, md: 5 }}
      mt={['0', '3rem']}
    >
      <Box width="100%" className="reviews">
        <Slider {...settings}>
          {perks.map((perk) => (
            <Flex
              key={perk.id}
              width={{ base: '100%', md: '33%', lg: '32%' }}
              direction="column"
              flex="0 0 auto"
            >
              <Flex
                width="100%"
                height="18rem"
                mb={{ base: '0rem', lg: '1rem' }}
                p="1rem"
                direction="column"
                background="inherit"
                backgroundImage={perk.background}
                backgroundRepeat="no-repeat"
                backgroundPosition="center"
                backgroundSize="100%"
              />
              <Flex>
                <Flex
                  mr={{ base: '0.5rem', md: '0.5rem' }}
                  ml="0.5rem"
                  width={{ base: 'auto', md: '18%', lg: '12%' }}
                >
                  <Flex
                    width="2.5rem"
                    height="2.5rem"
                    color="white"
                    justify="center"
                    align="center"
                    fontSize="lg"
                    borderRadius="50%"
                    backgroundColor="brand.secondary"
                    fontWeight="600"
                  >
                    {perk.id}
                  </Flex>
                </Flex>
                <Flex width="65%">
                  <Text
                    fontWeight="600"
                    fontSize="16px"
                    textAlign="left"
                    fontFamily="Montserrat, sans-serif"
                    marginTop=".5rem"
                  >
                    {perk.text}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          ))}
        </Slider>
      </Box>
    </Flex>
  );
}
