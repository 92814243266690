import { useState, useEffect } from 'react';

type Status = 'idle' | 'loading' | 'ready' | 'error';

interface IUseScript {
  src: string;
  cleanup?(): void;
}

export default function useScript({ src, cleanup }: IUseScript) {
  const [status, setStatus] = useState<Status>(src ? 'loading' : 'idle');

  useEffect(() => {
    if (!src) {
      setStatus('idle');
      return;
    }

    // Fetch existing script element by src
    let script = document.querySelector<HTMLScriptElement>(
      `script[src="${src}"]`,
    );

    if (!script) {
      // Create script
      script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.setAttribute('data-status', 'loading');

      // Add script to document body
      document.body.appendChild(script);

      // Store status in attribute on script
      const setAttributeFromEvent = (event: Event) => {
        script!.setAttribute(
          'data-status',
          event.type === 'load' ? 'ready' : 'error',
        );
      };

      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);
    } else {
      // Grab existing script status from attribute and set to state.
      const data_status = script.getAttribute('data-status') as Status;
      setStatus(data_status);
    }

    // event handler to update status in state
    const setStateFromEvent = (event: Event) => {
      setStatus(event.type === 'load' ? 'ready' : 'error');
    };

    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);

    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent);
        script.removeEventListener('error', setStateFromEvent);
        document.body.removeChild(script);
      }
      cleanup && cleanup();
    };
  }, [src, cleanup]);

  return status;
}
