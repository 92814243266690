import React from 'react';
import { Box, Flex, Text, Image, Heading } from '@chakra-ui/core';
import HeroBgImage from 'assets/images/fish_mobile_bg2.jpg';
import HeroImage from 'assets/images/fish_mobile_hero.png';
import MobileTag from 'assets/images/fish_mobile_tag.png';
import { StyledButton } from 'styles/StyledButton';
import config from 'config';
export default function Hero() {
  return (
    <Flex
      background={`url(${HeroBgImage}) no-repeat`}
      backgroundSize="cover"
      padding="0.5rem 1rem"
      width="100%"
      flexDir="column"
      align="center"
      height="max-content"
      mb="20px"
    >
      <Image
        w="full"
        src={require('assets/images/Readeo_Logo_Dark.svg')}
        alt="Readeo"
        height="36px"
        width="130px"
        onClick={() => {}}
        cursor="pointer"
      />
      <Box py="5px" width="90%">
        <Image src={MobileTag} width="100" />
      </Box>

      <Heading
        as="h2"
        color="#021E6A"
        fontSize="2.2rem"
        lineHeight="40px"
        fontWeight={600}
        textAlign="center"
      >
        We wrote a book with The FishFam!
      </Heading>
      <Text
        color="#2E2E2E"
        fontSize="16px"
        lineHeight="22px"
        textAlign="center"
        py="5px"
      >
        Halston’s twin sisters look exactly alike. But who looks like Halston?
        Follow along as she travels to faraway places, meeting new and
        interesting friends, in search of what she didn’t know she already had.
      </Text>
      <StyledButton
        borderRadius="6px"
        width="100%"
        height="50px"
        onClick={() => (window.location.href = `${config.appUrl}/signup`)}
      >
        TRY READEO FREE
      </StyledButton>
      <Image
        src={HeroImage}
        style={{ visibility: 'hidden' }}
        objectFit="cover"
      />
    </Flex>
  );
}
