import React from 'react';
import { Box, Text } from '@chakra-ui/core';

import { Heading } from './Heading';

interface ISectionLayout {
  children: React.ReactNode;
  heading: string;
  asset: any;
  btn?: any;
}

export function SectionContent(props: ISectionLayout) {
  const { children, heading, asset, btn } = props;
  return (
    <>
      <Box w={{ base: '100%', sm: '44%' }} m="auto">
        {asset}
      </Box>
      <Box w={{ xs: '100%', sm: '44%' }} m="auto">
        <Heading text={heading} />
        <Text
          fontSize={{ base: '1rem', sm: 'normal' }}
          lineHeight="1.5rem"
          mb={6}
        >
          {children}
        </Text>
        {btn}
      </Box>
    </>
  );
}
