import React from 'react';
import { Text, Box, Flex, Stack, Link } from '@chakra-ui/core';

import useScrollToTop from '../../hooks/useScrollToTop';

export default function ReferAFriend() {
  const { refEl } = useScrollToTop();

  return (
    <Box pt={20} ref={refEl}>
      <Flex py={6} flexDirection="column" alignItems="center">
        <Text
          fontWeight="bold"
          py={1}
          fontSize="1.875rem"
          color="brand.secondary"
          letterSpacing="0.02em"
          lineHeight="3.25rem"
          textAlign="center"
        >
          Refer-a-Friend Program Terms & Conditions
        </Text>
      </Flex>
      <Flex
        fontSize="sm"
        alignItems="center"
        px={3}
        flexDirection="column"
        flex={1}
      >
        <Stack
          pb={12}
          spacing={1}
          fontSize="1rem"
          lineHeight="1.3125rem"
          letterSpacing="0.02em"
          width={{ base: '100%', md: '5xl' }}
        >
          <Text textAlign="justify">
            As a Refer-a-Friend member (a "Referrer"), you are subject to
            Readeo's Terms of Service and Privacy Policy, as well as the
            following additional Terms & Conditions for Readeo's Refer-a-Friend
            program:
          </Text>
          <Stack as="ul" spacing={1}>
            <Box as="li" textAlign="justify" ml={4}>
              Qualified Referral. A Qualified Referral is defined as a purchase
              made at www.readeo.com by a person (a "Referred Customer") who
              arrives to our website by clicking your Refer-a-Friend program
              link. Rewards are only granted once the Referred Customer has
              purchased a Readeo subscription. For the avoidance of doubt,
              rewards will not be granted when a customer signs up solely for a
              Free Plan account. You are limited to one Qualified Referral for
              each Referred Customer; in other words, additional/repeat
              purchases made by a Referred Customer are not counted as
              additional Qualified Referrals. Rewards (defined below) in the
              form of discounts for Qualified Referrals are valid for single use
              only.
            </Box>
            <Box as="li" textAlign="justify" ml={4}>
              Referred Customer. The Referred Customer and the Referrer cannot
              be the same person (for example, by using a different email
              address). The Referred Customer is eligible for the Refer-a-Friend
              reward only for their first purchase placed on www.readeo.com.
            </Box>
            <Box as="li" textAlign="justify" ml={4}>
              Referral Rewards. The Referrer shall receive one (1) reward (each,
              a "Reward") in the form and value determined by Readeo for each
              verified Qualified Referral generated by the Referrer. No partial
              rewards will be given. Rewards may be redeemed in various forms in
              Readeo's sole discretion, including, but not limited to in the
              form of an Amazon.com* gift card. Restrictions may apply. For
              example, if the Reward is in the form of a gift card, gift
              certificate or voucher, it may be subject to the issuer's terms
              and conditions. Rewards in the form of discounts apply to the
              Referrer's or Referred Customer's full order, excluding sale
              items, or gift cards. Rewards cannot be combined with any other
              offers. Rewards may be for a limited time and for limited services
              only, and by taking advantage of any such Rewards, you are
              agreeing to any terms communicated to you through the Services (as
              defined in the{' '}
              <Link
                href="https://www.readeo.com/terms-of-service"
                color="blue.500"
                textDecoration="underline"
              >
                Terms of Service
              </Link>
              ). Rewards for free months of use on Readeo will be granted by the
              Readeo team and added to your account.
            </Box>
            <Box as="li" textAlign="justify" ml={4}>
              Eligibility. Eligibility is limited to individuals only. Readeo's
              Refer-a-Friend Program cannot be used by businesses for affiliate
              lead generation as determined in Readeo's sole discretion. Readeo
              can and will make all determinations regarding participation in
              the program. Readeo reserves the right to terminate participation
              in the program at any time for any reason or no reason, to the
              extent permitted by law, with or without written notice to the
              Referred Customer or the Referrer.
            </Box>
            <Box as="li" textAlign="justify" ml={4}>
              No Spam. You must comply with all up-to-date "SPAM" laws. For
              example, emails must be created and distributed in a personal
              manner and bulk email distribution is strongly discouraged. Any
              distribution of your referral link that could constitute
              unsolicited commercial email or "spam" under any applicable law or
              regulation is expressly prohibited and will be grounds for
              immediate termination of your account and exclusion from Readeo's
              Refer-a-Friend program.
            </Box>
            <Box as="li" textAlign="justify" ml={4}>
              Right to Close Accounts. Readeo reserves the right to close the
              account(s) of any Referrer and/or Referred Customer and to request
              proper payment if the Referrer and/or Referred Customer attempts
              to use the Readeo Refer-a-Friend program in a questionable manner
              or breaches any of these Terms & Conditions or is in violation of
              any law, statute or governmental regulation.
            </Box>
            <Box as="li" textAlign="justify" ml={4}>
              Right to Cancel Program or Change Terms. Readeo reserves the right
              to cancel the Refer-a-Friend Program or to change these Terms &
              Conditions at any time in its sole discretion. Any unclaimed
              Rewards will be forfeited at that time.
            </Box>
          </Stack>
          <Text marginTop={5} textAlign="justify">
            *Amazon.com is not a sponsor of this promotion. Except as required
            by law, Amazon.com Gift Cards ("GCs") cannot be transferred for
            value or redeemed for cash. GCs may be used only for purchases of
            eligible goods at Amazon.com or certain of its affiliated websites.
            For complete terms and conditions, see www.amazon.com/gc-legal. GCs
            are issued by ACI Gift Cards, Inc., a Washington corporation. All
            Amazon ®,™ & © are IP of Amazon.com, Inc. or its affiliates.
          </Text>
        </Stack>
      </Flex>
    </Box>
  );
}
