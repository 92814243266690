import React from 'react';
import { Flex, Box, Text, Image, useDisclosure } from '@chakra-ui/core';

import StoryTime from '../../assets/images/story-time.svg';
import BooksImage from '../../assets/images/books.svg';
import { Heading, ImageVideoModal } from '../shared';
import useScrollToTop from '../../hooks/useScrollToTop';

export default function About() {
  const { refEl } = useScrollToTop();
  const disclosure = useDisclosure();

  return (
    <Box pt={6} ref={refEl}>
      <Flex>
        <Flex
          w={{ sm: '1200px' }}
          m={{ base: '4rem auto', sm: '7rem auto' }}
          justify="space-between"
          flexWrap="wrap"
          flexDir={{ base: 'column-reverse', sm: 'row' }}
        >
          <Box w={{ base: '100%', sm: '44%' }} m="auto">
            <Box p={{ base: '1rem', sm: 0 }}>
              <Heading text="This is how our story begins" />
              <Text
                fontSize={{ base: '1rem', sm: 'normal' }}
                lineHeight="1.4rem"
                mb="1rem"
              >
                In 2010, Readeo founder Coby Neuenschwander and his family moved
                1,500 miles away—and quickly realized video calls alone weren't
                enough to keep his son and son’s grandparents connected.
              </Text>
              <Text
                fontSize={{ base: '1rem', sm: 'normal' }}
                lineHeight="1.4rem"
              >
                To bridge the distance, Coby turned to the power of stories.
                Combining beautifully digitized books with video chat
                technology, Readeo launched later that year, and BookChat™ was
                patented in 2012. Since then, Readeo has been committed to
                fostering the joy of reading between loved ones—near and far.
              </Text>
            </Box>
          </Box>

          <Box w={{ base: '100%', sm: '44%' }} m="auto">
            <ImageVideoModal
              image={require('../../assets/images/dad_reading.svg')}
              imageAlt="How does BookChat work?"
              disclosure={disclosure}
            />
          </Box>
        </Flex>
      </Flex>

      <Flex background="#A8DEEC">
        <Flex
          w={{ md: '1200px' }}
          m="4rem auto"
          justify="space-between"
          flexWrap="wrap"
        >
          <Box w={{ base: '100%', sm: '44%' }} m="auto">
            <Image src={BooksImage} alt="Our story image" mb="1rem" />
          </Box>
          <Box w={{ base: '100%', sm: '44%' }} m="auto">
            <Box p={{ base: '1rem', sm: 0 }}>
              <Heading text="Thousands of books for the whole family" />
              <Text
                fontSize={{ base: '1rem', sm: 'normal' }}
                lineHeight="1.4rem"
              >
                BookChat gives families a way to spend quality time together,
                even when they’re apart. We are passionate about growing our
                library with high-quality books that educate, entertain, and
                encourage little ones to let their imaginations run wild.
              </Text>
            </Box>
          </Box>
        </Flex>
      </Flex>

      <Flex>
        <Flex
          w={{ sm: '1200px' }}
          m="4rem auto"
          justify="space-between"
          flexWrap="wrap"
          flexDir={['column-reverse', 'row']}
        >
          <Box w={{ sm: '44%' }} m="auto">
            <Box p={{ base: '1rem', sm: 0 }}>
              <Heading text="Turn screen time into storytime" />
              <Text
                fontSize={{ base: '1rem', sm: 'normal' }}
                lineHeight="1.4rem"
              >
                BookChat lets either side of the video call flip through the
                pages of a picture book easily. This interactive experience
                captures kids’ attention longer—which makes it no surprise that
                90% of Readeo users prefer BookChat over other video chat
                services to connect with their loved ones.
              </Text>
            </Box>
          </Box>
          <Box w={{ sm: '44%' }} m="auto">
            <Image src={StoryTime} alt="Our story image" mb="1rem" />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}
