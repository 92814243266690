import React from 'react';
import { Box, Image, Flex, Text } from '@chakra-ui/core';
import { Link } from 'react-router-dom';

import ReviewSlider from '../reviews';
import CompanyLogos from '../company-logos';
import SectionLayout from '../../layouts/SectionLayout';
import {
  ImageVideoModal,
  SectionButton,
  SectionContent,
  IDisclosure,
} from '../shared';
import config from 'config';

export default function Sections(props: { disclosure: IDisclosure }) {
  const { disclosure } = props;

  return (
    <Box>
      <SectionLayout
        flexDirection={{ base: 'column', sm: 'row' }}
        p={{ base: '1.8rem 0.9375rem', sm: '5.618rem 0' }}
        background="linear-gradient(180deg, rgba(255, 255, 255, 0) 7.29%, rgba(255, 255, 255, 0.5) 100%), #F0F2E6"
      >
        <SectionContent
          heading="More storytime means more quality time"
          asset={
            <Image
              loading="lazy"
              src={require('../../assets/images/sec1.png')}
              alt="BookChat on a computer"
            />
          }
          btn={
            <SectionButton
              onClick={() => (window.location.href = `${config.appUrl}/signup`)}
            >
              SIGN UP FOR FREE
            </SectionButton>
          }
        >
          Our patented BookChat platform makes it easier to capture kids’
          attention. By combining interactive, digitized books with video chat,
          BookChat sessions last almost 10 minutes longer than traditional video
          calls.
        </SectionContent>
      </SectionLayout>

      <SectionLayout
        flexDirection={{ base: 'column', sm: 'row-reverse' }}
        background="linear-gradient(0deg, #FFFFFF, #FFFFFF), #F0F2E6"
        p={{ base: '1.8rem 0.9375rem', sm: '5.618rem 0' }}
      >
        <SectionContent
          heading="Give the whole family the gift of reading"
          asset={
            <Image
              mb="1rem"
              loading="lazy"
              src={require('../../assets/images/group.svg')}
              alt="group collage"
            />
          }
          btn={
            <SectionButton
              onClick={() => (window.location.href = `${config.appUrl}/signup`)}
            >
              GET THE FAMILY PLAN
            </SectionButton>
          }
        >
          When you sign up for a Readeo Gold Family Plan, you can share your
          gold benefits with four others - who will all have access to our
          entire library. With new books added weekly, kids can explore
          countless stories on their own or with you during your next BookChat.
        </SectionContent>
      </SectionLayout>

      <SectionLayout
        flexDirection={{ base: 'column', sm: 'row' }}
        background="linear-gradient(0deg, #A9DFED, #A9DFED), #F0F2E6"
        p={{ base: '1.8rem 0.9375rem', sm: '5.618rem 0' }}
      >
        <SectionContent
          heading="How does BookChat work?"
          asset={
            <ImageVideoModal
              image={require('../../assets/images/little_girl.png')}
              imageAlt="How does BookChat work?"
              disclosure={props.disclosure}
            />
          }
          btn={
            <SectionButton onClick={disclosure.onOpen}>
              LEARN MORE
            </SectionButton>
          }
        >
          All you need is a high-speed internet connection, webcam, and Readeo
          account. Easily access BookChat on an internet browser with your PC,
          laptop, or tablet—and let the stories begin.
        </SectionContent>
      </SectionLayout>

      <SectionLayout
        flexDirection={{ base: 'column', sm: 'row-reverse' }}
        background="linear-gradient(0deg, #FFFFFF, #FFFFFF), #F0F2E6"
        p={{ base: '1.8rem 0.9375rem', sm: '5.618rem 0' }}
      >
        <SectionContent
          heading="A good book can bridge any distance"
          asset={
            <Image
              mb="1rem"
              loading="lazy"
              src={require('../../assets/images/bookmap.svg')}
              alt="traverse the map through books"
            />
          }
          btn={
            <Link to="/library">
              <SectionButton>BROWSE THE LIBRARY</SectionButton>
            </Link>
          }
        >
          Stories have the power to bring people together. Visit our
          ever-growing library of carefully curated titles, and enjoy thousands
          of books you and your family can explore—anytime, anywhere.
        </SectionContent>
      </SectionLayout>

      <SectionLayout
        flexDirection={{ base: 'column', md: 'row' }}
        background="linear-gradient(90deg, rgba(179,0,55,1) 50%, rgba(127,0,39,1) 50%)"
        height={{ md: '26rem' }}
      >
        {/* review slider */}
        <Flex
          background="#B30037"
          width={{ md: '50%' }}
          height={{ base: '314px', md: 'auto' }}
          position="relative"
          py={3}
        >
          <ReviewSlider />
        </Flex>

        {/* company logos */}
        <Flex
          background="#7F0027"
          width={{ md: '50%' }}
          flexDirection="column"
          paddingY={{ base: 8, md: 16 }}
        >
          <Text
            p={5}
            color="white"
            fontSize="normal"
            fontWeight="bold"
            textAlign="center"
          >
            Rave reviews for Readeo's BookChat
          </Text>
          <CompanyLogos />
        </Flex>
      </SectionLayout>
    </Box>
  );
}
