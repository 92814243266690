const reviews = [
  {
    review:
      'This morning my grandson woke up and said he wanted to read with Gramma BEFORE he went outside to play! He always thanks me for reading to him. Thank you for a wonderful app!',
    author: 'Nadine B.',
  },
  {
    review:
      'I LOVED it, and so did my two grandsons! We spent 40 minutes together and they were entranced by it. My daughter-in-law loves it too because she can get all the laundry folded. I totally love Readeo! Thanks for a great product.',
    author: 'Leslie C.',
  },
  {
    review:
      'Our little grandson who lives in Shanghai loved this story. Every time we start reading to him he begs for one story after another—he stays glued to the pages. Thanks for what you are doing!',
    author: 'Tere M.',
  },
  {
    review:
      'The boys had a great time reading their books with daddy! We are excited for the opportunity for him to be able to read with the boys over [his deployment]. Thanks again for offering this to us!',
    author: 'Megan L.',
  },
  {
    review:
      'We love Readeo! What a great idea. This is so nice for us expats who live abroad and are very far from our parents. Both moms loved reading to my son.',
    author: 'Lisa B.',
  },
  {
    review:
      'I love this. My 6-year-old grandson and I have a scheduled reading date. Now my older grandkids want to do it too. Yes, they know the books are for younger kids, but they said it will be fun because I always do crazy voices and embellish the stories.',
    author: 'Pamela F.',
  },
];

export default reviews;
