import React from 'react';
import {
  Flex,
  Heading,
  Box,
  Text,
  Image,
  Progress,
  PseudoBox,
} from '@chakra-ui/core';

import bookshelfImage from 'assets/images/pricing-11-m.png';
import bookmarkImage from 'assets/images/pricing-22-m.png';
import sharePlanImage from 'assets/images/pricing-33-m.png';
import styled from '@emotion/styled';

interface IGoldPlanFeaturesSlider {
  currentFeature: number;
  setCurrentFeature: React.Dispatch<React.SetStateAction<number>>;
  setIsChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledCarousel = styled(Flex)`
  width: 100%;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function GoldPlanFeaturesSlider({
  currentFeature,
  setCurrentFeature,
  setIsChanged,
}: IGoldPlanFeaturesSlider) {
  const stopAllTimeouts = () => {
    const highestTimeoutId = setTimeout(() => {});
    for (let i = 0; i < highestTimeoutId; i++) {
      clearTimeout(i);
    }
  };

  return (
    <Flex
      backgroundColor="#083261"
      flexDirection="column"
      justify="center"
      align="center"
      pt="3rem"
      pb="1rem"
      width="100%"
      position="relative"
    >
      <Heading
        as="h3"
        color="#FFF"
        fontSize="24px"
        fontWeight="600"
        lineHeight="21px"
      >
        Gold Plan Perks
      </Heading>
      <Box
        pt="2.5rem"
        m="0px"
        display="flex"
        width="100%"
        flexWrap="nowrap"
        transition="ease 1000ms"
        transform={`translate3d(${-currentFeature * 100}%, 0, 0)`}
      >
        <StyledCarousel>
          <Image
            src={bookshelfImage}
            alt="send"
            height="195px"
            width="195px"
            objectFit="cover"
          />
          <Text
            fontSize="2xl"
            fontWeight="600"
            color="white"
            textAlign="center"
            mt="1.5rem"
          >
            Create customized bookshelves
          </Text>

          <Text
            color="white"
            textAlign="center"
            py="12px"
            px="9px"
            width="370px"
          >
            Keep your books organized and on display, using as many shelves as
            you like. Anyone on your Gold Family Plan can access them, too!
          </Text>
        </StyledCarousel>
        <StyledCarousel>
          <Image
            src={bookmarkImage}
            alt="send"
            height="195px"
            width="186px"
            objectFit="cover"
          />
          <Text
            fontSize="2xl"
            color="white"
            fontWeight="600"
            textAlign="center"
            mt="1.5rem"
          >
            Save your spot
          </Text>
          <Text
            color="white"
            textAlign="center"
            py="12px"
            px="23px"
            width="370px"
          >
            When bedtime cuts your BookChat short, bookmark that spot and jump
            back in later.
          </Text>
        </StyledCarousel>
        <StyledCarousel>
          <Image
            src={sharePlanImage}
            alt="send"
            height="195px"
            width="186px"
            objectFit="cover"
          />
          <Text
            fontSize="2xl"
            color="white"
            fontWeight="600"
            textAlign="center"
            mt="1.5rem"
          >
            Share Gold with Family
          </Text>
          <Text
            color="white"
            textAlign="center"
            py="12px"
            px="23px"
            width="370px"
          >
            Add up to 4 people on your Gold Family Plan. They’ll have the same
            full access to Readeo as you.
          </Text>
        </StyledCarousel>
      </Box>
      <Flex py="45px">
        {Array.from({ length: 3 }).map((_, index) => (
          <PseudoBox
            key={index}
            marginX="10px"
            _hover={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setIsChanged(true);
              setCurrentFeature(index);
              stopAllTimeouts();
            }}
          >
            <Progress
              value={currentFeature === index ? 100 : 0}
              backgroundColor="#CBECF4"
              color="orange"
              width="60px"
              height="5.83px"
              borderRadius="36.92px"
            />
          </PseudoBox>
        ))}
      </Flex>
    </Flex>
  );
}
