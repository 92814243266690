import React from 'react';
import { Box, Flex, Text, Button, Heading } from '@chakra-ui/core';
import desktopHeaderBg from 'assets/images/landing-hero.jpg';
import mobileHeaderBg from 'assets/images/header_bg_mobile.png';
import { useIsMobileView } from 'hooks/useMobileView';

import config from 'config';

export default function Header() {
  const isMobile = useIsMobileView();
  if (isMobile) {
    return <MobileHeader />;
  }
  return (
    <Flex height="800px" width="100%">
      <Flex
        width="50%"
        background={`linear-gradient(320.53deg, #04274F 19.75%, rgba(4, 39, 79, 0) 65.92%), #083261`}
        justifyContent="flex-end"
        alignItems="center"
        pr={{ md: '1rem', lg: '3rem', xl: '4rem' }}
        pl={{ md: '1rem', lg: 0 }}
      >
        <HeaderCopy />
      </Flex>
      <Box
        width="50%"
        background={`url(${desktopHeaderBg}) no-repeat center center`}
        backgroundSize="cover"
      />
    </Flex>
  );
}

function MobileHeader() {
  return (
    <Box
      background={`url(${mobileHeaderBg}) no-repeat center center`}
      paddingTop="1.5rem"
      height="800px"
      backgroundSize="cover"
      alignItems="end"
      display="grid"
      marginTop="4rem"
    >
      <HeaderCopy />
    </Box>
  );
}

function HeaderCopy() {
  const isMobile = useIsMobileView();
  return (
    <Flex
      flexDir="column"
      fontFamily="Montserrat"
      maxWidth="500px"
      color="white"
      margin={isMobile ? '20% auto' : 'none'}
      padding={isMobile ? '0 15px' : 'none'}
      style={{ placeItems: isMobile ? 'center' : 'none' }}
    >
      <Heading
        as="h1"
        fontWeight="bold"
        fontStyle={'normal'}
        fontSize={isMobile ? '30px' : '48px'}
        lineHeight="53px"
        letterSpacing="0.03em"
      >
        Read together from anywhere.
      </Heading>
      <Text
        fontWeight={500}
        fontSize={isMobile ? '16px' : '18px'}
        lineHeight="24px"
        margin="10px 0"
      >
        Readeo combines video chat technology with an award-winning library of
        digital kids' books to enable a virtual storytime experience. See for
        yourself how a good book can bridge any distance!
      </Text>
      <Flex
        flexDirection="column"
        width={{ base: '100%', md: '70%', lg: '65%' }}
      >
        <Button
          onClick={() => (window.location.href = `${config.appUrl}/signup`)}
          color="white"
          bg="#F38530"
          borderRadius="6px"
          size="sm"
          height="50px"
          fontWeight="bold"
          fontSize="13pt"
          margin="1rem 0"
          py={8}
          _hover={{
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #f38530',
          }}
        >
          SIGN UP FOR FREE
        </Button>
        {!isMobile && (
          <Text
            fontWeight={500}
            fontSize="16px"
            lineHeight="21px"
            alignSelf="center"
          >
            no credit card required
          </Text>
        )}
      </Flex>
    </Flex>
  );
}
