import React, { useEffect } from 'react';
import { Flex, Heading, Text, Image, Box } from '@chakra-ui/core';

// assets
import bookshelfImage from 'assets/images/pricing-11.png';
import bookmarkImage from 'assets/images/pricing-22.png';
import sharePlanImage from 'assets/images/pricing-33.png';
import StarGif from 'assets/images/star.gif';

import { StyledButton as Button } from 'styles/StyledButton';
import GoldPlanFeaturesSlider from '../pricing/NewPricing/Slider';
import { useIsMobileView } from 'hooks/useMobileView';
import config from 'config';

export default function GoldPlanFeatures() {
  const isMobile = useIsMobileView();
  const [currentFeature, setCurrentFeature] = React.useState(0);
  const [paused, setPaused] = React.useState(false);
  const ref = React.useRef<HTMLElement>(null);
  const [isChanged, setIsChanged] = React.useState(false);

  useEffect(() => {
    if (isChanged) {
      setIsChanged(false);
    }
  }, [isChanged]);

  useEffect(() => {
    if (!isMobile) return;
    const time = setInterval(() => {
      setCurrentFeature((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
    }, 10000);

    if (paused) {
      clearInterval(time);
    }

    return () => {
      if (time) clearInterval(time);
    };
  }, [paused, isMobile]);

  useEffect(() => {
    if (ref)
      ref.current?.addEventListener('click', () => {
        setPaused(true);
      });
  }, []);

  const getStyles = (isActive: boolean) => {
    if (!isMobile)
      return {
        width: '30%',
      };
    const activeStyle = {
      width: '100%',
      display: 'flex',
    };

    const inActiveStyle = {
      width: '100%',
      display: 'none',
      transition: 'opacity 1s ease-out',
    };
    return isActive ? activeStyle : inActiveStyle;
  };

  return (
    <Flex
      flexDirection="column"
      justify="center"
      align="center"
      width="100%"
      overflow="hidden"
    >
      {isMobile ? (
        <GoldPlanFeaturesSlider
          currentFeature={currentFeature}
          setCurrentFeature={setCurrentFeature}
          setIsChanged={setIsChanged}
        />
      ) : (
        <Flex
          backgroundColor="#083261"
          flexDirection="column"
          justify="center"
          align="center"
          pt="3rem"
          pb="1rem"
          width="100%"
          position="relative"
        >
          <Heading
            as="h3"
            color="#FFF"
            fontSize={isMobile ? '24px' : '30px'}
            fontWeight="600"
            lineHeight="21px"
          >
            More reasons to go for gold
          </Heading>
          <Box
            pt="2.5rem"
            pb="1rem"
            m="0px"
            maxWidth={!isMobile ? '1000px' : ''}
            display={isMobile ? 'grid' : 'flex'}
            gridTemplateColumns="100% 100% 100%"
            className={isMobile ? 'plans-mobile' : ''}
            gridAutoFlow="column"
            justifyContent="space-between"
          >
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              style={getStyles(currentFeature === 0)}
            >
              <Image
                src={bookshelfImage}
                alt="send"
                height="195px"
                width="195px"
                objectFit="cover"
              />
              <Text
                fontWeight="bold"
                color="white"
                textAlign="center"
                mt="1.5rem"
                lineHeight="24px"
                fontSize="18px"
              >
                Create customized bookshelves
              </Text>
              <Text color="white" textAlign="center" py="4px">
                Keep your books organized and on display, using as many shelves
                as you like. Anyone on your Gold Family Plan can access them,
                too!
              </Text>
            </Flex>
            <Flex
              flexDirection="column"
              // justifyContent="center"
              alignItems="center"
              width={isMobile ? '100%' : '30%'}
              style={getStyles(currentFeature === 1)}
            >
              <Image
                src={bookmarkImage}
                alt="send"
                height="195px"
                width="186px"
                objectFit="cover"
              />
              <Text
                // fontSize="2xl"
                color="white"
                fontWeight="bold"
                textAlign="center"
                mt="1.5rem"
                fontSize="18px"
              >
                Save your spot
              </Text>
              <Text color="white" textAlign="center" py="4px">
                When bedtime cuts your BookChat short, bookmark that spot and
                jump back in later.
              </Text>
            </Flex>
            <Flex
              flexDirection="column"
              // justifyContent="center"
              alignItems="center"
              style={getStyles(currentFeature === 2)}
            >
              <Image
                src={sharePlanImage}
                alt="send"
                height="195px"
                width="186px"
                objectFit="cover"
              />
              <Text
                // fontSize="2xl"
                color="white"
                fontWeight="bold"
                textAlign="center"
                mt="1.5rem"
                fontSize="18px"
              >
                Share Gold with Family
              </Text>
              <Text color="white" textAlign="center" py="4px">
                Add up to 4 people on your Gold Family Plan. They’ll have the
                same full access to Readeo as you.
              </Text>
            </Flex>
          </Box>
          {isMobile && (
            <Flex
              height="20px"
              width="100%"
              justifyContent="space-around"
              mt="55px"
              px="2.5rem"
              ref={ref}
            >
              {Array.from({ length: 3 }).map((_, index) => (
                <Box
                  key={`indicator-${index}`}
                  cursor="pointer"
                  height="6px"
                  width="55px"
                  background={`linear-gradient(90deg, #F47820 ${
                    currentFeature === index ? `80%` : '0%'
                  }, #F47820 0%, #CBECF4 0%, #CBECF4 60%)`}
                  borderRadius="36px"
                  onClick={() => setCurrentFeature(index)}
                />
              ))}
            </Flex>
          )}
        </Flex>
      )}
      <Flex
        bg="#001C3D"
        margin="0px"
        width="100%"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="center"
        alignItems="center"
        p={isMobile ? '2rem 1rem' : '3.7rem'}
      >
        <Image
          src={StarGif}
          w="11em"
          h="11em"
          objectFit="cover"
          rounded="full"
          mr="0.5rem"
        />
        <Flex
          flexDirection="column"
          width={isMobile ? '100%' : 'initial'}
          alignItems={isMobile ? 'center' : 'initial'}
          marginTop={isMobile ? '1.275rem' : 'initial'}
        >
          <Text fontSize="30px" color="white" fontWeight="600" pt="1rem">
            Try Readeo for Free
          </Text>
          <Button
            bg="#F38530"
            borderRadius="6px"
            textTransform="uppercase"
            width={{ base: '100%', md: '181.41px' }}
            height="50px"
            fontWeight="bold"
            fontSize="13pt"
            alignSelf={{ base: 'center', md: 'start' }}
            onClick={() => (window.location.href = `${config.appUrl}/signup`)}
            style={{
              marginBottom: '0px',
            }}
            _hover={{
              background:
                'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #f38530',
            }}
          >
            SIGN UP NOW
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
