import React from 'react';
import { Stack, Box, Image } from '@chakra-ui/core';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
  slidesToShow: 1.65,
  slidesToScroll: 1,
  autoplay: true,
  infinite: true,
  autoplaySpeed: 5000,
  centerMode: true,
};

export default function CompanyLogos(props: { logos: string[] }) {
  const { logos } = props;

  const renderedLogos = logos.map((logo: string, indx) => {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        maxWidth="inherit"
        key={indx}
        spacing={10}
        paddingX={1}
      >
        <Image
          src={logo}
          alt="logo"
          height="6rem"
          width="80%"
          style={{
            objectPosition: 'center',
            objectFit: 'contain',
          }}
        />
      </Stack>
    );
  });

  return (
    <Box width="inherit">
      <Slider {...settings}>{renderedLogos}</Slider>
    </Box>
  );
}
