import { FormEvent, RefObject, EventHandler, SyntheticEvent } from 'react';

export default function handleKeyPress(
  e: FormEvent<HTMLFormElement>,
  ref: RefObject<HTMLElement>,
  handler: EventHandler<SyntheticEvent>,
) {
  // here, we want to check if ENTER key is pressed and the action button (passed as ref) is not disabled
  // @ts-ignore
  if (e.charCode === 13 && !ref.current?.disabled) {
    return handler(e);
  }
}
