import React from 'react';
import { Flex, Heading, Text } from '@chakra-ui/core';

import { SectionButton } from '../shared';

import config from 'config';

export default function LibraryHeading() {
  // get access to history object

  return (
    <Flex justifyContent="center" background={'white'}>
      <Flex
        width={1200}
        px={['1.5rem', '1.5rem', '0.94rem']}
        flexDirection="column"
        alignItems={'center'}
        textAlign="center"
        paddingTop={127}
        paddingBottom={50}
      >
        <Heading
          as="h3"
          size="xl"
          color="brand.secondary"
          letterSpacing="0.01em"
        >
          Welcome to our library
        </Heading>
        <Text marginY={5} fontSize={16} letterSpacing={'0.02em'}>
          Explore 1000+ award-winning titles from publishers like Simon {'&'}{' '}
          Schuster, Candlewick Press, ABRAMS, and more.
        </Text>
        <SectionButton
          onClick={() => (window.location.href = `${config.appUrl}/signup`)}
        >
          START A BOOKCHAT
        </SectionButton>
      </Flex>
    </Flex>
  );
}
