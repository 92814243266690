// define initial state values
const _init: any = {
  requesting: false,
  exists: false,
  invalidE: true,
  fname: '',
  lname: '',
  email: '',
  paymentType: '',
  address: '',
  city: '',
  sorp: 'Illinois',
  zip: '',
  country: 'United States',
};

export default _init;
