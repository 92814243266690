import React from 'react';
import { PseudoBox, Flex } from '@chakra-ui/core';

export function SectionButton(props: {
  children: React.ReactNode;
  onClick?(): void;
}) {
  return (
    <PseudoBox
      as={Flex}
      w={{ md: 'fit-content' }}
      px={{ base: '1rem', md: '1.2rem' }}
      h="3.125rem"
      background="#F38530"
      color="white"
      fontFamily="Montserrat, sans-serif"
      justifyContent="center"
      alignItems="center"
      fontWeight="bold"
      fontSize="md"
      cursor="pointer"
      my={2}
      borderRadius="6px"
      onClick={props.onClick}
      _hover={{
        background: '#CF7129',
      }}
    >
      {props.children}
    </PseudoBox>
  );
}
