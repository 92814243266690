import { css } from '@emotion/core';

export const copyrightListStyle = css`
  ol {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0.14rem;
  }

  li {
    display: table;
  }

  li:before {
    content: counter(section, upper-alpha) ')';
    font-weight: bold;
    display: table-cell;
    text-align: left;
    width: 1.8rem;
  }

  @media screen and (min-width: 480px) {
    li:before {
      width: 2.5rem;
    }
  }

  li {
    counter-increment: section;

    & > ol {
      & li:before {
        content: counter(inner-section, upper-roman) '.';
        font-weight: bold;
        display: table-cell;
      }

      & li {
        counter-increment: inner-section;
      }
    }
  }
`;
