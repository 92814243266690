import { useRef, useEffect, RefObject } from 'react';

export default function useScrollToTop(offset = 80) {
  const refEl = useRef<HTMLElement>(null);

  const scrollToRef = (ref: RefObject<HTMLElement>) => {
    if (!ref.current) return;

    window.scrollTo(0, ref.current.offsetTop - offset);
  };

  useEffect(() => {
    scrollToRef(refEl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { refEl };
}
