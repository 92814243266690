import { useEffect, useState } from 'react';

export function useIsMobileView() {
  const [isMobileView, setIsMobileView] = useState(
    window.matchMedia('(max-width: 500px)')?.matches,
  );
  const checkMobileScreen = () =>
    setIsMobileView(window.matchMedia('(max-width: 500px)')?.matches);

  useEffect(() => {
    window.addEventListener('resize', checkMobileScreen);
    return () => window.removeEventListener('resize', checkMobileScreen);
  }, []);

  return isMobileView;
}
