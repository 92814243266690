import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/core';

import useScrollToTop from '../../../hooks/useScrollToTop';

import PageNav from '../pageNav';
import SectionCreator from '../SectionCreator';
import CardPreview from './CardPreview';
import { useIsMobileView } from 'hooks/useMobileView';

export default function GiftCardConfirmation() {
  const { refEl } = useScrollToTop();

  const location = useLocation();
  const isMobile = useIsMobileView();

  let rData: any;
  if (location && location.state) {
    rData = location.state;
  } else {
    return <Redirect to="/giftcard" />;
  }

  return (
    <Box ref={refEl} minHeight="calc(100vh - 18.25rem)">
      <PageNav />
      <SectionCreator
        id="confirmation"
        hText={`Purchase confirmation for your gift ${
          rData?.name.length > 1 ? 'to ' + rData?.name : 'to yourself'
        }`}
        hColor="brand.secondary"
        paddingT={[75, 60]}
        background="white"
        hFont={isMobile ? '24px' : '33px'}
        ChildComponent={() => <CardPreview data={rData} />}
      />
    </Box>
  );
}
