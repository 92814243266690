import React from 'react';
import { Flex, Heading, Text } from '@chakra-ui/core';
import { StyledButton } from 'styles/StyledButton';
import config from 'config';
export default function ReadTogether() {
  return (
    <Flex flexDir="column" py="1rem" px="0.6rem">
      <Heading
        as="h2"
        color="#021E6A"
        fontSize="2.25rem"
        lineHeight="40px"
        fontWeight={600}
        textAlign="left"
      >
        Read together, from wherever!
      </Heading>
      <Text mt="5px" fontSize="1rem">
        Enjoy more time with the little ones in your life, even when you're
        apart by combining video chat with nearly 1,500 beautifully digitized
        kids' books.
      </Text>
      <StyledButton
        borderRadius="6px"
        width="100%"
        height="50px"
        onClick={() => (window.location.href = `${config.appUrl}/signup`)}
      >
        TRY READEO FREE
      </StyledButton>
    </Flex>
  );
}
