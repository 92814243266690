import * as Cookies from 'js-cookie';

export const setCookie = (
  key: string,
  value: string | object,
  options?: { [key: string]: any },
) => {
  Cookies.remove(key);
  Cookies.set(key, value, options);
};

export const getCookie = (key: string) => {
  const cookie = Cookies.get(key);

  if (!cookie) {
    return null;
  }

  return cookie;
};
