import React from 'react';
import { Flex, Image, Text } from '@chakra-ui/core';
import { useIsMobileView } from 'hooks/useIsMobileView';
import logo1 from 'assets/images/logo1.png';
import logo2 from 'assets/images/logo2.png';
import logo3 from 'assets/images/logo3.png';
import logo4 from 'assets/images/logo4.png';
import logo5 from 'assets/images/logo5.png';
import logo6 from 'assets/images/logo6.png';
import logo7 from 'assets/images/logo7.png';
import logo8 from 'assets/images/logo8.png';

const Logos = () => {
  const logos = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8];
  const isMobileView = useIsMobileView();
  return (
    <>
      <Text
        color="#6b84a0"
        textAlign="center"
        fontSize="14px"
        fontWeight="bold"
        mb={{ base: '3px', lg: '1rem' }}
        style={{ ...(isMobileView && { marginTop: '10px' }) }}
      >
        Trusted By
      </Text>
      <Flex
        bg="#C5E8F3"
        alignContent="center"
        justifyContent={isMobileView ? 'flex-start' : 'space-evenly'}
        px="3rem"
        style={{
          ...(isMobileView && { overflowX: 'scroll', flexWrap: 'nowrap' }),
        }}
      >
        {logos.map((logo, index) => (
          <Image
            src={logo}
            alt="logo"
            style={{
              height: '6rem',
              width: isMobileView ? '100%' : '8rem',
              objectPosition: 'center',
              objectFit: 'contain',
            }}
            key={index}
          />
        ))}
      </Flex>
    </>
  );
};

export default Logos;
