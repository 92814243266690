import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { StyledButton } from '../../styles/elements.styled';

export default function ButtonControls(props: any) {
  const { state, isDisabled, submitHandler, buttonRef } = props;

  // check if current route matches upgrade route
  let match = useRouteMatch('/upgrade');

  return (
    <StyledButton
      size="lg"
      onClick={submitHandler}
      isLoading={state.requesting}
      loadingText="Submitting"
      isDisabled={isDisabled}
      ref={buttonRef}
    >
      {match ? 'UPGRADE ACCOUNT' : 'UPDATE BILLING'}
    </StyledButton>
  );
}
