import React, { useState, useEffect } from 'react';
import {
  Link,
  withRouter,
  RouteComponentProps,
  useRouteMatch,
} from 'react-router-dom';
import { Box, Flex, Image } from '@chakra-ui/core';

import Navigation from './navigation';
import NavigationButton from './navigation-button';
import { SalesBanner } from 'components/banners';

interface IHeader extends RouteComponentProps {
  isSticky: boolean;
}

export default withRouter(function Header(props: IHeader) {
  const { location, isSticky } = props;

  const [onHome, setOnHome] = useState<boolean>(true);
  const [activePath, setActivePath] = useState<string>('/');
  const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

  const onGiftcard = useRouteMatch({
    path: '/giftcard',
    strict: true,
  });

  useEffect(() => {
    setActivePath(location.pathname);
    if (location.pathname !== '/' && location.pathname !== '/school') {
      setOnHome(false);
      return;
    }
    setOnHome(true);
  }, [location.pathname]);

  const toggleNavigation = () => setMobileNavOpen(!mobileNavOpen);

  const togglePromotionDetails =
    location.pathname === '/' && process.env.REACT_APP_SALES_BANNER === 'show';

  return (
    <>
      {/* if on root route, render banner based on env variable */}
      {togglePromotionDetails && <SalesBanner />}

      <Flex
        w="100%"
        flexDirection={{ base: 'column' }}
        position="fixed"
        zIndex={1800}
        marginTop={togglePromotionDetails ? '44px' : ''} // offset height of promotion banner
        // makes nav to go up when scrolling
        top={isSticky ? 0 : ''}
      >
        <Flex
          width="100%"
          height="3.75rem"
          zIndex={1800}
          alignItems="center"
          p={{ base: 2, md: 4 }}
          justifyContent="space-between"
          transition="background-color 0.2s ease-in-out"
          borderBottom={{
            base: '1px solid #CBD5E0',
            md:
              onGiftcard?.isExact && !isSticky
                ? 'transparent'
                : !onHome || isSticky
                ? '1px solid #CBD5E0'
                : 'none',
          }}
          background={{
            base: 'white',
            md:
              onGiftcard?.isExact && !isSticky
                ? 'transparent'
                : !onHome || isSticky
                ? 'white'
                : 'none',
          }}
        >
          <Link to="/" onClick={() => mobileNavOpen && setMobileNavOpen(false)}>
            <Box w="8.125rem">
              {/* mobile header logo */}
              <Image
                w="full"
                src={require('../../assets/images/Readeo_Logo_Dark.svg')}
                alt="Readeo"
                display={{ md: 'none' }}
              />

              {/* desktop header logo */}
              {!onHome || isSticky ? (
                <Image
                  w="full"
                  src={
                    onGiftcard?.isExact && !isSticky
                      ? require('../../assets/images/Readeo_Logo_Light.svg')
                      : require('../../assets/images/Readeo_Logo_Dark.svg')
                  }
                  alt="Readeo"
                  display={{
                    base: 'none',
                    md: 'block',
                  }}
                />
              ) : (
                <Image
                  w="full"
                  src={require('../../assets/images/Readeo_Logo_Light.svg')}
                  alt="Readeo"
                  display={{
                    base: 'none',
                    md: 'block',
                  }}
                />
              )}
            </Box>
          </Link>

          <NavigationButton
            isOpen={mobileNavOpen}
            handleClick={toggleNavigation}
          />
          <Navigation
            isSticky={isSticky}
            isOpen={mobileNavOpen}
            activePath={activePath}
            handleClick={toggleNavigation}
          />
        </Flex>
      </Flex>
    </>
  );
});
