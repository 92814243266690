import React, { useEffect, useState } from 'react';
import { Box, Flex, Text, Image } from '@chakra-ui/core';
import Slider from 'react-slick';

import reasons1 from 'assets/images/reasons_1.jpeg';
import reasons2 from 'assets/images/reasons_2.jpeg';
import reasons3 from 'assets/images/reasons_3.jpeg';
import reasons4 from 'assets/images/reasons_4.jpeg';

interface itemType {
  image: string;
  header: string;
  text: string;
}

const items: itemType[] = [
  {
    image: reasons1,
    header: 'Storytime your way',
    text:
      'Reading together—in a BookChat or side-by-side on the couch—instills confidence and encourages children to read on their own as well.',
  },
  {
    image: reasons2,
    header: 'Healthy screentime',
    text:
      'BookChat sessions last almost 10 minutes longer than traditional video calls. More storytime means more quality time, learning, and growing.',
  },
  {
    image: reasons3,
    header: 'Ever-growing library',
    text:
      'Discover nearly 1,500 books from 12 publishers, across muliple categories and languages. We add new books every week!',
  },
  {
    image: reasons4,
    header: 'Start early, read often',
    text:
      'Studies show reading to children strengthens the part of the brain related to visual imagery, story comprehension, and word meaning.',
  },
];

const Reasons = () => {
  const [, setCurrentFeature] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [paused] = useState(true);
  const settings = {
    infinite: false,
    dots: true,
    arrows: false,
    autoplay: false,
    pauseOnHover: true,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          arrows: false,
          rows: 2,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    if (isChanged) {
      setIsChanged(false);
    }
  }, [isChanged]);

  useEffect(() => {
    const time = setInterval(() => {
      setCurrentFeature((prevIndex) => (prevIndex === 3 ? 0 : prevIndex + 1));
    }, 10000);

    if (paused) {
      clearInterval(time);
    }

    return () => {
      if (time) clearInterval(time);
    };
  }, [paused]);

  return (
    <Flex
      mt={{ base: '3rem', md: '5rem' }}
      justifyContent="center"
      alignItems="center"
      flexDir="column"
    >
      <Text
        fontWeight="600"
        textAlign="center"
        fontSize={{ base: '2.25rem', md: '44px' }}
        letterSpacing="0.01em"
        mb="1rem"
        color="#083261"
      >
        Reasons to Readeo
      </Text>
      <Box width="100%" overflow="hidden">
        <Flex
          justifyContent={{ base: 'space-between', md: 'space-evenly' }}
          maxWidth={{
            base: 'initial',
            sm: '90%',
            md: '90%',
            lg: '95%',
            xl: '85%',
          }}
          width={{ base: '100%', md: '1320px' }}
          m={{ base: 'initial', sm: '0 auto' }}
        >
          <Box
            width="100%"
            p={{ base: '1.5rem 0' }}
            className="reviews reasons-container"
          >
            <Slider {...settings}>
              {items.map(({ image, header, text }: itemType, index: number) => (
                <Box
                  border="2px solid #4D87CA"
                  borderRadius="14px"
                  color="#083261"
                  bg="#ffffff"
                  p="1rem"
                  m=".5rem"
                  minHeight={{ base: '455px', lg: '500px' }}
                  width={{ base: '96%', md: '307px' }}
                  justifyContent="space-between"
                  key={index}
                >
                  <Image
                    src={image}
                    alt="icon"
                    width={{ base: '70%', md: '216px' }}
                    height={{ base: 'auto', md: '216px' }}
                    m="1rem auto"
                  />
                  <Text fontWeight="600" fontSize="24px">
                    {header}
                  </Text>
                  <Text>{text}</Text>
                </Box>
              ))}
            </Slider>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Reasons;
