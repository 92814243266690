import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Image, Text } from '@chakra-ui/core';
import { useIsMobileView } from 'hooks/useMobileView';

import { StyledButton } from 'styles/StyledButton';
import fishFarmHeader from 'assets/images/fish_farm_header.jpg';
import yellowBk from 'assets/images/yellow_bk.png';
import freeText from 'assets/images/free_text.png';

import config from 'config';

const Header = () => {
  const isMobile = useIsMobileView();
  const history = useHistory();
  return (
    <Box
      background={`url(${fishFarmHeader}) no-repeat center center`}
      paddingTop="1rem"
      height="800px"
      backgroundSize="cover"
      alignItems={isMobile ? 'end' : 'none'}
      display={isMobile ? 'grid' : 'block'}
      marginTop={isMobile ? '4rem' : 0}
      fontFamily="Montserrat"
    >
      <Image
        w="full"
        src={require('assets/images/Readeo_Logo_Dark.svg')}
        alt="Readeo"
        height="36px"
        width="130px"
        ml="1rem"
        onClick={() => history.push('/')}
        cursor="pointer"
      />
      <Box
        width={{ base: '100%', md: '400px' }}
        mt="3rem"
        height="70px"
        background={`url(${yellowBk})`}
        bgSize="contain"
        backgroundRepeat="no-repeat"
        pt="0.8rem"
      >
        <Image
          w="full"
          src={freeText}
          alt="Readeo"
          // height="20px"
          width={{ base: '100px', md: '250px' }}
          ml={{ base: '1rem', md: '4rem' }}
        />
      </Box>
      <Box
        width={{ base: '100%', md: '420px' }}
        ml={{ base: '1rem', md: '4rem' }}
        fontWeight={500}
        mt="3rem"
      >
        <Text
          color="#021E6A"
          fontSize="40px"
          lineHeight="48px"
          letterSpacing="0.05em"
        >
          We wrote a book with The FishFam!
        </Text>
        <Text mt="1rem" color="#2E2E2E" fontSize="18px" lineHeight="24px">
          Halston’s twin sisters look exactly alike. But who looks like Halston?
          Follow along as she travels to faraway places, meeting new and
          interesting friends, in search of what she didn’t know she already
          had.
        </Text>

        <StyledButton
          borderRadius="6px"
          backgroundColor="#F38530"
          color="#ffffff"
          width="75%"
          mt="2rem"
          height="64px"
          fontWeight="700"
          fontSize="18px"
          onClick={() =>
            (window.location.href = `${config.appUrl}/signup?plan`)
          }
        >
          TRY READEO FREE
        </StyledButton>
      </Box>
    </Box>
  );
};

export default Header;
