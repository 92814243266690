import React, { useState } from 'react';
import { Box, Text, Flex, PseudoBox } from '@chakra-ui/core';
import PageNav from '../shared/pageNav';
import PageIntro from '../shared/PageIntro';
import useScrollToTop from '../../hooks/useScrollToTop';
import {
  FaFacebookSquare,
  FaTwitter,
  FaInstagram,
  FaYoutube,
} from 'react-icons/fa';
import { StyledButton } from '../../styles/elements.styled';
import { useHistory } from 'react-router-dom';
import SkipPrompt from '../shared/SkipPrompt';

const socials = [
  { id: 1, label: 'Facebook', handle: 'https://facebook.com/readeo' },
  { id: 2, label: 'Instagram', handle: 'https://instagram.com/readeo_kids' },
  { id: 3, label: 'Twitter', handle: 'https://twitter.com/readeo' },
  {
    id: 4,
    label: 'channel',
    handle: 'https://www.youtube.com/channel/UCaLfp-RDZLLoPOAhWV5KCxQ',
  },
];

export default function Follow() {
  const [clicked, setClicked] = useState(false);

  const { refEl } = useScrollToTop();
  const history = useHistory();

  return (
    <Box ref={refEl} minHeight="calc(100vh - 18.25rem)">
      <Flex flexDir="column" alignItems="center" background={'white'}>
        <PageNav />
        <PageIntro
          heading="Follow Us on Social Media"
          text="Like what you see (and read)? Share the Readeo love with your community, so everyone can enjoy storytime with their loved ones—near and far."
        />
      </Flex>
      <Flex justifyContent={'center'}>
        <Flex flexDir="column" width={420} mb={30} px="0.938rem">
          {socials.map(({ id, label, handle }) => (
            <PseudoBox
              as="div"
              key={id}
              display="flex"
              width="100%"
              mb="0.5rem"
              p="0.8rem 1rem"
              alignItems="center"
              color="white"
              backgroundColor="#23AAD9"
              cursor="pointer"
              _hover={{
                color: 'brand.secondary',
                backgroundColor: 'brand.lightBlue',
              }}
              onClick={() => {
                if (!clicked) setClicked(true);
                window.open(handle, label, 'blank, noopener, noreferrer');
              }}
            >
              {id === 1 ? (
                <FaFacebookSquare fontSize="2.875rem" />
              ) : id === 2 ? (
                <FaInstagram fontSize="2.875rem" />
              ) : id === 3 ? (
                <FaTwitter fontSize="2.875rem" />
              ) : id === 4 ? (
                <FaYoutube fontSize="2.875rem" />
              ) : null}
              <Text
                marginLeft="0.5rem"
                fontFamily="Montserrat"
                fontWeight={700}
                fontSize="1.25rem"
                fontStyle="normal"
                lineHeight="1.875rem"
                letterSpacing="0.01em"
              >
                {id < 4 ? `Follow Us on ${label}` : `Subscribe to our ${label}`}
              </Text>
            </PseudoBox>
          ))}
          <StyledButton
            width="100%"
            size="lg"
            onClick={() =>
              history.push({
                pathname: '/start',
                state: { isSignup: true },
              })
            }
          >
            {clicked ? 'CONTINUE' : 'SKIP THIS STEP'}
          </StyledButton>
          <SkipPrompt />
        </Flex>
      </Flex>
    </Box>
  );
}
