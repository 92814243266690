import { Box, Button, Flex, Text } from '@chakra-ui/core';
import React from 'react';

const HeroSection = () => {
  return (
    <Flex
      backgroundImage={`url(${require('../../assets/images/Background_1.webp')})`}
      height="75vh"
      width="100%"
      bgSize="cover"
      justifyContent="center"
      minHeight={{ base: '45rem', md: '50rem' }}
      p={{ base: '4rem 1rem', md: '1rem 3rem' }}
    >
      <Flex
        color="white"
        justifyContent={{ base: 'center', md: 'space-between' }}
        alignItems="center"
        maxWidth="1300px"
        flexDir={{ base: 'column-reverse', md: 'row' }}
        boxSizing="border-box"
      >
        <Box
          width={{ base: '100%', md: '38%' }}
          mr={{ base: 'initial', md: '2rem' }}
        >
          <Text
            fontSize={{ base: '1rem', md: '1.4rem' }}
            letterSpacing="0.03em"
          >
            Introducing the
          </Text>
          <Text
            fontWeight={700}
            fontSize={{ base: '1.8rem', md: '2.2rem' }}
            lineHeight="37px"
          >
            Guest Reader Program
          </Text>
          <Text
            fontSize={{ base: '16px', md: '18px' }}
            color="#EDEDED"
            my="1rem"
          >
            Our
            <Text as="span" fontWeight={700} px="5px">
              FREE
            </Text>
            program combines videochat technology with over 1,000 interactive,
            digitized books to help volunteers read to their child’s class
            anytime, from anywhere.
          </Text>
          <Flex alignItems="center" my="1.5rem">
            <Flex
              height="25px"
              width="25px"
              p="7px"
              bg="#6AE268"
              borderRadius="50%"
            >
              <Box width="100%" bg="#068B03" borderRadius="50%"></Box>
            </Flex>
            <Text
              ml=".5rem"
              fontWeight={600}
              fontSize="18px"
              color="#EDEDED"
              fontStyle="italic"
            >
              FREE for Teachers & Volunteers
            </Text>
          </Flex>
          <Button
            bg="#F38530"
            width={{ base: '100%', md: '238px' }}
            height="50px"
            borderRadius="6px"
            fontSize="18px"
            fontWeight={700}
            textTransform="uppercase"
            my="1rem"
            _hover={{
              backgroundColor: 'rgba(243, 133, 48, 0.7)',
            }}
            onClick={() =>
              window.open(`${window.location.origin}/volunteer#getting-started`)
            }
          >
            Learn More
          </Button>
        </Box>
        <Flex
          width={{ base: '100%', md: '55%' }}
          flex={1}
          maxWidth={{ md: '843px' }}
        >
          <Flex
            flex={1}
            width="100%"
            position="relative"
            overflow="hidden"
            pt="56.25%"
          >
            <iframe
              className="hero__video"
              title="Readeo's Guest Reader Program"
              src="https://player.vimeo.com/video/725836372?h=0f8ce593c5&autoplay=1&loop=1&muted=1"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{
                position: 'absolute',
                inset: 0,
                width: '100%',
                height: '100%',
                border: 'none',
                borderRadius: '10px',
              }}
            ></iframe>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default HeroSection;
