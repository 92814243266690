import { useEffect, useState } from 'react';
import accountApi from 'utils/axios';

export interface IUserReponse {
  username: string;
  first_name: string;
  last_name: string;
}

const useVerifyRegistration = () => {
  const [verifying, setVerifiying] = useState<boolean>(true);
  const [user, setUser] = useState<IUserReponse | null>(null);
  useEffect(() => {
    const getUserData = async () => {
      try {
        const response = await accountApi.get('register/verify-social-auth');
        const user = response.data.user as IUserReponse;
        setUser(user);
        setVerifiying(false);
      } catch (error) {
        setVerifiying(false);
      }
    };
    getUserData();
  }, []);

  return {
    verifying,
    user,
  };
};

export default useVerifyRegistration;
