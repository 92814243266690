import { css } from '@emotion/core';

export const speechBubbleStyle = css`
  width: 100%;
  height: 14rem;
  background: #7f0027;
  position: relative;
  color: #f0f2e6;
  border-radius: 0;

  @media screen and (min-width: 820px) {
    border-radius: 4px;
    width: 23.5rem;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  & > p {
    animation: fadeIn 0.5s ease-in;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation: fadeIn 0.5s ease-in;
    font-size: 1rem;

    @media screen and (min-width: 820px) {
      font-size: 1.125rem;
    }
  }

  &::after {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid #7f0027;
    border-top: 10px solid #7f0027;
    border-bottom: 10px solid transparent;
    right: 70px;
    bottom: -20px;
  }
`;
