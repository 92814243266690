import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Stack,
  Select,
  Heading,
  Accordion,
} from '@chakra-ui/core';
import { useLocation, useHistory } from 'react-router-dom';

import {
  AccordionDataType,
  CustomAccordionItem,
  AccordionTitle,
} from '../shared';
import Tab from './Tab';
import { faqData } from './data';
import useScrollToTop from '../../hooks/useScrollToTop';

export default function Faq() {
  const history = useHistory();
  const { refEl } = useScrollToTop();

  // at the beginning, all the tabs are displayed
  const initialTabStates = [...Array(faqData.length)].fill(true);
  const [tabStates, setState] = useState(initialTabStates);

  /**
   * When a tab is clicked, hide all other tabs except the one clicked
   * @param tabIndex
   */
  const onClick = (tabIndex: number) => {
    const updateTabStates = tabStates.map((_, idx) => {
      if (idx === tabIndex) return true;
      return false;
    });
    setState(updateTabStates);
  };

  let location = useLocation();
  useEffect(() => {
    if (location.hash === '#tech') {
      onClick(3);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box ref={refEl}>
      {/* <Stack pt="3em" pb="1rem" justify="center" align="center"> */}
      <Heading as="h1" color="#083261" mt="2rem" pt="3rem" textAlign="center">
        Frequently asked questions
      </Heading>
      {/* </Stack> */}
      <Box
        px={{ xs: '1rem', md: '1.5rem' }}
        fontSize="1rem"
        width="70%"
        margin="1rem auto"
      >
        <Text as="p" fontSize="1rem" textAlign="left" pb="1rem">
          If you can’t find what you’re looking for here, please email{' '}
          <Text as="span" color="#083261" fontWeight="bold">
            support@readeo.com
          </Text>
          or call our support team at{' '}
          <Text as="span" color="#083261" fontWeight="bold">
            801-337-9151
          </Text>
          .
        </Text>
        <Text fontWeight="700" color="#083261" fontSize="1.1rem">
          What is Readeo?
        </Text>
        <Text>
          <Text
            as="span"
            color="#083261"
            fontWeight="bold"
            cursor="pointer"
            onClick={() => history.push({ pathname: '/' })}
          >
            Readeo
          </Text>{' '}
          is a web-based program that combines video chat with a library of
          award-winning children’s books. This allows loved ones separated by
          distance to connect meaningfully via a virtual storytime
          experience—which we call a “BookChat.” Reading out loud is a magical
          way to connect and learn! This program is especially ideal for
          grandparents, military families, traveling parents, etc. Our goal is
          to create a fun and educational interaction for families near or far.{' '}
        </Text>
        <Text fontWeight="700" color="#083261" fontSize="1.1rem" mt="1rem">
          What is a BookChat?{' '}
        </Text>
        <Text>
          ‘BookChat’ is a term we’ve trademarked that references when two users
          read an online book together while video chatting on our platform.
          This is the essence of what Readeo provides!
        </Text>
      </Box>

      <Box
        background="linear-gradient(0deg, rgba(131, 208, 228, 0.7), rgba(131, 208, 228, 0.7)), #FFFFFF"
        transform="matrix(1, 0, 0, 1, 0, 0)"
        py="2rem"
        w="100%"
      >
        <Flex direction="column" justify="center" align="center">
          <Text
            as="p"
            display={['none', 'block']}
            color="#083261"
            fontSize="0.88rem"
            lineHeight="1.3rem"
            paddingBottom="0.7rem"
          >
            What do you need help with?
          </Text>
          <Flex paddingBottom="2.7rem" display={{ base: 'none', md: 'flex' }}>
            <Tab
              text="Membership"
              bl
              onClick={onClick}
              tabIndex={0}
              selected={tabStates[0]}
            />
            <Tab
              text="BookChat&trade;"
              onClick={onClick}
              tabIndex={1}
              selected={tabStates[1]}
            />
            <Tab
              text="Account"
              onClick={onClick}
              tabIndex={2}
              selected={tabStates[2]}
            />
            <Tab
              text="Technology"
              onClick={onClick}
              tabIndex={3}
              selected={tabStates[3]}
            />
          </Flex>
          <Flex
            paddingBottom={[0, '2.7rem']}
            display={{ md: 'none' }}
            w="100%"
            px="1rem"
          >
            <Select
              backgroundColor="#F38530"
              borderColor="#F38530"
              color="white"
              placeholder="QUESTION CATEGORIES"
              w="100%"
              onChange={(e) => onClick(Number(e.target.value))}
            >
              <option value={0}>Membership</option>
              <option value={1}>BookChat&trade;</option>
              <option value={2}>Account</option>
              <option value={3}>Technology</option>
            </Select>
          </Flex>
        </Flex>

        {faqData.map((faq: AccordionDataType, idx: number) => (
          <Stack
            justify="center"
            alignItems="center"
            key={idx}
            mb="1.88rem"
            id={`accordion${idx}`}
            w="100%"
            px="1rem"
            style={{
              display: tabStates[idx] ? 'flex' : 'none',
            }}
          >
            <AccordionTitle text={faq.headingText} />
            <Accordion
              allowMultiple
              w={{
                sm: '100%',
                md: 'calc(100% - 200px)',
                lg: 'calc(100% - 200px)',
                xl: 'calc(100% - 500px)',
              }}
            >
              {faq.items.map((item, _idx: number) => (
                <CustomAccordionItem
                  title={item.title}
                  key={_idx}
                  content={item.text}
                  spacing={2}
                />
              ))}
            </Accordion>
          </Stack>
        ))}
      </Box>
    </Box>
  );
}
