import { Book } from './assets/books';

// define interface
export interface IState {
  ageRange: string;
  category: string;
  books: Book[];
  start: number;
  pageSize: number;
  isFetching: boolean;
}

// define initial state values
const _init: IState = {
  ageRange: '',
  category: '',
  books: [],
  start: 0,
  pageSize: 18,
  isFetching: false,
};

export default _init;
