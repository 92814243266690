import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecurly } from '@recurly/react-recurly';
import { Flex, FormControl } from '@chakra-ui/core';

import axios from '../../../utils/axios';

import _init from './_init';
import useToastMod from '../../../hooks/useToastMod';
import handleKeyPress from '../../../utils/keypress';

import AccountInfo from '../../authentication/signup/v3/accountInfo';
import PaymentMethod from '../../authentication/signup/v3/paymentMethod';
import PayPalButton from '../../authentication/signup/v3/PayPalButton';
import SectionLabel from '../../authentication/signup/v3/sectionLabel';
import Recaptcha from 'react-google-recaptcha';
import {
  checkDisabled,
  validateEmail,
  validatePassword,
} from '../../authentication/utils';

import { StyledButton } from '../../../styles/elements.styled';
import config from 'config';

export default function GiftCardBilling() {
  const [state, setState] = React.useState(_init);
  const [loading, setLoading] = React.useState(false);

  // refs
  const buttonRef = React.useRef(null);
  const formRef = React.useRef<HTMLFormElement>(null);

  const recaptchaRef = React.useRef<Recaptcha>(null);
  const { recaptchaKey } = config;
  // access recurly instance
  const recurly = useRecurly();

  // toast some bread
  const toast = useToastMod();

  // access location object
  const location = useLocation();

  // access history object
  const history = useHistory();

  // handlers
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [e.currentTarget.name]: e.currentTarget.value });
    if (e.currentTarget.name === 'email') {
      setState((prevState: any) => ({
        ...prevState,
        invalidE: validateEmail(e?.currentTarget?.value),
      }));
    }
    if (e.currentTarget.name === 'password') {
      setState((prevState: any) => ({
        ...prevState,
        ...validatePassword(e?.currentTarget?.value),
      }));
    }
  };

  const toggleVisible = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setState({ ...state, visible: !state.visible });
  };

  // payment type handler
  const choosePay = (type: string) => {
    if (state.paymentType === type) {
      setState({ ...state, paymentType: '' });
    } else {
      setState({ ...state, paymentType: type });
    }
  };

  const pushConfirmation = (data: any) => {
    const rData = {
      name: `${
        data[0].delivery.first_name ? data[0].delivery.first_name : ''
      } ${data[0].delivery.last_name ? data[0].delivery.last_name : ''}`,
      email: data[0].delivery.email_address,
      gifter_name: data[0].delivery.gifter_name,
      personal_message: data[0].delivery.personal_message,
      redemption_code: data[0].redemption_code,
      amount: data[0].amount,
      deliver_at: data[0].delivery.deliver_at,
    };
    return history.push({
      pathname: '/giftcard/confirmation',
      state: rData,
    });
  };

  const handlePayPal = async (token_id: string) => {
    const payload = {
      token_id,
      ...location.state,
      b_first_name: state.fname,
      b_last_name: state.lname,
      b_email: state.email,
    };
    axios
      .post('giftcards', payload)
      .then(({ data: { message, data } }) => {
        setLoading(false);
        toast({
          title: `${message}`,
          description: `Voila!, gift card successfully purchased.`,
          status: 'success',
          duration: 10000,
          position: 'top',
          isClosable: true,
        });
        return pushConfirmation(data);
      })
      .catch((err) => {
        setLoading(false);
        return toast({
          title: 'Something went wrong!',
          description: err && err.message ? err.message : '',
          status: 'error',
          duration: 10000,
          position: 'top',
          isClosable: true,
        });
      });
  };

  const submitHandler = async () => {
    try {
      setState({ ...state, requesting: true });
      // @ts-ignore
      recurly.token(formRef.current, async (err, token) => {
        if (err) {
          setState({
            ...state,
            requesting: false,
          });
          // handle error
          return toast({
            title: 'An error occured',
            description: err.message,
            status: 'error',
            duration: 10000,
            position: 'top',
            isClosable: true,
          });
        } else {
          const captcha_token = await recaptchaRef.current?.executeAsync();
          if (!captcha_token) {
            throw new Error('Invalid response from Recaptcha');
          }

          const payload = {
            token_id: token.id,
            ...location.state,
            b_first_name: state.fname,
            b_last_name: state.lname,
            b_email: state.email,
            captcha_token,
            payment_method: 'credit_card',
          };
          axios
            .post('giftcards', payload)
            .then(({ data: { message, data } }) => {
              setState({ ...state, requesting: false });
              toast({
                title: message,
                description: `Voila!, gift card successfully purchased.`,
                status: 'success',
                duration: 10000,
                position: 'top',
                isClosable: true,
              });
              return pushConfirmation(data);
            })
            .catch((err) => {
              //@ts-ignore
              window?.grecaptcha?.reset();
              setState({ ...state, requesting: false });
              return toast({
                title: 'Something went wrong!',
                description: err && err.message ? err.message : '',
                status: 'error',
                duration: 10000,
                position: 'top',
                isClosable: true,
              });
            });
        }
      });
    } catch (err) {
      //@ts-ignore
      window?.grecaptcha?.reset();
      setState({ ...state, requesting: false });
      debugger;
      // @ts-ignore
      return toast({
        title: err && err.message ? err.message : '',
        description:
          'Unable to verify your request. Please refresh this page and try again',
        status: 'error',
        duration: 10000,
        position: 'top',
        isClosable: true,
      });
    }
  };
  return (
    <Flex width="100%" justify="center">
      <Flex width={470}>
        <FormControl
          as="form"
          width="100%"
          my={30}
          display="flex"
          flexDirection="column"
          ref={formRef}
          onKeyPress={(e) => handleKeyPress(e, buttonRef, submitHandler)}
        >
          {/* <SectionLabel text="Billing Details" mt="0.5rem" /> */}
          <AccountInfo
            state={state}
            changeHandler={changeHandler}
            toggleVisible={toggleVisible}
          />
          {/* <Checkbox
            marginY="1.5rem"
            size="md"
            style={{ borderColor: '#019cd5' }}
            color="#2E2E2E"
            fontSize="16px"
          >
            Curabitur varius nibh eu nunc eleifend, in tincidunt tortor finibus.
            Ut efficitur, lacus a tristique.{' '}
          </Checkbox> */}
          <SectionLabel text="Payment method" mt="2.5rem" />
          <PaymentMethod
            state={state}
            changeHandler={changeHandler}
            choose={choosePay}
          />
          {state.paymentType === 'paypal' ? (
            <PayPalButton
              loading={loading}
              setLoading={setLoading}
              isDisabled={checkDisabled(state)}
              handlePayPal={handlePayPal}
            />
          ) : (
            <>
              <Recaptcha
                ref={recaptchaRef}
                sitekey={recaptchaKey}
                size="invisible"
                badge="bottomleft"
              />

              <StyledButton
                size="lg"
                isLoading={state.requesting}
                loadingText="Submitting"
                isDisabled={
                  state.fname &&
                  state.lname &&
                  !state.invalidE &&
                  !state.requesting &&
                  state.paymentType.length !== 0 &&
                  state.zip.length !== 0
                    ? false
                    : true
                }
                width={{ base: '100%', md: 'initial' }}
                style={{ borderRadius: '0.25rem', height: '50px' }}
                ref={buttonRef}
                onClick={submitHandler}
              >
                PURCHASE GIFT
              </StyledButton>
            </>
          )}
        </FormControl>
      </Flex>
    </Flex>
  );
}
