import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FaTwitter, FaFacebookF } from 'react-icons/fa';
import {
  Box,
  Tag,
  Text,
  Flex,
  TagLabel,
  TagIcon,
  Input,
} from '@chakra-ui/core';

import axios from '../../utils/axios';
import useScrollToTop from '../../hooks/useScrollToTop';
import useToastMod from '../../hooks/useToastMod';
import { rmail } from '../authentication/utils';

import PageIntro from '../shared/PageIntro';
import PageNav from '../shared/pageNav';
import SkipPrompt from '../shared/SkipPrompt';

import { StyledButton } from '../../styles/elements.styled';

interface IShareState {
  email: string;
  emails: string[];
  isLoading: boolean;
}

const actionKeys = ['Enter', ',', 'Tab'];

const isEmail = (text: IShareState['email']) => {
  return rmail.test(text);
};

const emailExists = (
  text: IShareState['email'],
  emails: IShareState['emails'],
) => {
  return emails.includes(text);
};

const parseInput = (
  inputText: string,
  eventKey: string,
  emails: IShareState['emails'],
): string[] => {
  const re = /[ ,\n;']/g;
  let emailsArr: IShareState['emails'] = [];

  if (inputText !== '') {
    if (re.test(inputText) || actionKeys.includes(eventKey)) {
      // split text into valid emails
      const splitEmails = inputText
        .split(re)
        .filter((item) => item && isEmail(item));

      // remove duplicates in the split emails
      const setArr = new Set(splitEmails);

      // remove emails that already exits in emails array
      emailsArr = [...setArr].filter((item) => !emailExists(item, emails));
    }
  }

  return emailsArr;
};

export default function Share() {
  const [state, setState] = useState<IShareState>({
    email: '',
    emails: [],
    isLoading: false,
  });

  const toast = useToastMod();
  const { refEl } = useScrollToTop();
  const history = useHistory<{ isSignup: boolean }>();

  const emailInputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setState((prev) => ({
      ...prev,
      email: target.value,
    }));
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const parsedEmails = parseInput(state.email, event.key, state.emails);
    if (parsedEmails.length > 0) {
      setState((prev) => ({
        ...prev,
        email: '',
        emails: [...prev.emails, ...parsedEmails],
      }));
    }
  };

  const handleKeyDown = (event: any) => {
    if (actionKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleDelete = (email: string) => {
    setState((prev) => ({
      ...prev,
      emails: prev.emails.filter((item) => email !== item),
    }));
  };

  const handleBtnClick = () => {
    setState((prev) => ({
      ...prev,
      isLoading: true,
    }));

    axios
      .post('invitations', {
        emails: state.emails,
      })
      .then(() => {
        setState({
          email: '',
          emails: [],
          isLoading: false,
        });
        toast({
          title: 'Emails Sent.',
          description: 'Thank you for spreading the word',
          position: 'top',
          status: 'success',
          duration: 10000,
          isClosable: true,
        });
        return history.push({
          pathname: '/follow-us',
          state: { isSignup: true },
        });
      })
      .catch(() => {
        setState((prev) => ({
          ...prev,
          isLoading: false,
        }));
        toast({
          title: 'Something went wrong',
          description: 'Please try again',
          position: 'top',
          status: 'error',
          duration: 10000,
          isClosable: true,
        });
      });
  };

  return (
    <Box ref={refEl} minHeight="calc(100vh - 18.25rem)">
      <Flex flexDir="column" alignItems="center" background={'white'}>
        <PageNav />
        <PageIntro
          heading="Spread the word"
          text="Like what you see (and read)? Share the Readeo love with your
          community, so everyone can enjoy storytime with their loved
          ones—near and far."
        />
      </Flex>
      <Flex justifyContent={'center'}>
        <Flex
          alignItems="center"
          flexDirection="column"
          height="fit-content"
          width={420}
          px="0.938rem"
        >
          <Flex
            flex="1 0 auto"
            flexWrap="wrap"
            alignContent="flex-start"
            width="100%"
            borderRadius="4px"
            background="white"
            border="1.5px solid #68B8CD"
            fontSize="1rem"
            minHeight="9.75rem"
            onClick={() => {
              if (emailInputRef.current) {
                emailInputRef.current.focus();
              }
            }}
          >
            {state.emails.map((email, idx) => (
              <Tag
                key={`${email}-${idx}`}
                variantColor="blue"
                height="fit-content"
                margin="0.2rem"
              >
                <TagLabel>{email}</TagLabel>
                <TagIcon
                  icon="small-close"
                  size="18px"
                  cursor="pointer"
                  onClick={() => handleDelete(email)}
                />
              </Tag>
            ))}
            <Input
              width="100%"
              minHeight="3.75rem"
              resize="none"
              as="textarea"
              type="text"
              dir="ltr"
              aria-haspopup="false"
              aria-autocomplete="list"
              aria-expanded="false"
              data-gramm_editor="false"
              ref={emailInputRef}
              value={state.email}
              onChange={handleChange}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
              padding="0.3rem"
              margin="0.2rem"
              border="0 none"
              display="inline-block"
              verticalAlign="baseline"
              _focus={{
                outline: 'none',
              }}
              placeholder={
                state.emails.length === 0
                  ? 'Enter email addresses separated by commas'
                  : '...'
              }
            />
          </Flex>
          <Flex
            position="relative"
            width="100%"
            mt="2rem"
            justify="center"
            borderTop="1px solid #A7DCE8"
          >
            <Text
              width="2rem"
              mx="auto"
              position="absolute"
              top="-0.6rem"
              left="0"
              right="0"
              fontFamily="Montserrat"
              fontSize="0.75rem"
              fontWeight={700}
              textAlign="center"
              color="#083261"
              backgroundColor="white"
            >
              OR
            </Text>
          </Flex>
          <Flex width="60%" justify="space-between" mt="1.5rem" p="1rem">
            {[1, 2].map((el) => (
              <Flex
                key={el}
                width="45%"
                p="0.5rem 0.3rem"
                justify="center"
                align="center"
                color="white"
                cursor="pointer"
                backgroundColor={el === 1 ? '#083261' : '#2699ED'}
                onClick={() =>
                  el === 1
                    ? window.open(
                        'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ffacebook.com%2Freadeo',
                        'facebook',
                        'blank, noopener, noreferrer',
                      )
                    : window.open(
                        'https://twitter.com/intent/tweet?screen_name=readeo&ref_src=twsrc%5Etfw',
                        'twitter',
                        'blank, noopener, noreferrer',
                      )
                }
              >
                {el === 1 ? <FaFacebookF /> : <FaTwitter />}
                <Text ml="0.3rem" fontSize="0.7rem" fontWeight={700}>
                  SHARE
                </Text>
              </Flex>
            ))}
          </Flex>
          <StyledButton
            width="100%"
            size="lg"
            isLoading={state.isLoading}
            loadingText="Spreading the word 🚀"
            onClick={
              state.emails.length !== 0
                ? handleBtnClick
                : () =>
                    history.push({
                      pathname: '/follow-us',
                      state: { isSignup: true },
                    })
            }
          >
            {state.emails.length !== 0 ? 'SHARE NOW' : 'NEXT STEP'}
          </StyledButton>
          <SkipPrompt />
        </Flex>
      </Flex>
    </Box>
  );
}
