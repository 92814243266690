import React from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { TiTick } from 'react-icons/ti';
import { Box, Flex, Text } from '@chakra-ui/core';

export interface INav {
  id: number;
  check?: boolean | undefined;
  label: string;
  route: string;
  match: boolean;
}

export default function PageNav() {
  // access location object
  const location = useLocation();

  const checkPath = (path: string[]) =>
    path.some((item) => location.pathname.includes(item));

  const navs: INav[] = [
    {
      id: 1,
      label: 'Select Gift',
      route: '/giftcard',
      check: checkPath(['details', 'checkout', 'confirmation']),
      match: useRouteMatch('/giftcard')?.isExact ? true : false,
    },
    {
      id: 2,
      label: 'Gift Details',
      route: '/giftcard/details',
      check: checkPath(['checkout', 'confirmation']),
      match: useRouteMatch('/giftcard/details') ? true : false,
    },
    {
      id: 3,
      label: 'Payment',
      route: '/giftcard/checkout',
      check: checkPath(['confirmation']),
      match: useRouteMatch('/giftcard/checkout') ? true : false,
    },
    {
      id: 4,
      label: 'Confirmation',
      route: '/giftcard/confirmation',
      match: useRouteMatch('/giftcard/confirmation') ? true : false,
    },
  ];
  return (
    <Flex
      width="100%"
      height="4.3125rem"
      mt={58}
      display={
        window.matchMedia('(max-width: 500px)').matches ? 'none' : 'flex'
      }
      align="center"
      justify="center"
      backgroundColor="brand.lightBlue"
    >
      <Flex width={720} position="relative" align="center" justify="center">
        <Box width="100%" position="absolute" borderTop="3px solid #083261" />
        <Flex width="100%" height="auto" justify="space-between">
          {navs.map(({ id, label, route, check, match }) => {
            return (
              <Flex
                key={id}
                width="24%"
                height="1.875rem"
                px="0.5rem"
                align="center"
                color={match ? 'white' : 'brand.secondary'}
                fontFamily="Montserrat"
                fontWeight="700"
                fontSize="0.75rem"
                lineHeight="1.875rem"
                letterSpacing="0.01em"
                zIndex={199}
                // cursor={match ? 'initial' : 'pointer'}
                border="3px solid #083261"
                borderRadius={'1.875rem'}
                backgroundColor={match ? '#083261' : '#D1F1F8'}
                // onClick={() => history.push({ pathname: route })}
              >
                {check ? (
                  <TiTick fontSize="1rem" />
                ) : (
                  <Text width="10%">{id}</Text>
                )}
                <Text width="90%" textAlign="center">
                  {label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
}
