import React from 'react';
import moment from 'moment';
import { Box, Flex, Text, Heading, Image } from '@chakra-ui/core';

import useScrollToTop from '../../../hooks/useScrollToTop';
import useQuery from '../../../hooks/useQuery';
import config from '../../../config';
import axios from '../../../utils/axios';
import SectionLabel from './sectionLabel';
import confirmationHeaderSplash from '../../../assets/images/rd-confirm-header.png';
import lineArrow from '../../../assets/images/line_arrow2.png';
import bookSplash from '../../../assets/images/rd-book-splash.png';
import bookSplashSm from '../../../assets/images/rd-book-splash-sm.png';

import { StyledButton } from '../../../styles/elements.styled';
import useScript from '../../../hooks/useScript';
import AttributionSurvey from './AttributionSurvey';

export default function Confirmation1() {
  const [attribution, setAttribution] = React.useState('');
  const { refEl } = useScrollToTop();

  let query = useQuery();

  const bookSplashArr = [
    {
      image: `url(${bookSplash})`,
      size: '174px',
    },
    {
      image: `url(${bookSplashSm})`,
      size: '114px',
    },
  ];

  useScript({
    src: `https://acuityplatform.com/Adserver/pxlj/1629962410399272318?crev=${query.get(
      'amount',
    )}&ordid=${query.get('sub')}`,
  });

  const confDetailsArr = [
    {
      label: 'My Family Plan',
      value: query.get('giftcard')
        ? query.get('plan') === 'yearly'
          ? '12 Month Gift Card Redeemed'
          : '6 Month Gift Card Redeemed'
        : `$${query.get('amount')} ${query.get('plan')}`,
      include: true,
    },
    {
      label: 'First Payment',
      value: moment(query.get('uno')).format('LLL'),
      include: query.get('giftcard') ? false : true,
    },
    {
      label: 'Promo Code',
      value: query.get('giftcardpromo')
        ? query.get('giftcardpromo')
        : query.get('coupon_desc'),
      include:
        !query.get('giftcardpromo') && !query.get('coupon_desc') ? false : true,
    },
  ];

  const clickHandler = () => {
    const isUpgrade = query.get('upgrade');
    if (!isUpgrade && attribution) {
      axios.post('/accounts/attributions', { attribution });
    }
    if (isUpgrade || query.get('guest')) {
      return (window.location.href = config.appUrl);
    }
    window.location.href = config.appUrl;
    //history.push({ pathname: '/buddies' });
  };

  return (
    <Flex
      ref={refEl}
      flexDir="column"
      align="center"
      background="white"
      minHeight={{ sm: 'calc(100vh - 18.25rem)' }}
    >
      <Box
        width="100vw"
        height="350px"
        mt={57}
        position="relative"
        background="inherit"
        backgroundImage={`url(${confirmationHeaderSplash})`}
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        backgroundSize="cover"
      >
        {bookSplashArr.map((box, idx) => (
          <Box
            display={['none', 'none', 'none', 'block', 'block']}
            width={box.size}
            height={box.size}
            position="absolute"
            bottom={idx === 0 ? '-5rem' : ''}
            top={idx === 1 ? '2rem' : ''}
            right={
              idx === 0
                ? ['', '', '', '-1.5rem', '10rem']
                : ['', '', '', '2rem', '15rem']
            }
            backgroundImage={box.image}
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            backgroundSize="cover"
            key={idx}
          />
        ))}
      </Box>
      <Flex
        position="relative"
        justify="center"
        align="center"
        width={['100%', '80%']}
        maxWidth="768px"
        //  paddingX="3%"
      >
        <Image
          src={lineArrow}
          height="536px"
          width={{ base: 0, md: 0, lg: '350px' }}
          position="absolute"
          bottom="55%"
          left={['-55%', '-20%', '-55%', '-40%']}
        />
        <Flex
          m="4rem auto"
          px={['1.0rem', '1.5rem', '0.94rem']}
          flexDirection="column"
          textAlign="center"
          w={['94%', '70%', '80%', '80%']}
          marginRight={{ base: '4%', md: '12%' }}
        >
          <Heading
            as="h3"
            size="lg"
            color="brand.secondary"
            letterSpacing={'0.01em'}
          >
            Thank you for joining Readeo!
          </Heading>
          <Text pt="20px">
            Here are your family plan details. We’d love to know how you heard
            about us—please answer our quick survey question in the dropdown
            menu below!
          </Text>

          {!query.get('upgrade') && (
            <AttributionSurvey setAttribution={setAttribution} />
          )}
          {query.get('guest') ? (
            <Text my="0.9rem">
              Account successfully created. <br />
              Click the link below to login and access your benefits
            </Text>
          ) : (
            <>
              <Text m="1.25rem 0 1rem"></Text>
              <SectionLabel label="Subscription Details" />
              {confDetailsArr.map(
                (item, idx) =>
                  item.include && (
                    <>
                      {idx === 2 && <SectionLabel label="Promotion" />}
                      <Flex justify="space-between">
                        <Text
                          flex="1 0 25%"
                          textAlign="left"
                          fontFamily="Montserrat"
                          fontWeight="400"
                          fontSize="0.9375rem"
                          lineHeight="1.875rem"
                          color="#083261"
                        >
                          {item.label}
                        </Text>
                        <Text
                          fontFamily="Montserrat"
                          fontWeight="bold"
                          fontSize="0.9375rem"
                          lineHeight="1.875rem"
                          color="#083261"
                        >
                          {item.value}
                        </Text>
                      </Flex>
                    </>
                  ),
              )}
              <img
                src={`https://listen.audiohook.com/b60fd6bd-73de-423d-b690-883963542faf/pixel.png?order=${query.get(
                  'sub',
                )}&value=${query.get('amount')}`}
                alt={''}
                style={{ display: 'none' }}
              ></img>
            </>
          )}
          <StyledButton width="100%" size="lg" onClick={clickHandler}>
            {query.get('upgrade') ? 'CONTINUE TO ACCOUNT' : 'START READING'}
          </StyledButton>
        </Flex>
      </Flex>
    </Flex>
  );
}
