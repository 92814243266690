import React from 'react';
import { FaStar } from 'react-icons/fa';
import { Box } from '@chakra-ui/core';

export const Star = ({ small }: { small?: boolean }) => {
  return (
    <Box py={1} pr={1}>
      <FaStar size={small ? '14px' : '18px'} color="#F5B515" />
    </Box>
  );
};
