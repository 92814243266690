import React from 'react';
import { Box, PseudoBox, Flex } from '@chakra-ui/core';

export function PlayButton(props: {
  size: string;
  borderWidth: string;
  playBorderWidth: string;
  marginLeft: string;
  position?: any;
  _hover?: any;
  onClick?(): void;
}) {
  const { size, borderWidth, playBorderWidth, marginLeft, ...rest } = props;

  return (
    <PseudoBox
      as={Flex}
      cursor="pointer"
      justifyContent="center"
      alignItems="center"
      borderWidth={borderWidth}
      borderStyle="solid"
      borderColor="white"
      borderRadius="full"
      height={size}
      width={size}
      top="35%"
      left="40%"
      transition="background 0.2s ease-in-out"
      {...rest}
    >
      {/* play */}
      <Box
        marginLeft={marginLeft}
        borderStyle="solid"
        boxSizing="border-box"
        borderWidth={playBorderWidth}
        borderColor="transparent transparent transparent #FFFFFF"
      />
    </PseudoBox>
  );
}
