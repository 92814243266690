import React from 'react';
import Slider from 'react-slick';
import { Box, Flex, Text, Grid, Heading } from '@chakra-ui/core';

import { reviewers } from './data';
import generateSettings from '../../utils/carouselSettings';

import { Star } from './Stars';
import Img from './Img';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function Reviews() {
  const settings = generateSettings({ xl: 5, lg: 3, md: 2, sm: 1 });
  return (
    <Flex width="100%" alignItems="center" marginY={{ base: 3, md: 5 }}>
      <Box width="100%" className="reviews">
        <Slider {...settings}>
          {reviewers.map((item, idx) => (
            <Box
              key={idx}
              height={{ base: '24rem', md: '28rem', lg: '26rem' }}
              paddingX={{ base: '0.25rem', md: '0.5rem', xl: '0.75rem' }}
            >
              <Grid
                gridTemplateRows="1fr 2fr"
                height="100%"
                background="#fff"
                borderRadius={5}
                padding={{ base: 3, md: 5 }}
                border="0.5px solid #241E46"
                boxShadow="0px 5px 8px rgba(0, 0, 0, 0.12)"
              >
                <Flex alignItems="center" paddingBottom={3}>
                  <Box
                    width="4.8rem"
                    marginRight={{ base: '0.9rem', md: '0.75rem' }}
                  >
                    <Img src={`/assets/${item.avatar}.png`} alt={item.name} />
                  </Box>
                  <Flex flexDirection="column">
                    <Flex
                      flexDirection="column"
                      paddingLeft={{ base: '0.3rem', md: 0 }}
                      alignItems="flex-start"
                    >
                      <Heading as="h4" fontSize="1.5rem" textAlign="left">
                        {item.name}
                      </Heading>
                      <Text
                        fontFamily="Montserrat, sans-serif"
                        color="gray.400"
                        fontSize="sm"
                        textAlign="left"
                      >
                        Verified Customer
                      </Text>
                    </Flex>
                    <Flex alignSelf="flex-start">
                      <Star small />
                      <Star small />
                      <Star small />
                      <Star small />
                      <Star small />
                    </Flex>
                  </Flex>
                </Flex>
                <Box textAlign={{ md: 'center' }} height="100%">
                  {item.review}
                </Box>
              </Grid>
            </Box>
          ))}
        </Slider>
      </Box>
    </Flex>
  );
}
