export const getJsonFromUrl = function (url: string) {
  let query = url.substr(1);
  let result: any[] = [];
  query.split('&').forEach(function (part) {
    let item = part.split('=');
    result = [
      ...result,
      {
        key: item[0],
        value: item[1],
      },
    ];
  });
  return result;
};
