import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Flex, Spinner, FormLabel, InputRightElement } from '@chakra-ui/core';

import { IState } from '../../authState.types';
import PasswordConditions from '../../PasswordConditions';

import { InputGroup, StyledInput } from '../../../../styles/elements.styled';

interface IAccountInfo {
  state: IState;
  changeHandler: (arg1: any) => void;
  toggleVisible: (arg1: any) => void;
}

export default function AccountInfo(props: IAccountInfo) {
  const { state, changeHandler, toggleVisible } = props;

  const matched = useRouteMatch('/giftcard/checkout');

  return (
    <>
      <Flex
        justifyContent={'space-between'}
        display={state.exists ? 'none' : 'flex'}
      >
        <InputGroup width={'48%'}>
          <StyledInput
            type="text"
            data-recurly="first_name"
            name="fname"
            id="fname"
            autoComplete="off"
            isRequired
            value={state.fname}
            onChange={changeHandler}
            style={{
              border:
                state && state.fname && state.fname.length
                  ? '0.05rem solid #48bb78'
                  : '0.05rem solid #68B8CD',
            }}
          />
          <FormLabel htmlFor="fname">First name*</FormLabel>
        </InputGroup>
        <InputGroup width={'48%'}>
          <StyledInput
            type="text"
            data-recurly="last_name"
            name="lname"
            id="lname"
            autoComplete="off"
            isRequired
            value={state.lname}
            onChange={changeHandler}
            style={{
              border:
                state && state.lname && state.lname.length
                  ? '0.05rem solid #48bb78'
                  : '0.05rem solid #68B8CD',
            }}
          />
          <FormLabel htmlFor="lname">Last name*</FormLabel>
        </InputGroup>
      </Flex>
      <InputGroup width="100%">
        <StyledInput
          type="text"
          name="email"
          id="email"
          autoComplete="off"
          isRequired
          value={state.email}
          onChange={changeHandler}
          style={{
            border: !state.email.length
              ? '0.05rem solid #68B8CD'
              : state.invalidE
              ? '0.05rem solid red'
              : '0.05rem solid #48bb78',
            // adding padding here to make uniform with password field
            paddingRight: '3rem',
          }}
        />
        <InputRightElement
          // adding id, to set appropriate styles
          id="spinner"
          children={state.checking ? <Spinner color="#f38530" /> : null}
        />
        <FormLabel htmlFor="email">Email*</FormLabel>
      </InputGroup>
      {!matched && (
        <span>
          <InputGroup width="100%">
            <StyledInput
              type={state.visible ? 'text' : 'password'}
              name="password"
              id="password"
              autoComplete="off"
              isRequired
              value={state.password}
              onChange={changeHandler}
              style={{
                // conditionally apply border style logic, if state.exists is false
                ...(!state.exists && {
                  border: !state.password?.length
                    ? '0.05rem solid #68B8CD'
                    : state.invalidP || state.short
                    ? '0.05rem solid red'
                    : '0.05rem solid #48bb78',
                }),
              }}
            />
            <FormLabel htmlFor="password">Password</FormLabel>
            {!state.visible ? (
              <FaEye onClick={toggleVisible} />
            ) : (
              <FaEyeSlash onClick={toggleVisible} />
            )}
          </InputGroup>

          <PasswordConditions
            password={state.password}
            display={state.exists ? 'none' : 'block'}
          />
        </span>
      )}
    </>
  );
}
