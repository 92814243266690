import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  ReactEventHandler,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@chakra-ui/core';

import _init from './_init';
import LibraryHeading from './Heading';
import AllBooks from './AllBooks';
import useScrollToTop from '../../hooks/useScrollToTop';

import books from './assets/books';

export default function LibraryAllBooks() {
  const { refEl } = useScrollToTop();
  const [state, setState] = useState(_init);

  const { location } = useHistory<{
    filter: {
      age?: string;
    };
  }>();

  useEffect(() => {
    // fetch initial books based on pageSize
    fetchBooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // set filter from SingleBook page on clicking label/tag
    if (location.state) {
      setState((prevState) => ({
        ...prevState,
        ...location.state.filter,
      }));
    }
  }, [location.state]);

  const observer = useRef<any>();

  const trigger = useCallback(
    (node) => {
      if (state.isFetching) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && state.books.length !== books.length) {
          setState((prevState) => ({ ...prevState, isFetching: true }));
          // simulate loading books in 0.5s
          setTimeout(fetchBooks, 500);
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.isFetching],
  );

  // handlers
  const fetchBooks = () => {
    setState((prevState) => ({
      ...prevState,
      books: prevState.books.concat(
        books.slice(prevState.start, prevState.start + prevState.pageSize),
      ),
      start: prevState.start + prevState.pageSize,
      isFetching: false,
    }));
  };

  const changeHandler: ReactEventHandler = (e: any) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <Box ref={refEl}>
      <LibraryHeading />
      <AllBooks state={state} changeHandler={changeHandler} trigger={trigger} />
    </Box>
  );
}
