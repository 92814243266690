import React from 'react';
import { NavLink } from 'react-router-dom';

export interface INavWrapperProps {
  to: string;
  title: string;
  exact?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}

const NavWrapper = (props: INavWrapperProps) => {
  const { to, title, exact, children, onClick } = props;

  return (
    <>
      {title === 'join' || title === 'log in' ? (
        <a href={to}>{children}</a>
      ) : (
        <NavLink to={to} key={title} exact={exact} onClick={onClick}>
          {children}
        </NavLink>
      )}
    </>
  );
};
export default NavWrapper;
