import axios from 'axios';
import useConfig from '../hooks/useConfig';

const { baseUrl } = useConfig();
const instance = axios.create({
  baseURL: baseUrl,
});
instance.defaults.withCredentials = true;

export default instance;
