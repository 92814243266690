import React from 'react';
import { Stack, Box, Image, Text } from '@chakra-ui/core';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import logo5 from 'assets/images/logo5.png';
import logo6 from 'assets/images/logo6.png';
import logo1 from 'assets/images/logo1.png';
import logo2 from 'assets/images/logo2.png';
import logo3 from 'assets/images/logo3.png';
import logo4 from 'assets/images/logo4.png';
import logo7 from 'assets/images/logo7.png';
import logo8 from 'assets/images/logo8.png';

const logos = [logo6, logo5, logo7, logo8, logo1, logo2, logo3, logo4];
const settings = {
  slidesToShow: 1.65,
  slidesToScroll: 1,
  autoplay: true,
  infinite: true,
  autoplaySpeed: 0,
  speed: 2500,
  centerMode: true,
};

export default function CompanyLogos() {
  const renderedLogos = logos.map((logo: string, indx) => {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        maxWidth="inherit"
        key={indx}
        spacing={10}
        paddingX={1}
      >
        <Image
          src={logo}
          alt="logo"
          height="6rem"
          width="80%"
          style={{
            objectPosition: 'center',
            objectFit: 'contain',
          }}
        />
      </Stack>
    );
  });

  return (
    <>
      <Text
        color="#6b84a0"
        textAlign="center"
        fontSize="14px"
        fontWeight="bold"
        mb={{ base: '1rem', lg: '1rem' }}
        mt="12px"
      >
        Trusted By
      </Text>
      <Box width="inherit" bg="#C5E8F3">
        <Slider {...settings}>{renderedLogos}</Slider>
      </Box>
    </>
  );
}
