import React from 'react';
import { Flex, Heading, Text } from '@chakra-ui/core';

import giftcard from './../../../assets/images/rd-giftcard-new.png';

export default function CardPreview({ data }: any) {
  return (
    <Flex width="100%" mt="2.5rem">
      <Flex
        width={{ base: '100%', lg: '100%' }}
        direction={{ base: 'column-reverse', md: 'row' }}
        justify="space-between"
      >
        <Flex
          width={{ base: '100%', md: '40%', lg: '30%' }}
          textAlign="left"
          direction="column"
        >
          {data && data.amount && (
            <Heading as="h4" size="md">
              Readeo ${data.amount} gift card{' '}
            </Heading>
          )}
          {data && data.name && data.name.length > 1 && (
            <Text mt="1rem">
              <strong>To:</strong>
              <br />
              {data.name}
            </Text>
          )}
          {data && data.gifter_name && (
            <Text mt="1rem">
              <strong>From:</strong>
              <br />
              {data.gifter_name}
            </Text>
          )}
          {data && data.email && (
            <Text mt="1rem">
              <strong>Delivery method:</strong>
              <br />
              Email gift to {data.email}
            </Text>
          )}
          {data && data.payment && (
            <Text mt="1rem">
              <strong>Payment method:</strong>
              <br />
              ********12345
            </Text>
          )}
          {data && (
            <Text mt="1rem">
              <strong>Deliver:</strong>
              <br />
              {data.deliver_at
                ? new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                  }).format(new Date(data.deliver_at))
                : ' Immediately'}
            </Text>
          )}
          {data && data.personal_message && (
            <Text mt="1rem">
              <strong>Personal Message:</strong>
              <br />
              {data.personal_message}
            </Text>
          )}
          {
            <Text mt="1rem">
              Notice anything missing? Need to make a change?{' '}
              <a
                href="mailto:support@readeo.com"
                style={{ textDecoration: 'underline', color: 'blue' }}
              >
                Contact us
              </a>
            </Text>
          }
        </Flex>
        <Flex
          width={{ base: '100%', md: '55%', lg: '65%' }}
          direction="column"
          mb={{ base: '2rem', md: '0rem' }}
          backgroundColor="#FAFAFA"
        >
          <Text backgroundColor="#F1F1F1" color="#888">
            Preview
          </Text>
          {data && data.gifter_name && (
            <Text my="1rem" px="1rem" textAlign="center">
              {data.gifter_name} has gifted you a ${data.amount} Readeo
              membership gift card
            </Text>
          )}
          <Flex
            width={{ base: '96%', lg: '70%', xl: '60%' }}
            height={{ base: '16rem', lg: '19rem' }}
            alignSelf="center"
            marginBottom="2rem"
            background="inherit"
            backgroundImage={`url(${giftcard})`}
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            backgroundSize="cover"
            borderRadius="1.2rem"
            boxShadow="0 0.875rem 2.5rem rgb(0 0 0 / 0.25)"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
