import { Flex, Text, Box, Image } from '@chakra-ui/core';
import React, { useState } from 'react';

interface IStep {
  number: string;
  img: any;
  title: string;
  info: string;
}

type MoreInfoType = {
  svg: any;
  title: string;
  info: string;
  styles?: any;
};

interface IMainStep extends IStep {
  moreInfo?: MoreInfoType[];
}

const volunteerSteps: IMainStep[] = [
  {
    number: 'step 1',
    img: require('../../assets/images/volunteer1.webp'),
    title: 'Set up your FREE account',
    info:
      'Sign up using the Teacher Code located on the Guest Reader Program flyer, or in the email from your child’s teacher.',
  },
  {
    number: 'step 2',
    img: require('../../assets/images/volunteer2.webp'),
    title: 'Choose a timeslot',
    info:
      'Teachers set the schedule, you pick the available slot between your afternoon meetings — and the book you’d like to read! We’ll handle the rest.',
  },
  {
    number: 'step 3',
    img: require('../../assets/images/volunteer3.webp'),
    title: 'Enjoy storytime together!',
    info:
      'While most volunteer sessions last about 10 minutes, you’ll have plenty of time to read, answer questions, and chat with the class.',
  },
];

const teacherSteps: IMainStep[] = [
  {
    number: 'step 1',
    img: require('../../assets/images/teacher1.webp'),
    title: 'Create a FREE account',
    info:
      'Once you’ve logged in, you can setup and manage your class’ schedule and create customized Bookshelves. ',
    moreInfo: [
      {
        svg: require('../../assets/svg/calendar.svg'),
        title: 'Schedule management',
        info:
          ' – Add dates and times volunteers can reserve to read to your class.',
      },
      {
        svg: require('../../assets/svg/bookshelf.svg'),
        title: 'Bookshelf curation',
        info:
          ' – Create digital shelves filled with your class’ favorite books about animals, careers, civil rights, and everything in between!',
      },
    ],
  },
  {
    number: 'step 2',
    img: require('../../assets/images/teacher2.webp'),
    title: 'Invite volunteers to join',
    info:
      'We make it easy to invite your student’s family members to join the program! Once volunteers create their FREE account, they can sign up for any open calendar slots. ',
    moreInfo: [
      {
        svg: require('../../assets/svg/onboard.svg'),
        title: 'Volunteer onboarding',
        info:
          ' – We provide multiple ways to help onboard your student’s family and friends!',
      },
      {
        svg: require('../../assets/svg/plus.svg'),
        title: 'Volunteer management',
        info:
          ' – We’ll notify your volunteers every time an open time slot has becomes available. ',
      },
    ],
  },
  {
    number: 'step 3',
    img: require('../../assets/images/teacher3.webp'),
    title: 'Enjoy storytime together!',
    info:
      'We take care of the meeting link, books, and video chatting — you and your class can simply enjoy virtual storytime.  ',
    moreInfo: [
      {
        svg: require('../../assets/svg/mail.svg'),
        title: 'Email reminders',
        info:
          ' – We’ll remind both you and your guest of their upcoming BookChat.',
      },
      {
        svg: require('../../assets/svg/bkchat.svg'),
        title: 'BookChats',
        info:
          ' – We handle all the technical requirements to ensure everyone has a safe, easy, and fun experience. ',
        styles: {
          background: '#6B4799',
          borderRadius: '50%',
        },
      },
    ],
  },
];

const GettingStarted = () => {
  const [isTeacher, setIsTeacher] = useState(false);

  const StepsComponent = ({ steps }: { steps: IMainStep[] }) => {
    return (
      <Flex
        justifyContent={{ base: 'space-between', md: 'space-evenly' }}
        mt="4rem"
        alignSelf="center"
        width="100%"
        flexDir={{ base: 'column', md: 'row' }}
      >
        {steps.map((step: IMainStep, idx) => (
          <Flex
            minHeight={{ md: '400px' }}
            flexDir="column"
            width={{ base: '100%', md: '30%' }}
            position="relative"
            mb={{ base: '2rem', md: 'initial' }}
            alignItems={{ base: 'center', md: 'initial' }}
          >
            <Flex
              width={{ base: '100px', md: '125px' }}
              borderRadius="42px"
              height={{ base: '38px', md: '46px' }}
              bg={isTeacher ? '#6B4799' : '#083d79'}
              alignItems="center"
              alignSelf="center"
              justifyContent="center"
              mb="2rem"
              key={idx}
              position={{ base: 'absolute', md: 'relative' }}
              top={{ base: '145px', md: 'unset' }}
              left={{ base: '10px', md: 'unset' }}
            >
              <Text
                p="1rem"
                color="white"
                textTransform="uppercase"
                fontSize="19px"
                fontWeight={700}
                fontStyle="italic"
                textAlign="center"
              >
                {step.number}
              </Text>
            </Flex>
            <Image
              src={step.img}
              height={{ base: '180px', md: 'initial' }}
              width={{ base: '300px', md: 'initial' }}
              alt="step svg"
            />

            <Text
              fontWeight={600}
              fontSize={{ base: '1.5rem', md: '1.8rem' }}
              my="1rem"
              mb={{ base: '1 rem', md: '10px' }}
              color="#083d79"
              textAlign={{ base: 'left' }}
              lineHeight="37px"
            >
              {step.title}
            </Text>
            <Text fontSize={{ base: '16px', md: '18px' }}>{step.info}</Text>
            <Flex flexDir="column">
              {step?.moreInfo?.map((more: MoreInfoType, idx: number) => (
                <Flex key={idx}>
                  <Image
                    src={more.svg}
                    height="30px"
                    width="30px"
                    mt="1rem"
                    mr="1rem"
                    style={more.styles}
                  />
                  <Text
                    fontWeight={700}
                    fontSize="16px"
                    mt="1rem"
                    color="#222222"
                  >
                    {more.title}
                    <Text as="span" fontWeight={400}>
                      {more.info}
                    </Text>
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Flex>
        ))}
      </Flex>
    );
  };

  return (
    <Flex flexDir="column" p="1rem">
      <Flex
        color="#000000"
        flexDir="column"
        maxWidth="1300px"
        justifyContent="center"
        alignSelf="center"
        width="100%"
        mb={{ md: '4rem' }}
        pt="2rem"
      >
        <Text
          textAlign="center"
          fontSize="30px"
          fontWeight={600}
          my="1rem"
          color={isTeacher ? '#6B4799' : '#083d79'}
          lineHeight="35px"
        >
          Getting started is easy as 1-2-read!
        </Text>
        <Flex
          justifyContent="space-around"
          alignSelf="center"
          mt="1rem"
          width={{ base: '100%', md: '424px' }}
        >
          <Box
            width="100%"
            onClick={() => setIsTeacher(false)}
            cursor="pointer"
          >
            <Text
              textAlign="center"
              fontSize={{ base: '16px', md: '18px' }}
              fontWeight={500}
              color={isTeacher ? '#00000066' : 'initial'}
            >
              {!isTeacher ? <Text as="span">FOR</Text> : null} VOLUNTEERS
            </Text>
            <Box
              height="10px"
              background={isTeacher ? '#cbcbcb' : '#083d79'}
              borderRadius={isTeacher ? '42px 0 0 42px' : '42px'}
              mr={isTeacher ? 'unset' : '-2px'}
            ></Box>
          </Box>
          <Box width="100%" onClick={() => setIsTeacher(true)} cursor="pointer">
            <Text
              textAlign="center"
              fontSize={{ base: '16px', md: '18px' }}
              fontWeight={500}
              color={!isTeacher ? '#00000066' : 'initial'}
            >
              {isTeacher ? <Text as="span">FOR</Text> : null} TEACHERS
            </Text>
            <Box
              height="10px"
              width="100%"
              background={!isTeacher ? '#cbcbcb' : '#6b4799'}
              borderRadius={!isTeacher ? '0 42px 42px 0' : '42px'}
              ml={!isTeacher ? 'unset' : '-2px'}
            ></Box>
          </Box>
        </Flex>
        {!isTeacher ? (
          <StepsComponent steps={volunteerSteps} />
        ) : (
          <StepsComponent steps={teacherSteps} />
        )}
      </Flex>
    </Flex>
  );
};

export default GettingStarted;
